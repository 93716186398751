import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import PersonalSpace from '../molecules/PersonalSpace'

const useStyles = makeStyles((theme) => ({
  codeBlock: {
    paddingLeft: theme.layout.padding.p10,
    paddingTop: theme.layout.padding.p5,
    paddingRight: theme.layout.padding.p10,
    paddingBottom: theme.layout.padding.p5,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: ({ scroll }) => scroll ? '30vh' : undefined,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.gray.dark,
    fontWeight: theme.typography.weights.light,
    fontSize: theme.typography.fontSizes.sm
  }
}))

const CodeBlock = ({ code, title, scroll = false, style }) => {
  const classes = useStyles({ scroll })
  const _code = useMemo(() => {
    if (typeof code !== 'string') {
      return JSON.stringify(code, null, 2)
    }
    return code
  }, [code])

  return (
    <div style={style}>
      {title ? (<header>{title}</header>) : null}
      <article className={classes.codeBlock}>
        <pre>{_code}</pre>
        <PersonalSpace />
      </article>
    </div>
  )
}

CodeBlock.propTypes = {
  style: PropTypes.object,
  code: PropTypes.any,
  title: PropTypes.string,
  scroll: PropTypes.bool
}

export default CodeBlock
