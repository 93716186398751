import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import FlexRow from '../../molecules/FlexRow'
import Icon from '../../atoms/Icon'
import Loading from '../AddAccount/Loading'

const iconSizes = {
  sm: '36px',
  md: '50px',
  lg: '75px'
}

const sizes = (theme) => ({
  sm: {
    '& .__metric': {
      fontSize: theme.typography.fontSizes.h1
    }
  },
  md: {
    '& .__metric': {
      fontSize: '50px'
    }
  },
  lg: {
    '& .__metric': {
      fontSize: '75px'
    }
  }
})

const useStyles = makeStyles((theme) => ({
  kdpMetric: ({ size }) => ({
    ...(sizes(theme)[size] || {}),
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    '& .__header': {
      fontWeight: theme.typography.weights.light
    }
  })
}))

const useMetric = (keyDatapoint) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return {
      limit: 1,
      filters: {
        clientId: [{ op: 'eq', value: clientId }]
      },
      includes: {
        keyDatapoints: [keyDatapoint]
      }
    }
  }, [keyDatapoint, clientId])

  const clientResponse = useSearchClients(query)

  return clientResponse
}

function KDPMetric ({
  keyDatapoint,
  format,
  className,
  hideName,
  style,
  size,
  tooltip,
  icon,
  headerClassName,
  headerStyles
}) {
  const classes = useStyles({ size })
  const { data, isLoading } = useMetric(keyDatapoint)
  const datapoint = useMemo(() => {
    if (!data?.data?.length) return null

    return data.data.at(0).keyDatapoints[keyDatapoint]
  }, [data, keyDatapoint])
  // eslint-disable-next-line no-unused-vars
  const { formatter } = useFormattingContext()

  if (isLoading) {
    return (
      <div className={clsx(className, classes.kdpMetric)}>
        <Loading size={iconSizes[size]} />
      </div>
    )
  }

  const OuterComponent = tooltip ? Tooltip : Fragment

  return (
    <OuterComponent title={tooltip}>
      <div className={clsx(className, classes.kdpMetric)} style={style}>
        {!hideName ? (
          <div className={clsx('__header', headerClassName)} style={headerStyles}>{datapoint?.displayName}</div>
        ) : null}
        <FlexRow>
          {icon ? (
            <Icon name={icon} customSize={iconSizes[size]} />
          ) : null}
          <div className='__metric'>{formatter(datapoint?.value, format)}</div>
        </FlexRow>
      </div>
    </OuterComponent>
  )
}

KDPMetric.propTypes = {
  keyDatapoint: PropTypes.string,
  icon: PropTypes.string,
  format: PropTypes.string,
  className: PropTypes.string,
  hideName: PropTypes.bool,
  style: PropTypes.any,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  tooltip: PropTypes.string,
  headerClassName: PropTypes.string,
  headerStyles: PropTypes.object
}

KDPMetric.defaultProps = {
  size: 'md'
}

export default KDPMetric
