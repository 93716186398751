import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import FormattingProvider from '../../organisms/FormattingProvider'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import NotAvailable from '../../organisms/NotAvailable'
import { useIsDenaliActive } from '../../../hooks/featureChecks'
import IndexView from './IndexView'
import IntegratorHome from './Integrator/IntegratorHome'
import AlertsHome from './Alerts/AlertsHome'
import AnalysisHome from './Analysis/AnalysisHome'
import IngestionHome from './Ingestion/IngestionHome'
import OrchestratorHome from './Orchestrator/OrchestratorHome'
import IntegrationDomains from './Integrator/Domains'
import IntegrationJobs from './Integrator/IntegrationJobs'
import BatchList from './Integrator/BatchList'
import BatchDetails from './Integrator/BatchDetails'
import DenaliProvider from './DenaliProvider'
import QueryHome from './Integrator/Query'
import ViewsHome from './Analysis/Views/ViewsHome'
import ViewDetails from './Analysis/Views/ViewDetails/ViewDetails'
import QueryDenaliReport from './Analysis/Views/QueryDenaliReport'
import PipelinesHome from './Analysis/Pipelines/PipelinesHome'
import EditPipeline from './Analysis/Pipelines/EditPipeline'
import CatalogHome from './Catalog/CatalogHome'
import EventLogsHome from './Catalog/EventLogs/EventLogsHome'
import DataSourceList from './Catalog/DataSourceList/DataSourceList'
import DataSourceDetails from './Catalog/DataSourceDetails'
import ConnectionsList from './Integrator/ConnectionsList'
import SourceDetailsHome from './Integrator/SourceDetails/SourceDetailsHome'
import JobDetailsHome from './Integrator/JobDetails/JobDetailsHome'
import SystemJobsHome from './Ingestion/SystemJobs/SystemJobsHome'
import ListJobGroups from './Ingestion/SystemJobGroups/ListJobGroups'
import JobGroupDetails from './Ingestion/SystemJobGroups/JobGroupDetails'

const DenaliIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const isDenaliActive = useIsDenaliActive()

  if (!isDenaliActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Denali'>
      <FormattingProvider>
        <DenaliProvider>
          <Switch>
            <SecureRoute path={path} exact>
              <IndexView root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/alerts`} exact>
              <AlertsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis`} exact>
              <AnalysisHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis`} exact>
              <AnalysisHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/views`} exact>
              <ViewsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/views/:viewId`} exact>
              <ViewDetails root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/views/:reportId/query`} exact>
              <QueryDenaliReport root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/pipelines`} exact>
              <PipelinesHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/pipelines/new`} exact>
              <EditPipeline root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/analysis/pipelines/:pipelineId`} exact>
              <EditPipeline root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/catalog`} exact>
              <CatalogHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/catalog/sources`} exact>
              <DataSourceList root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/catalog/sources/:datasourceId`} exact>
              <DataSourceDetails root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/catalog/events`} exact>
              <EventLogsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/ingestion`} exact>
              <IngestionHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/ingestion/system-jobs`} exact>
              <SystemJobsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/ingestion/system-jobs/groups`} exact>
              <ListJobGroups root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/ingestion/system-jobs/groups/:jobGroupCode`} exact>
              <JobGroupDetails root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator`} exact>
              <IntegratorHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/connections`} exact>
              <ConnectionsList root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/connections/:connectionCode`} exact>
              <ConnectionsList root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/domains`} exact>
              <IntegrationDomains root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/sources/:integrationSourceId`} exact>
              <SourceDetailsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/jobs/:jobConfigurationId`} exact>
              <JobDetailsHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/jobs/:jobConfigurationId/query`} exact>
              <QueryHome root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/jobs`} exact>
              <IntegrationJobs root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/batches`} exact>
              <BatchList root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/integrator/batches/:batchId`} exact>
              <BatchDetails root={path} />
            </SecureRoute>
            <SecureRoute path={`${path}/orchestration`} exact>
              <OrchestratorHome root={path} />
            </SecureRoute>
          </Switch>
        </DenaliProvider>
      </FormattingProvider>
    </ErrorBoundary>
  )
}

export default DenaliIndex
