import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  basicTable: {
    width: '100%',
    '& thead > tr > th': {
      fontWeight: theme.typography.weights.bold,
      textAlign: 'left'
    },
    '& tbody > tr > td': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function BasicTable ({ children, className, ...tableProps }) {
  const classes = useStyles()
  return (
    <table className={clsx(className, classes.basicTable)} {...tableProps}>
      {children}
    </table>
  )
}

BasicTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default BasicTable
