import React, { useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../organisms/OperationalTable'

const sourceColumns = [
  { id: 'connectionName', accessor: 'connection.displayName', Header: 'Connection' },
  { id: 'namespace', accessor: 'namespace', Header: 'Namespace' },
  { id: 'domain', accessor: 'domain', Header: 'Domain' },
  { id: 'sourceKey', accessor: 'sourceKey', Header: 'Source Key' },
  { id: 'label', accessor: 'label', Header: 'Label' }
]
const defaultSort = [{
  id: 'sourceKey', desc: false
}]

function IntegrationSourceList ({ root, domain, sources }) {
  const domainSources = useMemo(() => {
    return (sources || []).filter(x => x.domain === domain)
  }, [domain, sources])

  const history = useHistory()
  const onRowClick = useCallback((row) => {
    if (!row.original?.integrationSourceId) return
    history.push(`${root}/integrator/sources/${row.original.integrationSourceId}`)
  }, [history, root])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OperationalTable
          variant='v2'
          data={domainSources}
          columns={sourceColumns}
          onRowClick={onRowClick}
          defaultSort={defaultSort}
          hideFooter
        />
      </Grid>
    </Grid>
  )
}

IntegrationSourceList.propTypes = {
  domain: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.shape({
    sourceKey: PropTypes.string,
    domain: PropTypes.string,
    label: PropTypes.string
  })),
  root: PropTypes.string
}

export default IntegrationSourceList
