import React, { useMemo } from 'react'
import { makeStyles, Chip, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import Icon from '../../atoms/Icon'
import PanelTrigger from './PanelTrigger'
import { useInternalReportContext } from './InternalStatusReportContext'

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  chip: {
    marginLeft: '10px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: '20px'
  },
  description: {
    fontSize: theme.typography.fontSizes.lg
  },
  descriptionWrap: {
    '&.MuiTooltip-popper': {
      '& .MuiTooltip-tooltipPlacementTop': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .MuiTooltip-tooltipPlacementBottom': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .__more': {
        width: '100%',
        textAlign: 'center'
      }
    }
  }
}))

const mapDescription = (desc, max) => {
  return desc?.length > max ? `${desc.slice(0, max)}...` : desc
}

function DisplayName ({ className, item, parent, maxTooltip }) {
  const { formatter } = useFormattingContext()
  const { openDescriptionPanel } = useInternalReportContext()
  const hasChildren = useMemo(() => item.items?.length > 0, [item])
  const itemFactor = useMemo(() => item.factors ? formatter(item.factors.value, item.factors.format) : null, [item, formatter])

  const classes = useStyles()
  const max = maxTooltip

  if (item.threadKey) {
    return (
      <PanelTrigger
        className={clsx(className)}
        item={item}
        parent={parent}
        disabled={hasChildren}
      >
        <div>
          <span className='__name-main'>{item.displayName}</span>
          {item.displayNameEx ? <span className='__name-extension'>{' ' + item.displayNameEx}</span> : null}
        </div>
        {item.description ? (
          <Tooltip
            classes={{
              popper: classes.descriptionWrap
            }}
            placement='top'
            title={(
              <>
                <ReactMarkdown className={classes.description} children={mapDescription(item.description, max)} remarkPlugins={[remarkGfm]} />
                {item?.description?.length > max ? (<div className='__more'><strong>Click info icon to view more</strong></div>) : null}
              </>
            )}
          >
            <span role='button' onClick={() => openDescriptionPanel(item.description, item.displayName)}>
              <Icon name='info' customSize={20} />
            </span>
          </Tooltip>
        ) : null}
        {itemFactor ? (
          <Chip className={classes.chip} size='small' label={itemFactor} />
        ) : null}
      </PanelTrigger>
    )
  }

  return (
    <div className={clsx(className)}>
      <div>
        <span className='__name-main'>{item.displayName}</span>
        {item.displayNameEx ? <span className='__name-extension'>{' ' + item.displayNameEx}</span> : null}
      </div>
      {item.description ? (
        <Tooltip
          classes={{
            popper: classes.descriptionWrap
          }}
          placement='top'
          title={(
            <>
              <ReactMarkdown className={classes.description} children={mapDescription(item.description, max)} remarkPlugins={[remarkGfm]} />
              {item?.description?.length > max ? (<div className='__more'><strong>Click info icon to view more</strong></div>) : null}
            </>
          )}
        >
          <span role='button' onClick={() => openDescriptionPanel(item.description, item.displayName)}>
            <Icon name='info' customSize={20} />
          </span>
        </Tooltip>
      ) : null}
      {itemFactor ? (
        <Chip className={classes.chip} size='small' label={itemFactor} />
      ) : null}
    </div>
  )
}

DisplayName.propTypes = {
  className: PropTypes.string,
  item: PropTypes.any,
  parent: PropTypes.any,
  maxTooltip: PropTypes.number
}

DisplayName.defaultProps = {
  maxTooltip: 512
}

export default React.memo(DisplayName)
