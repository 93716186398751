import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../../../organisms/OperationalTable'
import QueryTableCell from '../../../../shared/QueryTableCell'
import { flatten } from '../../../../../../../utils/flatten'

function AutoTable ({ data }) {
  const columns = useMemo(() => {
    const allColumns = (data || []).reduce((p, c) => {
      const cFlat = flatten(c)
      const fields = Object.entries(cFlat)
      fields.forEach(([key, value]) => {
        if (key in p) return
        p[key] = typeof value
      })

      return p
    }, {})

    return Object.entries(allColumns).map(([key]) => {
      return {
        id: key,
        Header: key,
        accessor: key,
        format: 'title',
        Cell: QueryTableCell
      }
    })
  }, [data])
  const defaultSort = [{ id: 'levelId', desc: false }]

  if (!data?.length) {
    return null
  }

  return (
    <OperationalTable
      mode='client'
      variant='v2'
      columns={columns}
      defaultSort={defaultSort}
      data={data}
      hideFooter
      autoSticky
    />
  )
}

AutoTable.propTypes = {
  data: PropTypes.array
}

export default AutoTable
