import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  scrollContent: {
    overflowY: 'auto'
  }
}))

function ScrollContent ({ children, maxHeight }) {
  const classes = useStyles()

  return (
    <div className={classes.scrollContent} style={{ maxHeight }}>
      {children}
    </div>
  )
}

ScrollContent.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ScrollContent
