import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Loading from '../../../../../../molecules/Loading'
import ErrorDisplay from '../../../../../../molecules/ErrorDisplay'
import CodeBlock from '../../../../../../atoms/CodeBlock'
import FadeIn from '../../../../../../molecules/Transitions/FadeIn'
import { usePipelineEditor } from '../PipelineEditorContext'
import AutoTable from './AutoTable'
import RequestValidationErrors from './RequestValidationErrors'

const useStyles = makeStyles((theme) => ({
  resultPanelBody: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.dark,
    boxSizing: 'border-box',
    overflowY: 'auto',
    '&.__err': {
      padding: theme.layout.padding.p10
    }
  }
}))

function ResultPanelBody () {
  const classes = useStyles()
  const { responseFetching, response, responseError, resultMode, refetch, requestValidation } = usePipelineEditor()

  if (requestValidation?.isValid === false) {
    return (
      <div className={classes.resultPanelBody}>
        <RequestValidationErrors requestValidation={requestValidation} />
      </div>
    )
  }

  if (responseError) {
    return (
      <div className={clsx(classes.resultPanelBody, '__err')}>
        <ErrorDisplay error={responseError} onRetry={refetch} />
      </div>
    )
  }

  if (responseFetching) {
    return (
      <div className={classes.resultPanelBody}>
        <Loading />
      </div>
    )
  }

  return (
    <FadeIn key={resultMode} className={classes.resultPanelBody}>
      {resultMode === 'table' ? (
        <AutoTable data={response} />
      ) : (
        <CodeBlock code={response} />
      )}
    </FadeIn>
  )
}

export default ResultPanelBody
