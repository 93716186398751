import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useComments } from '../Comments/CommentContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { useInternalReportContext } from './InternalStatusReportContext'
import StatusReportCommentTitle from './StatusReportCommentTitle'

const useStyles = makeStyles((theme) => ({
  panelTrigger: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function PanelTrigger ({ className, item, parent, tab, children, disabled }) {
  const { clientId } = useAppContext()
  const { showCommentThread, showAttachments } = useComments()
  const { onRefetch, statuses } = useInternalReportContext()
  const classes = useStyles()
  const action = useMemo(() => {
    if (disabled) return () => {}
    if (tab === 'attachments') return showAttachments
    return showCommentThread
  }, [tab, showCommentThread, showAttachments, disabled])

  return (
    <div
      className={clsx(className, classes.panelTrigger)}
      role='button'
      onClick={() => action({
        clientId,
        levelTypeId: 278,
        levelId: item.statusTemplateItemId,
        threadKey: item.threadKey,
        disabled: item.isCustom,
        title: StatusReportCommentTitle,
        titleProps: { item, parent, statuses, clientId },
        onRefetch
      })}
    >
      {children}
    </div>
  )
}

PanelTrigger.propTypes = {
  className: PropTypes.string,
  item: PropTypes.any,
  parent: PropTypes.any,
  tab: PropTypes.oneOf(['comments', 'attachments']),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
}

PanelTrigger.defaultProps = {
  tab: 'comments'
}

export default React.memo(PanelTrigger)
