import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import OperationalTable from '../../../../organisms/OperationalTable'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'
import { dataCatalog } from '../../../../../api/coreData'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import EmptySection from '../../../../atoms/EmptySection'
import QueryTableCell from '../../shared/QueryTableCell'

const columnsConfig = {
  columns: [
    { id: 'eventLogId', accessor: 'eventLogId', Header: 'ID' },
    { id: 'eventDate', accessor: 'eventDate', Header: 'Event Date', format: 'timestamp' },
    { id: 'eventType', accessor: 'eventType', Header: 'Event Type' },
    { id: 'message', accessor: 'message', Header: 'Message' },
    { id: 'severity', accessor: 'severity', Header: 'Level' },
    { id: 'data', accessor: 'data', Header: 'Data', Cell: QueryTableCell },
    { id: 'actorName', accessor: 'actorName', Header: 'Actor' }
  ],
  defaultSort: [{
    id: 'eventDate', desc: true
  }]
}

function EventLogsHome () {
  const query = useMemo(() => {
    return {
      includes: {
        actorName: true
      },
      take: 100,
      skip: 0
    }
  }, [])
  const { data, isLoading, refetch, isFetching } = dataCatalog.useGetCatalogEventLogs(query)
  const _columns = useConfigColumns({
    columnsConfig
  })

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!data?.logs?.length) {
    return (
      <AdminPage>
        <EmptySection title='No Data Available' description='' />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <div />
            <div>
              <SydButton size='sm' variant='primary' icon='sync' onClick={refetch} processing={isFetching}>Refresh</SydButton>
            </div>
          </FlexRow>
        </Grid>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            data={data?.logs ?? []}
            isLoading={isLoading || isFetching}
            itemName='Logs'
            columns={_columns.columns}
            defaultSort={_columns.defaultSort}
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

EventLogsHome.propTypes = {
}

export default EventLogsHome
