import React, { useCallback, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import {
  useCheckMigrationsMutation,
  useSearchIntegrationJobs, useSyncMutation
} from '../../../../../api/integrations'
import Switch from '../../../../molecules/Switch'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import Card from '../../../../molecules/Card'
import NotAvailable from '../../../../organisms/NotAvailable'
import CodeBlock from '../../../../atoms/CodeBlock'
import FlexRow from '../../../../molecules/FlexRow'
import { useDenaliContext } from '../../DenaliProvider'
import { SUCCESS_ALERT } from '../../../../../constants'
import SydActionMenu from '../../../../commonDesign/SydActionMenu'
import JobSources from './JobSources'
import JobPrimaryKeys from './JobPrimaryKeys'
import JobIncludedFields from './JobIncludedFields'
import EnableJobDialog from './EnableJobDialog'
import JobTableInfo from './JobTableInfo'

const useIntegrationActions = () => {
  const denaliContext = useDenaliContext()
  const { mutateAsync: syncJob } = useSyncMutation()
  const { mutateAsync: migrate } = useCheckMigrationsMutation()

  const handleSync = useCallback(async (job, options = {}) => {
    const force = options?.force || false
    const result = await syncJob({
      connectionId: job.integrationSource?.connectionId,
      domain: job.domain,
      objects: [job.jobKey],
      onlyEnabledObjects: !force
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`/admin/denali/integrator/batches/${result.batchId}`}>Sync started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [syncJob, denaliContext])

  const handleMigrate = useCallback(async (job) => {
    const result = await migrate({
      connectionId: job.integrationSource?.connectionId,
      domain: job.domain,
      objects: [job.jobKey]
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`/admin/denali/integrator/batches/${result.batchId}`}>Migration Check started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [migrate, denaliContext])

  return {
    handleSync,
    handleMigrate
  }
}

const useJobActions = ({ enableJobDialog, job }) => {
  const handleChangeJobStatus = useCallback((job) => {
    enableJobDialog.current.open(job)
  }, [enableJobDialog])

  const { handleSync, handleMigrate } = useIntegrationActions()

  return useMemo(() => {
    if (!job) return []

    const result = []
    result.push({ key: 'sync-job', label: 'Sync', icon: 'sync', onClick: handleSync })
    result.push({ key: 'force-sync-job', label: 'Force Sync', icon: 'sync', onClick: (job) => handleSync(job, { force: true }) })
    result.push({ key: 'check-migrations', label: 'Check Migrations', icon: 'database', onClick: handleMigrate })
    if (job.isEnabled) {
      result.push({ key: 'change-job-status', label: 'Disable Job', icon: 'close', onClick: handleChangeJobStatus })
    } else {
      result.push({ key: 'change-job-status', label: 'Enable Job', icon: 'check', onClick: handleChangeJobStatus })
    }
    return result
  }, [handleChangeJobStatus, handleSync, handleMigrate, job])
}

const useJobConfigurationId = () => {
  const { jobConfigurationId } = useParams()
  return useMemo(() => {
    return Number.parseInt(jobConfigurationId)
  }, [jobConfigurationId])
}

function JobDetails () {
  const jobConfigurationId = useJobConfigurationId()
  const query = useMemo(() => {
    return {
      filters: {
        jobConfigurationId
      },
      includes: {
        integrationSource: true
      }
    }
  }, [jobConfigurationId])
  const enableJobDialog = useRef(null)
  const { data, isLoading } = useSearchIntegrationJobs(query)
  const job = data?.data?.length ? data.data.at(0) : null
  const jobActions = useJobActions({ enableJobDialog, job })

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!job) {
    return (
      <AdminPage>
        <NotAvailable />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='clear'>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <h2>{job.domain} / {job.jobKey}</h2>
              <SydActionMenu actions={jobActions} subject={job} />
            </FlexRow>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <table>
                  <tbody>
                    <tr>
                      <td>Domain</td>
                      <td>{job.domain}</td>
                    </tr>
                    <tr>
                      <td>Job Key</td>
                      <td>{job.jobKey}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>{job.description}</td>
                    </tr>
                    <tr>
                      <td>Enabled</td>
                      <td><Switch checked={job.isEnabled} disabled /></td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <h3>Related Sources</h3>
                <JobSources jobConfigurationId={jobConfigurationId} />
              </Card>
            </Grid>
            <JobTableInfo jobConfigurationId={jobConfigurationId} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <h3>Primary Key</h3>
                <JobPrimaryKeys job={job} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <JobIncludedFields job={job} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <h3>Virtual Associations</h3>
                <div>Allows a table to be unwound</div>
                <div>Nothing here yet</div>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <h3>Indexes</h3>
                <div>Nothing here yet</div>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <h3>Other Options</h3>
                <div>Nothing here yet</div>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CodeBlock title='Job Configuration (RAW)' code={job.configuration} scroll />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EnableJobDialog ref={enableJobDialog} />
    </AdminPage>
  )
}

JobDetails.propTypes = {
}

export default JobDetails
