import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  forwardRef,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import first from 'lodash/first'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import ErrorView from '../components/pages/ErrorView'
import { useAbundanceEngineViews, useSetAbundanceEngineContext } from '../redux/slices/abundanceEngineContext'
import { ABUNDANCE_ENGINE_PREFIX_PATH } from '../constants'
import LoadingView from '../components/pages/LoadingView'
import { useAbundanceEngineViewByPathName, useClientViewEnablement } from '../api/abundanceEngine'
import NotFoundView from '../components/pages/NotFoundView'
import { useAppContext } from '../redux/slices/appContext'
import AbundanceParser from './AbundanceParser'
import AbundanceEngineContextProvider from './components/AbundanceParameterContextProvider'
import ViewEnablementWarning from './ViewEnablementWarning'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  }
}))

/* Using ...rest objects causes multiple object references to form on subsequent re-renders,
*  Normally this is probably not an issue, but it causes the JSX parser to totally
*  re-mount the abundance engine view */
const AbundanceEngineView = forwardRef((
  { viewPath: _viewPath, fallbackViewPath, className, advisorId },
  ref
) => {
  const classes = useStyles()
  const location = useLocation()
  const views = useAbundanceEngineViews()
  const setAbundanceEngineContext = useSetAbundanceEngineContext()

  const [viewProps, setViewProps] = useState(null)

  const viewPath = useMemo(() => {
    if (_viewPath) {
      return _viewPath
    }
    const viewPathName = location.pathname
      .substring(`/${ABUNDANCE_ENGINE_PREFIX_PATH}`.length)
      .replace('/', '')

    return viewPathName
  }, [_viewPath, location])

  const requestedViewPath = useMemo(() => {
    const defaultView = first(views)
    return viewPath || defaultView?.path
  }, [viewPath, views])

  const { clientId, isAdvisor } = useAppContext()
  const { isLoading: loading, error, data: viewDataConfig } = useAbundanceEngineViewByPathName(requestedViewPath, fallbackViewPath)
  const { isLoading: enablementLoading, error: enablementError, data: enablement } = useClientViewEnablement(requestedViewPath, clientId)

  const [viewOriginal, setViewOriginal] = useState(false)
  const isEnablementException = useMemo(() => !enablement && isAdvisor, [isAdvisor, enablement])
  const viewData = useMemo(() => {
    if (!viewOriginal && (enablement || isEnablementException)) {
      return viewDataConfig?.view || null
    }
    return viewDataConfig?.disabledView || null
  }, [enablement, viewDataConfig, isEnablementException, viewOriginal])

  const abundanceEngineViewRef = useCallback(
    (node) => {
      if (node !== null) {
        const { ParsedChildren } = node
        const [parsedChild] = ParsedChildren || []
        const viewProperties = {
          viewName: viewData?.hideTitle ? null : viewData?.name,
          ...(parsedChild?.props || {})
        }
        setViewProps(viewProperties)
      }
    },
    [viewData?.name, viewData?.hideTitle]
  )

  useEffect(() => {
    if (!isEmpty(viewProps)) {
      const { viewName } = viewProps
      setAbundanceEngineContext({
        currentView: {
          name: viewData?.hideTitle ? null : viewName,
          path: viewPath
        }
      })
    }
    return () => {
      setAbundanceEngineContext({
        currentView: {
          name: null,
          path: null
        }
      })
    }
  }, [viewProps, viewPath, setAbundanceEngineContext, viewData?.hideTitle])

  useImperativeHandle(
    ref,
    () => ({
      viewProps,
      viewDefaultProps: viewData?.defaultProps
    }),
    [viewProps, viewData?.defaultProps]
  )

  const bindings = useMemo(() => {
    return ({
      advisorId: Number(advisorId) || advisorId
    })
  }, [advisorId])

  if (error?.code === 404) {
    return <NotFoundView />
  }

  if (error) return <ErrorView error={error} />
  if (enablementError) return <ErrorView error={enablementError} />

  if (loading || enablementLoading) return <LoadingView width='100%' height='100%' />

  return (
    <AbundanceEngineContextProvider additionalParams={bindings}>
      <AbundanceParser
        jsx={viewData?.jsxView}
        bindings={bindings}
        className={className || classes.root}
        ref={abundanceEngineViewRef}
        jsxComponentsDefaultProps={viewData.defaultProps}
      />
      <ViewEnablementWarning
        variant='over'
        isInWarning={isEnablementException}
        viewOriginal={viewOriginal}
        setViewOriginal={setViewOriginal}
      />
    </AbundanceEngineContextProvider>
  )
})

AbundanceEngineView.propTypes = {
  viewPath: PropTypes.string,
  fallbackViewPath: PropTypes.string,
  className: PropTypes.string,
  advisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

AbundanceEngineView.defaultProps = {
  viewPath: undefined,
  fallbackViewPath: undefined,
  className: undefined,
  advisorId: undefined
}

export default React.memo(AbundanceEngineView)
