import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SydAutocomplete from '../../../../commonDesign/SydAutocomplete'
import { useGetClients } from '../../../../../api/clients'
import { DEFAULT_SEARCH_CLIENTS_PAGE_SIZE } from '../../../../../constants'
import useDebouncedValue from '../../../../../hooks/useDebounceValue'

export const AutocompleteClientSelect = ({
  field,
  multiple = false
}) => {
  const [clientSearchValue, setClientSearchValue] = useState('')
  const debouncedSearchValue = useDebouncedValue(clientSearchValue, 500)
  const { data: clients, isLoading: clientsLoading } = useGetClients({
    searchValue: debouncedSearchValue,
    take: DEFAULT_SEARCH_CLIENTS_PAGE_SIZE
  })

  const handleOnChange = useCallback((value) => {
    if (multiple) {
      return field.onChange(value.map((val) => {
        if (val.levelTypeId) return val
        return {
          ...val,
          levelTypeId: 201,
          levelId: val.value
        }
      }).sort((a, b) => a.levelId - b.levelId))
    }
    field.onChange(value.value)
  }, [field, multiple])

  const defaultValue = useMemo(() => (
    multiple
      ? [...(field.value?.map(({ value, levelId, label }) => ({ value: value ?? levelId, label })) ?? [])]
      : clients?.find(client => client.clientId === field.value)?.shortName
  ), [field.value, clients, multiple])

  return (
    <>
      <SydAutocomplete
        options={(clients ?? []).map(({ clientId, shortName }) => ({ value: clientId, label: shortName }))}
        onInputChange={(e, value) => setClientSearchValue(value)}
        onChange={(e, value) => handleOnChange(value)}
        value={defaultValue}
        defaultValue={defaultValue}
        loading={clientsLoading}
        size='sm'
        multiple={multiple}
      />
    </>
  )
}

AutocompleteClientSelect.propTypes = {
  field: PropTypes.object,
  multiple: PropTypes.bool
}
