import { useQuery } from '@tanstack/react-query'
import { postNamedQuery } from '../../../../../service'

export const useListInfographicTemplates = (body = {}, options = {}) => {
  const { mapper = null, enabled = true } = options ?? {}
  return useQuery({
    queryKey: ['reports', 'listInfographicTemplates', body],
    queryFn: async () => {
      const { data } = await postNamedQuery('reports', 'list-infographic-templates', body)
      return data
    },
    enabled,
    mapper: mapper ?? ((data) => data?.data ?? [])
  })
}
