import { useMemo } from 'react'
import { cellTemplates } from '../../constants/cellTemplates'
import { InfographicTemplateActionsCell } from '../components/InfographicTemplateActionsCell'
import { InfographicTemplateLevelCell } from '../components/InfographicTemplateLevelCell'

export const useInfographicTemplateColumnConfig = ({
  userIsInternal,
  onRefetch
}) => {
  return useMemo(() => ({
    columns: [
      {
        Header: 'Id',
        accessor: 'infographicTemplateId',
        id: 'templateId',
        width: 60,
        disableSortBy: false
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name'
      },
      {
        Header: 'Ordinal',
        accessor: 'ordinal',
        id: 'ordinal'
      },
      {
        Header: 'Is Global?',
        accessor: 'global',
        id: 'global',
        width: 70,
        Cell: cellTemplates.boolean
      },
      ...(userIsInternal && [{
        Header: 'Internal Only?',
        accessor: 'internal',
        id: 'internal',
        width: 100,
        Cell: cellTemplates.boolean
      }]),
      {
        Header: 'Clients',
        accessor: 'levelTypes',
        id: 'levelTypes',
        Cell: ({ value, row }) => InfographicTemplateLevelCell({ value, row }),
        disableSortBy: true
      },
      {
        Header: 'Actions',
        accessor: 'infographicTemplateId',
        id: 'infographicTemplateId',
        Cell: ({ row }) => InfographicTemplateActionsCell({ row, onRefetch })
      }
    ],
    defaultSort: [
      { id: 'ordinal', desc: false }
    ],
    defaultPageSize: 20
  }), [onRefetch, userIsInternal])
}
