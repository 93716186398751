import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import CreateJobForm from './form'

const CreateJobDialog = React.forwardRef(function CreateJobDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (source) => setDialogState({ open: true, processing: false, source })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Create Integration Job'
      open={dialogState.open}
      onClose={close}
      size='medium'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<CreateJobForm source={dialogState.source} onComplete={handleComplete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

CreateJobDialog.propTypes = {
  onComplete: PropTypes.func
}

CreateJobDialog.defaultProps = {
  onComplete: () => {}
}

export default CreateJobDialog
