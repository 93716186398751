import { AddBox } from '@material-ui/icons'
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import SydButton from '../../../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  infographicBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    border: theme.palette.gray.dark,
    background: theme.palette.background.default,
    borderRadius: '4px',
    '& h3': {
      margin: 0,
      fontSize: '1.3em'
    },
    '& p': {
      margin: 0,
      fontSize: '0.7rem',
      lineHeight: '1.3em'
    }
  },
  infographicBlockContent: {
    flex: '1'
  },
  infographicBlockObjects: {
    fontSize: '0.7rem',
    color: '#666',
    display: 'flex',
    gridGap: '.5rem',
    marginTop: '.5rem',
    flexWrap: 'wrap'
  },
  infographicBlockObject: {
    padding: '.25em 1em',
    background: theme.palette.gray.main,
    borderRadius: '4px',
    margin: 0
  }
}))

export const InfographicBlock = ({ block, onAddBlockToPage }) => {
  const classes = useStyles()

  return (
    <div
      key={block.infographicBlockId}
      className={classes.infographicBlock}
    >
      <div className={classes.infographicBlockContent}>
        <h3>{block.name}</h3>
        <p>{block.description}</p>
        <div className={classes.infographicBlockObjects}>
          {block.reportObjects.map(ro => (
            <div key={ro.name} className={classes.infographicBlockObject}>
              {ro.name}
            </div>
          ))}
        </div>
      </div>

      <SydButton variant='primary' onClick={() => onAddBlockToPage?.(block)}>
        <AddBox />
      </SydButton>
    </div>
  )
}

InfographicBlock.propTypes = {
  block: PropTypes.shape({
    infographicBlockId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    reportObjects: PropTypes.arrayOf(PropTypes.object)
  }),
  onAddBlockToPage: PropTypes.func
}
