import { useMutation } from '@tanstack/react-query'
import { postNamedCommand } from '../../../../../../service'

export const useSaveInfographicTemplateMutation = () => {
  return useMutation({
    mutationFn: async (body) => {
      const { data } = await postNamedCommand('reports', 'save-infographic-template', body)
      return data
    }
  })
}
