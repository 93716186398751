import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../../molecules/Card'
import InlineCardHeader from '../../../../../../molecules/Card/InlineCardHeader'
import ErrorBoundary from '../../../../../../molecules/ErrorBoundary'
import SydActionMenu from '../../../../../../commonDesign/SydActionMenu'
import SydButton from '../../../../../../commonDesign/Button'
import FilterFieldDisplay from './FilterItem'
import EditFilterDialog from './EditFilterDialog'
import RemoveFilterDialog from './RemoveFilterDialog'
import AddFilterDialog from './AddFilterDialog'

const useFilterActions = ({ editFilterRef, removeFilterRef }) => {
  const handleEdit = useCallback(({ report, filter }) => {
    editFilterRef.current.open(report, filter)
  }, [editFilterRef])

  const handleRemove = useCallback(({ report, filter }) => {
    removeFilterRef.current.open(report, filter)
  }, [removeFilterRef])

  return useMemo(() => {
    return [
      { key: 'edit-filter', label: 'Edit Filter', icon: 'edit', onClick: handleEdit },
      { key: 'remove-filter', label: 'Remove Filter', icon: 'delete', onClick: handleRemove }
    ]
  }, [handleEdit, handleRemove])
}

function FiltersTab ({ report, filters }) {
  const addFilterRef = useRef(null)
  const editFilterRef = useRef(null)
  const removeFilterRef = useRef(null)
  const filterActions = useFilterActions({ editFilterRef, removeFilterRef })

  return (
    <FadeIn>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <InlineCardHeader
              action={<SydButton size='sm' variant='outline' icon='add' onClick={() => addFilterRef.current.open(report)}>Add Filter</SydButton>}
            >
              {report.name}
            </InlineCardHeader>
          </Card>
        </Grid>
        {(filters || []).map(filter => (
          <Grid item xs={12} md={6} key={`filter_${filter.firmReportFilterId}`}>
            <Card>
              <InlineCardHeader
                action={<SydActionMenu actions={filterActions} subject={{ report, filter }} />}
              >
                {filter.firmReportFilterId}
              </InlineCardHeader>
              <div>
                <ErrorBoundary name='filter'>
                  <FilterFieldDisplay
                    item={filter.filter}
                    columns={report.configuration.columns}
                  />
                </ErrorBoundary>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AddFilterDialog ref={addFilterRef} />
      <EditFilterDialog ref={editFilterRef} />
      <RemoveFilterDialog ref={removeFilterRef} />
    </FadeIn>
  )
}

FiltersTab.propTypes = {
  report: PropTypes.object.isRequired,
  filters: PropTypes.array
}

export default FiltersTab
