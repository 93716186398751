import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { dataPipelines as dataPipeline } from '../../../../../../api/coreData'
import { useAppContext } from '../../../../../../redux/slices/appContext'
import ErrorDisplay from '../../../../../molecules/ErrorDisplay'
import Loading from '../../../../../molecules/Loading'
import EditPipelineHome from './EditPipelineHome'

const defaultQuery = (availableDates, clientId) => ({
  levelFilters: {
    levelTypes: ['client'],
    clientIds: [clientId]
  },
  tableDateRanges: {
    balanceColumns: {
      balance: {
        startDate: `${dayjs(availableDates.max).format('YYYY-MM-DD')}`,
        endDate: `${dayjs(availableDates.max).format('YYYY-MM-DD')}`
      }
    }
  }
})

const newPipeline = (availableDates, clientId) => {
  return {
    displayName: '',
    code: '',
    description: '',
    scope: 'client',
    pipeline: {
      coreTableDataRequest: defaultQuery(availableDates, clientId),
      variables: {}
    },
    analysis: null,
    pipelineVersion: 1
  }
}

const usePipelineId = () => {
  const { pipelineId } = useParams()
  return useMemo(() => {
    if (Number.parseInt(pipelineId) > 0) {
      return Number.parseInt(pipelineId)
    }
    return null
  }, [pipelineId])
}

function EditPipeline () {
  const pipelineId = usePipelineId()
  const { data, isFetching, error } = dataPipeline.useDataPipeline(pipelineId, { enabled: !!pipelineId })
  const { availableDates, clientId } = useAppContext()
  const pipelineValue = useMemo(() => {
    if (!pipelineId) return newPipeline(availableDates, clientId)
    return data?.pipeline
  }, [pipelineId, data, availableDates, clientId])

  if (error) {
    return (
      <ErrorDisplay error={error} />
    )
  }

  if (pipelineId && isFetching) {
    return <Loading />
  }

  return (
    <EditPipelineHome pipeline={pipelineValue} />
  )
}

export default EditPipeline
