import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import AdminPage from '../../../admin/shared/AdminPage'
import { systemJobs } from '../../../../../api/ingestion'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import SydLinkButton from '../../../../commonDesign/LinkButton'
import FlexActions from '../../../../molecules/FlexActions'
import FlexRow from '../../../../molecules/FlexRow'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydButton from '../../../../commonDesign/Button'
import useDebouncedValue from '../../../../../hooks/useDebounceValue'
import JobGroup from './JobGroup'

function ListJobGroupContent ({ jobGroups, onCreate, processDate }) {
  if (!jobGroups?.length) {
    return (
      <Grid item xs={12}>
        <EmptySection
          title='No Job Groups Yet'
          description=''
        />
      </Grid>
    )
  }

  return (
    <>
      {(jobGroups || []).map(jg => (
        <Grid item xs={12} key={`jg_${jg.jobGroupId}`}>
          <JobGroup jobGroup={jg} processDate={processDate} />
        </Grid>
      ))}
    </>
  )
}

ListJobGroupContent.propTypes = {
  jobGroups: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  processDate: PropTypes.any
}

const defaultDate = dayjs()

const useProcessDate = () => {
  const [processDate, setProcessDate] = useState(defaultDate)
  const { canGoForward, canGoBack } = useMemo(() => ({
    canGoForward: (dayjs(processDate).startOf('day').isBefore(dayjs().startOf('day'))),
    canGoBack: true
  }), [processDate])
  const nextDate = useCallback(() => {
    if (canGoForward) {
      setProcessDate(dayjs(processDate).add(1, 'day').startOf('day'))
    }
  }, [processDate, setProcessDate, canGoForward])
  const prevDate = useCallback(() => {
    if (canGoBack) {
      setProcessDate(dayjs(processDate).add(-1, 'day').startOf('day'))
    }
  }, [processDate, setProcessDate, canGoBack])

  return {
    processDate,
    setProcessDate,
    nextDate,
    prevDate,
    canGoForward,
    canGoBack
  }
}

function SystemJobsHome () {
  const { data, isLoading } = systemJobs.useListSystemJobGroups()
  const createJobGroupRef = useRef(null)
  const procDate = useProcessDate()
  const debouncedDate = useDebouncedValue(procDate.processDate, 500)

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexRow style={{ width: '100%' }}>
            <SydLabel label='Process Date'>
              <DatePicker
                variant='dialog'
                value={procDate.processDate}
                onChange={procDate.setProcessDate}
                format='MMM D, YYYY'
              />
            </SydLabel>
            <SydButton disabled={!procDate.canGoBack} onClick={procDate.prevDate} icon='chevronLeft'>Prev</SydButton>
            <SydButton disabled={!procDate.canGoForward} onClick={procDate.nextDate} icon='chevronRight'>Next</SydButton>
            <FlexActions style={{ flex: 1 }}>
              <SydLinkButton size='sm' to='/admin/denali/ingestion/system-jobs/groups'>Job Groups</SydLinkButton>
            </FlexActions>
          </FlexRow>
        </Grid>
        <ListJobGroupContent
          jobGroups={data?.jobGroups}
          onCreate={() => createJobGroupRef.current.open()}
          processDate={debouncedDate}
        />
      </Grid>
    </AdminPage>
  )
}

export default SystemJobsHome
