import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { useCheckPolicy } from '../../../hooks'
import { ADMIN_ROUTES } from '../../../constants'
import Text from '../../atoms/Text'
import { REPORTS } from '../../../policies/admin'
import CardTile from './CardTile'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem'
  },
  content: {
    flexGrow: 1
  },
  header: {
    marginBottom: '1.25rem'
  }
}))

const ReportingView = () => {
  const classes = useStyles()
  const canRunReports = useCheckPolicy(REPORTS.runReports)
  const canRunInfographicReports = useCheckPolicy(REPORTS.runInfographicReports)

  const GridItem = ({ to, name, title }) => useMemo(() => (
    <Grid item xs={12} md={3}>
      <CardTile to={to} icon={name} title={title} />
    </Grid>
  ), [name, title, to])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Text text='Reports' variant='h1' className={classes.header} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          {canRunReports && <GridItem to={ADMIN_ROUTES.REPORTS} name='file' title='Standard Reports' />}
          {canRunInfographicReports && <GridItem to={ADMIN_ROUTES.INFOGRAPHIC_REPORTS} name='layers' title='Infographic Reports' />}
        </Grid>
      </div>
    </Container>
  )
}

export default ReportingView
