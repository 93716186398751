import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import {
  useGetIntegrationSourceById,
  useScanMutation
} from '../../../../../api/integrations'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import Card from '../../../../molecules/Card'
import NotAvailable from '../../../../organisms/NotAvailable'
import CodeBlock from '../../../../atoms/CodeBlock'
import FlexRow from '../../../../molecules/FlexRow'
import { useDenaliContext } from '../../DenaliProvider'
import { SUCCESS_ALERT } from '../../../../../constants'
import SydActionMenu from '../../../../commonDesign/SydActionMenu'
import SourceJobs from './SourceJobs'
import SourceFields from './SourceFields'
import DefineKeysDialog from './DefineKeysDialog'
import CreateJobDialog from './CreateJobDialog'

const useFieldActions = ({ defineKeys }) => {
  const handleDefineKeys = useCallback((source) => {
    defineKeys.current.open(source)
  }, [defineKeys])

  return useMemo(() => {
    return [
      { key: 'define-keys', label: 'Define Keys', icon: 'settings', onClick: handleDefineKeys }
    ]
  }, [handleDefineKeys])
}

const useJobActions = ({ createJobDialog }) => {
  const handleCreateJobFromSource = useCallback((source) => {
    createJobDialog.current.open(source)
  }, [createJobDialog])

  return useMemo(() => {
    return [
      { key: 'create-job-from-source', label: 'Create Job', icon: 'add', onClick: handleCreateJobFromSource }
    ]
  }, [handleCreateJobFromSource])
}

const useSourceActions = () => {
  const denaliContext = useDenaliContext()
  const { mutateAsync: scanJob } = useScanMutation()

  const handleScan = useCallback(async (source) => {
    const result = await scanJob({
      domain: source.domain,
      connectionId: source.connectionId,
      objects: [source.sourceKey]
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`/admin/denali/integrator/batches/${result.batchId}`}>Scan started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [scanJob, denaliContext])

  return useMemo(() => {
    return [
      { key: 'scan-source', label: 'Scan this Source', icon: 'search', onClick: handleScan }
    ]
  }, [handleScan])
}

function SourceDetailsHome ({ root }) {
  const { integrationSourceId } = useParams()
  const { data, isLoading } = useGetIntegrationSourceById(integrationSourceId)
  const defineKeysRef = useRef(null)
  const createJobDialogRef = useRef(null)
  const sourceActions = useSourceActions()
  // eslint-disable-next-line no-unused-vars
  const jobActions = useJobActions({ createJobDialog: createJobDialogRef })
  const fieldActions = useFieldActions({
    defineKeys: defineKeysRef
  })

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  const source = data?.integrationSource
  if (!source) {
    return (
      <AdminPage>
        <NotAvailable />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='clear'>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <h2>{source.domain} / {source.label}</h2>
              <SydActionMenu id='source-actions' actions={sourceActions} subject={source} />
            </FlexRow>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <table>
              <tbody>
                <tr>
                  <td>Domain</td>
                  <td>{source.domain}</td>
                </tr>
                <tr>
                  <td>Source Key</td>
                  <td>{source.sourceKey}</td>
                </tr>
                <tr>
                  <td>Label</td>
                  <td>{source.label}</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <h3>Related Jobs</h3>
              <SydActionMenu actions={jobActions} subject={source} />
            </FlexRow>
            <SourceJobs integrationSourceId={source.integrationSourceId} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <h3>Fields</h3>
              <SydActionMenu id='field-actions' actions={fieldActions} subject={source} />
            </FlexRow>
            <SourceFields fields={source.fields || []} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CodeBlock title='Source Configuration (RAW)' code={source.data} />
          </Card>
        </Grid>
      </Grid>
      <DefineKeysDialog ref={defineKeysRef} />
      <CreateJobDialog ref={createJobDialogRef} />
    </AdminPage>
  )
}

SourceDetailsHome.propTypes = {
  root: PropTypes.any
}

export default SourceDetailsHome
