import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import NotAvailable from '../../../../organisms/NotAvailable'
import { useGetIntegrationBatchById } from '../../../../../api/integrations'
import Card from '../../../../molecules/Card'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import CodeBlock from '../../../../atoms/CodeBlock'
import SydButton from '../../../../commonDesign/Button'
import FlexRow from '../../../../molecules/FlexRow'
import BatchTasks from './BatchTasks'
import BatchDetailActionMenu from './BatchDetailActionMenu'

function BatchList ({ root }) {
  const { formatter } = useFormattingContext()
  const { batchId } = useParams()
  const { data, isLoading, isFetching, refetch } = useGetIntegrationBatchById(+batchId, {
    includeTasks: true
  })

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!data?.batch) {
    return (
      <AdminPage>
        <NotAvailable />
      </AdminPage>
    )
  }

  const batch = data.batch

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <h2>Batch {batch.batchId}</h2>
              <FlexRow>
                <SydButton size='sm' variant='primary' icon='sync' processing={isFetching} onClick={refetch}>Refresh</SydButton>
                <BatchDetailActionMenu
                  batch={batch}
                  onCancel={() => { alert('not yet implemented') }}
                />
              </FlexRow>
            </FlexRow>
            <table>
              <tbody>
                <tr>
                  <td>Batch ID</td>
                  <td>{batch.batchId}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{batch.type}</td>
                </tr>
                <tr>
                  <td>isProcessing</td>
                  <td>{JSON.stringify(batch.isProcessing)}</td>
                </tr>
                <tr>
                  <td>isCancelled</td>
                  <td>{JSON.stringify(batch.isCancelled)}</td>
                </tr>
                <tr>
                  <td>Last Started</td>
                  <td>{formatter(batch.lastStartedDate, 'timestamp')} ({formatter(batch.lastStartedDate, 'ß')})</td>
                </tr>
                <tr>
                  <td>Last Finished</td>
                  <td>{formatter(batch.lastFinishedDate, 'timestamp')} ({formatter(batch.lastFinishedDate, 'ß')})</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CodeBlock title='Metadata' code={batch.metadata} scroll />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <h3>Tasks</h3>
            <BatchTasks tasks={data?.tasks || []} />
          </Card>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

BatchList.propTypes = {
  root: PropTypes.any
}

export default BatchList
