import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { useFieldArray, useForm } from 'react-hook-form'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import SaveCancelFooter from '../../../admin/shared/SaveCancelFooter'
import { systemJobs } from '../../../../../api/ingestion'
import Loading from '../../../../molecules/Loading'
import SydButton from '../../../../commonDesign/Button'
import BasicTable from '../../../../atoms/BasicTable'
import Icon from '../../../../atoms/Icon'
import ErrorDisplay from '../../../../molecules/ErrorDisplay'
import SectionScreen from '../../../admin/shared/SectionScreen'

const useStyles = makeStyles((theme) => ({
  jobButton: {
    width: '100%'
  }
}))

function createMember (jobGroup, job, length) {
  return {
    jobId: job.jobId,
    jobGroupId: jobGroup.jobGroupId,
    ordinal: length,
    name: job.name,
    code: job.code
  }
}

function JobGroupMemberEdit ({ jobGroup, onCancel, onSave, processing, error }) {
  const classes = useStyles()
  const { data, isLoading } = systemJobs.useListSystemJobs()

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      members: jobGroup.members || []
    }
  })

  // eslint-disable-next-line no-unused-vars
  const { fields, remove, append, move } = useFieldArray({
    control: form.control,
    name: 'members'
  })

  const itemFns = useCallback((index, length) => {
    return {
      down: () => {
        const next = index + 1 >= length ? 0 : index + 1
        return move(index, next)
      },
      up: () => {
        const next = index - 1 < 0 ? length - 1 : index - 1
        return move(index, next)
      },
      top: () => move(index, 0),
      bottom: () => move(index, length - 1),
      remove: () => remove(index)
    }
  }, [move, remove])

  const length = fields.length

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <SectionScreen editing={processing}>
        <Grid container spacing={1}>
          {error ? (
            <ErrorDisplay error={error} />
          ) : null}
          <Grid item xs={12} md={6}>
            <BasicTable>
              <thead>
                <tr>
                  <th />
                  <th>Order</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, i) => {
                  const fns = itemFns(i, fields?.length)
                  return (
                    <tr key={field.id}>
                      <td>
                        <IconButton size='small' onClick={fns.up} disabled={i === 0}>
                          <Icon name='up' customSize={16} />
                        </IconButton>
                        <IconButton size='small' onClick={fns.down} disabled={i === (length - 1)}>
                          <Icon name='down' customSize={16} />
                        </IconButton>
                      </td>
                      <td>{i + 1}</td>
                      <td>{field.code}</td>
                      <td>{field.name}</td>
                      <td>
                        <IconButton size='small' onClick={fns.remove}>
                          <Icon name='close' customSize={16} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </BasicTable>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              {(data?.jobs || []).map((job, i) => (
                <Grid item xs={12} key={`j_${job.jobId}`}>
                  <SydButton
                    className={classes.jobButton}
                    size='sm'
                    icon='add'
                    disabled={fields.some(f => f.code === job.code)}
                    onClick={() => append(createMember(jobGroup, job, length))}
                  >
                    {job.code} ({job.name})
                  </SydButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </SectionScreen>
      <PersonalSpace />
      <SaveCancelFooter
        onSave={(e) => onSave(form.handleSubmit, e)}
        onCancel={onCancel}
        processing={processing}
      />
    </div>
  )
}

JobGroupMemberEdit.propTypes = {
  jobGroup: PropTypes.shape({
    members: PropTypes.array
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  processing: PropTypes.bool,
  error: PropTypes.any
}

export default JobGroupMemberEdit
