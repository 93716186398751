import { useCallback } from 'react'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useAction, useAsyncAction } from '../utils'
import { postNamedQuery } from '../../service'
import { FEATURE_FLAG } from '../../constants'
import appConfigSlice, { fetchUserViews } from './appConfig'

export const initialState = {
  views: [],
  currentView: { name: null, path: null },
  userViews: [],
  abundanceEngineViewsByLevel: false
}

const abundanceEngineContextSlice = createSlice({
  name: 'abundanceEngineContext',
  initialState,
  reducers: {
    setAbundanceEngineContext: (state, { payload }) => {
      Object.assign(state, payload)
    }
  }
})

const { actions } = abundanceEngineContextSlice
const getAbundanceEngineViewsByLevel = (state) => {
  try {
    return state[appConfigSlice.name]?.featureFlags[FEATURE_FLAG.ABUNDANCE_ENGINE_VIEW_BY_LEVEL]?.active ?? false
  } catch (err) {
    return false
  }
}

const getAbundanceEngineContext = (state) => {
  const abundanceEngineViewsByLevel = getAbundanceEngineViewsByLevel(state)
  return {
    ...state[abundanceEngineContextSlice.name],
    abundanceEngineViewsByLevel
  }
}
const getAbundanceEngineViews = createSelector(getAbundanceEngineContext, ({ views }) => views)
const getNavigationViews = createSelector(getAbundanceEngineContext, ({ views, userViews, abundanceEngineViewsByLevel }) => {
  if (!abundanceEngineViewsByLevel) {
    return views.filter(({ showInNavigation }) => showInNavigation)
  }
  return userViews.filter(({ showInNavigation }) => showInNavigation)
})

function fetchAbundanceEngineConfigAction () {
  return async (dispatch) => {
    try {
      const [
        { data: { rows: views } },
        { data: userViews }
      ] = await Promise.all([
        postNamedQuery('abundanceEngine', 'list-views', {}),
        fetchUserViews()
      ])
      dispatch(actions.setAbundanceEngineContext({
        views: normalizeViews(views),
        userViews: normalizeViews(userViews)
      }))
    } catch (err) {
      console.error('There was an error trying to retrieve [Abundance Engine] views', err)
      dispatch(actions.setAbundanceEngineContext({
        views: initialState.views,
        userViews: initialState.userViews
      }))
    }
  }
}

const normalizeViews = (views) => {
  const formattedViews = views.reduce(
    (acc, { viewId: id, name, path, showInNavigation, hideTitle, enablementCodeId, disabledViewId }) => ({ ...acc, [path]: { id, name, path, showInNavigation: showInNavigation ?? true, hideTitle: hideTitle ?? false, enablementCodeId, disabledViewId } }),
    {}
  )
  return Object.values(formattedViews)
}

const getViewsByPath = createSelector(
  getAbundanceEngineViews,
  (views) => {
    const viewsFormatted = views.reduce((acc, view) => {
      return {
        ...acc,
        [view.path]: view
      }
    }, {})
    return viewsFormatted
  }
)

const getViewsInNavigation = createSelector(
  getNavigationViews,
  (views) => {
    return views
  }
)

const getViewByPath = createSelector(
  getAbundanceEngineViews,
  (_, pathname) => pathname,
  (views, pathname) => {
    const view = views.find(({ path }) => pathname === path)
    return view
  }
)

const getCurrentView = createSelector(
  getAbundanceEngineContext,
  ({ views, currentView }) => {
    const view = views.find(({ path }) => currentView.path === path)
    return view
  }
)

export function useAbundanceEngineCurrentView () {
  const payloadFn = useCallback((state) => getCurrentView(state), [])
  return useSelector(payloadFn)
}

export function useAbundanceViewByPath (viewPath) {
  const payloadFn = useCallback((state) => getViewByPath(state, viewPath), [
    viewPath
  ])
  return useSelector(payloadFn)
}

export function useAbundanceViewsByPath () {
  return useSelector(getViewsByPath)
}

export function useAbundanceViewsInNavigation () {
  return useSelector(getViewsInNavigation)
}

export function useFetchAbundanceEngineConfig () {
  return useAsyncAction(fetchAbundanceEngineConfigAction)
}

export function useAbundanceEngineContext () {
  return useSelector(getAbundanceEngineContext)
}

export function useSetAbundanceEngineContext () {
  const payloadFn = useCallback((abundanceEngineContext) => abundanceEngineContext, [])
  return useAction(actions.setAbundanceEngineContext, payloadFn)
}

export function useAbundanceEngineViews () {
  return useSelector(getAbundanceEngineViews)
}

export default abundanceEngineContextSlice
