import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import OperationalTable from '../../../../organisms/OperationalTable'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import { useQueryIntegrationTable } from '../../../../../api/integrations'
import FlexRow from '../../../../molecules/FlexRow'
import QueryTableCell from '../../shared/QueryTableCell'

function normalizePrimaryKey (key) {
  if (!key) return []
  if (Array.isArray(key)) return key
  return [key]
}

const useStyles = makeStyles((theme) => ({
  queryTable: {
    width: '100%'
  },
  queryTableHeader: {
    paddingLeft: theme.layout.padding.p20,
    paddingRight: theme.layout.padding.p20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

function QueryTable ({ job }) {
  const classes = useStyles()
  const [columns, defaultSort] = useMemo(() => {
    const keys = normalizePrimaryKey(job.configuration.primaryKey)
    const fields = job.configuration.virtualFields || []
    const dataField = { name: 'data', path: '$.data', type: 'json' }

    const combinedColumns = [...keys, ...fields, dataField]
    const columnResult = combinedColumns.map(cc => {
      const col = {
        id: cc.name,
        accessor: cc.name,
        Header: cc.name,
        Cell: QueryTableCell
      }

      return col
    })
    const defaultSortResult = []
    if (columnResult.length) {
      defaultSortResult.push(columnResult.map(cr => ({ id: cr.id, desc: false })).at(0))
    }

    return [columnResult, defaultSortResult]
  }, [job])
  const tableQuery = useMemo(() => {
    return {
      includeRawData: true,
      take: 200
    }
  }, [])
  const totalQuery = useMemo(() => {
    return {
      resultType: 'total'
    }
  }, [])
  const { data: queryResult, isFetching: queryResultFetching } = useQueryIntegrationTable(job.jobConfigurationId, tableQuery)
  const { data: totalResult } = useQueryIntegrationTable(job.jobConfigurationId, totalQuery)

  return (
    <div className={classes.queryTable}>
      <FlexRow className={classes.queryTableHeader}>
        <h2><Link to={`/admin/denali/integrator/jobs/${job.jobConfigurationId}`}>{job.domain} / {job.jobKey}</Link></h2>
        {typeof totalResult?.result?.total === 'number' ? (<div>{totalResult.result.total} Total Records</div>) : null}
      </FlexRow>
      <OperationalTable
        variant='v2'
        columns={columns}
        data={queryResult?.result || []}
        total={typeof totalResult?.result?.total === 'number' ? totalResult.result.total : undefined}
        defaultPageSize={200}
        isLoading={queryResultFetching}
        defaultSort={defaultSort}
        itemName={job.jobKey}
        autoSticky
      />
      <PersonalSpace />
    </div>
  )
}

QueryTable.propTypes = {
  job: PropTypes.shape({
    jobConfigurationId: PropTypes.number,
    configuration: PropTypes.shape({
      primaryKey: PropTypes.any,
      virtualFields: PropTypes.array
    }),
    domain: PropTypes.string,
    jobKey: PropTypes.string
  })
}

export default QueryTable
