import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

function cleanJsx (rawJsx) {
  if (!rawJsx) return null
  return rawJsx
    .replace(/^\n|\n$/g, '')
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/\s+/g, ' ')
    .replace(/>\s+/g, '>')
    .replace(/\s+</g, '<')
    .replace(';', '')
    .trim()
}

export const useAbundanceEngineViewByPathName = (viewPath, fallbackPath) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['abundanceEngineViewByPathName', userId, viewPath, fallbackPath],
    queryFn: async () => {
      let abundanceView = null
      try {
        abundanceView = await postNamedQuery('abundanceEngine', 'get-view-by-path-v2', { path: viewPath })
      } catch (err) {
        console.log('Unable to find view', viewPath)
      }
      if (!abundanceView && fallbackPath) {
        abundanceView = await postNamedQuery('abundanceEngine', 'get-view-by-path-v2', { path: fallbackPath })
      }

      if (abundanceView?.response?.status === 204) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: 'View not found',
          code: 404
        }
      }

      return abundanceView?.data || null
    },
    select: (data) => {
      if (!data) return null
      // const { name, content, defaultProps } = data
      const { view, disabledView } = data
      const _view = {
        name: view.name,
        defaultProps: view.defaultProps,
        jsxView: cleanJsx(view.content)
      }
      const _disabledView = disabledView ? {
        name: disabledView.name,
        defaultProps: disabledView.defaultProps,
        jsxView: cleanJsx(disabledView.content)
      } : _view

      return {
        view: _view,
        disabledView: _disabledView
      }
    }
  })
}

export const usePreloadAbundanceEngineView = (viewPath, fallbackPath) => {
  const { userId } = useAppContext()
  const queryClient = useQueryClient()
  const preload = useCallback(async () => {
    await queryClient.prefetchQuery({
      queryKey: ['abundanceEngineViewByPathName', userId, viewPath, fallbackPath],
      queryFn: async () => {
        let abundanceView = null
        try {
          abundanceView = await postNamedQuery('abundanceEngine', 'get-view-by-path-v2', { path: viewPath })
        } catch (err) {
          console.log('Unable to find view', viewPath)
        }
        if (!abundanceView && fallbackPath) {
          abundanceView = await postNamedQuery('abundanceEngine', 'get-view-by-path-v2', { path: fallbackPath })
        }

        return abundanceView?.data || null
      }
    })
  }, [queryClient, userId, viewPath, fallbackPath])

  return { preload }
}

export const useClientViewEnablement = (viewPath, clientId) => {
  return useQuery({
    queryKey: ['abundance-engine/get-client-view-enablement', viewPath, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-client-view-enablement', {
        path: viewPath,
        clientId
      })

      return !!data?.enablement?.enabled
    }
  })
}

/** TODO - need to make this endpoint */
export const useAdvisorViewTemplateConfig = (templateConfig) => {
  return useMemo(() => {
    if (templateConfig === 'default') {
      return ({
        data: {
          config: {
            sections: [
              { name: 'Advisors', type: 'group', levelTypeId: 301 }
            ]
          }
        },
        isLoading: false
      })
    }

    return {
      data: templateConfig,
      isLoading: false
    }
  }, [templateConfig])
}

export const useAbundanceEngineViews = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.abundanceEngineViews, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-active-views', {})
      return data
    },
    select: mapper
  })
}

export const useListAbundanceEngineViews = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper } = queryOptions
  const queryKey = query ? JSON.stringify(query) : '{}'
  return useQuery({
    queryKey: [QUERY_KEYS.abundanceEngineViews, userId, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'list-views', query || {})
      return data
    },
    select: mapper
  })
}

export const useAdvisorViewTemplates = () => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.advisorViewTemplates, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'searchAdvisorViewTemplates', {
        includes: {
          scopes: true
        },
        sort: [{ field: 'hierarchyLevel', dir: 'desc' }]
      })

      return data
    }
  })
}

export const useAbundanceEngineViewContentById = viewId => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.abundanceEngineViewContentById, userId, viewId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'getViewContentById', {
        viewId
      })

      return data
    }
  })
}

export const useAccountTearSheetView = accountId => {
  return useQuery({
    queryKey: ['abundanceEngine', QUERY_KEYS.accountTearSheet, accountId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-tear-sheet-view', {
        accountId
      })

      return data
    },
    select: (data) => {
      if (!data?.view) return null
      return {
        ...data.view,
        jsxView: data.view.content
          .replace(/^\n|\n$/g, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\s+/g, ' ')
          .replace(/>\s+/g, '>')
          .replace(/\s+</g, '<')
          .replace(';', '')
          .trim()
      }
    }
  })
}

export const useAssetTearSheetView = assetId => {
  return useQuery({
    queryKey: ['abundanceEngine', QUERY_KEYS.assetTearSheet, assetId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-tear-sheet-view', {
        assetId
      })

      return data
    },
    select: (data) => {
      if (!data?.view) return null
      return {
        ...data.view,
        jsxView: data.view.content
          .replace(/^\n|\n$/g, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\s+/g, ' ')
          .replace(/>\s+/g, '>')
          .replace(/\s+</g, '<')
          .replace(';', '')
          .trim()
      }
    }
  })
}

export const useAssetTearSheetViewById = tearSheetId => {
  return useQuery({
    queryKey: ['abundanceEngine', QUERY_KEYS.assetTearSheet, tearSheetId],
    enabled: !!tearSheetId,
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-tear-sheet-view-by-id', {
        tearSheetId
      })

      return data
    },
    select: (data) => {
      if (!data?.view) return null
      return {
        ...data.view,
        jsxView: data.view.content
          .replace(/^\n|\n$/g, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\s+/g, ' ')
          .replace(/>\s+/g, '>')
          .replace(/\s+</g, '<')
          .replace(';', '')
          .trim()
      }
    }
  })
}

export const useAssetTearSheetList = (levelTypeId) => {
  return useQuery({
    queryKey: ['abundanceEngine', QUERY_KEYS.assetTearSheetList, levelTypeId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'get-tear-sheets', {
        levelTypeId
      })

      return data
    }
  })
}

export const useListEnablementCodes = () => {
  return useQuery({
    queryKey: ['abundanceEngine', QUERY_KEYS.listEnablementCodes],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'list-enablement-codes', {
      })

      return data
    }
  })
}
