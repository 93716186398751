import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import SettingsIcon from '@material-ui/icons/Settings'
import CodeBlock from '../../../../../../atoms/CodeBlock'
import ColumnToken from './ColumnToken'
import Token from './Token'
import { useFilterContextValues, FilterContext } from './FilterContext'

const opTranslation = {
  eq: 'is equal to',
  neq: 'is not equal to',
  gt: 'is greater than',
  gte: 'is greater than or equal to',
  lt: 'is less than',
  lte: 'is less than or equal to',
  in: 'in the set of',
  notIn: 'is not in the set of',
  exists: 'has a value',
  missing: 'does not have a value',
  notNull: 'is not null',
  isNull: 'is null',
  exact: 'exactly matches',
  startsWith: 'starts with',
  endsWith: 'ends with',
  contains: 'contains',
  notStartsWith: 'does not start with',
  notEndsWith: 'does not end with',
  notContains: 'does not contain'
}

function ValueFilterItem ({ column, valueFilter }) {
  if (Array.isArray(valueFilter) && valueFilter.length === 1) {
    return (
      <span>
        {column ? (<span>The column <ColumnToken value={column} /></span>) : null}
        <ValueFilterItem valueFilter={valueFilter.at(0)} />
      </span>
    )
  }

  if (Array.isArray(valueFilter) && valueFilter.at(0).combine !== 'or') {
    return (
      <span>
        {column ? (<span>The column <ColumnToken value={column} /></span>) : null}
        <span>meets <strong>ALL</strong> of the following conditions</span>
        <div>
          <ol>
            {valueFilter.map((item, id) => (
              <li key={`col_${column}_vf_${id}`}>
                <ValueFilterItem valueFilter={item} />
              </li>
            ))}
          </ol>
        </div>
      </span>
    )
  }

  if (Array.isArray(valueFilter) && valueFilter.at(0).combine === 'or') {
    return (
      <span>
        {column ? (<span>The column <ColumnToken value={column} /></span>) : null}
        <span>meets <strong>ANY</strong> of the following conditions</span>
        <div>
          <ul>
            {valueFilter.map((item, id) => (
              <li key={`col_${column}_vf_${id}_2`}>
                <ValueFilterItem valueFilter={item} />
              </li>
            ))}
          </ul>
        </div>
      </span>
    )
  }

  if (valueFilter === null) {
    return <div>FUCK</div>
  }

  if ('$and' in valueFilter) {
    return (
      <span>
        <span>Meets <strong>ALL</strong> of the following conditions:</span>
        <div>
          <ol>
            {Object.entries(valueFilter).map(([k, v], id) => (
              <li key={`col_${column}_vf_${id}_3`}>
                <ValueFilterItem column={k} valueFilter={v} />
              </li>
            ))}
          </ol>
        </div>
      </span>
    )
  }

  if (column === '$or') {
    return (
      <span>
        <span>Meets <strong>ANY</strong> of the following conditions:</span>
        <div>
          <ul>
            {Object.entries(valueFilter).map(([k, v], id) => (
              <li key={`col_${column}_vf_${id}_4`}>
                <ValueFilterItem column={k} valueFilter={v} />
              </li>
            ))}
          </ul>
        </div>
      </span>
    )
  }

  return (
    <span>
      {column ? (<span>The column <ColumnToken value={column} /></span>) : null}
      <span>{opTranslation[valueFilter.op]}</span>
      <span><Token>{JSON.stringify(valueFilter.value, null, 2)}</Token></span>
    </span>
  )
}

function FilterFieldItem ({ item }) {
  if ('$and' in item) {
    return (
      <span>
        <span>Must meet <strong>ALL</strong> of the following conditions:</span>
        <div>
          <ol>
            {Object.entries(item.$and).map(([k, v], id) => (
              <li key={`col_${k}_vf_${id}_6`}>
                <ValueFilterItem column={k} valueFilter={v} />
              </li>
            ))}
          </ol>
        </div>
      </span>
    )
  }

  if ('or' in item) {
    return (
      <span>
        <span>Must meet <strong>ANY</strong> of the following conditions:</span>
        <div>
          <ul>
            {Object.entries(item.or).map(([k, v], id) => (
              <li key={`col_${k}_vf_${id}_5`}>
                <ValueFilterItem column={k} valueFilter={v} />
              </li>
            ))}
          </ul>
        </div>
      </span>
    )
  }

  return (
    <div>
      {Object.entries(item).map(([k, v]) => (
        <div key={k}>
          <span>The column <Token>{k}</Token></span>
          <ValueFilterItem valueFilter={v} />
        </div>
      ))}
    </div>
  )
}

FilterFieldItem.propTypes = {
  item: PropTypes.any
}

ValueFilterItem.propTypes = {
  column: PropTypes.string,
  valueFilter: PropTypes.any
}

const useStyles = makeStyles(theme => ({
  relative: {
    position: 'relative'
  },
  filterFieldDisplayContainer: {
    paddingLeft: theme.layout.padding.p5,
    paddingRight: theme.layout.padding.p5,
    paddingTop: theme.layout.padding.p2,
    paddingBottom: theme.layout.padding.p2,
    borderRadius: theme.layout.radius.tight,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.gray.dark,
    lineHeight: '30px'
  },
  filterToggle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  filterToggleButton: {
    paddingTop: theme.layout.padding.p0,
    paddingBottom: theme.layout.padding.p0,
    paddingLeft: theme.layout.padding.p2,
    paddingRight: theme.layout.padding.p2,
    borderRadius: theme.layout.radius.tight,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.gray.dark
  }
}))

function FilterFieldDisplay ({ item, columns }) {
  const classes = useStyles()
  const [showJson, setShowJson] = useState(false)
  const contextValue = useFilterContextValues(columns)

  return (
    <FilterContext.Provider value={contextValue}>
      <div className={classes.relative}>
        {showJson ? (
          <CodeBlock code={JSON.stringify(item, null, 2)} scroll />
        ) : (
          <div className={classes.filterFieldDisplayContainer}>
            <FilterFieldItem item={item} />
          </div>
        )}
        <div className={classes.filterToggle}>
          <ToggleButton value='settings' className={classes.filterToggleButton} selected={showJson} onClick={() => setShowJson(p => !p)}>
            <SettingsIcon className='h-10' />
          </ToggleButton>
        </div>
      </div>
    </FilterContext.Provider>
  )
}

FilterFieldDisplay.propTypes = {
  item: PropTypes.object,
  columns: PropTypes.array
}

export default FilterFieldDisplay
