/**
 * FilterFieldEditor Module
 *
 * This module provides components for creating and editing complex filter conditions
 * for Denali reports. It allows users to build nested filter structures with various
 * operations and combinators (AND, OR). The main components are:
 *
 * - FilterFieldEditor: The main component that renders the entire filter editor
 * - FilterFieldEditorRoot: Handles the top-level filter structure
 * - FilterCombinator: Manages nested AND/OR combinations of filters
 * - FilterColumn: Represents a single column filter, which can be simple or multi-condition
 * - FilterColumnObject: Handles the specific filter conditions for a column
 *
 * The module also includes utility components like AddColumnButton, OperationSelect,
 * ValueEditor, and ObjectCombinatorSelect to enhance the user interface and experience.
 */

import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { jsonpatch } from 'json-p3/dist/json-p3.esm'
import { Divider, Menu, MenuItem, Select, TextField, Grid, makeStyles } from '@material-ui/core'
import CodeBlock from '../../../../../../atoms/CodeBlock'
import SydButton from '../../../../../../commonDesign/Button'
import { combinators, opSchema } from './schema'
import { makePath, makePathIndex, makePathRoot } from './pathUtils'
import { FilterContext, useFilterContextValues } from './FilterContext'
import ColumnToken from './ColumnToken'

/**
 * Creates a default value filter object
 * @returns {Object} Default value filter with 'eq' operation and empty string value
 */
const defaultValueFilter = () => ({ op: 'eq', value: '' })

function AddColumnButton ({ onAdd, message = 'Add Filter Condition' }) {
  const { columns } = useContext(FilterContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAdd = useCallback((id) => {
    onAdd(id)
    handleClose()
  }, [handleClose, onAdd])

  return (
    <>
      <SydButton variant='primary' size='sm' onClick={handleClick}>{message}</SydButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {columns.list.map(c => (
          <MenuItem key={c.id} onClick={() => handleAdd(c.id)}>
            <div className='__space-between'>
              <span>{c.id}</span>
              <em className='text-secondary'>({c.type})</em>
            </div>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={() => handleAdd('$and')}>Combine ALL</MenuItem>
        <MenuItem onClick={() => handleAdd('$or')}>Combine ANY</MenuItem>
      </Menu>
    </>
  )
}

AddColumnButton.propTypes = {
  onAdd: PropTypes.func,
  message: PropTypes.string
}

function OperationSelect ({ value, type, onChange }) {
  const _options = useMemo(() => {
    return Object.entries(opSchema).filter(([, val]) => val.validFor.includes(type))
      .map(([key, val]) => ({
        label: val.label,
        value: key
      }))
  }, [type])

  if (!type) {
    return <em>Invalid column type</em>
  }

  return (
    <Select
      value={value} onChange={(e) => {
        onChange(e.target.value, opSchema[e.target.value].unary || false)
      }}
    >
      {_options.map(opt => (
        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
      ))}
    </Select>
  )
}

OperationSelect.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(['number', 'string']),
  onChange: PropTypes.func
}

function ValueEditor ({ value, onChange, type, op }) {
  const _op = useMemo(() => {
    return opSchema[op]
  }, [op])

  if (!type || !op) return null

  if (_op.unary) return null

  return (
    <TextField value={value} onChange={(e) => onChange(e.target.value)} />
  )
}

ValueEditor.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['number', 'string']),
  op: PropTypes.string
}

function ObjectCombinatorSelect ({ value, onChange }) {
  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)}>
      <MenuItem value='and'>ALL</MenuItem>
      <MenuItem value='or'>ANY</MenuItem>
      <MenuItem value='one'>One</MenuItem>
    </Select>
  )
}

ObjectCombinatorSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

function FilterCombinator ({ op, value, onChange, path }) {
  return (
    <div>
      <div className='__action'>
        <div>Must meet <strong>{op === '$and' ? 'ALL' : 'ANY'}</strong> of the following:</div>
        <AddColumnButton
          message='Add Condition'
          onAdd={(id) => {
            return (id in value)
              ? undefined
              : onChange({
                op: 'add',
                path: makePath(path, id),
                value: combinators.includes(id) ? {} : defaultValueFilter()
              })
          }}
        />
        <SydButton size='sm' variant='ghost' onClick={() => onChange({ op: 'remove', path })}>Remove</SydButton>
      </div>
      <ol>
        {Object.entries(value).map(([key, val]) => {
          const _path = makePath(path, key)
          return (
            <li key={_path}>
              {combinators.includes(key) ? (
                <FilterCombinator
                  path={_path}
                  op={key}
                  value={val}
                  onChange={onChange}
                />
              ) : (
                <FilterColumn
                  path={_path}
                  column={key}
                  value={val}
                  onChange={onChange}
                />
              )}
            </li>
          )
        })}
      </ol>
    </div>
  )
}

FilterCombinator.propTypes = {
  /** Combinator operation ('$and' or '$or') */
  op: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  /** Current path in the filter structure */
  path: PropTypes.string
}

function FilterColumn ({ column, value, onChange, isMulti, path }) {
  const combinator = useMemo(() => {
    if (Array.isArray(value) && (!value.length || ['and', null, undefined].includes(value.at(0).combine))) {
      return 'and'
    }
    if (Array.isArray(value) && value.length && value.at(0).combine === 'or') {
      return 'or'
    }
    return 'one'
  }, [value])

  if (['and', 'or'].includes(combinator)) {
    return (
      <div id={path}>
        <div className='__action'>
          <div className='__action'>
            Column
            <ColumnToken value={column} /> meets
            <ObjectCombinatorSelect
              value={combinator} onChange={(nc) => {
                switch (nc) {
                  case 'and':
                  case 'or':
                    if (Array.isArray(value)) {
                      return onChange({ op: 'replace', path, value: value.map(x => ({ ...structuredClone(x), combine: nc })) })
                    } else {
                      return onChange({ op: 'replace', path, value: { ...structuredClone(value), combine: nc } })
                    }
                  case 'one':
                    return onChange({
                      op: 'replace',
                      path,
                      value: value.length ? structuredClone(value.at(0)) : defaultValueFilter()
                    })
                  default: throw new Error('Not implemented')
                }
              }}
            />
            of the following conditions:
          </div>
          <SydButton variant='ghost' size='sm' onClick={() => onChange({ op: 'add', path: makePath(path, '-'), value: defaultValueFilter() })}>Add Condition</SydButton>
          <SydButton variant='ghost' size='sm' onClick={() => onChange({ op: 'remove', path })}>Remove</SydButton>
        </div>
        <ol>
          {value.map((val, index) => {
            const _path = makePathIndex(path, index)
            return (
              <li key={_path}>
                <div className='__action'>
                  <FilterColumn
                    path={_path}
                    column={column}
                    value={val}
                    isMulti
                    onChange={onChange}
                  />
                  <SydButton variant='ghost' size='sm' onClick={() => onChange({ op: 'remove', path: _path })}>Remove</SydButton>
                </div>
              </li>
            )
          })}
        </ol>
      </div>
    )
  }

  return (
    <div className='__action'>
      <FilterColumnObject
        path={path}
        column={column}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
      />
      {!isMulti ? (
        <>
          <SydButton variant='ghost' size='sm' onClick={() => onChange({ op: 'replace', path, value: [value, defaultValueFilter()] })}>Add Condition</SydButton>
          <SydButton variant='ghost' size='sm' onClick={() => onChange({ op: 'remove', path })}>Remove</SydButton>
        </>
      ) : null}
    </div>
  )
}

FilterColumn.propTypes = {
  column: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  /** Whether this is a multi-condition filter */
  isMulti: PropTypes.bool,
  /** Current path in the filter structure */
  path: PropTypes.string
}

function FilterColumnObject ({ column, value, onChange, isMulti, path }) {
  const { columns } = useContext(FilterContext)
  const _selectedColumn = useMemo(() => {
    return columns.dictionary[column]
  }, [column, columns])

  const handleValueChange = useCallback((v, type, op) => {
    const _op = opSchema[op]
    const iv = _op.parse(v)
    if (Array.isArray(iv)) {
      if (type === 'number') {
        return iv.map(Number)
      } else {
        return iv
      }
    } else {
      return type === 'number' ? Number(iv) : iv
    }
  }, [])

  return (
    <div id={path} className='__action'>
      {isMulti ? null : <span>Column <ColumnToken value={column} /></span>}
      <span>
        <OperationSelect
          value={value.op} type={_selectedColumn?.type} onChange={(op, unary) => {
            const nv = { ...structuredClone(value), op }
            if (unary && nv.value) {
              delete nv.value
            } else {
              nv.value = handleValueChange(nv.value, _selectedColumn?.type, op)
            }
            onChange({
              op: 'replace',
              path: path,
              value: nv
            })
          }}
        />
      </span>
      <span>
        <ValueEditor
          value={value.value}
          type={_selectedColumn?.type}
          op={value.op}
          onChange={(v) => {
            const newValue = { ...structuredClone(value), value: handleValueChange(v, _selectedColumn?.type, value.op) }
            onChange({ op: 'replace', path, value: newValue })
          }}
        />
      </span>
    </div>
  )
}

FilterColumnObject.propTypes = {
  column: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  /** Whether this is a multi-condition filter */
  isMulti: PropTypes.bool,
  /** Current path in the filter structure */
  path: PropTypes.string
}

const useStyles = makeStyles((theme) => ({
  filterFieldEditor: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g10,
    '& .__action': {
      display: 'flex',
      gap: theme.layout.gap.g5,
      alignItems: 'center'
    },
    '& .__divider': {
      marginTop: theme.layout.margin.m5
    },
    '& .__space-between': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      gap: theme.layout.gap.g5
    }
  }
}))

function FilterFieldEditorRoot ({ value, onChange }) {
  const path = makePathRoot()
  const classes = useStyles()

  return (
    <div className={classes.filterFieldEditor}>
      {Object.entries(value).map(([key, val]) => {
        const _path = makePath(path, key)
        return (
          <Fragment key={_path}>
            {combinators.includes(key) ? (
              <FilterCombinator
                path={_path}
                op={key}
                value={val}
                onChange={onChange}
              />
            ) : (
              <FilterColumn
                path={_path}
                column={key}
                value={val}
                onChange={onChange}
              />
            )}
          </Fragment>
        )
      })}
      <Divider className='__divider' />
      <div className='__action'>
        <AddColumnButton onAdd={(id) => {
          if (id in value) {
            return
          }
          return (id === '$and' || id === '$or')
            ? onChange({ op: 'add', path: makePath(path, id), value: {} })
            : onChange({ op: 'add', path: makePath(path, id), value: defaultValueFilter() })
        }}
        />
      </div>
    </div>
  )
}

FilterFieldEditorRoot.propTypes = {
  /** Current filter value */
  value: PropTypes.any,
  /** Callback function when filter changes */
  onChange: PropTypes.func
}

function FilterFieldEditor ({ value: filter, columns, onChange: setFilter }) {
  const contextValue = useFilterContextValues(columns)

  const handleFilterPatch = useCallback((patch) => {
    const value = structuredClone(filter)
    jsonpatch.apply([patch], value)
    setFilter(value)
  }, [filter, setFilter])

  return (
    <FilterContext.Provider value={contextValue}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <FilterFieldEditorRoot value={filter} onChange={handleFilterPatch} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CodeBlock title='JSON Preview' code={JSON.stringify(filter, null, 2)} scroll />
        </Grid>
      </Grid>
    </FilterContext.Provider>
  )
}

FilterFieldEditor.propTypes = {
  /** Current filter value */
  value: PropTypes.object,
  /**  Available columns for filtering */
  columns: PropTypes.object,
  /** Callback function when filter changes */
  onChange: PropTypes.func
}

export default FilterFieldEditor
