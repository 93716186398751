import React, { useCallback, useState } from 'react'
import { Menu, MenuItem, makeStyles, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.layout.radius.circle,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.layout.shadow.normal
  }
}))

function SydActionMenu ({ id, className, style, actions, size, icon, subject }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const open = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const close = useCallback(() => setAnchorEl(null), [])
  const withClose = useCallback((fn) => () => {
    fn()
    close()
  }, [close])

  if (!actions?.length) return null

  return (
    <FadeIn className={className} style={style}>
      <IconButton aria-controls='more-opts' aria-haspopup='true' onClick={open}>
        <Icon name={icon} customSize={size} additionalClasses={classes.icon} />
      </IconButton>
      <Menu
        id={id}
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
      >
        {(actions || []).filter(a => !a.disabled).map((action, index) => (
          <MenuItem key={`${id}_${action.key}`} onClick={withClose((e) => action.onClick(subject, e))}>
            <div className={classes.menuItem}>
              <Icon name={action.icon} />
              <div>{action.label}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </FadeIn>
  )
}

SydActionMenu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  })),
  icon: PropTypes.string,
  subject: PropTypes.any
}

SydActionMenu.defaultProps = {
  id: 'syd-actions',
  size: '24px',
  icon: ICON_NAMES.vertDots
}

export default SydActionMenu
