export function flatten (obj, delimiter = '.', maxDepth = Infinity, currentDepth = 0, prefix = '') {
  const result = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}${delimiter}${key}` : key

      if (typeof obj[key] === 'object' && obj[key] !== null && currentDepth < maxDepth) {
        const nestedObj = flatten(obj[key], delimiter, maxDepth, currentDepth + 1, newKey)
        Object.assign(result, nestedObj)
      } else {
        result[newKey] = obj[key]
      }
    }
  }

  return result
}
