import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import SydButton from '../../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../../api/coreData'
import { decipherError } from '../../../../../../../../utils/decipherError'
import FieldDisplay from '../../../../../../../molecules/FieldDisplay'

const useSubmitter = (onComplete, report, filter) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: removeDenaliReportFilter } = dangerZone.__useRemoveDenaliReportFilter()
  const onSubmit = useCallback(async () => {
    const command = {
      reportId: report.reportId,
      filterId: filter.firmReportFilterId
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await removeDenaliReportFilter(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, removeDenaliReportFilter, report, filter])

  return {
    submitter: onSubmit,
    processing,
    error
  }
}

function DeleteReportExportForm ({ report, filter, onCancel, onComplete }) {
  const { submitter, processing, error } = useSubmitter(onComplete, report, filter)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FieldDisplay
          fields={[
            { label: 'View', value: report.reportId },
            { label: 'Filter', value: filter.firmReportFilterId }
          ]}
        />
        <h2>Are you sure you want to permanently delete this filter?</h2>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            priority='warning'
            icon='delete'
            size='md'
            onClick={submitter}
          >
            Delete
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

DeleteReportExportForm.propTypes = {
  report: PropTypes.object,
  filter: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default DeleteReportExportForm
