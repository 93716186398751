import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import NotAvailable from '../../../organisms/NotAvailable'
import { useIsSchwabApiActive } from '../../../../hooks/featureChecks'
import Schwab from './Schwab'
import Callback from './Schwab/Callback'

const Partners = (prop) => {
  const { path } = useRouteMatch()

  const isActive = useIsSchwabApiActive()
  if (!isActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Partners'>
      <Switch>
        <Route exact path={`${path}/schwab/callback`}>
          <Callback />
        </Route>
        <Route exact path={`${path}/schwab`}>
          <Schwab />
        </Route>
      </Switch>
    </ErrorBoundary>
  )
}

export default Partners
