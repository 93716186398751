import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../api/coreData'
import SydInput from '../../../../../../commonDesign/SydInput'
import { decipherError } from '../../../../../../../utils/decipherError'
import SydLabel, { hookFormErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import Switch from '../../../../../../molecules/Switch'

const useSubmitter = (form, onComplete, report) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyReportAttributes } = dangerZone.__useModifyDenaliReportAttributes()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      reportId: report.reportId,
      name: formData.name || null,
      description: formData.description || null,
      imageUrl: formData.imageUrl || null,
      published: !!formData.published
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifyReportAttributes(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifyReportAttributes, report])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ModifyColumnsForm ({ report, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: report.name || '',
      description: report.description || '',
      published: report.published || false,
      imageUrl: report.imageUrl || ''
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, report)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={form.control}
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 128, message: 'Maximum 128 Characters' }
          }}
          render={f => (
            <SydLabel label='Name' required error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='description'
          control={form.control}
          rules={{
            maxLength: { value: 256, message: 'Maximum 256 Characters' }
          }}
          render={f => (
            <SydLabel label='Description' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='imageUrl'
          control={form.control}
          rules={{
            maxLength: { value: 255, message: 'Maximum 255 Characters' }
          }}
          render={f => (
            <SydLabel label='Image URL' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='published'
          control={form.control}
          render={f => (
            <SydLabel label='Published' error={hookFormErrorAdapter(form, f.fieldState)}>
              <Switch {...f.field} checked={f.field.value} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyColumnsForm.propTypes = {
  report: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyColumnsForm
