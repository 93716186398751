import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Checkbox } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import ScrollContent from '../../../../../molecules/ScrollContent'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import PersonalSpace from '../../../../../molecules/PersonalSpace'
import { useModifyIntegrationJobFields } from '../../../../../../api/integrations'
import BasicTable from '../../../../../atoms/BasicTable'

const useSubmitter = (form, onComplete, job) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyIntegrationJobFields } = useModifyIntegrationJobFields()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      jobConfigurationId: job.jobConfigurationId,
      fields: formData.fields.filter(x => x.isIncluded).map(x => x.fieldName)
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifyIntegrationJobFields(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to define keys')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifyIntegrationJobFields, job])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ModifyFieldsForm ({ job, source, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      fields: source?.fields || []
    }
  })
  const { fields } = useFieldArray({
    name: 'fields',
    control: form.control
  })

  const { submitter, processing } = useSubmitter(form, onComplete, job)
  const { formatter } = useFormattingContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScrollContent maxHeight='70vh'>
          <BasicTable>
            <thead>
              <tr>
                <th>Included?</th>
                <th>Is Key?</th>
                <th>Name</th>
                <th>Type</th>
                <th>Length</th>
                <th>Nullable</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, idx) => (
                <tr key={field.key}>
                  <td>
                    <Controller
                      name={`fields.${idx}.isIncluded`}
                      control={form.control}
                      render={(f) => (
                        <Checkbox {...f.field} checked={f.field.value} disabled={field.isKey} />
                      )}
                    />
                  </td>
                  <td>{field.isKey ? 'PK' : ''}</td>
                  <td>{field.fieldName}</td>
                  <td>{field.fieldType}</td>
                  <td>{field.length ? field.length : ((field.numericScale && field.numericPrecision) ? `(${field.numericScale}, ${field.numericPrecision})` : '')}</td>
                  <td>{formatter(field.nullable, 'bool')}</td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
          <PersonalSpace />
        </ScrollContent>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyFieldsForm.propTypes = {
  source: PropTypes.object,
  job: PropTypes.shape({
    domain: PropTypes.string,
    sourceKey: PropTypes.string,
    configuration: PropTypes.object
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyFieldsForm
