import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import Icon from '../components/atoms/Icon'
import { viewDisabledWarningMessage } from './viewDisabledWarningMessage'

const useStyles = makeStyles((theme) => ({
  warningMarker: {
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    backgroundColor: theme.palette.warning.main,
    fill: theme.palette.danger.contrast,
    color: theme.palette.danger.contrast,
    outlineStyle: 'solid',
    outlineColor: `color-mix(in srgb, ${theme.palette.warning.main} 90%, black)`,
    outlineWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.layout.radius.circle,
    minWidth: '30px',
    minHeight: '30px',
    boxShadow: theme.layout.shadow.normal,
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.warning.main} 60%, white)`,
      cursor: 'pointer'
    }
  },
  warningMarkerInside: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.warning.main,
    fill: theme.palette.danger.contrast,
    color: theme.palette.danger.contrast,
    outlineStyle: 'solid',
    outlineColor: `color-mix(in srgb, ${theme.palette.warning.main} 90%, black)`,
    outlineWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    minHeight: '20px',
    borderBottomRightRadius: theme.layout.radius.tight,
    boxShadow: theme.layout.shadow.normal,
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.warning.main} 60%, white)`,
      cursor: 'pointer'
    }
  },
  descriptionWrap: {
    '&.MuiTooltip-popper': {
      '& .MuiTooltip-tooltipPlacementTop': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        fontSize: theme.typography.fontSizes.xl,
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .MuiTooltip-tooltipPlacementBottom': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        fontSize: theme.typography.fontSizes.xl,
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      }
    }
  }
}))

function ViewEnablementWarning ({ isInWarning, viewOriginal, setViewOriginal, variant }) {
  const classes = useStyles()
  const size = variant === 'inside'
    ? '16px'
    : '22px'

  if (!isInWarning) {
    return null
  }

  return (
    <Tooltip
      classes={{
        popper: classes.descriptionWrap
      }}
      title={viewDisabledWarningMessage(viewOriginal)}
    >
      <div onClick={() => setViewOriginal(p => !p)} className={variant === 'inside' ? classes.warningMarkerInside : classes.warningMarker}>
        <Icon name='warning' customSize={size} style={{ marginTop: '-2px' }} />
      </div>
    </Tooltip>
  )
}

ViewEnablementWarning.propTypes = {
  isInWarning: PropTypes.bool,
  viewOriginal: PropTypes.bool,
  setViewOriginal: PropTypes.func,
  variant: PropTypes.oneOf(['over', 'inside'])
}

ViewEnablementWarning.defaultProps = {
  variant: 'inside'
}

export default ViewEnablementWarning
