import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  cardHeader: {
    marginTop: '-5px',
    paddingTop: theme.layout.padding.p10,
    paddingRight: theme.layout.padding.p10,
    paddingBottom: theme.layout.padding.p10,
    paddingLeft: theme.layout.padding.p10,
    fontSize: theme.typography.fontSizes.h5,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.g10,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

function InlineCardHeader ({ children, action }) {
  const classes = useStyles()
  return (
    <div className={classes.cardHeader}>
      <div>{children}</div>
      {action ? <div>{action}</div> : null}
    </div>
  )
}

InlineCardHeader.propTypes = {
  children: PropTypes.node,
  action: PropTypes.node
}

export default InlineCardHeader
