import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Box, makeStyles } from '@material-ui/core'
import { useGetInfographicTemplateDetails } from '../hooks/useGetInfographicTemplateDetails'
import SydButton from '../../../../../commonDesign/Button'
import Checkbox from '../../../../../molecules/Checkbox'
import SydInput from '../../../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import { useSaveInfographicTemplateMutation } from '../hooks/useSaveInfographicTemplateMutation'
import { useGetSelectedPageContent } from '../hooks/useGetSelectedPageContent'
import { ADMIN_ROUTES } from '../../../../../../constants'
import Loading from '../../../../../molecules/Loading'
import { AutocompleteClientSelect } from '../../components/AutocompleteClientSelect'
import { TemplatePageNavigation } from './TemplatePageNavigation'
import { TemplatePageDisplay } from './TemplatePageDisplay'
import { InfographicBlockList } from './InfographicBlockList'
import { SelectedBlockEditCard } from './SelectedBlockEditCard'

const useStyles = makeStyles((theme) => ({
  templateContentContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  selectedBlockInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem'
  },
  placeholder: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: '1.2rem'
  }
}))

export const EditInfographicTemplateForm = ({
  defaultTemplateId,
  onSubmit: _onSubmit
}) => {
  const classes = useStyles()
  const form = useForm({
    defaultValues: {
      infographicTemplateId: defaultTemplateId,
      content: {},
      name: ''
    }
  })

  if (!defaultTemplateId) {
    window.location.replace(ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE)
  }

  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [selectedBlockIndex, setSelectedBlockIndex] = useState(null)
  useEffect(() => {
    setSelectedBlockIndex(null)
  }, [currentPageIndex])

  const infographicTemplateId = form.getValues('infographicTemplateId')
  const templateContent = form.watch('content')
  const templateIsGlobal = form.watch('global')

  const { data, isFetching, refetch } = useGetInfographicTemplateDetails({
    infographicTemplateId: infographicTemplateId,
    includes: { levelTypes: true, infographicBlocks: true }
  }, { enabled: !!infographicTemplateId && infographicTemplateId !== 'NEW' })

  useEffect(() => {
    if (infographicTemplateId && data) {
      form.reset(data)
    }
    if (infographicTemplateId === 'NEW') {
      form.setValue('content', {
        pages: [{ blocks: [] }]
      })
      form.setValue('name', '')
    }
  }, [data, form, infographicTemplateId])

  const currentPageContentBlocks = useGetSelectedPageContent(currentPageIndex, templateContent)
  const selectedBlock = useMemo(() => {
    if (!currentPageContentBlocks || selectedBlockIndex === null) return null
    return currentPageContentBlocks?.[selectedBlockIndex] ?? null
  }, [currentPageContentBlocks, selectedBlockIndex])

  const handleOnChange = (key, value) => {
    const existingContent = form.getValues('content')
    const newValue = {
      ...existingContent,
      pages: existingContent.pages.map((p, pi) => {
        if (pi !== currentPageIndex) return p
        return {
          ...p,
          blocks: currentPageContentBlocks.map((b, bi) => bi === selectedBlockIndex ? {
            ...b,
            [key]: value
          } : b)?.filter(x => !x.isEmptyBlock)
        }
      })
    }
    return form.setValue('content', newValue)
  }

  const addBlockToPage = (block) => {
    if (!selectedBlock.isEmptyBlock) return

    const newValue = { ...templateContent }
    newValue.pages[currentPageIndex].blocks.push({
      infographicBlockId: block.infographicBlockId,
      infographicBlock: block,
      cords: selectedBlock.cords,
      size: {
        rows: Math.min(block.metadata.size.rows, selectedBlock.size.rows),
        cols: Math.min(block.metadata.size.cols, selectedBlock.size.cols)
      }
    })
    form.setValue('content', newValue)
    setSelectedBlockIndex(newValue.pages[currentPageIndex].blocks.length - 1)
  }

  const saveMutation = useSaveInfographicTemplateMutation()
  const onSubmit = async (data) => {
    const normalizedContent = {
      ...data.content,
      pages: data.content?.pages.map(page => ({
        ...page,
        blocks: page.blocks.map(({ infographicBlock, ...block }) => block)
      }))
    }
    const response = await saveMutation.mutateAsync({
      ...data,
      infographicTemplateId: data.infographicTemplateId === 'NEW' ? null : data.infographicTemplateId,
      content: normalizedContent
    })
    await refetch()
    const parsedBody = JSON.parse(response.body)
    if (parsedBody.infographicTemplateId !== infographicTemplateId) {
      window.location.push(`${ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE}/${parsedBody.infographicTemplateId}`)
    }
  }

  return (
    <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
      <Box display='flex' gridGap='8px' flexWrap='wrap'>
        {isFetching ? (
          <Box flex={1} alignItems='center' justifyContent='center'>
            <Loading />
          </Box>
        ) : (
          <>
            <Box flex='1 1' maxWidth='500px'>
              <Controller
                control={form.control}
                name='name'
                rules={{ required: { value: true, message: 'Name required' } }}
                render={({ field, fieldState }) => (
                  <SydLabel label='Template Name' error={hookFormErrorAdapter(form, fieldState)} required>
                    <SydInput
                      size='sm'
                      {...field}
                    />
                  </SydLabel>
                )}
              />

              <Controller
                control={form.control}
                name='global'
                render={({ field, fieldState }) => (
                  <Box display='flex' gridGap={4} mt={2}>
                    <Checkbox
                      checked={!!field.value}
                      onChange={(e, checked) => field.onChange(checked)}
                    />
                    <Box onClick={() => field.onChange(!field.value)}>
                      <SydLabel
                        label='Is Global template'
                        description='Template will be available to all clients'
                      />
                    </Box>
                  </Box>
                )}
              />

              {!templateIsGlobal && (
                <Box mt={2}>
                  <Controller
                    control={form.control}
                    name='levelTypes'
                    render={({ field, fieldState }) => (
                      <SydLabel label='Limited to Clients' error={fieldState?.error?.message}>
                        <AutocompleteClientSelect field={field} multiple />
                      </SydLabel>
                    )}
                  />
                </Box>
              )}

              {selectedBlock?.isEmptyBlock && (
                <>
                  <InfographicBlockList
                    sizeFilter={selectedBlock?.size}
                    onAddBlockToPage={addBlockToPage}
                  />
                </>
              )}

              {!!selectedBlock && !selectedBlock.isEmptyBlock && (
                <Box mt={4}>
                  <SelectedBlockEditCard block={selectedBlock} onBlockChange={handleOnChange} />
                </Box>
              )}

              <Box display='flex' justifyContent='space-between' mt={4}>
                <SydButton
                  variant='secondary'
                  type='button'
                  onClick={() => form.setValue('infographicTemplateId', null)}
                  disabled={saveMutation.isLoading}
                >
                  Cancel
                </SydButton>
                <SydButton
                  variant='primary'
                  type='submit'
                  processing={saveMutation.isLoading}
                  disabled={saveMutation.isLoading}
                >
                  Save
                </SydButton>
              </Box>
            </Box>
            <Box flex='1 0'>
              <div className={classes.templateContentContainer}>
                <TemplatePageNavigation
                  templateContent={templateContent}
                  onSetContent={(val) => form.setValue('content', val)}
                  currentPageIndex={currentPageIndex}
                  onSetCurrentPageIndex={setCurrentPageIndex}
                />

                <TemplatePageDisplay
                  content={currentPageContentBlocks}
                  templateContent={templateContent}
                  onSetContent={(content) => form.setValue('content', content)}
                  selectedBlockIndex={selectedBlockIndex}
                  onSetSelectedBlockIndex={setSelectedBlockIndex}
                />
              </div>
            </Box>
          </>
        )}
      </Box>
    </form>
  )
}

EditInfographicTemplateForm.propTypes = {
  defaultTemplateId: PropTypes.number,
  onSubmit: PropTypes.func
}
