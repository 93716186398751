import React, { useCallback, useContext, useState } from 'react'
import { Menu, MenuItem, makeStyles, IconButton, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { TemplateContext } from './index'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  },
  danger: {
    color: theme.palette.danger.main
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.layout.radius.circle,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.layout.shadow.normal
  }
}))

function TemplateItemActionMenu ({ templateItem, size }) {
  const classes = useStyles()
  const templateContext = useContext(TemplateContext)
  const [anchorEl, setAnchorEl] = useState()
  const open = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const close = useCallback(() => setAnchorEl(null), [])
  const withClose = useCallback((fn) => () => {
    fn()
    close()
  }, [close])

  return (
    <FadeIn className={classes.entryActions}>
      <IconButton aria-controls='more-opts' aria-haspopup='true' onClick={open}>
        <Icon name={ICON_NAMES.vertDots} customSize={size} additionalClasses={classes.icon} />
      </IconButton>
      <Menu
        id='template-item-actions'
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
      >
        <MenuItem onClick={withClose(() => templateContext.editRef.current.open(templateItem))}>
          <div className={classes.menuItem}>
            <Icon name='edit' />
            <div>Edit</div>
          </div>
        </MenuItem>
        <MenuItem onClick={withClose(() => templateContext.moveRef.current.open(templateItem))}>
          <div className={classes.menuItem}>
            <Icon name='expand' />
            <div>Move</div>
          </div>
        </MenuItem>
        {templateItem.options?.length ? null : (
          <MenuItem onClick={withClose(() => templateContext.addRef.current.open(templateItem))}>
            <div className={classes.menuItem}>
              <Icon name='add' />
              <div>Add Child</div>
            </div>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={withClose(() => templateContext.deleteRef.current.open(templateItem))}>
          <div className={clsx(classes.menuItem, classes.danger)}>
            <Icon name='delete' />
            <div>Remove</div>
          </div>
        </MenuItem>
      </Menu>
    </FadeIn>
  )
}

TemplateItemActionMenu.propTypes = {
  templateItem: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TemplateItemActionMenu.defaultProps = {
  size: '24px'
}

export default TemplateItemActionMenu
