import React from 'react'
import { makeStyles } from '@material-ui/core'
import { usePipelineEditor } from '../PipelineEditorContext'
import CodeTag from '../../../../../../atoms/CodeTag'
import PersonalSpace from '../../../../../../molecules/PersonalSpace'
import CodeBlock from '../../../../../../atoms/CodeBlock'

const useStyles = makeStyles((theme) => ({
  fieldInfo: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.lighter,
    boxSizing: 'border-box',
    overflowY: 'auto',
    padding: theme.layout.padding.p5,
    '& .__list': {
      marginBottom: theme.layout.margin.m5
    },
    '& .__type': {
      color: theme.palette.text.secondary
    }
  }
}))

function FieldInfo () {
  const classes = useStyles()
  const { analysis } = usePipelineEditor()

  if (!analysis?.fields?.length) {
    return (
      <div className={classes.fieldInfo}>
        <header>Field Accessors</header>
        <CodeBlock code={analysis} />
      </div>
    )
  }

  return (
    <div className={classes.fieldInfo}>
      <header>Field Accessors</header>
      <ul>
        {analysis.fields.map(field => (
          <li key={field.accessor} className='__list'>
            <CodeTag><span>{field.accessor}</span> <span className='__type'>({field.type})</span></CodeTag>
          </li>
        ))}
      </ul>
      <PersonalSpace />
    </div>
  )
}

export default FieldInfo
