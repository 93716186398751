import ace from 'ace-builds'
// Ensure base JSON mode is available
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-xcode'

const JsonMode = ace.require('ace/mode/json').Mode
const TextHighlightRules = ace.require('ace/mode/text_highlight_rules').TextHighlightRules

const JsonPathHighlightRules = function () {
  this.$rules = {
    start: [
      {
        token: 'path',
        regex: /\$\.[a-zA-Z_][a-zA-Z0-9_]*(\.[a-zA-Z_][a-zA-Z0-9_]*)*/
      }
    ]
  }
}

JsonPathHighlightRules.prototype = new TextHighlightRules()

export class JsonWithPathsMode extends JsonMode {
  constructor () {
    super()
    this.HighlightRules = JsonPathHighlightRules
  }
}
