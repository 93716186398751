import { useMemo } from 'react'
import { statusMap } from '../constants/statusMap'
import { cellTemplates } from '../constants/cellTemplates'
import ReportRunActionCell from '../components/ReportRunActionCell'
import { ReportRunFileCell } from '../components/ReportRunFileCell'

export const useInfographicRunColumnConfig = ({
  classes = {},
  includeRunBy = true,
  includeTemplate = true,
  isTableLoading,
  onPostRerunReport,
  onSetPostToClientVault
}) => {
  return useMemo(() => ({
    columns: [
      { Header: 'Run Date', accessor: 'createdDate', id: 'createdDate', Cell: cellTemplates.date, disableSortBy: false },
      { Header: 'Report Name', accessor: 'title', id: 'title', disableSortBy: false },
      { Header: 'As of Date', accessor: 'inputParams.endDate', id: 'endDate', Cell: cellTemplates.date, disableSortBy: true },
      { Header: 'Client Name', accessor: 'clientName', id: 'clientName', disableSortBy: true },
      ...(includeTemplate ? [{
        Header: 'Template',
        accessor: 'infographicTemplate.name',
        id: 'templateName',
        disableSortBy: true
      }] : []),
      ...(includeRunBy ? [{
        Header: 'Run by',
        accessor: 'createdByLastName',
        id: 'createdByLastName',
        Cell: cellTemplates.createdBy,
        disableSortBy: false
      }] : []),
      { Header: 'Status', accessor: 'statusDescription', id: 'status', Cell: cellTemplates.status({ classes, statusMap }) },
      { Header: 'Files', accessor: 'returnTypes', id: 'returnTypes', Cell: ({ row }) => ReportRunFileCell({ row }), disableSortBy: true },
      {
        Header: 'Actions',
        accessor: 'reportRunId',
        id: 'reportRunId',
        Cell: ({ row }) => ReportRunActionCell({
          row,
          isTableLoading,
          onPostRerunReport,
          onSetPostToClientVault
        }),
        disableSortBy: true
      }
    ],
    defaultSort: [
      { id: 'createdDate', desc: true }
    ],
    defaultPageSize: 20
  }), [includeTemplate, includeRunBy, classes, isTableLoading, onPostRerunReport, onSetPostToClientVault])
}
