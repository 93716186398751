/**
 * @typedef OpSchemaItem
 * @property {string} label
 * @property {('number'|'string')[]} validFor
 * @property {boolean?} unary
 * @property {function} parse
 */

export const combinators = ['$and', '$or']

const parseScalar = (v) => Array.isArray(v) ? v.join(',') : v
const parseVoid = (_) => undefined
const parseArray = (v) => v?.split(',')

/**
 * @type {Record<string, OpSchemaItem>}
 */
export const opSchema = {
  eq: {
    label: 'is equal to',
    validFor: ['number', 'string'],
    parse: parseScalar
  },
  neq: {
    label: 'is not equal to',
    validFor: ['number', 'string'],
    parse: parseScalar
  },
  gt: {
    label: 'is greater than',
    validFor: ['number'],
    parse: parseScalar
  },
  gte: {
    label: 'is greater than or equal to',
    validFor: ['number'],
    parse: parseScalar
  },
  lt: {
    label: 'is less than',
    validFor: ['number'],
    parse: parseScalar
  },
  lte: {
    label: 'is less than or equal to',
    validFor: ['number'],
    parse: parseScalar
  },
  in: {
    label: 'in the set of',
    validFor: ['number', 'string'],
    parse: parseArray
  },
  notIn: {
    label: 'is not in the set of',
    validFor: ['number', 'string'],
    parse: parseArray
  },
  exists: {
    label: 'has a value',
    validFor: ['number', 'string'],
    unary: true,
    parse: parseVoid
  },
  missing: {
    label: 'does not have a value',
    validFor: ['number', 'string'],
    unary: true,
    parse: parseVoid
  },
  notNull: {
    label: 'is not null',
    validFor: ['number', 'string'],
    unary: true,
    parse: parseVoid
  },
  isNull: {
    label: 'is null',
    validFor: ['number', 'string'],
    unary: true,
    parse: parseVoid
  },
  exact: {
    label: 'exactly matches',
    validFor: ['string'],
    parse: parseScalar
  },
  startsWith: {
    label: 'starts with',
    validFor: ['string'],
    parse: parseScalar
  },
  endsWith: {
    label: 'ends with',
    validFor: ['string'],
    parse: parseScalar
  },
  contains: {
    label: 'contains',
    validFor: ['string'],
    parse: parseScalar
  },
  notStartsWith: {
    label: 'does not start with',
    validFor: ['string'],
    parse: parseScalar
  },
  notEndsWith: {
    label: 'does not end with',
    validFor: ['string'],
    parse: parseScalar
  },
  notContains: {
    label: 'does not contain',
    validFor: ['string'],
    parse: parseScalar
  }
}
