import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, useTheme } from '@material-ui/core'
import Tag from '../../../../../atoms/Tag'
import SydModal from '../../../../../commonDesign/SydModal'
import PresentationTable from '../../../../../organisms/PresentationTable'

export const InfographicTemplateLevelCell = ({ row, value, defaultDisplayCount = 1 }) => {
  const [showModal, setShowModal] = useState(false)
  const labelsToDisplay = value.length > defaultDisplayCount ? value.slice(0, defaultDisplayCount) : value
  const theme = useTheme()

  return (
    <>
      <Box display='flex' gridGap={2} flexWrap='wrap'>
        {labelsToDisplay.map(({ levelTypeId, label }) => (
          <Tag
            key={levelTypeId}
            fontSize={12}
            label={label}
            backgroundColor={theme.palette.primary.main}
          />
        ))}
        {value?.length > defaultDisplayCount && (
          <div>
            <Tag
              clickable
              fontSize={12}
              backgroundColor={theme.palette.secondary.main}
              label={`+ ${value.length - defaultDisplayCount} More`}
              onClick={() => setShowModal(!showModal)}
            />
          </div>
        )}
      </Box>
      {showModal && (
        <SydModal
          disablePortal={false}
          open
          title={`All linked clients for ${row.original.name}`}
          onClose={() => setShowModal(false)}
        >
          <Box>
            <PresentationTable.Wrapper>
              <PresentationTable
                columns={[
                  { Header: 'Client Id', accessor: 'levelId', width: 40 },
                  { Header: 'Name', accessor: 'label' }
                ]}
                data={value}
              />
            </PresentationTable.Wrapper>
          </Box>
        </SydModal>
      )}
    </>
  )
}

InfographicTemplateLevelCell.propTypes = {
  value: PropTypes.arrayOf({
    label: PropTypes.string,
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number
  }),
  row: PropTypes.shape({
    original: PropTypes.shape({
      infographicTemplateId: PropTypes.string,
      name: PropTypes.string,
      ordinal: PropTypes.number,
      internal: PropTypes.bool
    })
  }),
  defaultDisplayCount: PropTypes.number
}
