import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../Transitions/FadeIn'
import SydButton from '../../commonDesign/Button'

const useStyles = makeStyles(theme => ({
  errorDisplay: {
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.danger.main,
    borderRadius: theme.layout.radius.loose,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g20,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '10vh',
    '&.__error-content': {
      fontSize: theme.typography.fontSizes.xxl
    },
    '&.__value': {
      paddingLeft: theme.layout.padding.p5
    }
  }
}))

const ErrorDisplay = ({ error, onRetry }) => {
  const classes = useStyles()
  const message = useMemo(() => {
    if (error?.response?.message) {
      return error.response.message
    }

    return error.toString()
  }, [error])
  return (
    <FadeIn className={classes.errorDisplay}>
      <div className='__error-content'>
        {message}
      </div>
      {onRetry ? (
        <div className='__error-actions'>
          <SydButton variant='primary' priority='warning' onClick={onRetry} icon='sync'>Retry</SydButton>
        </div>
      ) : null}
    </FadeIn>
  )
}

ErrorDisplay.propTypes = {
  error: PropTypes.any,
  onRetry: PropTypes.func
}

export default ErrorDisplay
