import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { IconButton } from '@material-ui/core'
import SydButton from '../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import ScrollContent from '../../../../../../molecules/ScrollContent'
import PersonalSpace from '../../../../../../molecules/PersonalSpace'
import { dangerZone } from '../../../../../../../api/coreData'
import Loading from '../../../../../../molecules/Loading'
import BasicTable from '../../../../../../atoms/BasicTable'
import Icon from '../../../../../../atoms/Icon'
import FlexRow from '../../../../../../molecules/FlexRow'
import Switch from '../../../../../../molecules/Switch'
import SydInput from '../../../../../../commonDesign/SydInput'
import SydSelect from '../../../../../../commonDesign/SydSelect'
import Card from '../../../../../../molecules/Card'
import { decipherError } from '../../../../../../../utils/decipherError'

const typeOptions = [
  { label: 'String', value: 'string' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Boolean', value: 'boolean' }
]

const typeMapping = {
  text: 'string',
  varchar: 'string',
  nvarchar: 'string',
  decimal: 'number',
  int: 'number',
  bigint: 'number',
  tinyint: 'boolean',
  date: 'date',
  datetime: 'date',
  timestamp: 'date'
}

function mapType (col) {
  const type = typeMapping[col.columnType] || 'string'

  return {
    type,
    search: type === 'string'
  }
}

const useSubmitter = (form, onComplete, report) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyReportColumns } = dangerZone.__useModifyDenaliReportColumns()
  const onSubmit = useCallback(async (formData) => {
    const columns = (formData.columns || []).reduce((prev, col, idx) => {
      prev[col.columnId] = {
        ordinal: idx,
        type: col.type,
        search: !!col.search,
        format: col.format || null,
        title: col.title || null
      }
      return prev
    }, {})

    const command = {
      reportId: report.reportId,
      columns: columns
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifyReportColumns(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifyReportColumns, report])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ModifyColumnsForm ({ report, initialValues, onCancel, onComplete }) {
  const { data: tableInfo, isLoading } = dangerZone.__useGetDenaliReportTableInfo(report.reportId)
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      columns: initialValues || []
    }
  })
  const { fields, remove, move, append } = useFieldArray({
    name: 'columns',
    control: form.control
  })

  const availableColumns = useMemo(() => {
    const tc = tableInfo?.tableInfo?.columns || []
    return tc.filter(x => !fields.some(y => y.columnId === x.columnName)).map(x => ({
      columnId: x.columnName,
      title: x.columnName,
      type: 'string',
      format: '',
      search: false,
      ...mapType(x)
    }))
  }, [fields, tableInfo])
  const availableColumnOptions = useMemo(() => {
    return availableColumns.map(ac => ({
      label: ac.columnId,
      value: ac.columnId
    }))
  }, [availableColumns])

  const { submitter, processing, error } = useSubmitter(form, onComplete, report)
  const itemFns = useCallback((index, length) => {
    return {
      down: () => {
        const next = index + 1 >= length ? 0 : index + 1
        return move(index, next)
      },
      up: () => {
        const next = index - 1 < 0 ? length - 1 : index - 1
        return move(index, next)
      },
      top: () => move(index, 0),
      bottom: () => move(index, length - 1),
      remove: () => remove(index)
    }
  }, [move, remove])
  const [newColVal, setNewColVal] = useState(null)
  const addColumn = useCallback(() => {
    const selectedColumn = newColVal
    const col = availableColumns.find(x => x.columnId === selectedColumn)
    if (col) {
      append({ ...col })
      setNewColVal(null)
    }
  }, [newColVal, setNewColVal, availableColumns, append])
  const addAllColumns = useCallback(() => {
    availableColumns.forEach(col => {
      append({ ...col })
    })
  }, [availableColumns, append])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScrollContent maxHeight='70vh'>
          <BasicTable>
            <thead>
              <tr>
                <th>Position</th>
                <th>Column ID</th>
                <th>Display Name</th>
                <th>Type</th>
                <th>Format String</th>
                <th>Incl in Search</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {fields.map((field, idx) => {
                const fns = itemFns(idx, fields?.length)
                return (
                  <tr key={field.id}>
                    <td>
                      <FlexRow>
                        <SydButton size='xs' variant='ghost' onClick={fns.top}>Top</SydButton>
                        <IconButton onClick={fns.up}>
                          <Icon name='up' size={20} />
                        </IconButton>
                        <IconButton onClick={fns.down}>
                          <Icon name='down' size={20} />
                        </IconButton>
                        <SydButton size='xs' variant='ghost' onClick={fns.bottom}>Bottom</SydButton>
                      </FlexRow>
                    </td>
                    <td>
                      <FlexRow>
                        <div>{field.columnId}</div>
                        {tableInfo?.tableInfo?.columns?.some(column => (column.columnName === field.columnId)) ? (
                          <Icon name='check' size={20} />
                        ) : (
                          <Icon name='close' size={20} />
                        )}
                      </FlexRow>
                    </td>
                    <td>
                      <Controller
                        name={`columns.${idx}.title`}
                        control={form.control}
                        render={(f) => (
                          <SydInput minWidth='0px' size='sm' {...f.field} />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`columns.${idx}.type`}
                        control={form.control}
                        render={(f) => (
                          <SydSelect minWidth='0px' size='sm' {...f.field} options={typeOptions} />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`columns.${idx}.format`}
                        control={form.control}
                        render={(f) => (
                          <SydInput minWidth='0px' size='sm' {...f.field} placeholder='Not Set' />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`columns.${idx}.search`}
                        control={form.control}
                        render={(f) => (
                          <Switch {...f.field} checked={f.field.value} />
                        )}
                      />
                    </td>
                    <td>
                      <FlexRow>
                        <IconButton onClick={fns.remove}>
                          <Icon name='close' size={20} />
                        </IconButton>
                      </FlexRow>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </BasicTable>
          {availableColumns.length ? (
            <Card variant='clear'>
              <FlexRow>
                <SydSelect options={availableColumnOptions} value={newColVal} onChange={(e) => setNewColVal(e.target.value)} size='sm' />
                <SydButton variant='primary' icon='add' onClick={addColumn} size='sm' disabled={!newColVal}>Add Column</SydButton>
                <SydButton variant='ghost' icon='addCircle' onClick={addAllColumns} size='sm'>Add All</SydButton>
              </FlexRow>
            </Card>
          ) : (
            <div><em>No more columns available</em></div>
          )}
          <PersonalSpace />
        </ScrollContent>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyColumnsForm.propTypes = {
  report: PropTypes.object,
  initialValues: PropTypes.array,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyColumnsForm
