import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Loading from '../../molecules/Loading'
import { useListAbundanceEngineViews } from '../../../api/abundanceEngine'
import SydSelectBox from '../../commonDesign/SydSelectBox'
import SydInput from '../../commonDesign/SydInput'

const query = {
  includes: {
    contents: false
  }
}

function ViewSelect ({ value, onChange }) {
  const { data, isLoading, error } = useListAbundanceEngineViews(query)
  const options = useMemo(() => {
    const result = (data?.rows || []).map(v => ({
      label: v.name || v.path || v.viewId,
      value: v.viewId
    }))

    if (result.length) {
      result.push({ label: '<Not Assigned>', value: null })
    }

    return result.sort((a, b) => a.label.localeCompare(b.label))
  }, [data])
  const selectedOption = useMemo(() => {
    return (options || []).find(x => x.value === value)
  }, [options, value])

  if (error) {
    return (
      <div>
        There was a problem loading the list
      </div>
    )
  }

  if (isLoading) {
    return (
      <div>
        <Loading size={10} />
      </div>
    )
  }

  if (!options.length) {
    return (
      <div>
        No views available
      </div>
    )
  }

  return (
    <div>
      <SydInput disabled value={selectedOption.label} size='sm' />
      <SydSelectBox
        placeholder='Select an disabled-fallback-view'
        options={options}
        onChange={(value) => onChange({ target: { value } })}
        value={value}
      />
    </div>
  )
}

ViewSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default ViewSelect
