import { useMutation } from '@tanstack/react-query'
import { postNamedCommand } from '../../../../../service'

export const useRetryInfographicReportRunMutation = () => {
  return useMutation({
    mutationFn: async (body) => {
      const { data } = await postNamedCommand('reports', 'retry-infographic-report-run', body)
      return data
    }
  })
}
