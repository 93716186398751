import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createClient,
  deleteClient,
  editClient,
  fetchClient,
  fetchClientBalances,
  fetchClients,
  fetchClientsBalanceActivity,
  getClientBillingInfo,
  postNamedCommand,
  postNamedQuery
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE } from '../constants'
import { QUERY_KEYS } from './queryKeys'

export const useRecentClients = (take = 10) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.recentClients, take, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-recent-clients', {
        resolveAvatar: true,
        take
      })
      return data.clients
    }
  })
}

export const useInvalidateRecentClients = () => {
  const { userId } = useAppContext()
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient
      .invalidateQueries({
        queryKey: [QUERY_KEYS.recentClients, userId]
      })
      .catch(console.error)
  }, [queryClient, userId])
}

export const useInvalidateClientsQueries = () => {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEYS.getClient]).catch(console.error)
    queryClient.invalidateQueries([QUERY_KEYS.getCurrentClient]).catch(console.error)
    queryClient.invalidateQueries([QUERY_KEYS.searchClients]).catch(console.error)
    queryClient.invalidateQueries([QUERY_KEYS.searchClientsMultiple]).catch(console.error)
  }, [queryClient])
}

export const useInvalidateClientSearch = () => {
  const client = useQueryClient()
  const { userId } = useAppContext()
  return useCallback(() => {
    console.debug('invalidating', QUERY_KEYS.searchClients)
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.searchClients, userId],
      refetchType: 'all'
    }).catch(console.error)
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.searchClientsMultiple, userId],
      refetchType: 'all'
    }).catch(console.error)
  }, [client, userId])
}

export const useGetClients = (query) => {
  const { userId } = useAppContext()
  const { searchValue = '', take = DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE } = query
  return useQuery({
    queryKey: [QUERY_KEYS.getClients, userId, searchValue],
    queryFn: async () => {
      const { data } = await fetchClients({
        take,
        userId,
        search: searchValue,
        source: 'useGetClients'
      })
      return data
    }
  })
}

export const useGetAssociatedClients = (query, internalUserId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.getAssociatedClients, query, userId, internalUserId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'searchClients', query)
      return data
    },
    enabled: !!internalUserId
  })
}

export const useCurrentClient = () => {
  const { userId, clientId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getCurrentClient, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-client-by-id', {
        clientId,
        resolveAvatar: true
      })

      return data.client
    }
  })
}

export const useClient = (clientId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.getClient, userId, clientId],
    queryFn: async () => {
      const { data } = await fetchClient(clientId)
      return data
    },
    enabled
  })
}

export const useClientBillingInfo = (clientId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.getClientBillingInfo, userId, clientId],
    queryFn: async () => {
      const { data } = await getClientBillingInfo(clientId)
      return data
    },
    enabled
  })
}

export const useSearchClients = (query, options = {}, onSuccess) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchClients, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'levels',
        'searchClients',
        query
      )
      return data
    },
    enabled,
    select: mapper,
    onSuccess
  })
}

export const useSearchClientsMultiple = (query, mapper = null, enabled = true) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchClientsMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'levels',
        'searchClientsMultiple',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useRemoveClient = (id, onSuccess, onSettled) => {
  return useMutation({
    mutationFn: async () => {
      await deleteClient(id)
    },
    onSuccess,
    onSettled
  })
}

export const useInfiniteSearchClients = (defaultQuery, searchQuery, searchFilters, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper } = queryOptions

  return useInfiniteQuery({
    queryKey: [
      QUERY_KEYS.infiniteSearchClients,
      userId,
      searchQuery,
      searchFilters
    ],
    queryFn: async ({
      pageParam = {
        ...defaultQuery,
        textSearch: searchQuery,
        filters: searchFilters
      }
    }) => {
      const { data } = await postNamedQuery(
        'levels',
        'searchClients',
        pageParam
      )
      return data
    },
    select: mapper
  })
}

export const useClientBalancesMultiple = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options

  const queryMap = useMemo(() => {
    return queries.map((query) => ({
      queryKey: [QUERY_KEYS.clientBalances, userId, query],
      queryFn: async () => {
        const { data } = await fetchClientBalances(query)
        return data
      },
      select: mapper,
      enabled
    }))
  }, [enabled, mapper, queries, userId])

  const results = useQueries({
    queries: queryMap
  })

  /** React Query returns a new array every render, this helps determine us memoize the result array */
  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  const [safeResult, setSafeResult] = useState(results)
  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  return safeResult
}

export const useClientBalances = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, queryKey, enabled = true } = options

  return useQuery({
    queryKey: [queryKey || QUERY_KEYS.clientBalances, userId, query],
    queryFn: async () => {
      const { data } = await fetchClientBalances(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useRecentlyViewedCards = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options

  return useQuery({
    queryKey: [QUERY_KEYS.recentlyViewedClients, userId, query],
    queryFn: async () => {
      const { data } = await fetchClientsBalanceActivity(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useClientTeamMembers = (clientId, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions

  return useQuery({
    queryKey: [QUERY_KEYS.clientTeamMembers, userId, clientId],
    queryFn: async () => {
      if (!clientId) return null
      const { data } = await postNamedQuery('levels', 'get-client-team-members', {
        clientId
      })
      return data?.teamMembers || []
    },
    select: mapper,
    enabled
  })
}

export const useTeamMembers = (queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions

  return useQuery({
    queryKey: [QUERY_KEYS.teamMembers, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-team-members', {
      })
      return data?.teamMembers || []
    },
    select: mapper,
    enabled
  })
}

export const useModifyClientTeamMembersMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-team-members', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { clientId } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.clientTeamMembers, userId, clientId], exact: true }).catch(console.error)
    }
  })
}

export const useModifyClientAccountsMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-accounts', command)

      return data
    }
  })
}

export const useModifyClientGroupsMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'replace-client-groups', command)

      return data
    }
  })
}

export const useSaveClientMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { clientId, client } = command
      const { data } = await editClient(clientId, { client })

      return data
    }
  })
}

export const useCreateClientMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { client } = command
      const { data } = await createClient({ client })

      return data
    }
  })
}

export const useDeleteClientMutation = () => {
  return useMutation({
    mutationFn: async (clientId) => {
      await deleteClient(clientId)
    }
  })
}

export const useListAssignedClientPeople = (clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.listAssignedClientPeople, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-client-people', {
        clientId
      })

      return data
    }
  })
}

export const useModifyClientPersonsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-persons', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { clientId } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listAssignedClientPeople, userId, clientId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAllPeople], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAllPeopleMultiple], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAssignedPeople], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAssignedPeopleMultiple], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyClientInfoMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'modify-client-info', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { clientId } = variables
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getCurrentClient, userId, clientId], exact: true }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchClients], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchClientsMultiple], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.recentClients], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.infiniteSearchClients], refetchType: 'all' }).catch(console.error)
    }
  })
}

/** Pulls EVERY client you have access to */
export const useFullClientSearch = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['clients.full-client-search', userId, JSON.stringify(query || null)],
    queryFn: async () => {
      const pages = []
      let skip = 0
      let keepFetching = true
      const take = 1000
      while (keepFetching) {
        const { data } = await postNamedQuery('levels', 'searchClients', {
          ...(query || {}),
          includes: {
            ...(query.includes || {}),
            inUserBookOfBusiness: true // for "my-clients"
          },
          take,
          skip
        })
        pages.push(data.data)
        if (data.data.length < take) {
          keepFetching = false
        } else {
          skip += take
        }
      }
      return pages.flatMap(x => x)
    }
  })
}
