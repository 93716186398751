import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SuperLongDescriptionPanel from './SuperLongDescriptionPanel'
import ManageOptionsDialog from './ManageOptionsDialog'

const StatusReportInternalContext = createContext({})

export const useInternalReportContext = () => {
  return useContext(StatusReportInternalContext)
}

function InternalStatusReportContextProvider ({ onRefetch, children, data, expanded, statuses, reportOptions, report }) {
  const [expandState, setExpandState] = useState({})
  const toggleExpand = useCallback((e, item) => {
    setExpandState(prev => ({
      ...prev,
      [item.codeName]: !prev[item.codeName]
    }))
  }, [setExpandState])

  const getExpandState = useCallback((item, defaultExpand) => {
    return (item.codeName in expandState ? expandState[item.codeName] : defaultExpand) ?? false
  }, [expandState])

  const flattenedData = useMemo(() => {
    const flatten = (agg, children) => {
      children.forEach(child => {
        agg.push(child)
        if (child.items) {
          flatten(agg, child.items)
        }
      })
      return agg
    }
    return {
      data: flatten([], data),
      id: data?.at(0)?.statusReportId || -1
    }
  }, [data])

  /* eslint-disable react-hooks/exhaustive-deps */

  // Maybe all of this should be moved out of here so we don't have to use useEffect
  useEffect(() => {
    if (expanded) {
      setExpandState(flattenedData.data.reduce((prev, cur) => {
        prev[cur.codeName] = true
        return prev
      }, {}))
    } else {
      setExpandState(flattenedData.data.reduce((prev, cur) => {
        prev[cur.codeName] = false
        return prev
      }, {}))
    }
  }, [flattenedData.id, expanded, setExpandState])
  /* eslint-enable react-hooks/exhaustive-deps */
  const descriptionPanelRef = useRef()
  const manageOptionsRef = useRef()

  const manager = useMemo(() => {
    return {
      report,
      onRefetch,
      toggleExpand,
      getExpandState,
      statuses,
      openManageOptionsDialog: (...params) => manageOptionsRef.current.open(...params),
      openDescriptionPanel: (...params) => descriptionPanelRef.current.open(...params),
      reportOptions,
      allowManagement: report?.allowManagement
    }
  }, [onRefetch, toggleExpand, getExpandState, statuses, descriptionPanelRef, manageOptionsRef, reportOptions, report])

  return (
    <StatusReportInternalContext.Provider value={manager}>
      {children}
      <SuperLongDescriptionPanel ref={descriptionPanelRef} />
      <ManageOptionsDialog ref={manageOptionsRef} />
    </StatusReportInternalContext.Provider>
  )
}

InternalStatusReportContextProvider.propTypes = {
  children: PropTypes.node,
  onRefetch: PropTypes.func,
  data: PropTypes.array,
  expanded: PropTypes.bool,
  statuses: PropTypes.array,
  reportOptions: PropTypes.object,
  report: PropTypes.object
}

export default InternalStatusReportContextProvider
