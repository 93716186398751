import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import BasicTable from '../../../../atoms/BasicTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import FlexRow from '../../../../molecules/FlexRow'
import SydActionMenu from '../../../../commonDesign/SydActionMenu'
import { useGetIntegrationSourceById } from '../../../../../api/integrations'
import Loading from '../../../../molecules/Loading'
import ModifyFieldsDialog from './ModifyFieldsDialog'

const useVirtualFieldActions = ({ modifyFields }) => {
  const handleModifyFields = useCallback(({ job, source }) => {
    modifyFields.current.open(job, source)
  }, [modifyFields])

  return useMemo(() => {
    return [
      { key: 'modify-fields', label: 'Modify Fields', icon: 'settings', onClick: handleModifyFields }
    ]
  }, [handleModifyFields])
}

function JobIncludedFields ({ job }) {
  const { data: sourceData, isLoading } = useGetIntegrationSourceById(job.integrationSourceId)
  const includedFields = useMemo(() => {
    return job.configuration?.virtualFields || []
  }, [job])
  const { formatter } = useFormattingContext()
  const modifyFieldsRef = useRef(null)
  const virtualFieldActions = useVirtualFieldActions({ modifyFields: modifyFieldsRef })
  const subject = useMemo(() => ({ job, source: sourceData?.integrationSource }), [job, sourceData])

  if (isLoading) {
    return <Loading />
  }

  const content = !includedFields?.length ? (
    <div>No Additional Fields Included</div>
  ) : (
    <div>
      <BasicTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Length</th>
            <th>Nullable</th>
          </tr>
        </thead>
        <tbody>
          {includedFields.map(f => (
            <tr key={`included_field_${f.name}`}>
              <td>{f.name}</td>
              <td>{f.type}</td>
              <td>{f.length}</td>
              <td>{formatter(!f.notNull, 'bool')}</td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </div>
  )

  return (
    <div>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <h3>Included Fields</h3>
        <SydActionMenu actions={virtualFieldActions} subject={subject} />
      </FlexRow>
      {content}
      <ModifyFieldsDialog ref={modifyFieldsRef} />
    </div>
  )
}

JobIncludedFields.propTypes = {
  job: PropTypes.shape({
    integrationSourceId: PropTypes.number,
    domain: PropTypes.string,
    sourceKey: PropTypes.string,
    configuration: PropTypes.shape({
      virtualFields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        path: PropTypes.string,
        length: PropTypes.number,
        notNull: PropTypes.bool
      }))
    })
  })
}

export default JobIncludedFields
