import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import {
  connections,
  useListIntegrationDomains
} from '../../../../../../api/integrations'
import { decipherError } from '../../../../../../utils/decipherError'
import Loading from '../../../../../molecules/Loading'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import DomainSelect from './DomainSelect'
import DomainConnectionForm from './DomainConnectionForm'

const useSubmitter = (form, onComplete, domain) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { handleSubmit } = form
  const { mutateAsync: createIntegrationConnection } = connections.useCreateIntegrationConnection()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      domain,
      code: formData.code,
      displayName: formData.displayName,
      description: formData.description,
      connectionArgs: formData.connectionArgs
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await createIntegrationConnection(command)
      if (result?.statusCode === 500) {
        setError('Something unexpected happened while creating the integration connection')
        return
      }
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, createIntegrationConnection, domain])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

const domainQuery = {
  domainClass: 'connection'
}

function CreateConnectionForm ({ onCancel, onComplete }) {
  const { data: domains, isLoading } = useListIntegrationDomains(domainQuery)
  const [selectedDomain, setSelectedDomain] = useState(null)
  const form = useForm({
    defaultValues: {
      code: '',
      displayName: '',
      description: '',
      commandArgs: {}
    },
    mode: 'onChange'
  })
  const { submitter, processing, error } = useSubmitter(form, onComplete, selectedDomain)
  const { data: template, isLoading: templateLoading } = connections.useIntegrationConnectionTemplate(selectedDomain)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <DomainSelect
          domains={domains?.integrationDomains}
          selected={selectedDomain}
          onSelect={setSelectedDomain}
          processing={processing}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        {templateLoading ? (
          <Loading />
        ) : (
          <FadeIn key={selectedDomain || 'none'}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <DomainConnectionForm
                    template={template?.connectionTemplate}
                    form={form}
                    processing={processing}
                  />
                </Card>
              </Grid>
            </Grid>
          </FadeIn>
        )}
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? <div className='__error'>{error}</div> : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Create
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

CreateConnectionForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateConnectionForm
