import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import AdminPage from '../../admin/shared/AdminPage'
import NavTile from '../shared/NavTile'

function AnalysisHome ({ root }) {
  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <NavTile to={`${root}/analysis/views`} label='Reports (Views)' />
        </Grid>
        <Grid item xs={12} md={3}>
          <NavTile to={`${root}/analysis/pipelines`} label='Pipelines' />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

AnalysisHome.propTypes = {
  root: PropTypes.any
}

export default AnalysisHome
