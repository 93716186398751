import React, { useContext, useMemo, useRef } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useStatusTemplateContext } from '../StatusTemplateProvider'
import PersonalSpace from '../../shared/PersonalSpace'
import SydButton from '../../../../commonDesign/Button'
import EditTemplateItemDialog from './EditTemplateItemDialog'
import DeleteTemplateItemDialog from './DeleteTemplateItemDialog'
import AddTemplateItemDialog from './AddTemplateItemDialog'
import MoveTemplateItemDialog from './MoveTemplateItemDialog'
import DescribeTemplateItemDialog from './DescribeTemplateItemDialog'
import TemplateItemActionMenu from './TemplateItemActionMenu'

const useStyles = makeStyles((theme) => ({
  tabBody: {
    padding: '20px 0'
  },
  composition: {
    tableLayout: 'auto',
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      borderBottom: '1px solid black'
    },
    '& tr:nth-child(2n) td': {
      backgroundColor: theme.palette.gray.light
    },
    '& .__item > td': {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '10px'
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row-reverse',
      gap: '10px'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px'
  }
}))

function TemplateItem ({ item, depth }) {
  const templateContext = useContext(TemplateContext)
  return (
    <>
      <tr className='__item'>
        <td data-depth={depth}>
          {depth > 0 ? Array(depth).fill(0).map((_, i) => <span key={i}>&rarr;</span>) : null}
          <span>{item.ordinal}</span>
        </td>
        <td>{item.codeName}</td>
        <td>{item.displayName}</td>
        <td style={{ textAlign: 'center' }}>
          <SydButton
            size='sm'
            variant={item.description ? 'ghost' : 'outline'}
            onClick={() => templateContext.describeRef.current.open(item)}
          >
            {item.description ? 'Edit Description' : 'Add Description'}
          </SydButton>
        </td>
        <td>{item.mapping}</td>
        <td>{item.source}</td>
        <td>{item.linkReference}</td>
        <td>
          <div className='__actions'>
            <TemplateItemActionMenu templateItem={item} />
          </div>
        </td>
      </tr>
      {item.items?.length ? (
        item.items.map(t => (
          <TemplateItem key={t.codeName} item={t} depth={depth + 1} />
        ))
      ) : null}
    </>
  )
}

TemplateItem.propTypes = {
  item: PropTypes.any,
  depth: PropTypes.number
}

function TemplateItemHeader () {
  return (
    <thead>
      <tr>
        <th>Ordinal</th>
        <th>Code Name</th>
        <th>Display Name</th>
        <th>Description</th>
        <th>Mapping</th>
        <th>Source</th>
        <th>Link</th>
        <th>Actions</th>
      </tr>
    </thead>
  )
}

function generateTree (allItems, currentPath = []) {
  const children = allItems.filter(x => {
    if (x.path?.length !== currentPath.length) return false

    for (let i = 0; i < currentPath.length; ++i) {
      if (x.path[i] !== currentPath[i]) return false
    }

    return true
  })

  children.sort((a, b) => a.ordinal - b.ordinal)

  return children.map(x => ({
    ...x,
    items: generateTree(allItems, [...currentPath, x.codeName])
  }))
}

export const TemplateContext = React.createContext({})

function CompositionTab () {
  const classes = useStyles()
  const { statusTemplate, isFetching } = useStatusTemplateContext()
  const treeItems = useMemo(() => {
    return generateTree(statusTemplate.items)
  }, [statusTemplate])

  const editRef = useRef()
  const deleteRef = useRef()
  const addRef = useRef()
  const moveRef = useRef()
  const describeRef = useRef()

  if (isFetching) {
    return <CircularProgress />
  }

  return (
    <FadeIn className={classes.tabBody}>
      <TemplateContext.Provider value={{ editRef, deleteRef, addRef, moveRef, describeRef }}>
        <div className={classes.header}>
          <p>
            Here you can modify the composition of the template.
            Changes to the template do not take effect on reports until a report is run.
          </p>
          <span>
            <SydButton icon='add' size='sm' variant='outline' onClick={() => addRef.current.open(null)}>Add Root Item</SydButton>
          </span>
        </div>
        <table className={classes.composition}>
          <TemplateItemHeader />
          <tbody>
            {treeItems.map(t => (
              <TemplateItem key={t.codeName} item={t} depth={0} />
            ))}
          </tbody>
        </table>
        <PersonalSpace />
        <EditTemplateItemDialog ref={editRef} />
        <DeleteTemplateItemDialog ref={deleteRef} />
        <AddTemplateItemDialog ref={addRef} />
        <MoveTemplateItemDialog ref={moveRef} />
        <DescribeTemplateItemDialog ref={describeRef} />
      </TemplateContext.Provider>
    </FadeIn>
  )
}

CompositionTab.propTypes = {
}

export default CompositionTab
