import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import OperationalTable from '../../../organisms/OperationalTable'
import { systemJobs } from '../../../../api/ingestion'
import { useConfigColumns } from '../../../../hooks/useConfigColumns'
import QueryTableCell from '../shared/QueryTableCell'

const columnsConfig = {
  columns: [
    { id: 'eventLogId', accessor: 'eventLogId', Header: 'ID' },
    { id: 'eventDate', accessor: 'eventDate', Header: 'Event Date', format: 'timestamp' },
    { id: 'eventType', accessor: 'eventType', Header: 'Event Type' },
    { id: 'message', accessor: 'message', Header: 'Message' },
    { id: 'severity', accessor: 'severity', Header: 'Level' },
    { id: 'data', accessor: 'data', Header: 'Data', Cell: QueryTableCell },
    { id: 'actorName', accessor: 'actorName', Header: 'Actor' }
  ],
  defaultSort: [{
    id: 'eventDate', desc: true
  }]
}

function EventLogs ({ filter, enabled }) {
  const query = useMemo(() => {
    return {
      includes: {
        actorName: true
      },
      filters: {
        ...(filter || {})
      },
      take: 100,
      skip: 0
    }
  }, [filter])
  const { data, isLoading, isFetching } = systemJobs.useSearchEventLogs(query, { enabled })
  const _columns = useConfigColumns({
    columnsConfig
  })

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <OperationalTable
          variant='v2'
          data={data?.logs ?? []}
          isLoading={isLoading || isFetching}
          itemName='Logs'
          columns={_columns.columns}
          defaultSort={_columns.defaultSort}
        />
      </Grid>
    </Grid>
  )
}

EventLogs.propTypes = {
  filter: PropTypes.object,
  enabled: PropTypes.bool
}

export default EventLogs
