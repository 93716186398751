import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import NavigationProvider from './NavigationProvider'
import ClientDashboardItem from './ClientDashboardItem'
import Wheel from './Layouts/Wheel'
import ClientDashboardContextProvider from './ClientDashboardContextProvider'
import SubViewRoute from './components/SubViewRoute'

const ClientDashboard = ({ settings, children, rootUrl, clientAccountSettings, hideMinimap }) => {
  const items = Children.toArray(children)
  const { path, url } = useRouteMatch()

  return (
    <ErrorBoundary name='Client Dashboard'>
      <Switch>
        <ClientDashboardContextProvider
          settings={settings}
          dashboardItems={items}
          root={rootUrl || url}
          clientAccountSettings={clientAccountSettings}
        >
          <NavigationProvider dashboardItems={items} root={rootUrl || url} hideMinimap={hideMinimap}>
            <SecureRoute path={path} exact>
              <Wheel />
            </SecureRoute>
            <SecureRoute path={`${path}/:subView`}>
              <SubViewRoute />
            </SecureRoute>
          </NavigationProvider>
        </ClientDashboardContextProvider>
      </Switch>
    </ErrorBoundary>
  )
}

ClientDashboard.propTypes = {
  settings: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  rootUrl: PropTypes.string,
  clientAccountSettings: PropTypes.object,
  hideMinimap: PropTypes.bool
}

ClientDashboard.Item = ClientDashboardItem

export default ClientDashboard
