import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useAppContext } from '../../../../redux/slices/appContext'
import InfographicNavigation from './components/InfographicNavigation'
import { CreateInfographicReportForm } from './components/CreateInfographicReportForm'
import { useInfographicRunColumnConfig } from './hooks/useInfographicRunColumnConfig'
import { reportStatusClasses } from './classes'
import { useListInfographicReportRuns } from './hooks/useListInfographicReportRuns'
import RefreshRunActions from './components/RefreshRunActions'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    width: '6rem'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 0 0',
    gap: '1rem',
    '& a': {
      opacity: 0.25,
      '&.active': {
        opacity: 1
      }
    }
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ...reportStatusClasses
}))

const InfographicReportsView = () => {
  const { isSummitUser } = useAppContext()

  const classes = useStyles()
  const columnConfig = useInfographicRunColumnConfig({
    classes,
    isSummitUser
  })

  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange
  } = useOperationalTable(columnConfig)

  const { data, isLoading, isFetching, refetch } = useListInfographicReportRuns({
    limit: pageSize,
    offset: pageIndex * pageSize,
    sort
  })

  return (
    <div className={classes.container}>
      <InfographicNavigation />
      <Box mt={2}>
        <CreateInfographicReportForm
          onSubmit={() => refetch()}
        />
      </Box>

      <div className={classes.tableHeader}>
        <Text text='Reports' variant={TEXT_VARIANTS.h2} />
      </div>

      <RefreshRunActions
        onRefetch={refetch}
        isLoading={isLoading}
      />
      <OperationalTable.Wrapper>
        <OperationalTable
          showTotalItemsLabel={false}
          autoSticky
          mode='server'
          columns={columnConfig.columns}
          data={data || []}
          defaultPageSize={100}
          defaultSort={columnConfig.defaultSort}
          itemName='Reports'
          loading={isLoading || isFetching}
          total={data?.length || 0}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

export default InfographicReportsView
