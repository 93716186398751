import React from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useListFirmReportStatus } from '../../../../../api/coreData'
import Loading from '../../../../molecules/Loading'
import AdminPage from '../../../admin/shared/AdminPage'
import ErrorDisplay from '../../../../molecules/ErrorDisplay'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import OperationalTable from '../../../../organisms/OperationalTable'

const columnConfig = {
  columns: [
    { id: 'reportId', accessor: 'reportId', Header: 'View ID' },
    { id: 'name', accessor: 'name', Header: 'Name' },
    { id: 'category', accessor: 'firmReportCategoryName', Header: 'Category' },
    { id: 'description', accessor: 'description', Header: 'Description' },
    { id: 'published', accessor: 'published', Header: 'Published', format: 'bool' },
    { id: 'filterCount', accessor: 'filterCount', Header: '# Filters', format: 'number' }
  ],
  defaultSort: [{ id: 'reportId', desc: false }]
}
const q = {}

function ViewsHome () {
  const { data, isLoading, error, refetch } = useListFirmReportStatus(q)
  const { formatter } = useFormattingContext()
  const _columns = useConfigColumns({
    columnsConfig: columnConfig,
    format: formatter
  })
  const history = useHistory()

  if (error) {
    return (
      <AdminPage>
        <ErrorDisplay error={error} onRetry={refetch} />
      </AdminPage>
    )
  }

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            columns={_columns.columns}
            defaultSort={_columns.defaultSort}
            data={data?.reports || []}
            onRowClick={row => history.push(`/admin/denali/analysis/views/${row.original.reportId}`)}
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

ViewsHome.propTypes = {
}

export default ViewsHome
