import dayjs from 'dayjs'
import clsx from 'clsx'
import { Tooltip } from '@material-ui/core'
import React from 'react'
import Icon from '../../../../atoms/Icon'

export const cellTemplates = {
  date: ({ value }) => value ? dayjs.utc(value).format('M/D/YYYY') : '',
  status: ({ classes, statusMap }) => ({ row }) => (
    <div className={classes.statusContainer}>
      <div className={clsx(classes.status, classes[statusMap.get(row.original?.status)?.class] ?? 'inProgress')}>
        <Tooltip key={row.original.reportRunId} title={row.original.statusDescription} arrow placement='top'>
          <span>{statusMap.get(row.original?.status)?.label ?? 'In Progress'}</span>
        </Tooltip>
      </div>
    </div>
  ),
  boolean: ({ value }) => (
    <div style={{ width: '50px', textAlign: 'center', margin: '0 auto' }}>
      {value ? <Icon name='check' color='#14ac7e' customSize={16} /> : <Icon name='close' color='#D44333' customSize={16} />}
    </div>
  ),
  createdBy: ({
    row: {
      original: { createdByFirstName, createdByLastName }
    }
  }) => `${createdByFirstName} ${createdByLastName}`
}
