import React, { useCallback, useState } from 'react'
import { Menu, MenuItem, makeStyles, IconButton, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useHideStatusReportItemMutation } from '../../../api/groups'
import { usePolicy } from '../../../hooks/usePolicy'
import { useInternalReportContext } from './InternalStatusReportContext'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  },
  danger: {
    color: theme.palette.danger.main
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.layout.radius.circle,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.layout.shadow.normal
  }
}))

function StatusReportItemActionMenu ({ item, size, noHide }) {
  const classes = useStyles()
  const canEdit = usePolicy('status_report_edit')
  const [anchorEl, setAnchorEl] = useState()
  const { openManageOptionsDialog, report } = useInternalReportContext()
  const { mutateAsync: hideItem } = useHideStatusReportItemMutation()
  const open = useCallback((e) => {
    e.stopPropagation()
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const hide = useCallback(async () => {
    return await hideItem({
      statusReportId: item.statusReportId,
      statusReportItemId: item.statusReportItemId,
      hidden: true
    })
  }, [item, hideItem])
  const close = useCallback((e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }, [])
  const withClose = useCallback((fn) => (e) => {
    fn()
    close(e)
  }, [close])

  if (!canEdit) return null

  return (
    <FadeIn>
      <IconButton aria-controls='more-opts' aria-haspopup='true' size='small' onClick={open}>
        <Icon name={ICON_NAMES.vertDots} customSize={size} additionalClasses={classes.icon} />
      </IconButton>
      <Menu
        id='item-actions'
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
      >
        <MenuItem onClick={withClose(() => openManageOptionsDialog(item, report))}>
          <div className={classes.menuItem}>
            <Icon name='edit' />
            <div>Manage Items</div>
          </div>
        </MenuItem>
        <Divider />
        {noHide ? null : (
          <MenuItem onClick={withClose(hide)}>
            <div className={clsx(classes.menuItem, classes.danger)}>
              <Icon name='close' />
              <div>Hide</div>
            </div>
          </MenuItem>
        )}
      </Menu>
    </FadeIn>
  )
}

StatusReportItemActionMenu.propTypes = {
  item: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noHide: PropTypes.bool
}

StatusReportItemActionMenu.defaultProps = {
  size: '20px'
}

export default StatusReportItemActionMenu
