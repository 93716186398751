import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import SydButton from '../../../../commonDesign/Button'
import { postNamedQuery } from '../../../../../service'
import Icon from '../../../../atoms/Icon'

const fileTypeMap = new Map([
  ['pdf', { label: 'PDF', icon: 'document' }],
  ['html', { label: 'HTML', icon: 'file' }]
])

const ReportRunFileButton = ({ reportRunId, fileType, canDownload }) => {
  const [isLoading, setIsLoading] = useState(false)

  const getFileUrl = useCallback(async (fileType) => {
    setIsLoading(true)
    const { data } = await postNamedQuery('reports', 'get-infographic-report-run-exports', {
      reportRunId
    })
    const { htmlUrl, pdfUrl } = data
    const fileLocation = fileType === 'html' ? htmlUrl : pdfUrl
    if (!fileLocation?.length) return

    window.open(fileLocation, '_blank')
    setIsLoading(false)
  }, [reportRunId])

  return (
    <SydButton
      size='xs'
      onClick={() => getFileUrl(fileType.value)}
      disabled={!canDownload || isLoading}
      variant={canDownload ? 'primary' : 'outline'}
    >
      <Box display='flex' gridGap='4px' alignItems='center'>
        {isLoading ? <CircularProgress size={8} /> : <Icon name={fileType.icon} />}
        {isLoading ? 'Loading...' : fileType.label}
      </Box>
    </SydButton>
  )
}

ReportRunFileButton.propTypes = {
  reportRunId: PropTypes.number,
  fileType: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string
  }),
  canDownload: PropTypes.bool
}

export const ReportRunFileCell = ({
  row
}) => {
  const { original: { returnTypes } } = row

  if (!returnTypes?.length) return null

  return (
    <Box display='flex' gridGap='8px'>
      {returnTypes?.sort((a, b) => a.localeCompare(b))?.map((file) => {
        const fileType = fileTypeMap.get(file) ?? { label: file, icon: 'file' }
        return (
          <ReportRunFileButton
            key={row.original.reportRunId + file}
            reportRunId={row.original.reportRunId}
            fileType={{ ...fileType, value: file }}
            canDownload={row.original.status === 4}
          />
        )
      })}
    </Box>
  )
}

ReportRunFileCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      reportRunId: PropTypes.number,
      returnTypes: PropTypes.array,
      status: PropTypes.number
    })
  })
}
