import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import NotAvailable from '../../../../organisms/NotAvailable'
import { useSearchIntegrationBatches } from '../../../../../api/integrations'
import OperationalTable from '../../../../organisms/OperationalTable'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'

const sourceColumns = [
  { id: 'batchId', accessor: 'batchId', Header: 'Batch ID' },
  { id: 'type', accessor: 'type', Header: 'Type' },
  { id: 'isProcessing', accessor: 'isProcessing', Header: 'Processing?', Cell: ({ value }) => value ? 'Y' : 'N' },
  { id: 'isCancelled', accessor: 'isCancelled', Header: 'Cancelled?', Cell: ({ value }) => value ? 'Y' : 'N' },
  { id: 'lastStartedDate', accessor: 'lastStartedDate', Header: 'Last Started' },
  { id: 'lastFinishedDate', accessor: 'lastFinishedDate', Header: 'Last Finished' }
]
const defaultSort = [{
  id: 'batchId', desc: true
}]

function BatchList ({ root }) {
  const query = useMemo(() => {
    return {
      sort: [{ field: 'batchId', dir: 'desc' }]
    }
  }, [])
  const { data, isLoading, refetch, isFetching } = useSearchIntegrationBatches(query)
  const columns = useMemo(() => {
    return sourceColumns.map(sc => {
      if (sc.id === 'batchId') {
        // eslint-disable-next-line react/prop-types
        sc.Cell = ({ value, row }) => <Link to={`${root}/integrator/batches/${row.original.batchId}`}>{value}</Link>
      }
      return sc
    })
  }, [root])

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!data?.data?.length) {
    return (
      <AdminPage>
        <NotAvailable />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <div />
            <div>
              <SydButton size='sm' variant='primary' icon='sync' onClick={refetch} processing={isFetching}>Refresh</SydButton>
            </div>
          </FlexRow>
        </Grid>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            data={data?.data ?? []}
            isLoading={isLoading || isFetching}
            itemName='Batch'
            columns={columns}
            defaultSort={defaultSort}
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

BatchList.propTypes = {
  root: PropTypes.any
}

export default BatchList
