import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../organisms/OperationalTable'

const sourceColumns = [
  { id: 'batchTaskId', accessor: 'batchTaskId', Header: 'Task ID' },
  { id: 'taskType', accessor: 'taskType', Header: 'Task Type' },
  { id: 'taskPriority', accessor: 'taskPriority', Header: 'Priority' },
  { id: 'isComplete', accessor: 'isComplete', Header: 'Completed?', Cell: ({ value }) => value ? 'Y' : 'N' },
  { id: 'isError', accessor: 'isError', Header: 'Error?', Cell: ({ value }) => value ? 'Y' : 'N' }
]
const defaultSort = [
  { id: 'taskPriority', desc: false },
  { id: 'batchTaskId', desc: false }
]

function BatchTasks ({ tasks }) {
  return (
    <OperationalTable
      variant='v2'
      data={tasks}
      columns={sourceColumns}
      defaultSort={defaultSort}
      hideFooter
    />
  )
}

BatchTasks.propTypes = {
  tasks: PropTypes.array
}

export default BatchTasks
