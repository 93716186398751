import React, { useRef } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import { systemJobs } from '../../../../../api/ingestion'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'
import InlineCardHeader from '../../../../molecules/Card/InlineCardHeader'
import Card from '../../../../molecules/Card'
import SydLinkButton from '../../../../commonDesign/LinkButton'
import FlexActions from '../../../../molecules/FlexActions'
import CreateJobGroupDialog from './CreateJobGroupDialog'

function ListJobGroupContent ({ jobGroups, onCreate }) {
  if (!jobGroups?.length) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EmptySection
            title='No Job Groups Yet'
            description={(
              <FlexRow>
                <SydButton variant='primary' size='lg' icon='add' onClick={onCreate}>New Job Group</SydButton>
              </FlexRow>
            )}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FlexActions>
          <SydButton variant='primary' size='sm' icon='add' onClick={onCreate}>New Job Group</SydButton>
        </FlexActions>
      </Grid>
      {(jobGroups || []).map(jg => (
        <Grid item xs={12} key={`jg_${jg.jobGroupId}`}>
          <Card>
            <InlineCardHeader action={(<SydLinkButton size='sm' icon='edit' to={`/admin/denali/ingestion/system-jobs/groups/${jg.code}`}>Edit</SydLinkButton>)}>
              <div>{jg.name} ({jg.code})</div>
            </InlineCardHeader>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

ListJobGroupContent.propTypes = {
  jobGroups: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired
}

function ListJobGroups () {
  const { data, isLoading } = systemJobs.useListSystemJobGroups()
  const createJobGroupRef = useRef(null)
  const history = useHistory()

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <ListJobGroupContent
        jobGroups={data?.jobGroups}
        onCreate={() => createJobGroupRef.current.open()}
      />
      <CreateJobGroupDialog
        ref={createJobGroupRef}
        onComplete={(result) => history.push(`/admin/denali/ingestion/system-jobs/groups/${result.code}`)}
      />
    </AdminPage>
  )
}

export default ListJobGroups
