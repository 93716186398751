import React from 'react'
import { Alert } from '@material-ui/lab'
import { usePipelineEditor } from '../PipelineEditorContext'

function RequestStatus () {
  const { response, requestValidation, currentQuery, responseError } = usePipelineEditor()

  if (!currentQuery) {
    return (
      <Alert severity='info'>The request has not been validated yet</Alert>
    )
  }
  if (requestValidation?.isValid === false) {
    return (
      <Alert severity='error'>The request is malformed</Alert>
    )
  }
  if (responseError) {
    return (
      <Alert severity='error'>There was an error processing the request</Alert>
    )
  }
  if (response?.length) {
    return (
      <Alert severity='success'>The request was successful</Alert>
    )
  }

  return null
}

export default RequestStatus
