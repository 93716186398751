import React, { useMemo } from 'react'
import { Box, Link, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'

const useStyles = makeStyles((theme) => ({
  edit: {
    fontWeight: 'bold',
    color: theme.palette.cloudBurst
  },
  delete: {
    fontWeight: 'bold',
    color: theme.palette.error.A500
  }
}))

const ViewActionsCell = ({ row }) => {
  const [abundanceEngineViewContext] = useContextContainer(
    'abundanceEngineView'
  )
  const classes = useStyles()

  const viewItem = useMemo(() => {
    const {
      viewId,
      name,
      path,
      description,
      version,
      content,
      liveView,
      showInNavigation,
      hideTitle,
      enablementCodeId,
      disabledViewId
    } = row?.original
    return {
      viewId,
      viewName: name,
      viewPath: path,
      viewDescription: description,
      viewVersion: version,
      viewContent: content,
      viewIsLive: !isEmpty(liveView) ? '1' : '0',
      showInNavigation: showInNavigation ? '1' : '0',
      hideTitle: hideTitle ? '1' : '0',
      enablementCodeId,
      disabledViewId
    }
  }, [row?.original])

  return (
    <Box display='flex'>
      <Link
        className={classes.edit}
        onClick={() => abundanceEngineViewContext.onEditClick(viewItem)}
      >
        Edit
      </Link>
      <Box mx='0.5rem' />
      <Link
        className={classes.delete}
        onClick={() => abundanceEngineViewContext.onDeleteClick(viewItem)}
      >
        Delete
      </Link>
    </Box>
  )
}

ViewActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  })
}

export default ViewActionsCell
