import React from 'react'
import PropTypes from 'prop-types'
import BasicTable from '../../../../atoms/BasicTable'
import CodeTag from '../../../../atoms/CodeTag'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

function DatapointsTable ({ datapoints }) {
  const { formatter } = useFormattingContext()

  return (
    <BasicTable>
      <thead>
        <tr>
          <th>Accessor</th>
          <th>Name</th>
          <th>Type Class</th>
          <th>Type</th>
          <th>Enumeration</th>
          <th>Ordinal</th>
          <th>Nullable</th>
          <th>Len</th>
          <th>Scale</th>
          <th>Precision</th>
        </tr>
      </thead>
      <tbody>
        {(datapoints || []).map((dp) => (
          <tr key={`dp_${dp.catalogDatapointId}`}>
            <td>
              <CodeTag>{dp.accessor}</CodeTag>
            </td>
            <td>{dp.displayName}</td>
            <td>{dp.typeClass}</td>
            <td>{dp.type}</td>
            <td>{formatter(!!dp.enumerationId, 'bool')}</td>
            <td>{dp.ordinal}</td>
            <td>{formatter(!!dp.nullable, 'bool')}</td>
            <td>{formatter(dp.length, 'number')}</td>
            <td>{formatter(dp.numericScale, 'number')}</td>
            <td>{formatter(dp.precision, 'number')}</td>
          </tr>
        ))}
      </tbody>
    </BasicTable>
  )
}

DatapointsTable.propTypes = {
  datapoints: PropTypes.arrayOf(PropTypes.shape({
    catalogDatapointId: PropTypes.number,
    accessor: PropTypes.string,
    displayName: PropTypes.string,
    type: PropTypes.string,
    typeClass: PropTypes.string,
    enumerationId: PropTypes.number,
    ordinal: PropTypes.number,
    nullable: PropTypes.bool,
    length: PropTypes.number,
    numericScale: PropTypes.number,
    numericPrecision: PropTypes.number,
    description: PropTypes.string
  }))
}

export default DatapointsTable
