import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import SydLinkButton from '../../../../../commonDesign/LinkButton'
import { ADMIN_ROUTES } from '../../../../../../constants'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import SydModal from '../../../../../commonDesign/SydModal'
import {
  useArchiveInfographicTemplateMutation
} from '../../InfographicTemplatesDetailsView/hooks/useArchiveInfographicTemplateMutation'
import { useCheckPolicy } from '../../../../../../hooks'

export const InfographicTemplateActionsCell = ({ row, onRefetch }) => {
  const userIsInternal = useCheckPolicy('internalOnly')
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const { mutate, isLoading, isSuccess } = useArchiveInfographicTemplateMutation()
  const handleOnDelete = useCallback(() => {
    mutate({ infographicTemplateId: row.original.infographicTemplateId })
  }, [mutate, row.original.infographicTemplateId])

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteConfirm(false)
      onRefetch()
    }
  }, [isSuccess, onRefetch])

  return (
    <>
      <Box display='flex' gridGap='8px'>
        <SydLinkButton
          to={`${ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE}/${row.original.infographicTemplateId}`}
          variant='primary'
          size='sm'
        >
          Edit
        </SydLinkButton>
        {userIsInternal && (
          <SydButton
            variant='primary'
            priority='warning'
            size='sm'
            onClick={() => setShowDeleteConfirm(true)}
          >
            Delete
          </SydButton>
        )}
      </Box>

      <SydModal
        disablePortal={false}
        title='Are you sure you want to delete/archive this template?'
        subtitle={`This action cannot be undone. Report runs with ${row.original.name} template will still be visible / be unaffected.`}
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
      >
        <SydModalActions>
          <SydButton
            onClick={() => setShowDeleteConfirm(false)}
            variant='outline'
            processing={isLoading}
          >
            Cancel
          </SydButton>

          <SydButton
            onClick={() => handleOnDelete()}
            variant='primary'
            priority='warning'
            processing={isLoading}
          >
            Delete
          </SydButton>
        </SydModalActions>
      </SydModal>
    </>
  )
}

InfographicTemplateActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      infographicTemplateId: PropTypes.number,
      name: PropTypes.string,
      ordinal: PropTypes.number,
      internal: PropTypes.bool
    })
  }),
  onRefetch: PropTypes.func
}
