import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles(theme => ({
  fieldDisplay: {
    width: '100%',
    tableLayout: 'auto',
    borderCollapse: 'collapse',
    '& .__field': {
      paddingTop: theme.layout.padding.p5,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p10,
      paddingRight: theme.layout.padding.p10,
      // backgroundColor: theme.palette.gray.dark,
      borderRight: `2px solid ${theme.palette.gray.main}`,
      whiteSpace: 'nowrap',
      breakWord: 'never'
    },
    '& .__value': {
      paddingLeft: theme.layout.padding.p10,
      width: '100%'
    }
  }
}))

export const FieldDisplayFieldValue = ({ children }) => {
  return (
    <td className='__value'>
      {children}
    </td>
  )
}

FieldDisplayFieldValue.propTypes = {
  children: PropTypes.node
}

export const FieldDisplayField = ({ name, children }) => {
  return (
    <tr>
      <td className='__field'>
        {name}
      </td>
      {children}
    </tr>
  )
}

FieldDisplayField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node
}

export const FieldDisplayTable = ({ children }) => {
  const classes = useStyles()
  return (
    <table className={classes.fieldDisplay}>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

FieldDisplayTable.propTypes = {
  children: PropTypes.node
}

const FieldDisplay = ({ fields }) => {
  const { formatter } = useFormattingContext()
  return (
    <FieldDisplayTable>
      {(fields || []).map(field => (
        <FieldDisplayField key={field.label} name={field.label}>
          <FieldDisplayFieldValue>{field.format ? formatter(field.value, field.format) : field.value}</FieldDisplayFieldValue>
        </FieldDisplayField>
      ))}
    </FieldDisplayTable>
  )
}

FieldDisplay.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    format: PropTypes.string
  }))
}

export default FieldDisplay
