import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '../../atoms/Skeleton'
import FadeIn from '../../molecules/Transitions/FadeIn'
import StatusReportItem from './StatusReportItem'
import InternalStatusReportContextProvider from './InternalStatusReportContext'

const useStyles = makeStyles((theme) => ({
  reportWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  report: {
    width: '100%',

    '& .__header': {
      flex: '1 0 auto',
      fontWeight: '600',
      alignItems: 'center',
      padding: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p5,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.g10
    },

    '& .__group': {
      paddingLeft: theme.layout.padding.p10,
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      height: '48px',
      minWidth: '350px',
      fontWeight: '600',
      alignItems: 'center'
    },
    '& .__group-wrapper': {
      border: `1.5px solid ${theme.palette.gray.darker}`,
      borderRadius: '10px',
      marginBottom: '10px'
    },
    '& .__group-wrapper .__summary-content': {
      margin: 0
    },
    '& .__group-wrapper .__summary-root': {
      paddingLeft: 0
    },
    '& .__group-wrapper .__summary-root-expanded': {
      minHeight: '48px',
      paddingLeft: 0
    },
    '& .__group-wrapper .__details-root': {
      boxShadow: '0px 3px 3px rgba(0, 0, 0, .1) inset'
    },

    '& .__group-children': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },

    '& .__detail': {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      // height: '30px',
      paddingTop: theme.layout.padding.p5,
      paddingBottom: theme.layout.padding.p5,
      minHeight: '30px',
      fontWeight: '100',
      alignItems: 'center',
      width: '100%'
    },
    '& .__detail-children': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    '& .__detail-wrapper': {
      padding: '10px 0',
      minHeight: '30px',
      fontWeight: '100',
      alignItems: 'center',
      width: '100%'
    },
    '& .__detail-wrapper .__summary-content': {
      margin: 0
    },
    '& .__detail-wrapper .__summary-root': {
      paddingLeft: 0
    },
    '& .__detail-wrapper .__summary-root-expanded': {
      minHeight: '48px',
      paddingLeft: 0
    },
    '& .__detail-wrapper .__details-root': {
      boxShadow: 'none !important'
    },

    '& .__detail-accordion': {
      paddingRight: 0,
      width: '100%',
      background: 'none',
      boxShadow: 'none'
    },
    '& .__detail-accordion > .__summary-root': {
      paddingRight: '0 !important'
    },
    '& .__detail-accordion > .__summary-root-expanded': {
      paddingRight: '0 !important'
    },

    '& .__accordion': {
      paddingRight: 0,
      width: '100%',
      background: 'none',
      boxShadow: 'none'
    },
    '& .__accordion .Mui-expanded': {
      margin: '0'
    },

    '& .__status': {
      width: '20px',
      display: 'flex',
      alignItems: 'center'
    },

    '& .__ordinal': {
      width: '70px'
    },

    '& .__display-name': {
      flex: '1 0',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.dense,
      alignItems: 'normal',
      paddingTop: '2px',

      '& .__name-main': {
        // fontWeight: theme.typography.weights.bold
        wordBreak: 'none'
      },
      '& .__name-extension': {
        color: `color-mix(in srgb, ${theme.palette.text.primary} 60%, transparent)`,
        wordBreak: 'break-word'
      }
    },

    '& .__option-container': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 10%, transparent)`,
      borderRadius: theme.layout.radius.loose,
      marginTop: theme.layout.margin.m5,
      marginBottom: theme.layout.margin.m5,
      marginLeft: theme.layout.margin.m20,
      paddingTop: theme.layout.padding.p5,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p10,
      boxShadow: theme.layout.shadow.light,
      borderColor: `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`,
      borderStyle: 'solid',
      borderWidth: '1px',
      '& ol': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.layout.gap.g10,
        fontWeight: theme.typography.weights.light,
        listItemStyle: 'disc'
      }
    }
  }
}))

function StatusReport ({ report, statuses, loading, config, filter, onRefetch, expanded, reportOptions }) {
  const classes = useStyles()
  const filteredItems = useMemo(() => {
    if (loading) return []
    if (!report) return []
    if (filter?.length) {
      return report.items.filter(x => filter.includes(x.codeName))
    }
    return report.items
  }, [report, loading, filter])

  if (loading) {
    return (
      <FadeIn delay={5} duration={5} className={classes.reportWrapper}>
        <Skeleton width='100%' height='80px' />
      </FadeIn>
    )
  }

  if (!report) {
    return (
      <div className={classes.reportWrapper}>
        No Data Available
      </div>
    )
  }

  return (
    <InternalStatusReportContextProvider
      onRefetch={onRefetch}
      data={filteredItems}
      statuses={statuses}
      expanded={expanded}
      reportOptions={reportOptions}
      report={report}
    >
      <div className={classes.reportWrapper}>
        <div className={classes.report}>
          {filteredItems.map(item => (
            <StatusReportItem
              key={item.codeName}
              item={item}
              statuses={statuses}
              config={config}
            />
          ))}
        </div>
      </div>
    </InternalStatusReportContextProvider>
  )
}

StatusReport.propTypes = {
  report: PropTypes.shape({
    items: PropTypes.array
  }),
  statuses: PropTypes.arrayOf(PropTypes.shape({
    statusId: PropTypes.number,
    codeName: PropTypes.string,
    displayName: PropTypes.string,
    color: PropTypes.string
  })),
  config: PropTypes.object,
  loading: PropTypes.bool,
  filter: PropTypes.array,
  onRefetch: PropTypes.func,
  expanded: PropTypes.bool,
  reportOptions: PropTypes.shape({
    openLinksInNewTab: PropTypes.bool
  })
}

StatusReport.defaultProps = {
  reportOptions: {
    openLinksInNewTab: false
  }
}

export default StatusReport
