import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import ModifyColumnsForm from './form'

const ModifyColumnsDialog = React.forwardRef(function ModifyColumnsDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (report) => {
      const initialValues = Object.entries(report.configuration?.columns || []).map(([columnId, col]) => ({
        columnId,
        ...col
      })).sort((a, b) => a.ordinal - b.ordinal)
      setDialogState({ open: true, processing: false, report, initialValues })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Columns'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <ModifyColumnsForm
            report={dialogState.report}
            initialValues={dialogState.initialValues}
            onComplete={handleComplete}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

ModifyColumnsDialog.propTypes = {
  onComplete: PropTypes.func
}

ModifyColumnsDialog.defaultProps = {
  onComplete: () => {}
}

export default ModifyColumnsDialog
