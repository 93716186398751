import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import SydButton from '../../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../../api/coreData'
import { decipherError } from '../../../../../../../../utils/decipherError'
import FilterFieldEditor from '../FilterFieldEditor'

const useSubmitter = (onComplete, report, filter, filterComposition) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyReportFilter } = dangerZone.__useModifyDenaliReportFilter()
  const onSubmit = useCallback(async () => {
    const command = {
      reportId: report.reportId,
      filterId: filter.firmReportFilterId,
      filter: filterComposition
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifyReportFilter(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifyReportFilter, report, filter, filterComposition])

  return {
    submitter: onSubmit,
    processing,
    error
  }
}

function EditFilterForm ({ filter, report, onCancel, onComplete }) {
  const [_filter, setFilter] = useState(filter?.filter)
  const { submitter, processing, error } = useSubmitter(onComplete, report, filter, _filter)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilterFieldEditor value={_filter} columns={report.configuration?.columns} onChange={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

EditFilterForm.propTypes = {
  report: PropTypes.object,
  filter: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditFilterForm
