import React from 'react'
import PropTypes from 'prop-types'
import { usePublicImageUrl } from '../../hooks/usePublicResource'

const firmLogoResourceIdentifier = 'firm_logo.png'
const Logo = ({ className, style }) => {
  const resourceUrl = usePublicImageUrl(firmLogoResourceIdentifier)

  if (!resourceUrl) return null

  return (
    <img style={style} className={className} src={resourceUrl} crossOrigin='anonymous' alt='Logo' />
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}

Logo.defaultProps = {
  className: undefined
}

export default Logo
