import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useListInfographicBlocks } from '../hooks/useListInfographicBlocks'
import SydInput from '../../../../../commonDesign/SydInput'
import Loading from '../../../../../molecules/Loading'
import { TEXT_VARIANTS } from '../../../../../../constants'
import Text from '../../../../../atoms/Text'
import { InfographicBlock } from './InfographicBlock'

const useStyles = makeStyles((theme) => ({
  infographicBlocksContainer: {
    background: theme.palette.gray.light,
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '.5rem',
    margin: '2rem auto'
  },
  infographicBlockHeader: {
    padding: '.5rem 1rem',
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > h3': {
      flexShrink: 0,
      margin: 0
    }
  },
  infographicBlocksList: {
    minHeight: '20rem',
    maxHeight: '60vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '.5rem',
    padding: '.5rem',
    background: theme.palette.gray.main
  }
}))

export const InfographicBlockList = ({
  sizeFilter,
  onAddBlockToPage
}) => {
  const classes = useStyles()
  const { data, isLoading } = useListInfographicBlocks()

  const [searchTerm, setSearchTerm] = useState('')

  const filteredBlocks = useMemo(() => {
    if (!data?.length || !sizeFilter) return []
    return data?.filter(block => {
      const size = block.metadata?.size
      const matchesSizeFilter = sizeFilter.cols >= size.cols && sizeFilter.rows >= size.rows
      const matchesSearchTerm = block.name.toLowerCase().includes(searchTerm.toLowerCase()) || block.reportObjects?.some(ro => ro.name.toLowerCase().includes(searchTerm.toLowerCase()))

      return matchesSizeFilter && matchesSearchTerm
    })
  }, [data, searchTerm, sizeFilter])

  return (
    <div className={classes.infographicBlocksContainer}>
      <div className={classes.infographicBlockHeader}>
        <Text variant={TEXT_VARIANTS.h3} text='Infographic Blocks' />
        <div>
          <SydInput
            size='sm'
            iconName='search'
            placeholder='Search blocks'
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
      </div>
      <div className={classes.infographicBlocksList}>
        {isLoading && <Loading />}
        {filteredBlocks?.map((block, i) => (
          <InfographicBlock
            key={i}
            block={block}
            onAddBlockToPage={(block) => onAddBlockToPage(block)}
          />
        ))}
      </div>
    </div>
  )
}

InfographicBlockList.propTypes = {
  sizeFilter: PropTypes.shape({
    cols: PropTypes.number,
    rows: PropTypes.number
  }),
  onAddBlockToPage: PropTypes.func
}
