import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ResultPanel from './ResultPanel'
import InfoPanel from './InfoPanel'
import PipelineEditorProvider from './PipelineEditorContext'
import EditorPanel from './EditorPanel'

const useStyles = makeStyles(theme => ({
  editPipelineLayout: {
    height: `calc(100vh - ${theme.layout.appBar.height}px)`,
    width: '100vw',

    display: 'grid',
    gridTemplateColumns: 'minmax(40px, 1fr) minmax(200px, 1fr)',
    gridTemplateRows: '10px calc(50% - 5px) calc(50% - 5px)',
    gap: '0px 0px',
    gridTemplateAreas: '"spacing spacing" "info-panel query-editor" "results results"',
    '& .__spacing': {
      gridArea: 'spacing',
      borderBottom: `1px solid ${theme.palette.gray.dark}`
    },
    '& .__info-panel': {
      gridArea: 'info-panel'
    },
    '& .__query-editor': {
      gridArea: 'query-editor'
    },
    '& .__results': {
      gridArea: 'results'
    }
  }
}))

function EditPipelineHome ({ pipeline }) {
  const classes = useStyles()

  return (
    <PipelineEditorProvider initialValue={pipeline}>
      <div className={classes.editPipelineLayout}>
        <div className='__spacing' />
        <div className='__info-panel'>
          <InfoPanel />
        </div>
        <div className='__query-editor'>
          <EditorPanel
            defaultValue={JSON.stringify(pipeline.pipeline?.coreTableDataRequest, null, 2)}
          />
        </div>
        <div className='__results'>
          <ResultPanel />
        </div>
      </div>
    </PipelineEditorProvider>
  )
}

EditPipelineHome.propTypes = {
  pipeline: PropTypes.object
}

export default EditPipelineHome
