import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useCoreDataDataset } from '../../../../../api/coreData'
import { useFormattingContext } from '../../../FormattingProvider/FormattingContext'
import ClientMetricContainer from './ClientMetricContainer'

const ClientMarketValue = ({ title, formatString }) => {
  const { clientId, availableDates } = useAppContext()
  const coreDataQuery = useMemo(() => ({
    levelFilters: {
      calcType: 'balance',
      dateType: 'all',
      clientIds: [clientId],
      levelTypes: ['client'],
      V2: true
    },
    dateRange: {
      startDate: availableDates.min,
      endDate: availableDates.max
    }
  }), [clientId, availableDates.min, availableDates.max])
  const { data, isLoading, error } = useCoreDataDataset(coreDataQuery)
  const { formatter } = useFormattingContext()

  const marketValue = useMemo(() => {
    if (isLoading || error) return null

    return data.length
      ? data[0].length
        ? data[0][0].endingValue
        : null
      : null
  }, [isLoading, error, data])
  if (error) return null

  return (
    <ClientMetricContainer title={title} isLoading={isLoading}>
      {formatter(marketValue, formatString)}
    </ClientMetricContainer>
  )
}

ClientMarketValue.propTypes = {
  title: PropTypes.string,
  formatString: PropTypes.string
}

ClientMarketValue.defaultProps = {
  title: 'Market Value',
  formatString: '($0,0a)'
}

export default ClientMarketValue
