import React from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { dataPipelines } from '../../../../../api/coreData'
import Loading from '../../../../molecules/Loading'
import AdminPage from '../../../admin/shared/AdminPage'
import ErrorDisplay from '../../../../molecules/ErrorDisplay'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import OperationalTable from '../../../../organisms/OperationalTable'
import FlexRow from '../../../../molecules/FlexRow'
import SydLinkButton from '../../../../commonDesign/LinkButton'

const columnConfig = {
  columns: [
    { id: 'pipelineId', accessor: 'pipelineId', Header: 'Pipeline ID' },
    { id: 'code', accessor: 'code', Header: 'Code' },
    { id: 'displayName', accessor: 'displayName', Header: 'Display Name' },
    { id: 'pipelineVersion', accessor: 'pipelineVersion', Header: 'Version' },
    { id: 'lastAnalysisDate', accessor: 'lastAnalysisDate', Header: 'Last Analysis', format: 'timestamp' }
  ],
  defaultSort: [{ id: 'pipelineId', desc: false }]
}

function PipelinesHome () {
  const { data, isLoading, error, refetch } = dataPipelines.useListDataPipelines()
  const { formatter } = useFormattingContext()
  const _columns = useConfigColumns({
    columnsConfig: columnConfig,
    format: formatter
  })
  const history = useHistory()

  if (error) {
    return (
      <AdminPage>
        <ErrorDisplay error={error} onRetry={refetch} />
      </AdminPage>
    )
  }

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <div />
            <div><SydLinkButton to='/admin/denali/analysis/pipelines/new' icon='add' size='sm' variant='primary'>New</SydLinkButton></div>
          </FlexRow>
        </Grid>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            itemName='Data Pipelines'
            columns={_columns.columns}
            defaultSort={_columns.defaultSort}
            data={data?.pipelines || []}
            onRowClick={row => history.push(`/admin/denali/analysis/pipelines/${row.original.pipelineId}`)}
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

PipelinesHome.propTypes = {
}

export default PipelinesHome
