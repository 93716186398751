import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { BUTTON_SIZES } from '../../../constants'
import RoundedButton from '../../atoms/RoundedButton'
import { acceptTerms } from '../../../service'
import { useAppContext } from '../../../redux/slices/appContext'
import PublicLayout from '../../molecules/PublicLayout'
import { useFirmInfo } from '../publicViews/hooks'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 150
  },
  title: {
    margin: '3rem 0 1rem 0',
    fontSize: '1.4rem',
    width: '22rem',
    fontWeight: 700,
    textAlign: 'center'
  },
  form: {
    position: 'relative',
    marginBottom: '2rem'
  },
  button: {
    marginTop: '1rem'
  },
  container: {
    height: '100% !important',
    flexDirection: 'column !important'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '1rem 0'
  },
  termsAndPolicyLabel: {
    fontSize: '.720rem',
    fontWeight: 800
  },
  termsAndPolicyLink: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  errorsContainer: {
    position: 'absolute',
    top: '100%',
    marginTop: '1rem',
    textAlign: 'center'
  },
  logout: {
    fontSize: '.875rem',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '3rem',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

const AcceptTerms = () => {
  const classes = useStyles()
  const history = useHistory()
  const { userProfile } = useAppContext()
  const { termsAcceptance, isValidTermsAcceptance } = userProfile || {}
  const { authState, oktaAuth } = useOktaAuth()
  const firmInfo = useFirmInfo()

  const [isLoading, setIsLoading] = useState(false)

  const signOut = useCallback(async () => {
    setIsLoading(true)
    await oktaAuth.signOut()
  }, [oktaAuth])

  useEffect(() => {
    const { isAuthenticated } = authState ?? { isAuthenticated: null }
    if (isAuthenticated === false || isValidTermsAcceptance) {
      history.replace('/')
    }
  }, [authState, history, isValidTermsAcceptance])

  const title = useMemo(() => {
    if (termsAcceptance && isValidTermsAcceptance) {
      return 'The terms & conditions have changed.  To continue, please accept them below.'
    }
    return 'To begin using the app, accept the terms & conditions below.'
  }, [isValidTermsAcceptance, termsAcceptance])

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { acceptedTC: false }
  })

  const onSubmitHandler = async () => {
    try {
      clearErrors()
      setIsLoading(true)
      await acceptTerms()
      await oktaAuth.signInWithRedirect({
        prompt: 'none',
        originalUri: '/'
      })
    } catch (err) {
      setIsLoading(false)
      setError('submit', { type: 'saving', message: 'Issue saving selection.  Please try again.' })
      await trigger()
    }
  }

  const submitButtonText = useMemo(() => {
    if (!isValid) {
      return 'Agree to the terms above'
    }
    return isLoading ? 'Saving...' : 'Agree'
  }, [isValid, isLoading])

  if (!firmInfo) {
    return null
  }
  const { backgroundPath, logoPath } = firmInfo

  return (
    <>
      <PublicLayout backgroundPath={backgroundPath}>
        <>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            width='100%'
          >
            {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
          </Box>
          <div className={classes.container}>
            <Typography component='h2' className={classes.title}>{title}</Typography>
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className={classes.form}
            >
              <div className={classes.termsContainer}>
                <div>
                  <Controller
                    control={control}
                    name='acceptedTC'
                    rules={{ required: 'Must agree to the terms & conditions to continue' }}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                        id='acceptedTC'
                        classes={{
                          root: classes.checkbox
                        }}
                      />
                    )}
                  />
                  <label
                    htmlFor='acceptedTC'
                    className={classes.termsAndPolicyLabel}
                  >I agree to Summit Wealth's
                  </label>
                </div>
                <div className={classes.termsAndPolicyLabel}>
                  <div>
                    <span>
                      <a
                        className={classes.termsAndPolicyLink} href='/legal/terms-of-use'
                        target='_blank' rel='noopener noreferrer'
                      >Terms of Use
                      </a> and&nbsp;
                      <a
                        className={classes.termsAndPolicyLink} href='/legal/privacy-policy'
                        target='_blank' rel='noopener noreferrer'
                      >Privacy Policy
                      </a>.
                    </span>
                  </div>
                </div>
              </div>
              <RoundedButton
                primary
                fullWidth
                type='submit'
                size={BUTTON_SIZES.medium}
                className={classes.button}
                isLoading={isLoading}
                disabled={!isDirty || !isValid || isLoading || isSubmitting}
              >
                {submitButtonText}
              </RoundedButton>

              <div className={classes.errorsContainer}>
                {Object.keys(errors) && Object.entries(errors).map(([key, value]) => (
                  <Typography key={key} color='error'>{value.message}</Typography>
                ))}
              </div>
            </form>

            <Typography
              component='h3'
              className={classes.logout}
              onClick={signOut}
            >
              Logout
            </Typography>
          </div>
        </>
      </PublicLayout>
    </>
  )
}

export default AcceptTerms
