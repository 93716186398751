import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../molecules/RoundedModal'
import ManageOptionsForm from './ManageOptionsForm'

const ManageOptionsDialog = React.forwardRef(function ManageOptionsDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item, report) => setDialogState({ open: true, processing: false, item, report })
  }), [setDialogState])

  const title = useMemo(() => {
    return `Manage Items for ${dialogState.item?.displayName}`
  }, [dialogState.item])

  return (
    <RoundedModal
      title={title}
      open={dialogState.open}
      onClose={close}
      size='large'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.item ? (
          <ManageOptionsForm item={dialogState.item} onCancel={close} onComplete={close} report={dialogState.report} />
        ) : null}
      </div>
    </RoundedModal>
  )
})

ManageOptionsDialog.propTypes = {
}

export default ManageOptionsDialog
