import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import Card from '../../../molecules/Card'

function OrchestratorHome ({ root }) {
  return (
    <FadeIn>
      <div style={{ margin: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card>
              Denali Orchestrator
            </Card>
          </Grid>
        </Grid>
      </div>
    </FadeIn>
  )
}

OrchestratorHome.propTypes = {
  root: PropTypes.any
}

export default OrchestratorHome
