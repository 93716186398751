import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../../../molecules/RoundedModal'
import ErrorBoundary from '../../../../../../../molecules/ErrorBoundary'
import EditFilterForm from './form'

const EditFilterDialog = React.forwardRef(function EditFilterDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (report, filter) => {
      setDialogState({ open: true, processing: false, filter: structuredClone(filter), report })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Filter'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <ErrorBoundary name='Modify Filter'>
            <EditFilterForm
              filter={dialogState.filter}
              report={dialogState.report}
              onComplete={handleComplete}
              onCancel={close}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    </RoundedModal>
  )
})

EditFilterDialog.propTypes = {
  onComplete: PropTypes.func
}

EditFilterDialog.defaultProps = {
  onComplete: () => {}
}

export default EditFilterDialog
