import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import PersonalSpace from './PersonalSpace'

const useStyles = makeStyles((theme) => ({
  adminPage: {
    margin: theme.layout.margin.m20
  }
}))

function AdminPage ({ children, ...fadeInProps }) {
  const classes = useStyles()
  return (
    <FadeIn className={classes.adminPage} {...fadeInProps}>
      {children}
      <PersonalSpace />
    </FadeIn>
  )
}

AdminPage.propTypes = {
  children: PropTypes.node
}

export default AdminPage
