import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import FlexRow from '../../../../../../molecules/FlexRow'
import SydButton from '../../../../../../commonDesign/Button'
import CodeBlock from '../../../../../../atoms/CodeBlock'

const useStyles = makeStyles((theme) => ({
  variablesPanel: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.main,
    borderLeft: `1px solid ${theme.palette.gray.dark}`,
    boxSizing: 'border-box',
    padding: theme.layout.padding.p5
  }
}))

function tryParseJson (str) {
  if (!str) return null
  try {
    return JSON.parse(str)
  } catch {
    return str
  }
}

function getDefaultFilterVariables (variables = {}) {
  return Object.entries(variables?.filters || {}).reduce((p, [k, v]) => {
    p.push({ id: Math.random().toString(), name: k, value: JSON.stringify(v) })
    return p
  }, [])
}

function getDefaultDateVariables (variables = {}) {
  return Object.entries(variables?.dates || {}).reduce((p, [k, v]) => {
    p.push({ id: Math.random().toString(), name: k, startDate: JSON.stringify(v.startDate), endDate: JSON.stringify(v.endDate) })
    return p
  }, [])
}

function Variables ({ defaultVariables, onChange }) {
  const classes = useStyles()
  const [filterVariables, setFilterVariables] = useState(getDefaultFilterVariables(defaultVariables))
  const [dateVariables, setDateVariables] = useState(getDefaultDateVariables(defaultVariables))
  const addFilterVariable = useCallback(() => {
    setFilterVariables(p => {
      const copy = [...p]
      copy.push({ id: Math.random().toString(), name: '', value: '' })
      return copy
    })
  }, [setFilterVariables])
  const addDateVariable = useCallback(() => {
    setDateVariables(p => {
      const copy = [...p]
      copy.push({ id: Math.random().toString(), name: '', startDate: '', endDate: '' })
      return copy
    })
  }, [setDateVariables])
  const changeFilterVariable = useCallback((id, field) => (e) => {
    const valCopy = e.target.value
    setFilterVariables(p => {
      const copy = [...p]
      p.find(x => x.id === id)[field] = valCopy
      return copy
    })
  }, [setFilterVariables])
  const changeDateVariable = useCallback((id, field) => (e) => {
    const valCopy = e.target.value
    setDateVariables(p => {
      const copy = [...p]
      p.find(x => x.id === id)[field] = valCopy
      return copy
    })
  }, [setDateVariables])
  const removeFilterVariable = useCallback((id) => () => {
    setFilterVariables(p => {
      return [...p.filter(x => x.id !== id)]
    })
  }, [setFilterVariables])
  const removeDateVariable = useCallback((id) => () => {
    setDateVariables(p => {
      return [...p.filter(x => x.id !== id)]
    })
  }, [setDateVariables])
  const [value, setValue] = useState({})
  useEffect(() => {
    setValue({
      filters: filterVariables.reduce((p, c) => {
        if (c.name) {
          p[c.name] = tryParseJson(c.value)
        }
        return p
      }, {}),
      dates: dateVariables.reduce((p, c) => {
        if (c.name) {
          p[c.name] = {
            startDate: tryParseJson(c.startDate),
            endDate: tryParseJson(c.endDate)
          }
        }
        return p
      }, {})
    })
  }, [filterVariables, dateVariables, setValue])
  useEffect(() => {
    onChange?.(value)
  }, [value, onChange])

  return (
    <div className={classes.variablesPanel}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexRow>
            <div>Filter Variables</div>
            <SydButton size='xs' icon='add' onClick={addFilterVariable}>Add</SydButton>
          </FlexRow>
        </Grid>
        {filterVariables.map(fv => (
          <Grid item xs={12} key={fv.id}>
            <FlexRow>
              <div style={{ flex: 1 }}>
                <input placeholder='variable name' value={fv.name} onChange={changeFilterVariable(fv.id, 'name')} style={{ width: '100%' }} />
              </div>
              <div style={{ flex: 1 }}>
                <input placeholder='variable value' value={fv.value} onChange={changeFilterVariable(fv.id, 'value')} style={{ width: '100%' }} />
              </div>
              <div>
                <SydButton size='xs' icon='close' onClick={removeFilterVariable(fv.id)}>Remove</SydButton>
              </div>
            </FlexRow>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FlexRow>
            <div>Date Variables</div>
            <SydButton size='xs' icon='add' onClick={addDateVariable}>Add</SydButton>
          </FlexRow>
        </Grid>
        {dateVariables.map(dv => (
          <Grid item xs={12} key={dv.id}>
            <FlexRow>
              <div style={{ flex: 1 }}>
                <input placeholder='variable name' value={dv.name} onChange={changeDateVariable(dv.id, 'name')} style={{ width: '100%' }} />
              </div>
              <div style={{ flex: 1 }}>
                <input placeholder='start' value={dv.startDate} onChange={changeDateVariable(dv.id, 'startDate')} style={{ width: '100%' }} />
              </div>
              <div style={{ flex: 1 }}>
                <input placeholder='end' value={dv.endDate} onChange={changeDateVariable(dv.id, 'endDate')} style={{ width: '100%' }} />
              </div>
              <div>
                <SydButton size='xs' icon='close' onClick={removeDateVariable(dv.id)}>Remove</SydButton>
              </div>
            </FlexRow>
          </Grid>
        ))}
        <Grid item xs={12}>
          <CodeBlock code={value} scroll />
        </Grid>
      </Grid>
    </div>
  )
}

Variables.propTypes = {
  defaultVariables: PropTypes.object,
  onChange: PropTypes.func
}

export default Variables
