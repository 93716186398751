import React, { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SecureRoute, useOktaAuth } from '@okta/okta-react'
import { useFeatureFlags, useHomeViewPath } from '../../redux/slices/appConfig'
import { usePermissions, useAuthState, usePolicies } from '../../hooks'
import { staticViews } from '../../staticViews'
import AbundanceEngineView from '../../abundanceEngine/AbundanceEngineView'
import { ABUNDANCE_ENGINE_PREFIX_PATH, ADVISOR_HOME_PATH, ADVISOR_VIEW_PATH } from '../../constants'
import { useAbundanceViewsInNavigation } from '../../redux/slices/abundanceEngineContext'
import ErrorBoundary from '../molecules/ErrorBoundary'
import AdvisorViewRoute from '../organisms/AdvisorView/AdvisorViewRoute'
import { VIEWS_PATH } from '../../utils'
import AbundanceEngineRoute from '../../abundanceEngine/AbundanceEngineRoute'
import AdvisorHome from '../pages/advisorHome'
import InternalRedirect from '../pages/redirect'
import AbundanceEngineGalleryView from '../pages/admin/abundanceEngineGalleryView'
import NotFoundView from '../pages/NotFoundView'
import TearSheet from '../pages/tearSheet'
import Partners from '../pages/views/Partners'

export const ABUNDANCE_ENGINE_PRIMARY_VIEWS = [
  {
    name: 'Client Cards',
    path: 'clients',
    exact: true,
    primary: true
  }
]

const isAllowedByPolicies = ({ appPolicies, policies, policyOperator }) => {
  const result = appPolicies[policies[0]]
  const isAllowed = policies.reduce((acc, policyName) => {
    const allowed = appPolicies[policyName]
    if (policyOperator === 'and') {
      return acc && allowed
    }
    return acc || allowed
  }, result)

  return isAllowed
}

const grantAccess = ({
  isFeatureFlagViewAllowed,
  forAdvisor,
  forSummitUser,
  isAdvisor,
  isSummitUser,
  policies,
  appPolicies,
  policyOperator
}) => {
  if (forSummitUser && !isSummitUser) return false

  if (forAdvisor && !isSummitUser && !isAdvisor) return false

  if (!isFeatureFlagViewAllowed) return false

  const usePolicy = !isEmpty(policies)
  const allowByPolicy = isAllowedByPolicies({ appPolicies, policies, policyOperator })

  if (usePolicy) return Boolean(allowByPolicy)

  return true
}

const RoutedViews = () => {
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { isAdvisor, isSummitUser, isWealthOwner } = usePermissions(authState.accessToken || {})
  const appPolicies = usePolicies()
  const homeViewPath = useHomeViewPath()
  const featureFlags = useFeatureFlags()
  const viewsInNavigation = useAbundanceViewsInNavigation()

  const staticViewsRendered = useMemo(() =>
    staticViews.map(({ Component, exact, forAdvisor, path, featureFlagKey, forSummitUser, policies = [], policyOperator = 'and' }) => {
      const isFeatureFlagViewAllowed = !featureFlagKey || featureFlags?.[featureFlagKey]?.active
      const hasAccess = grantAccess({
        isFeatureFlagViewAllowed,
        forAdvisor,
        forSummitUser,
        isAdvisor,
        isSummitUser,
        isWealthOwner,
        policies,
        appPolicies,
        policyOperator
      })
      if (hasAccess && Component) {
        return (
          <SecureRoute exact={exact} path={path} key={path}>
            <Component />
          </SecureRoute>
        )
      }
      return null
    })
  , [featureFlags, isSummitUser, isAdvisor, isWealthOwner, appPolicies])

  const viewsInNavigationRendered = useMemo(
    () => viewsInNavigation.map(({ path }) => (
      <SecureRoute path={`/${path}`} key={path} exact>
        <AbundanceEngineView viewPath={path} />
      </SecureRoute>
    )),
    [viewsInNavigation]
  )

  const abundanceEngineViewRendered = useMemo(
    () => (
      <SecureRoute path={`/${ABUNDANCE_ENGINE_PREFIX_PATH}`}>
        <AbundanceEngineView />
      </SecureRoute>
    ),
    []
  )

  const abundanceEnginePrimaryViewsRendered = useMemo(
    () =>
      ABUNDANCE_ENGINE_PRIMARY_VIEWS.map(({ path, exact }) => (
        <SecureRoute key={path} path={`/${path}`} exact={exact}>
          <AbundanceEngineView viewPath={path} />
        </SecureRoute>
      )),
    []
  )

  if (!isAdvisor) {
    return (
      <ErrorBoundary name='RoutedViews'>
        <Switch>
          <SecureRoute path={homeViewPath}>
            <AbundanceEngineRoute viewPath={homeViewPath.split('/').at(-1)} />
          </SecureRoute>
          {staticViewsRendered}
          <SecureRoute exact path='/'>
            <Redirect to={homeViewPath} />
          </SecureRoute>
          <Route path='*'>
            <NotFoundView />
          </Route>
        </Switch>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary name='RoutedViews'>
      <Switch>
        <SecureRoute path='/inbound-redirect'>
          <InternalRedirect />
        </SecureRoute>
        <SecureRoute path='/partners'>
          <Partners />
        </SecureRoute>
        <SecureRoute path={`/${ADVISOR_HOME_PATH}`}>
          <AdvisorHome />
        </SecureRoute>
        <SecureRoute exact path={`/${ADVISOR_VIEW_PATH}`}>
          <AbundanceEngineRoute viewPath='advisor' />
        </SecureRoute>
        <SecureRoute exact path={`/${ADVISOR_VIEW_PATH}/:id`}>
          <AdvisorViewRoute />
        </SecureRoute>
        <SecureRoute path={`/${ADVISOR_VIEW_PATH}/:id/:subView`}>
          <AdvisorViewRoute />
        </SecureRoute>
        <SecureRoute path={`/${VIEWS_PATH}/:view`}>
          <AbundanceEngineRoute />
        </SecureRoute>
        <SecureRoute path='/tear-sheets'>
          <TearSheet />
        </SecureRoute>
        {abundanceEnginePrimaryViewsRendered}
        {viewsInNavigationRendered}
        {abundanceEngineViewRendered}
        <SecureRoute path={`/admin/${ABUNDANCE_ENGINE_PREFIX_PATH}-gallery`}>
          <AbundanceEngineGalleryView />
        </SecureRoute>
        {staticViewsRendered}
        <SecureRoute exact path='/'>
          <Redirect to={homeViewPath} />
        </SecureRoute>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </ErrorBoundary>
  )
}

export default RoutedViews
