import { useQuery } from '@tanstack/react-query'
import { postNamedQuery } from '../../../../../../service'

export const useGetInfographicTemplateDetails = (body = {}, options = {}) => {
  const { mapper = null, enabled = true } = options ?? {}
  return useQuery({
    queryKey: ['reports', 'getInfographicTemplateDetails', body],
    queryFn: async () => {
      const { data } = await postNamedQuery('reports', 'get-infographic-template-details', body)
      return data
    },
    enabled,
    mapper: mapper ?? ((data) => data?.data ?? [])
  })
}
