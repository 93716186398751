import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Grid, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Text from '../../../../atoms/Text'
import Skeleton from '../../../../atoms/Skeleton'
import { useBoolean, useCheckPolicy } from '../../../../../hooks'
import { BUTTON_SIZES } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import { useLevelDates } from '../../../../../api/coreData'
import Dialog from '../../../../molecules/Dialog/Dialog'
import RoundedButton from '../../../../atoms/RoundedButton'
import { ACCOUNTS } from '../../../../../policies/admin'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ACCOUNT_FORM_NAMES, levelDatesQuery, saveAccountStatus, savePositionsStatus } from '../helpers'
import { useHoldings, usePositionColumns } from '../hooks'
import FormSelectField from '../FormSelectField'
import SubmitButtons from '../SubmitButtons'
import SectionHeader from '../../shared/SectionHeader'
import { useAccountFormContext } from '../AccountFormProvider'
import EditButton from '../../shared/EditButton'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  container: {
    padding: '0',
    width: '100%'
  },
  subtitle: {
    fontSize: '22px',
    color: '#141929',
    marginBottom: '18px'
  },
  checkboxCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkbox: {
    marginRight: '8px'
  },
  selector: {
    marginBottom: '24px'
  },
  loader: {
    marginTop: '10px'
  }
}))

const ManageStatusOptions = {
  1: {
    label: 'Managed',
    value: 1
  },
  2: {
    label: 'Unmanaged',
    value: 2
  }
}

const DiscretionaryStatusOptions = {
  1: {
    label: 'Discretionary',
    value: 1
  },
  2: {
    label: 'Non-Discretionary',
    value: 2
  }
}

const PerformingStatusOptions = {
  1: {
    label: 'Performing',
    value: 1
  },
  2: {
    label: 'Non-Performing',
    value: 2
  }
}

const FIELDS = [
  {
    accessor: ACCOUNT_FORM_NAMES.managedStatusId,
    options: Object.values(ManageStatusOptions),
    label: 'Managed Status',
    posibleOptions: ManageStatusOptions
  },
  {
    accessor: ACCOUNT_FORM_NAMES.discretionaryStatusId,
    options: Object.values(DiscretionaryStatusOptions),
    label: 'Discretionary Status',
    posibleOptions: DiscretionaryStatusOptions
  },
  {
    accessor: ACCOUNT_FORM_NAMES.performanceStatusId,
    options: Object.values(PerformingStatusOptions),
    label: 'Performing Status',
    posibleOptions: PerformingStatusOptions
  }
]

const POSITIONS_FIELDS = [
  {
    accessor: ACCOUNT_FORM_NAMES.positionsManagedStatusId,
    options: Object.values(ManageStatusOptions),
    label: 'Managed Status',
    posibleOptions: ManageStatusOptions
  },
  {
    accessor: ACCOUNT_FORM_NAMES.positionsDiscretionaryStatusId,
    options: Object.values(DiscretionaryStatusOptions),
    label: 'Discretionary Status',
    posibleOptions: DiscretionaryStatusOptions
  },
  {
    accessor: ACCOUNT_FORM_NAMES.positionsPerformanceStatusId,
    options: Object.values(PerformingStatusOptions),
    label: 'Performing Status',
    posibleOptions: PerformingStatusOptions
  }
]

const FORMAT = 'YYYY-MM-DD'

function getStatusValue (account, accessor) {
  let status = ''
  if (typeof account[accessor] === 'number') {
    status = account[accessor] === 1 ? 1 : 2
  }
  return status
}

function getRequestValue (value) {
  return value === 1 ? 1 : 0
}

export const mapFormDefaultValues = (account) => {
  if (!account) return null

  return {
    [ACCOUNT_FORM_NAMES.managedStatusId]: getStatusValue(account, ACCOUNT_FORM_NAMES.managedStatusId),
    [ACCOUNT_FORM_NAMES.discretionaryStatusId]: getStatusValue(account, ACCOUNT_FORM_NAMES.discretionaryStatusId),
    [ACCOUNT_FORM_NAMES.performanceStatusId]: getStatusValue(account, ACCOUNT_FORM_NAMES.performanceStatusId),
    [ACCOUNT_FORM_NAMES.positionsManagedStatusId]: '',
    [ACCOUNT_FORM_NAMES.positionsDiscretionaryStatusId]: '',
    [ACCOUNT_FORM_NAMES.positionsPerformanceStatusId]: ''
  }
}

const getFormValues = ({ updateDefault, values, dirtyFields }) => {
  if (updateDefault) {
    const dirtyFieldsKeys = Object.keys(dirtyFields)
    return {
      managedStatusId: dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.managedStatusId) ? getRequestValue(values[ACCOUNT_FORM_NAMES.managedStatusId]) : null,
      discretionaryStatusId: dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.discretionaryStatusId) ? getRequestValue(values[ACCOUNT_FORM_NAMES.discretionaryStatusId]) : null,
      performanceStatusId: dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.performanceStatusId) ? getRequestValue(values[ACCOUNT_FORM_NAMES.performanceStatusId]) : null
    }
  }

  const managedStatusId = values[ACCOUNT_FORM_NAMES.positionsManagedStatusId] !== '' ? getRequestValue(values[ACCOUNT_FORM_NAMES.positionsManagedStatusId]) : null
  const discretionaryStatusId = values[ACCOUNT_FORM_NAMES.positionsDiscretionaryStatusId] !== '' ? getRequestValue(values[ACCOUNT_FORM_NAMES.positionsDiscretionaryStatusId]) : null
  const performanceStatusId = values[ACCOUNT_FORM_NAMES.positionsPerformanceStatusId] !== '' ? getRequestValue(values[ACCOUNT_FORM_NAMES.positionsPerformanceStatusId]) : null
  return {
    managedStatusId,
    discretionaryStatusId,
    performanceStatusId
  }
}

function Positions ({
  onSave
}) {
  const { account, cancelEdit, ...accountForm } = useAccountFormContext()
  const sectionEditing = accountForm.sectionEditing('account_positions')
  const editMode = sectionEditing
  const classes = useStyles()
  const [formLoading, setFormLoading] = useBoolean()
  const [showOptions, setShowOptions] = useBoolean()
  const [isAllChecked, setIsAllChecked] = useBoolean(false)
  const [selectedAssets, setSelectedAssets] = useState([])
  const canEditPositions = useCheckPolicy(ACCOUNTS.editPositionSettings)

  const { data: userLevelDates = {} } = useLevelDates(levelDatesQuery)

  const onCheckAsset = useCallback((assetId) => {
    if (selectedAssets.includes(assetId)) {
      setSelectedAssets(selectedAssets.filter((id) => id !== assetId))
      setIsAllChecked.off()
    } else {
      setSelectedAssets((prevState) => [...prevState, assetId])
    }
  }, [selectedAssets, setIsAllChecked])

  const onSelectAll = useCallback(() => setIsAllChecked.toggle(), [setIsAllChecked])

  const defaultColumnConfig = usePositionColumns({
    onSelect: onCheckAsset,
    onSelectAll,
    selected: selectedAssets,
    allSelected: isAllChecked,
    classes,
    editMode: editMode && canEditPositions
  })

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(defaultColumnConfig?.defaultSort || {})

  const { data = [], loading, refetch: refetchHoldings, isFetching } = useHoldings({
    pageIndex,
    pageSize,
    startDate: dayjs.utc(userLevelDates.max).format(FORMAT),
    accountId: account.accountId,
    options: {
      enabled: !!userLevelDates?.max
    }
  })

  const dataById = useMemo(() => {
    return data.reduce((acc, position) => {
      return { ...acc, [position.positionId]: position }
    }, {})
  }, [data])

  const defaultValues = useMemo(() => {
    return mapFormDefaultValues(account)
  }, [account])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {}
  })

  const {
    reset,
    handleSubmit,
    watch,
    register,
    formState: { isValid, dirtyFields }
  } = formMethods

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const onSubmit = useCallback(
    async (values, updateDefault) => {
      try {
        setFormLoading.on()
        await Promise.all([
          saveAccountStatus({
            account,
            newValues: getFormValues({ updateDefault: true, values, dirtyFields })
          }),
          savePositionsStatus({
            positionsIds: selectedAssets,
            data: dataById,
            isAllChecked: updateDefault || isAllChecked,
            ...getFormValues({ updateDefault, values, dirtyFields })
          })
        ])
        await Promise.all([
          onSave(),
          refetchHoldings()
        ])
      } catch (err) {
        console.error(err)
      } finally {
        reset()
        setShowOptions.off()
        cancelEdit()
        setFormLoading.off()
      }
    },
    [
      dirtyFields,
      refetchHoldings,
      setFormLoading,
      cancelEdit,
      account,
      reset,
      selectedAssets,
      dataById,
      isAllChecked,
      setShowOptions,
      onSave
    ]
  )

  const submitForm = useCallback(async (values) => {
    const dirtyFieldsKeys = Object.keys(dirtyFields)
    const includeManagedStatus = dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.managedStatusId)
    const includeDiscretionary = dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.discretionaryStatusId)
    const includePerformance = dirtyFieldsKeys.includes(ACCOUNT_FORM_NAMES.performanceStatusId)
    if (includeManagedStatus || includeDiscretionary || includePerformance) {
      setShowOptions.on()
    } else {
      await onSubmit(values, false)
    }
  }, [dirtyFields, onSubmit, setShowOptions])

  const onAcceptUpdateDefault = useCallback(async () => {
    await onSubmit(watch(), true)
  }, [onSubmit, watch])

  const onDeclineUpdateDefault = useCallback(async () => {
    await onSubmit(watch(), false)
  }, [onSubmit, watch])

  const onResetForm = useCallback(() => {
    reset(mapFormDefaultValues(account))
  }, [account, reset])

  const onCancel = useCallback(() => {
    setShowOptions.off()
    onResetForm()
    cancelEdit()
    setIsAllChecked.off()
  }, [onResetForm, cancelEdit, setIsAllChecked, setShowOptions])

  const renderField = useCallback(
    ({ accessor, label, options, posibleOptions }) => {
      return (
        <FormSelectField
          key={accessor}
          label={label}
          accessor={accessor}
          editMode={editMode && canEditPositions}
          value={watch(accessor)}
          options={options}
          text={posibleOptions[watch(accessor)]?.label || ' '}
          InputProps={{
            ...register(accessor),
            disableUnderline: true
          }}
        />
      )
    },
    [canEditPositions, editMode, register, watch]
  )

  if (loading) {
    return (
      <Grid container spacing={4} className={classes.loader}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Skeleton
              width='80%'
              height='2.5rem'
              className={classes.subtitle}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton
            width='100%'
            height='2.5rem'
            className={classes.selector}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton
            width='100%'
            height='2.5rem'
            className={classes.selector}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton
            width='100%'
            height='2.5rem'
            className={classes.selector}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <FadeIn className={classes.container}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid container>
          <SectionHeader text='Account Defaults'>
            <EditButton
              policy={ACCOUNTS.editPositionSettings}
              editing={sectionEditing}
              onClick={() => accountForm.editSection({
                section: 'account_positions'
              })}
            />
          </SectionHeader>
          <Grid item container xs={12}>
            {FIELDS.map((field) => renderField(field))}
          </Grid>
          <Grid item xs={12}>
            <Text text='Set Position Status' className={classes.subtitle} />
          </Grid>
          <Grid item xs={12} container>
            {POSITIONS_FIELDS.map((field) => renderField(field))}
          </Grid>
          <SubmitButtons
            editMode={editMode && canEditPositions}
            onCancel={onCancel}
            isSubmitting={formLoading}
            isFormValid={isValid}
          />
        </Grid>
      </form>
      <OperationalTable.Wrapper>
        <OperationalTable
          mode='server'
          columns={defaultColumnConfig.columns || []}
          data={data}
          defaultPageSize={defaultPageSize}
          defaultSort={defaultColumnConfig.defaultSort || {}}
          itemName='Accounts'
          loading={loading || isFetching}
          total={data.length}
          onPagingChange={onPagingChange}
          onSortingChange={onSortingChange}
          onTableModeChange={onTableModeChange}
        />
      </OperationalTable.Wrapper>
      <Dialog size='xs' open={showOptions} onClose={onCancel}>
        <Dialog.Body className={classes.dialogBody}>
          <Text text='Would you like to change the status for all current positions?' />
        </Dialog.Body>
        <Dialog.Actions>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item sm={6} xs={12}>
              <RoundedButton
                onClick={onDeclineUpdateDefault}
                secondary
                fullWidth
                disabled={formLoading}
                isLoading={formLoading}
                size={BUTTON_SIZES.medium}
              >
                No
              </RoundedButton>
            </Grid>
            <Grid item sm={6} xs={12}>
              <RoundedButton
                primary
                fullWidth
                size={BUTTON_SIZES.medium}
                disabled={formLoading}
                isLoading={formLoading}
                onClick={onAcceptUpdateDefault}
              >
                Yes
              </RoundedButton>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </FadeIn>
  )
}

Positions.propTypes = {
  onSave: PropTypes.func
}

export default Positions
