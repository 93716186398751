import React from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'

function ModifiedBy ({ author, timestamp }) {
  const { formatter } = useFormattingContext()

  if (!author && !timestamp) {
    return '--'
  }

  return (
    <span>{formatter(author, 'title')}@{formatter(timestamp, 'localDateTime')}</span>
  )
}

ModifiedBy.propTypes = {
  author: PropTypes.string,
  timestamp: PropTypes.string
}

export default ModifiedBy
