import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import dayjs from 'dayjs'
import AdminPage from '../../../admin/shared/AdminPage'
import { systemJobs } from '../../../../../api/ingestion'
import Loading from '../../../../molecules/Loading'
import Card from '../../../../molecules/Card'
import FieldDisplay from '../../../../molecules/FieldDisplay'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { decipherError } from '../../../../../utils/decipherError'
import EventLogs from '../EventLogs'
import SydButton from '../../../../commonDesign/Button'
import FlexActions from '../../../../molecules/FlexActions'
import JobGroupMemberDisplay from './JobGroupMemberDisplay'
import JobGroupMemberEdit from './JobGroupMemberEdit'

const useSubmitter = (onComplete, jobGroupId) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifySystemJobGroupMembers } = systemJobs.useModifySystemJobGroupMembers()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      jobGroupId,
      members: formData.members.map((m, i) => ({
        ordinal: i,
        jobId: m.jobId
      }))
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifySystemJobGroupMembers(command)
      if (result?.statusCode === 500) {
        setError('Something unexpected happened while modifying job group membership')
        return
      }
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifySystemJobGroupMembers, jobGroupId])

  const submitter = useCallback(async (handleSubmit, e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function JobGroupDetails () {
  const { jobGroupCode } = useParams()
  const { data, isLoading } = systemJobs.useGetJobGroupByKey(jobGroupCode)
  const [isEditing, setIsEditing] = useState(false)
  const groupFields = useMemo(() => {
    if (!data?.jobGroup) return []

    return [
      { key: 'name', label: 'Name', value: data.jobGroup.name },
      { key: 'code', label: 'Code', value: data.jobGroup.code }
    ]
  }, [data])
  const stopEditing = useCallback(() => setIsEditing(false), [setIsEditing])
  const { submitter, processing, error } = useSubmitter(stopEditing, data?.jobGroup?.jobGroupId)
  const { mutateAsync: startJobGroupCommand, isLoading: starting } = systemJobs.useStartSystemJobGroup()
  const startJobGroup = useCallback(async () => {
    await startJobGroupCommand({
      code: data.jobGroup.code,
      processDate: dayjs().format('YYYY-MM-DD'),
      executionArgs: {}
    })
  }, [data?.jobGroup?.code, startJobGroupCommand])
  const logFilter = useMemo(() => {
    if (!data?.jobGroup) return null

    return ({
      targetType: 'systemJobGroup',
      targetIdentifier: data?.jobGroup?.jobGroupId
    })
  }, [data?.jobGroup])

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FieldDisplay fields={groupFields} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FlexActions>
                  <SydButton onClick={startJobGroup} processing={starting}>Start</SydButton>
                </FlexActions>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {(isEditing) ? (
            <FadeIn key='mode_editing'>
              <JobGroupMemberEdit
                jobGroup={data?.jobGroup}
                onSave={submitter}
                onCancel={() => setIsEditing(false)}
                processing={processing}
                error={error}
              />
            </FadeIn>
          ) : (
            <FadeIn key='mode_display'>
              <JobGroupMemberDisplay
                onEdit={() => setIsEditing(true)}
                jobGroup={data?.jobGroup}
              />
            </FadeIn>
          )}
        </Grid>
        <Grid item xs={12}>
          <EventLogs filter={logFilter} enabled={!!logFilter} />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

export default JobGroupDetails
