import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { dataPipelines, useCoreTableData } from '../../../../../../api/coreData'
import { flatten } from '../../../../../../utils/flatten'
import { decipherError } from '../../../../../../utils/decipherError'
import { useDenaliContext } from '../../../DenaliProvider'
import { SUCCESS_ALERT } from '../../../../../../constants'
import { validateTableDataRequest } from './validateTableDataRequest'

export const PipelineEditorContext = createContext({})

export const usePipelineEditor = () => {
  return useContext(PipelineEditorContext)
}

/** wow what a mess - jason */
const PipelineEditorProvider = ({ initialValue, children }) => {
  const { setAlert } = useDenaliContext()
  const history = useHistory()
  const editorRef = useRef(null)
  const [pipelineId] = useState(initialValue.pipelineId || null)
  const [currentQuery, setCurrentQuery] = useState(null)
  const [variables, setVariables] = useState(initialValue.pipeline.variables)
  const [displayName, setDisplayName] = useState(initialValue.displayName)
  const [code, setCode] = useState(initialValue.code)
  const [description, setDescription] = useState(initialValue.description)
  const [scope, setScope] = useState(initialValue.scope)
  const [parsedRequest, setParsedRequest] = useState(null)
  const { data: response, isFetching: responseFetching, error: responseError, refetch } = useCoreTableData(parsedRequest, { enabled: !!parsedRequest })
  const { mutateAsync: createDataPipeline } = dataPipelines.useCreateDataPipeline()
  const { mutateAsync: modifyDataPipeline } = dataPipelines.useModifyDataPipeline()
  const [validation, setValidation] = useState(null)
  const [requestValidation, setRequestValidation] = useState(null)
  const [analysis, setAnalysis] = useState(initialValue.analysis || { fields: [] })
  useEffect(() => {
    if (responseError) {
      return
    }
    if (!response?.length) {
      return
    }
    const data = response || []
    const allColumns = (data || []).reduce((p, c) => {
      const cFlat = flatten(c)
      const fields = Object.entries(cFlat)
      fields.forEach(([key, value]) => {
        if (key in p && p[key] !== 'null') return
        if (value === null) {
          p[key] = 'null'
          return
        }
        p[key] = typeof value
      })

      return p
    }, {})
    const fields = Object.entries(allColumns).map(([key, type]) => {
      return {
        accessor: key,
        type
      }
    })
    setAnalysis({
      fields
    })
  }, [response, responseError, setAnalysis])

  const validate = useCallback(() => {
    const result = {}
    if (!displayName) {
      result.displayName = 'Required'
    } else if (displayName?.length > 64) {
      result.displayName = 'Maximum 64 Characters'
    }

    if (!code) {
      result.code = 'Required'
    } else if (code.length > 64) {
      result.code = 'Maximum 64 Characters'
    } else if (!(/^[a-z0-9_-]+$/.test(code))) {
      result.code = 'Only lowercase letters, numbers, dashes, underscores allowed'
    }

    if (!scope) {
      result.scope = 'Required'
    }

    if (isEmpty(result)) {
      setValidation(null)
      return null
    }

    setValidation(result)
    return result
  }, [displayName, code, scope, setValidation])

  const handleRun = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    const valid = validateTableDataRequest(editorRef, variables)
    setRequestValidation(valid)
    if (valid.isValid) {
      setCurrentQuery(valid.request)
      setParsedRequest(valid.parsedRequest)
    }
  }, [editorRef, setCurrentQuery, variables, setRequestValidation])

  const [processing, setProcessing] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const handleSave = useCallback(async () => {
    const validationResult = validate()
    if (validationResult) return

    const command = {
      displayName,
      code,
      description,
      scope,
      pipeline: {
        coreTableDataRequest: currentQuery,
        variables
      },
      analysis,
      pipelineVersion: 1
    }

    if (pipelineId) {
      command.pipelineId = pipelineId
    }

    try {
      setProcessing(true)
      setSubmitError(null)
      const response = pipelineId ? await modifyDataPipeline(command) : await createDataPipeline(command)
      if (response?.statusCode === 500) {
        throw new Error('There was a problem saving the data pipeline')
      }

      setAlert({
        ...SUCCESS_ALERT,
        alertMessage: `Data Pipeline "${displayName}" saved!`
      })

      if (!pipelineId) {
        history.push(`/admin/denali/analysis/pipelines/${response.pipelineId}`)
      }

      return response
    } catch (error) {
      setSubmitError(decipherError(error))
    } finally {
      setProcessing(false)
    }
  }, [validate, createDataPipeline, modifyDataPipeline, setProcessing, setSubmitError,
    analysis, variables, currentQuery, displayName, code, description, scope, pipelineId, setAlert, history])

  const [resultMode, setResultMode] = useState('table')
  const contextValue = useMemo(() => {
    return {
      editorRef,
      handleRun,
      response,
      responseFetching,
      responseError,
      refetch,
      resultMode,
      setResultMode,
      variables,
      setVariables,
      displayName,
      setDisplayName,
      code,
      setCode,
      description,
      setDescription,
      scope,
      setScope,
      validate,
      validation,
      requestValidation,
      currentQuery,
      analysis,
      processing,
      submitError,
      handleCreate: handleSave,
      pipelineId
    }
  }, [
    editorRef, handleRun, response, responseFetching, responseError, refetch,
    resultMode, setResultMode, variables, setVariables,
    displayName, setDisplayName,
    code, setCode,
    description, setDescription,
    scope, setScope,
    validate, validation,
    requestValidation,
    currentQuery, analysis,
    processing, submitError, handleSave, pipelineId
  ])

  return (
    <PipelineEditorContext.Provider value={contextValue}>
      {children}
    </PipelineEditorContext.Provider>
  )
}

PipelineEditorProvider.propTypes = {
  children: PropTypes.node,
  initialValue: PropTypes.object
}

export default PipelineEditorProvider
