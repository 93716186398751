import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import { useConfigColumns } from '../../../../../../hooks/useConfigColumns'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import OperationalTable from '../../../../../organisms/OperationalTable'
import FlexRow from '../../../../../molecules/FlexRow'
import SydButton from '../../../../../commonDesign/Button'
import ModifyColumnsDialog from './ModifyColumnsDialog'

const columnConfig = {
  columns: [
    { id: 'ordinal', accessor: 'ordinal', Header: 'Ordinal', format: 'number' },
    { id: 'id', accessor: 'id', Header: 'Col ID' },
    { id: 'title', accessor: 'title', Header: 'Title' },
    { id: 'type', accessor: 'type', Header: 'Type' },
    { id: 'format', accessor: 'format', Header: 'Format String', format: 'title' },
    { id: 'search', accessor: 'search', Header: 'Text Searchable?', format: 'bool' }
  ],
  defaultSort: [{ id: 'ordinal', desc: false }]
}

function ViewColumnsTab ({ report }) {
  const columnData = useMemo(() => {
    const c = Object.entries(report.configuration.columns) || []
    return c.map(([id, val]) => ({
      id,
      ...val
    }))
  }, [report])
  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useConfigColumns({
    columnsConfig: columnConfig,
    format: formatter
  })
  const modifyColumnsRef = useRef(null)

  return (
    <FadeIn>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card>
            <FlexRow style={{ justifyContent: 'flex-end', paddingBottom: '5px' }}>
              <SydButton size='sm' variant='primary' icon='edit' onClick={() => modifyColumnsRef.current.open(report)}>Edit</SydButton>
            </FlexRow>
            <OperationalTable
              variant='v2'
              columns={columns}
              defaultSort={defaultSort}
              data={columnData}
              hideFooter
            />
          </Card>
        </Grid>
      </Grid>
      <ModifyColumnsDialog ref={modifyColumnsRef} />
    </FadeIn>
  )
}

ViewColumnsTab.propTypes = {
  report: PropTypes.object.isRequired
}

export default ViewColumnsTab
