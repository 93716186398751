import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import DeleteuserForm from './form'

const DeleteuserDialog = React.forwardRef(function DeleteUserDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (user) => setDialogState({ open: true, processing: false, user })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Permanently Delete Advisor'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<DeleteuserForm user={dialogState.user} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

DeleteuserDialog.propTypes = {
}

export default DeleteuserDialog
