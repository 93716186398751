import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Card from '../../../../molecules/Card'
import {
  useListIntegrationDomains,
  useSearchIntegrationSources
} from '../../../../../api/integrations'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import NavTile from '../../shared/NavTile'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import IntegrationSourceList from './IntegrationSourceList'

function IntegrationDomains ({ root }) {
  const { data, isLoading } = useListIntegrationDomains()
  const [selectedDomain, setSelectedDomain] = useState(null)
  const sourceQuery = useMemo(() => ({
    filters: {
      domain: selectedDomain?.domain
    },
    includes: {
      connection: true
    }
  }), [selectedDomain])
  const { data: isData, isLoading: integrationSourcesLoading } = useSearchIntegrationSources(sourceQuery, { enabled: !!selectedDomain?.domain })

  useEffect(() => {
    if (data?.integrationDomains?.length && !selectedDomain) {
      setSelectedDomain(data.integrationDomains.at(0))
    }
  }, [data, selectedDomain, setSelectedDomain])

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {(data?.integrationDomains || []).map(domain => (
            <NavTile
              key={`domain_${domain.domain}`}
              label={domain.domain}
              selected={selectedDomain === domain}
              onSelect={() => setSelectedDomain(domain)}
            />
          ))}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {integrationSourcesLoading ? (
                <FadeIn>
                  <Loading />
                </FadeIn>
              ) : selectedDomain ? (
                <FadeIn>
                  <Card>
                    <IntegrationSourceList
                      root={root}
                      domain={selectedDomain.domain}
                      sources={(isData?.data || [])}
                    />
                  </Card>
                </FadeIn>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

IntegrationDomains.propTypes = {
  root: PropTypes.any
}

export default IntegrationDomains
