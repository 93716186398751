import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PublicLayout from './components/molecules/PublicLayout'
import { usePublicStyles } from './components/pages/publicViews/usePublicStyles'
import { useFirmInfo } from './components/pages/publicViews/hooks'
import Card from './components/molecules/Card'
import Logo from './components/atoms/Logo'

const AuthError = () => {
  const location = useLocation()
  const classes = usePublicStyles()
  const firmInfo = useFirmInfo()

  useEffect(() => {
    console.error('Auth Error:', location.state?.error)
  }, [location])

  return (
    <PublicLayout className={classes.layout} backgroundPath={firmInfo?.backgroundPath}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '50px', marginTop: '-10vh' }}>
        <Logo style={{ maxWidth: '30vw' }} />
        <Card>
          <h1>Sorry, there was a problem while trying to sign in.</h1>
          <div>
            <p><em>{location.state?.error || 'An unknown error occurred.'}</em></p>
          </div>
          <Link to='/'>Go to Home</Link>
        </Card>
      </div>
    </PublicLayout>
  )
}

export default AuthError
