import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { Snackbar, makeStyles } from '@material-ui/core'

import { initialAlertState } from '../../pages/admin/common'

const useStyles = makeStyles(() => ({
  alertMessage: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function SnackAlert ({ alert }) {
  const classes = useStyles()
  const [alertState, setAlertState] = useState(alert)
  const alertMessage = alertState?.alertMessage
  const alertSeverity = alertState?.alertSeverity
  const openAlert = alertState?.openAlert

  const handleCloseAlert = useCallback((_, reason) => {
    if (reason === 'clickaway') return
    setAlertState(initialAlertState)
  }, [])

  useEffect(() => {
    if (alert) {
      setAlertState(alert)
    }
  }, [alert])

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={6000}
      onClose={handleCloseAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleCloseAlert}
        severity={alertSeverity}
        className={classes.alertMessage}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  )
}

SnackAlert.propTypes = {
  alert: PropTypes.shape({
    openAlert: PropTypes.bool,
    alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    alertSeverity: PropTypes.string
  })
}

export default SnackAlert
