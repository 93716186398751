import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router-dom'
import {
  TextField,
  makeStyles,
  Typography,
  Box,
  Checkbox,
  useTheme,
  CircularProgress,
  InputAdornment
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import noop from 'lodash/noop'

import Text from '../../atoms/Text'
import RoundedButton from '../../atoms/RoundedButton'
import PasswordRequirements from '../../organisms/PasswordRequirements/PasswordRequirements'

import { API_ERROR_CODES, BUTTON_SIZES, PASSWORD_ROUTES, TEXT_VARIANTS } from '../../../constants'
import { useBoolean, useGetInputProps, useValidatePassword } from '../../../hooks'
import { resetPassword } from '../../../service'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 150
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    marginTop: '1rem'
  },
  field: {
    width: '100%',
    backgroundColor: theme.palette.gray.main,
    border: '1px solid transparent'
  },
  fieldContainer: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '1.5rem'
  },
  fieldError: {
    border: `1px solid ${theme.palette.error.main}`
  },
  button: {
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  backToLoginLabel: {
    fontSize: '.875rem',
    fontWeight: 600,
    marginTop: '1.75rem',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  termsAndPolicyLabel: {
    fontSize: '.720rem',
    fontWeight: 800
  },
  termsAndPolicyLink: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  welcomeMsg: {
    marginBottom: '20px',
    whiteSpace: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
      textAlign: 'initial'
    }
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '5px'
  },
  inputBase: {
    borderRadius: '0px',
    border: 'none',
    padding: '0.75rem',
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    padding: '10px 0 7px',
    '&::placeholder': {
      fontSize: '1rem',
      textOverflow: 'ellipsis !important',
      color: theme.palette.common.black,
      opacity: '60%',
      fontWeight: 600
    }
  }
}))

const validateConfirmPassword = (password, confirmPassword, errorKey, clearErrors) => {
  if (confirmPassword && password !== confirmPassword) {
    return 'Password does not match'
  } else {
    clearErrors(errorKey)
  }
}

const inputProps = ({ classes, showLoading = false }) => ({
  className: classes.inputBase,
  classes: {
    input: classes.input
  },
  disableUnderline: true,
  ...(showLoading && {
    endAdornment: (
      <InputAdornment position='end'>
        <CircularProgress size={15} />
      </InputAdornment>
    )
  })
})

const SetPasswordForm = ({ logoPath, formMethods, authInfo }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState(null)

  const pathname = location.pathname

  const isActivationPath = pathname === PASSWORD_ROUTES.ACTIVATION || pathname === PASSWORD_ROUTES.RESET_USER

  const [showPasswords, getInputProps] = useGetInputProps({
    password: false,
    confirmPassword: false
  })
  const [passErrors, validatePassword] = useValidatePassword({
    stringLength: true,
    lowercase: true,
    uppercase: true,
    digit: true,
    confirmPassword: true,
    invalidPassword: true
  })

  const {
    handleSubmit,
    watch,
    control,
    clearErrors,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = formMethods

  const passwordRef = useRef()
  const confirmPassRef = useRef()
  passwordRef.current = watch('password', '')
  confirmPassRef.current = watch('confirmPassword', '')

  const showProfileLabel = useMemo(
    () => authInfo.email && authInfo.firstName && authInfo.lastName,
    [authInfo]
  )

  const onResetPasswordHandler = useCallback(
    async (values) => {
      try {
        setLoading.on()
        const body = {
          email: authInfo.email,
          password: values.password,
          recoveryToken: authInfo.activationToken,
          recoveryAnswer: values.recoveryAnswer
        }

        await resetPassword(body)

        history.push('/login')
      } catch (err) {
        setLoading.off()
        const message = 'Reset password failed.'
        const errorMsg = (err.code === API_ERROR_CODES.FORBIDDEN || err.code === API_ERROR_CODES.UNAUTHORIZED)
          ? `${message} Please contact your administrator.`
          : `${message} Please try again or contact your administrator.`

        setError(errorMsg)
      }
    },
    [
      setLoading,
      authInfo,
      history
    ]
  )

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        marginBottom='50px'
      >
        {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
      </Box>
      {showProfileLabel && (
        <>
          <Text
            text={`Welcome, ${authInfo.firstName} ${authInfo.lastName}`}
            variant={TEXT_VARIANTS.h2}
            className={classes.welcomeMsg}
          />
          {
            isActivationPath ? (
              <Text
                text={`Please set the password for ${authInfo.email}`}
                customFontSize='1rem'
                customFontWeight={400}
                className={classes.welcomeMsg}
              />
            ) : (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Text
                  text={`Please answer the security question for ${authInfo.email}`}
                  customFontSize='1rem'
                  customFontWeight={400}
                  className={classes.welcomeMsg}
                />
                <Text
                  text='Type in your new password, confirm and save'
                  customFontSize='1rem'
                  customFontWeight={400}
                  className={classes.welcomeMsg}
                />
              </Box>
            )
          }
        </>
      )}
      <form onSubmit={handleSubmit(isActivationPath ? noop : onResetPasswordHandler)} className={classes.form}>
        {
          !isActivationPath ? (
            <>
              <div className={classes.fieldContainer}>
                <div className={classes.field}>
                  <TextField
                    fullWidth
                    type='text'
                    placeholder='Recovery Question'
                    value={authInfo.recovery_question.questionText ?? ''}
                    disabled
                    InputProps={inputProps({ classes })}
                    multiline
                  />
                </div>
              </div>
              <div className={classes.fieldContainer}>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: errors.recoveryAnswer
                  })}
                >
                  <Controller
                    name='recoveryAnswer'
                    render={({ field: { onChange } }) => (
                      <TextField
                        fullWidth
                        type='text'
                        placeholder='Answer'
                        onChange={onChange}
                        error={Boolean(errors.recoveryAnswer)}
                        InputProps={inputProps({ classes })}
                        autoComplete='no'
                      />)}
                    control={control}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </>
          ) : null
        }
        <div className={classes.fieldContainer}>
          <div
            className={clsx(classes.field, {
              [classes.fieldError]: errors.password
            })}
          >
            <Controller
              name='password'
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  type={showPasswords.password ? 'text' : 'password'}
                  placeholder='Password'
                  onChange={onChange}
                  error={Boolean(errors.password)}
                  InputProps={getInputProps('password')}
                  autoComplete='new-password'
                />)}
              control={control}
              rules={{
                validate: {
                  password: (password) => {
                    const isInvalid = validatePassword(password, authInfo.email)

                    if (isInvalid && errors.confirmPassword) {
                      clearErrors('confirmPassword')
                    }

                    if (isInvalid) return ''
                  },
                  confirmPassword: password => validateConfirmPassword(password, confirmPassRef.current, 'password', clearErrors)
                }
              }}
            />
          </div>
        </div>
        <div className={classes.fieldContainer}>
          <div
            className={clsx(classes.field, {
              [classes.fieldError]: errors.confirmPassword
            })}
          >
            <Controller
              name='confirmPassword'
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  type={showPasswords.confirmPassword ? 'text' : 'password'}
                  onChange={onChange}
                  placeholder='Confirm Password'
                  error={Boolean(errors.confirmPassword)}
                  InputProps={getInputProps('confirmPassword')}
                  autoComplete='new-password'
                />)}
              control={control}
              rules={{
                validate: {
                  confirmPassword: password => validateConfirmPassword(passwordRef.current, password, 'confirmPassword', clearErrors)
                },
                required: 'Confirm Password is required'
              }}
            />
          </div>
          {(errors.confirmPassword || errors.password) && (
            <Text
              text={errors?.confirmPassword?.message ?? errors?.password?.message}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </div>
        <PasswordRequirements errors={passErrors} />
        {
          isActivationPath && (
            <div className={classes.fieldContainer}>
              <div>
                <Controller
                  control={control}
                  name='acceptedTC'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      classes={{
                        root: classes.checkbox
                      }}
                    />
                  )}
                />
                <span className={classes.termsAndPolicyLabel}>By setting up this account, you are agreeing to Summit Wealth's</span>
                <div className={classes.termsAndPolicyLabel}>
                  <div>
                    <span>
                      <a className={classes.termsAndPolicyLink} href='/legal/terms-of-use' target='_blank' rel='noopener noreferrer'>Terms of Use</a> and&nbsp;
                      <a className={classes.termsAndPolicyLink} href='/legal/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {error && <Typography align='center' color='error'>{error}</Typography>}
        <RoundedButton
          primary
          fullWidth
          type='submit'
          size={BUTTON_SIZES.large}
          className={classes.button}
          disabled={!isDirty || !isValid || isSubmitting}
          isLoading={loading}
        >
          Save
        </RoundedButton>
        <Typography
          component='h3'
          className={classes.backToLoginLabel}
          onClick={() => history.push('/login')}
        >
          Back to Sign in
        </Typography>
      </form>
    </>
  )
}

SetPasswordForm.propTypes = {
  formMethods: PropTypes.object,
  authInfo: PropTypes.object,
  logoPath: PropTypes.string
}

export default SetPasswordForm
