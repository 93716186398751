import React from 'react'
import PropTypes from 'prop-types'
import { useLatestClientStatusReport } from '../../../api/groups'
import ItemStatusEditor from './ItemStatusEditor'
import { useFindItemInTree, useStatusInfo } from './hooks'
import StatusReportItemTitle from './StatusReportItemTitle'

function StatusReportCommentTitle ({ item, parent, statuses, clientId }) {
  const { data, isFetching } = useLatestClientStatusReport(clientId)
  const theItem = useFindItemInTree(data?.report?.items ?? [], item.statusReportItemId)
  const statusInfo = useStatusInfo(theItem.status, statuses)

  return (
    <>
      <StatusReportItemTitle statusInfo={statusInfo} item={theItem} parent={parent} />
      <ItemStatusEditor
        statusInfo={statusInfo}
        item={theItem}
        statuses={statuses}
        isFetching={isFetching}
      />
    </>
  )
}

StatusReportCommentTitle.propTypes = {
  clientId: PropTypes.number,
  item: PropTypes.shape({
    statusReportItemId: PropTypes.number,
    optionLabel: PropTypes.string
  }),
  parent: PropTypes.any,
  statuses: PropTypes.array
}

export default StatusReportCommentTitle
