import React from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import BasicTable from '../../../../atoms/BasicTable'

function SourceFields ({ fields }) {
  const { formatter } = useFormattingContext()

  if (!fields?.length) {
    return (
      <div>No Fields</div>
    )
  }

  return (
    <div>
      <BasicTable>
        <thead>
          <tr>
            <th>Key</th>
            <th>Name</th>
            <th>Type</th>
            <th>Path</th>
            <th>Length</th>
            <th>Nullable</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((f) => (
            <tr key={`sourceField_${f.integrationSourceFieldId}`}>
              <td>{f.isKey ? 'PK' : ''}</td>
              <td>{f.fieldName}</td>
              <td>{f.fieldType}</td>
              <td>{f.path}</td>
              <td>{f.length ? f.length : ((f.numericScale && f.numericPrecision) ? `(${f.numericScale}, ${f.numericPrecision})` : '')}</td>
              <td>{formatter(f.nullable, 'bool')}</td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </div>
  )
}

SourceFields.propTypes = {
  fields: PropTypes.array
}

export default SourceFields
