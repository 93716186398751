import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../api/coreData'
import { decipherError } from '../../../../../../../utils/decipherError'
import { exportFormatTypes } from '../../../../../firmReporting/ExportControls/exportFormats'
import Switch from '../../../../../../molecules/Switch'
import BasicTable from '../../../../../../atoms/BasicTable'

const useSubmitter = (form, onComplete, report) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: createDenaliReportExportConfiguration } = dangerZone.__useCreateDenaliReportExportConfiguration()
  const onSubmit = useCallback(async (formData) => {
    const formats = Object.entries(formData.formats).map(([key, value]) => ({
      key,
      ...value
    })).filter(k => !!k.value).map(k => k.key)

    if (!formats.length) {
      setError('Must choose at least 1 format')
      return
    }

    const command = {
      reportId: report.reportId,
      formats
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await createDenaliReportExportConfiguration(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, createDenaliReportExportConfiguration, report])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ModifyColumnsForm ({ report, initialValues, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      formats: initialValues
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, report)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>Enabled Formats</h3>
        <BasicTable>
          <tbody>
            {exportFormatTypes.map(eft => (
              <tr key={eft.key}>
                <td>
                  <Controller
                    control={form.control}
                    name={`formats.${eft.key}.value`}
                    render={(f) => (
                      <Switch {...f.field} checked={f.field.value} />
                    )}
                  />
                </td>
                <td>{eft.label}</td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyColumnsForm.propTypes = {
  report: PropTypes.object,
  initialValues: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyColumnsForm
