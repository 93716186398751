import { Box, ButtonGroup } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SydLabel from '../../commonDesign/SydLabel'
import SydButton from '../../commonDesign/Button'
import { ICON_NAMES } from '../../../constants'
import { presetMonthOptions } from './constants'
import { useResearchViewContext } from './ResearchViewProvider'
import { ResearchDatePickerModal } from './DatePickerModal'
import { AssetClassFilter } from './AssetClassFilter'

const FilterItem = ({ id, type }) => {
  const { accountsMap, levelFilters, setLevelFilters } = useResearchViewContext()
  const prefix = useMemo(() => {
    const prefixMap = {
      account: 'Account',
      asset: 'Asset'
    }
    return prefixMap[type] ?? type
  }, [type])

  const suffix = useMemo(() => {
    if (type === 'account') {
      return accountsMap?.[id]?.accountNumber
    }
  }, [accountsMap, id, type])

  const handleFilterRemove = useCallback(() => {
    const _levelFilters = { ...levelFilters }
    if (type === 'account') {
      _levelFilters.accountIds = levelFilters.accountIds.filter((accountId) => accountId !== id)
    } else if (type === 'asset') {
      _levelFilters.assetIds = levelFilters.assetIds.filter((assetId) => assetId !== id)
    }
    setLevelFilters((prev) => ({ ...prev, ..._levelFilters }))
  }, [id, levelFilters, setLevelFilters, type])

  return (
    <SydButton
      icon={ICON_NAMES.close}
      key={`${prefix}-${id}`}
      id={id}
      onClick={() => handleFilterRemove()}
      size='sm'
      variant='primary'
    >
      {`${prefix} ${id}`} {suffix ? `(${suffix})` : ''}
    </SydButton>
  )
}
FilterItem.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string
}

export const Filters = () => {
  const { levelFilters, dateRange, setDateRange, selectedMonth, setSelectedMonth } = useResearchViewContext()

  const accountFilters = [...(levelFilters?.accountIds ?? [])]
  const assetFilters = [...(levelFilters?.assetIds ?? [])]
  const [showDateSelectorModal, setShowDateSelector] = useState(false)

  const onMoveDates = useCallback((val) => {
    setDateRange((prev) => ({
      ...prev,
      startDate: dayjs.utc(dateRange.endDate).subtract(val, 'month').format('YYYY-MM-DD'),
      endDate: dayjs.utc(dateRange.endDate).format('YYYY-MM-DD')
    }))
  }, [setDateRange, dateRange.endDate])

  const onMonthClick = useCallback((val) => {
    setSelectedMonth(val)
    onMoveDates(val)
  }, [onMoveDates, setSelectedMonth])

  return (
    <>
      <Box display='flex' gridGap='12px' justifyContent='space-between' alignItems='center'>
        <Box display='flex' flexDirection='column' grow={1} gridGap='4px'>
          <AssetClassFilter />
          <Box display='flex' grow={1} gridGap='4px'>
            {accountFilters?.map((id) => (
              <FilterItem key={`account-${id}`} id={id} type='account' />
            ))}
            {assetFilters?.map((id) => (
              <FilterItem key={`account-${id}`} id={id} type='asset' />
            ))}
          </Box>
        </Box>
        <Box display='flex' gridGap='8px' alignItems='center'>
          <SydLabel style={{ marginTop: '-4px' }}>Show</SydLabel>
          <ButtonGroup color='primary'>
            {presetMonthOptions?.map((option) => (
              <SydButton
                key={option}
                size='sm'
                id={option.toString()}
                onClick={() => onMonthClick(option)}
                variant={selectedMonth === option ? 'primary' : 'secondary'}
                style={{ paddingLeft: '16px', minWidth: 0 }}
              >
                {option}
              </SydButton>
            ))}
          </ButtonGroup>
          <SydLabel style={{ marginTop: '-4px' }}>months</SydLabel>
        </Box>
        <Box>
          <ButtonGroup color='primary'>
            <SydButton
              size='sm'
              onClick={() => onMoveDates(selectedMonth * -1)}
              variant='secondary'
            > {'<'} prev {selectedMonth} mo
            </SydButton>
            <SydButton
              size='sm'
              variant='primary'
              icon={ICON_NAMES.calendar}
              onClick={() => setShowDateSelector(true)}
            >
              {`${dayjs(dateRange.startDate).format('M/D/YY')} - ${dayjs(dateRange.endDate).format('M/D/YY')}`}
            </SydButton>
            <SydButton
              size='sm'
              onClick={() => onMoveDates(selectedMonth)}
              variant='secondary'
            >next {selectedMonth} mo >
            </SydButton>
          </ButtonGroup>
        </Box>
      </Box>
      <ResearchDatePickerModal isOpen={showDateSelectorModal} onClose={() => setShowDateSelector(false)} />
    </>
  )
}
