import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import EmptySection from '../../../../../atoms/EmptySection'
import SydInput from '../../../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'

const FormField = ({ type, ...rest }) => {
  if (type === 'string') {
    return <SydInput size='sm' {...rest} role='presentation' />
  }
  if (type === 'password') {
    return <SydInput size='sm' type='password' {...rest} autoComplete='new-password' role='presentation' />
  }

  return <SydInput {...rest} />
}

FormField.propTypes = {
  type: PropTypes.oneOf(['string', 'password'])
}

function DomainConnectionForm ({ template, form, processing }) {
  // useEffect(() => {
  //   if (!template) {
  //     return
  //   }
  //   const formValues = template.fields.reduce((p, c) => {
  //     p[c.name] = c.defaultValue || ''
  //     return p
  //   }, {})
  //   form.reset(formValues)
  // }, [form.reset, template])

  if (!template) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EmptySection title='Not Available' description='This connection cannot be created' />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='h3'>{template.description}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={form.control}
          name='code'
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 16, message: 'Maximum 16 characters' }
          }}
          render={(f) => (
            <SydLabel label='Connection Code' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput size='sm' {...f.field} disabled={processing} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={form.control}
          name='displayName'
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 96, message: 'Maximum 96 characters' }
          }}
          render={(f) => (
            <SydLabel label='Name' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput size='sm' {...f.field} disabled={processing} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          name='description'
          render={(f) => (
            <SydLabel label='Description'>
              <textarea {...f.field} disabled={processing} style={{ width: '100%', minHeight: '100px' }} />
            </SydLabel>
          )}
        />
      </Grid>
      {(template.fields || []).map(field => (
        <Grid item xs={12} key={`field_${field.name}`}>
          <Controller
            control={form.control}
            name={`connectionArgs.${field.name}`}
            rules={field.rules}
            render={(f) => (
              <SydLabel label={field.label || field.name} error={hookFormErrorAdapter(form, f.fieldState)}>
                <FormField type={field.type} {...f.field} disabled={processing} />
              </SydLabel>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}

DomainConnectionForm.propTypes = {
  template: PropTypes.shape({
    description: PropTypes.string,
    fields: PropTypes.array
  }),
  form: PropTypes.shape({
    reset: PropTypes.func,
    control: PropTypes.any
  }),
  processing: PropTypes.bool
}

export default DomainConnectionForm
