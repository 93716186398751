import React, { useCallback, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { dangerZone } from '../../../../../../api/coreData'
import Loading from '../../../../../molecules/Loading'
import AdminPage from '../../../../admin/shared/AdminPage'
import ErrorDisplay from '../../../../../molecules/ErrorDisplay'
import Card from '../../../../../molecules/Card'
import FieldDisplay from '../../../../../molecules/FieldDisplay'
import ModifiedBy from '../../../../../molecules/ModifiedBy'
import InlineCardHeader from '../../../../../molecules/Card/InlineCardHeader'
import SydTabs from '../../../../../commonDesign/SydTabs'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import CodeBlock from '../../../../../atoms/CodeBlock'
import SydActionMenu from '../../../../../commonDesign/SydActionMenu'
import DenaliReportQueryInfo from '../DenaliReportQueryInfo'
import DenaliReportExportInfo from '../DenaliReportExportInfo'
import ViewColumnsTab from './ViewColumnsTab'
import ModifyReportAttributesDialog from './ModifyReportAttributesDialog'
import FiltersTab from './FiltersTab'

const useDetailsActions = ({ editRef }) => {
  const handleEdit = useCallback((report) => {
    return editRef.current.open(report)
  }, [editRef])

  return useMemo(() => {
    return [
      { key: 'edit-report', label: 'Edit', icon: 'edit', onClick: handleEdit }
    ]
  }, [handleEdit])
}

const useViewStatusInfo = (data) => {
  return useMemo(() => {
    if (!data) return []

    return [
      { label: 'Latest As-Of Date', value: data.asOfDate, format: 'date' },
      { label: 'Current Status', value: data.status?.status, format: 'title' },
      { label: 'Last Started', value: data.status?.started, format: 'datetime' },
      { label: 'Last Finished', value: data.status?.finished, format: 'datetime' },
      { label: 'Percent Complete', value: data.status?.pct, format: 'percent' }
    ]
  }, [data])
}

const useViewFieldInfo = (data) => {
  return useMemo(() => {
    if (!data) return []

    return [
      { label: 'Name', value: data.report.name },
      { label: 'View ID', value: data.report.reportId },
      { label: 'Category', value: data.report.firmReportCategoryId },
      { label: 'Underlying Table Name', value: data.report.tableName },
      { label: 'Description', value: data.report.description },
      { label: 'Published', value: data.report.published, format: 'bool' },
      {
        label: 'Image URL',
        value: <a href={data.report.imageUrl} rel='noopener noreferrer' target='_blank'>{data.report.imageUrl}</a>
      },
      {
        label: 'Created',
        value: <ModifiedBy author={data.report.createdBy} timestamp={data.report.createdDate} />
      },
      {
        label: 'Modified',
        value: <ModifiedBy author={data.report.modifiedBy} timestamp={data.report.modifiedDate} />
      }
    ]
  }, [data])
}

const tabs = {
  columns: {
    label: 'Columns',
    value: 'columns'
  },
  filters: {
    label: 'Filters',
    value: 'filters'
  },
  raw: {
    label: 'Raw Config',
    value: 'raw'
  }
}

function ViewDetails () {
  const { viewId } = useParams()
  const { data, isLoading, error, refetch } = dangerZone.__useGetDenaliReportConfiguration(viewId)
  const viewFieldInfo = useViewFieldInfo(data)
  const viewStatusInfo = useViewStatusInfo(data)
  const [currentTab, setCurrentTab] = React.useState(null)
  const editRef = useRef(null)
  const detailsActions = useDetailsActions({ editRef })

  if (error) {
    return (
      <AdminPage>
        <ErrorDisplay error={error} onRetry={refetch} />
      </AdminPage>
    )
  }

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Card>
            <InlineCardHeader
              action={<SydActionMenu subject={data?.report} actions={detailsActions} />}
            >
              Report Info
            </InlineCardHeader>
            <FieldDisplay fields={viewFieldInfo} />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <InlineCardHeader>Status</InlineCardHeader>
            <FieldDisplay fields={viewStatusInfo} />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DenaliReportQueryInfo xs={12} md={4} report={data?.report} />
            </Grid>
            <Grid item xs={12}>
              <DenaliReportExportInfo report={data?.report} />
            </Grid>
          </Grid>
        </Grid>
        <SydTabs.Group
          tabOptions={tabs}
          onChange={setCurrentTab}
          defaultValue={currentTab}
          key='denali_view_details_tabs'
        >
          <Grid item xs={12}>
            <SydTabs.Links />
          </Grid>
        </SydTabs.Group>
        {currentTab === 'columns' ? (
          <Grid key='columns' item xs={12}>
            <ViewColumnsTab report={data?.report} />
          </Grid>
        ) : currentTab === 'filters' ? (
          <Grid key='filters' item xs={12}>
            <FiltersTab report={data?.report} filters={data?.filters} />
          </Grid>
        ) : currentTab === 'raw' ? (
          <Grid key='raw' item xs={12}>
            <FadeIn>
              <Card>
                <CodeBlock code={data} />
              </Card>
            </FadeIn>
          </Grid>
        ) : null}
      </Grid>
      <ModifyReportAttributesDialog ref={editRef} />
    </AdminPage>
  )
}

ViewDetails.propTypes = {
}

export default ViewDetails
