import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider, makeStyles, IconButton } from '@material-ui/core'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import SydButton from '../../commonDesign/Button'
import SydModalActions from '../../commonDesign/SydModal/SydModalActions'
import { useModifyStatusReportItemsMutation } from '../../../api/groups'
import Switch from '../../molecules/Switch'
import FlexRow from '../../molecules/FlexRow'
import SydInput from '../../commonDesign/SydInput'
import Icon from '../../atoms/Icon'
import SydLabel, { hookFormErrorAdapter } from '../../commonDesign/SydLabel'
import StatusReportItemTitle from './StatusReportItemTitle'
import { useFindItemInTree, useFindParentItem, useStatusInfo } from './hooks'
import { useInternalReportContext } from './InternalStatusReportContext'

const newCustomOption = (item) => ({
  statusReportId: item.statusReportId,
  displayName: '',
  displayNameEx: '',
  codeName: '__new',
  isCustom: true,
  hidden: false
})

const createCodeNameGenerator = (parent, items = []) => {
  const codeNameList = items.filter(x => x.codeName !== '__new').map(x => x.codeName)

  return (item) => {
    if (item.codeName !== '__new') {
      return item.codeName
    }

    let foundAvailable = false
    let postFix = 1
    while (!foundAvailable && postFix < 1000) {
      const candidateCodeName = `${parent.codeName.slice(0, 48)}__c${postFix}`
      foundAvailable = !codeNameList.some(x => x === candidateCodeName)

      if (foundAvailable) {
        codeNameList.push(candidateCodeName)
        return candidateCodeName
      } else {
        postFix++
      }
    }

    console.warn('unable to finx a suitable code name')
    return null
  }
}

const useSubmitter = (parent, form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyItem } = useModifyStatusReportItemsMutation()
  const onSubmit = useCallback(async (formData) => {
    const items = formData.items.map((opt, idx) => ({
      ...opt,
      ordinal: idx + 1,
      hidden: opt.hidden || false,
      displayName: opt.displayName || null,
      displayNameEx: opt.displayNameEx || null,
      codeName: opt.codeName
    }))
    const codeNameGenerator = createCodeNameGenerator(parent, items)
    items.forEach(item => {
      item.codeName = codeNameGenerator(item)
    })
    const command = {
      item: {
        statusReportItemId: formData.statusReportItemId,
        statusReportId: formData.statusReportId,
        items
      }
    }

    try {
      setProcessing(true)
      const result = await modifyItem(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyItem, setProcessing, onComplete, parent])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: '100%',
    fontFamily: theme.typography.fontFamily
  },
  scrollContainer: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.gray.dark,
    borderRadius: theme.layout.radius.loose,
    maxHeight: '50vh',
    overflowY: 'auto'
  },
  optionItem: {
    padding: theme.layout.padding.p20,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.gray.main
    },
    '&: .__value': {
      width: '100%'
    }
  },
  selectThing: {
    '& .__placeholder': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.weights.bold
    },
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.gray.main,
      transition: 'background-color .2s ease-in-out',
      '&:hover': {
        backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`
      }
    }
  }
}))

function ManageOptionsForm ({ item, report, onCancel, onComplete }) {
  const classes = useStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      statusReportId: item.statusReportId,
      statusReportItemId: item.statusReportItemId,
      statusTemplateItemId: item.statusTemplateItemId,
      items: item.items || []
    }
  })

  const { submitter, processing } = useSubmitter(item, form, onComplete)
  // eslint-disable-next-line no-unused-vars
  const { fields, remove, append, swap } = useFieldArray({
    control: form.control,
    name: 'items'
  })

  const { statuses } = useInternalReportContext()
  const statusInfo = useStatusInfo(item.status, statuses)
  const parentId = useFindParentItem(report.items, item.statusReportItemId)
  const parent = useFindItemInTree(report.items, parentId)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatusReportItemTitle
          statusInfo={statusInfo}
          parent={parent}
          item={item}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.scrollContainer}>
          {(fields.map((field, idx) => (
            <FlexRow className={classes.optionItem} key={field.id}>
              <div>
                <Controller
                  name={`items.${idx}.hidden`}
                  control={form.control}
                  render={(f) => (
                    <Switch {...f.field} checked={!f.field.value} onChange={e => f.field.onChange(!e.target.checked)} />
                  )}
                />
              </div>
              <div>
                <Controller
                  name={`items.${idx}.displayName`}
                  control={form.control}
                  rules={{
                    required: { value: true, message: 'Required' },
                    maxLength: { value: 128, message: 'Too long' }
                  }}
                  render={(f) => (
                    <SydLabel label='' description='' error={hookFormErrorAdapter(form, f.fieldState)}>
                      <SydInput
                        className={classes.textArea}
                        size='sm'
                        disabled={!field.isCustom}
                        placeholder={field.isCustom ? 'Item Name' : undefined}
                        {...f.field}
                      />
                    </SydLabel>
                  )}
                />
              </div>
              <div>
                <Controller
                  name={`items.${idx}.displayNameEx`}
                  control={form.control}
                  render={(f) => (
                    <SydInput
                      size='sm'
                      className={classes.textArea}
                      placeholder='Name Extension'
                      disabled={!field.isCustom}
                      {...f.field}
                    />
                  )}
                />
              </div>
              <IconButton disabled={idx === 0} onClick={() => swap(idx, idx - 1)}>
                <Icon name='up' size='20px' />
              </IconButton>
              <IconButton disabled={idx === (fields.length - 1)} onClick={() => swap(idx, idx + 1)}>
                <Icon name='down' size='20px' />
              </IconButton>
              {field.isCustom ? (
                <IconButton onClick={() => remove(idx)}>
                  <Icon name='close' size='20px' />
                </IconButton>
              ) : null}
            </FlexRow>
          )))}
          {item.allowCustomChildren ? (
            <div className={classes.optionItem}>
              <SydButton
                variant='outline' size='sm' icon='add'
                onClick={() => append(newCustomOption(item, fields.length))}
              >Custom Item
              </SydButton>
            </div>
          ) : null}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='lg'
            disabled={!form.formState.isValid}
            onClick={submitter}
            processing={processing}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ManageOptionsForm.propTypes = {
  item: PropTypes.object,
  report: PropTypes.shape({
    statusTemplateId: PropTypes.number,
    items: PropTypes.array
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ManageOptionsForm
