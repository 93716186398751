import React from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import { dataCatalog } from '../../../../../api/coreData'
import EmptySection from '../../../../atoms/EmptySection'
import Card from '../../../../molecules/Card'
import InlineCardHeader from '../../../../molecules/Card/InlineCardHeader'
import RecentLogs from '../shared/RecentLogs'
import DataSourceAttributes from './DatasourceAttributes'
import DatapointsTable from './DatapointsTable'

const targetType = 'catalog/datasource'

function DataSourceDetails () {
  const { datasourceId } = useParams()
  const { data, isLoading } = dataCatalog.useGetCatalogDataSource(datasourceId)

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!data?.datasource) {
    return (
      <AdminPage>
        <EmptySection title='Not Available' description='' />
      </AdminPage>
    )
  }

  return (
    <AdminPage key='data-source-details-main'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card>
            <InlineCardHeader>Details</InlineCardHeader>
            <DataSourceAttributes datasource={data.datasource} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <InlineCardHeader>Datapoints</InlineCardHeader>
            <DatapointsTable datapoints={data.datasource.datapoints} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <InlineCardHeader>Recent Events</InlineCardHeader>
            <RecentLogs targetType={targetType} targetIdentifier={datasourceId} />
          </Card>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

DataSourceDetails.propTypes = {
}

export default DataSourceDetails
