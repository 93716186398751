import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useListEnablementCodes } from '../../../api/abundanceEngine'
import Loading from '../../molecules/Loading'
import SydSelect from '../../commonDesign/SydSelect'

function EnablementCodeSelect ({ value, onChange }) {
  const { data, isLoading, error } = useListEnablementCodes()
  const options = useMemo(() => {
    const result = (data?.enablementCodes || []).map(ec => ({
      label: ec.enablementCode,
      value: ec.enablementCodeId
    }))

    if (result.length) {
      result.push({ label: '<Not Assigned>', value: null })
    }

    return result
  }, [data])

  if (error) {
    return (
      <div>
        There was a problem loading the list
      </div>
    )
  }

  if (isLoading) {
    return (
      <div>
        <Loading size={10} />
      </div>
    )
  }

  if (!options.length) {
    return (
      <div>
        No enablement codes available
      </div>
    )
  }

  return (
    <div>
      <SydSelect
        placeholder='Select an enablement code'
        options={options}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

EnablementCodeSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default EnablementCodeSelect
