import { isArray, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useGroupTypeSearch } from '../../../../../api/groups'

export const mapGroupTypes = ({ data: groupTypes }, groupsFilterFunc = () => true) => {
  return groupTypes
    .map(({ groupTypeId, longName: groupTypeLongName, groups }) => ({
      label: groupTypeLongName,
      value: groupTypeId,
      groups: groups.filter(groupsFilterFunc).map(({ groupId, longName }) => ({
        label: longName,
        value: groupId,
        payload: { groupTypeId, groupName: groupTypeLongName }
      }))
    }))
}

export const getGroupTypesQuery = (query = {}, queryOptions = {}) => {
  return {
    query: query == null ? null : {
      skip: 0,
      take: 100,
      ...query,
      includes: {
        groups: true,
        groupMembers: true,
        groupMemberTypes: true,
        ...(query?.includes ?? {})
      },
      filters: {
        ...(query?.filters ?? {})
      }
    },
    queryOptions: {
      mapper: mapGroupTypes,
      ...queryOptions
    }
  }
}

export const DYNAMIC_FILTER_PREFIX = 'dynamic_filter'

export const getFilterColumnId = (id = '') => {
  return `${DYNAMIC_FILTER_PREFIX}_${id}`
}

export const mapGroupTypeFilterId = (groupTypeId = '') => {
  return getFilterColumnId(`groupType_${groupTypeId}`)
}

export const UNASSIGNED_OPTION = {
  label: 'Unassigned',
  value: 'unassigned'
}

const getFilterColumnAccessor = (groupTypeValue) => {
  return ({ groups }) => {
    if (!groups) return UNASSIGNED_OPTION.label
    if (isArray(groups)) {
      return (
        groups
          ?.filter(({ groupTypeId }) => groupTypeId === groupTypeValue)
          .map(({ name }) => name)
          .join(',') || UNASSIGNED_OPTION.label
      )
    }
    const groupValues = Object.values(groups).flat()
    const group = groupValues.find(
      ({ groupTypeId }) => groupTypeId === groupTypeValue
    )
    return group?.shortName || UNASSIGNED_OPTION.label
  }
}

const useGroupTypeColumns = ({
  query: _query,
  queryOptions: _queryOptions,
  tagFilters,
  visibleFilters,
  defaultColumnConfig
}) => {
  const { query, queryOptions } = useMemo(() => {
    return getGroupTypesQuery(_query, _queryOptions)
  }, [_query, _queryOptions])

  const {
    data: groupTypes,
    isLoading: isLoadingGroupTypes
  } = useGroupTypeSearch(query, queryOptions)

  const columnConfig = useMemo(() => {
    /**
     * create and append a column config per available group type
     */
    const groupTypeColumns = (groupTypes || []).map(
      ({ groups, value: groupTypeValue, label: groupTypeLabel }) => {
        const columnId = mapGroupTypeFilterId(groupTypeValue)

        const hidden = !isEmpty(tagFilters)
          ? !groups.find(({ value }) => tagFilters.includes(value))
          : true

        return {
          id: columnId,
          Header: groupTypeLabel,
          accessor: getFilterColumnAccessor(groupTypeValue),
          hidden: hidden && !visibleFilters.includes(columnId),
          disableSortBy: true
        }
      }
    )
    const columns = defaultColumnConfig.columns.map((col) => {
      return {
        ...col,
        hidden: col.id.startsWith(DYNAMIC_FILTER_PREFIX)
          ? !visibleFilters.includes(col.id)
          : col.hidden
      }
    })

    return {
      ...defaultColumnConfig,
      columns: [...columns, ...groupTypeColumns]
    }
  }, [groupTypes, defaultColumnConfig, tagFilters, visibleFilters])

  return {
    columnConfig,
    groupTypes,
    isLoadingGroupTypes
  }
}

export default useGroupTypeColumns
