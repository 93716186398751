import React from 'react'
import SchwabTable from './Table'

// eslint-disable-next-line react/prop-types
const SchwabRealtime = ({ accountNumber, useRealtime }) => {
  return (
    <SchwabTable accountNumber={accountNumber} useRealtime={useRealtime ?? true} />
  )
}

export default SchwabRealtime
