import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchIntegrationJobs } from '../../../../../api/integrations'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import MiniTable from '../../../admin/shared/MiniTable'

const sourceColumns = [
  { id: 'id', accessor: 'jobConfigurationId', header: 'ID', minWidth: 20 },
  { id: 'jobKey', accessor: 'jobKey', header: 'Job Key', minWidth: 20, link: (job) => `/admin/denali/integrator/jobs/${job.jobConfigurationId}` },
  { id: 'description', accessor: 'description', header: 'Description', minWidth: 20 },
  { id: 'isEnabled', accessor: 'isEnabled', header: 'Enabled?', format: 'bool', minWidth: 20 }
]

const keyAccessor = (x) => x?.jobKey

function SourceJobs ({ integrationSourceId }) {
  const query = useMemo(() => {
    return {
      filters: {
        integrationSourceId
      }
    }
  }, [integrationSourceId])
  const { data, isLoading } = useSearchIntegrationJobs(query)

  if (isLoading) {
    return <Loading />
  }

  if (!data?.data?.length) {
    return <EmptySection description='No Results' title='' />
  }

  return (
    <MiniTable
      data={data?.data || []}
      columns={sourceColumns}
      keyAccessor={keyAccessor}
    />
  )
}

SourceJobs.propTypes = {
  integrationSourceId: PropTypes.number
}

export default SourceJobs
