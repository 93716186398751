import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import EnableJobForm from './form'

const EnableJobDialog = React.forwardRef(function EnableJobDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (job) => setDialogState({ open: true, processing: false, job, direction: job?.isEnabled ? 'disable' : 'enable' })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`${dialogState.direction === 'disable' ? 'Disable' : 'Enable'} Integration Job`}
      open={dialogState.open}
      onClose={close}
      size='medium'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<EnableJobForm job={dialogState.job} direction={dialogState.direction} onComplete={handleComplete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

EnableJobDialog.propTypes = {
  onComplete: PropTypes.func
}

EnableJobDialog.defaultProps = {
  onComplete: () => {}
}

export default EnableJobDialog
