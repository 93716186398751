import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SnackAlert from '../../molecules/SnackAlert/SnackAlert'

const DenaliContext = createContext({
  setAlert: () => {}
})

export const useDenaliContext = () => {
  return useContext(DenaliContext)
}

function DenaliProvider ({ children }) {
  const [alert, setAlert] = useState(null)
  const contextValue = useMemo(() => {
    return {
      setAlert
    }
  }, [setAlert])

  return (
    <DenaliContext.Provider value={contextValue}>
      {children}
      <SnackAlert alert={alert} />
    </DenaliContext.Provider>
  )
}

DenaliProvider.propTypes = {
  children: PropTypes.node
}

export default DenaliProvider
