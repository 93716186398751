import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Box, Grid } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useListInfographicTemplates } from '../hooks/useListInfographicTemplates'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydButton from '../../../../commonDesign/Button'
import SydSelect from '../../../../commonDesign/SydSelect'
import SydDatePicker from '../../../../commonDesign/SydDatePicker'
import { disableHolidaysAndWeekends } from '../../../../../utils'
import { useHolidays } from '../../../../../api/coreData'
import { useAsOfDate } from '../../Reports/hooks'
import { useCreateInfographicReportRunMutation } from '../hooks/useCreateInfographicReportRunMutation'
import Checkbox from '../../../../molecules/Checkbox'
import SydInput from '../../../../commonDesign/SydInput'
import { AutocompleteClientSelect } from './AutocompleteClientSelect'

dayjs.extend(utc)

export const CreateInfographicReportForm = ({
  onSubmit: _onSubmit
}) => {
  const defaultMaxDate = dayjs().subtract(1, 'day')

  const form = useForm({
    defaultValues: {
      templateId: '',
      name: '',
      clientId: null,
      asOfDate: defaultMaxDate,
      returnTypes: []
    }
  })

  const clientId = form.watch('clientId')

  const { data: templates, isLoading: templatesLoading } = useListInfographicTemplates({
    filters: {
      levelTypes: [{ levelTypeId: 201, levelId: clientId }]
    }
  }, { enabled: !!clientId })

  const { data: holidays, isLoading: loadingHolidays } = useHolidays()
  const { data: availableDates, isLoading: loadingAvailableDates } = useAsOfDate(clientId)
  const shouldDisableDate = useCallback((d) => {
    return disableHolidaysAndWeekends(d, holidays)
  }, [holidays])

  useEffect(() => {
    if (clientId && availableDates?.max) {
      form.setValue('asOfDate', dayjs.utc(availableDates?.max).format('YYYY-MM-DD'))
    }
  }, [availableDates?.max, clientId, form])

  useEffect(() => {
    if (templates?.length && !templates.find(({ infographicTemplateId }) => infographicTemplateId === form.getValues('templateId'))) {
      form.setValue('templateId', '')
    }
  }, [templates, form])

  const saveMutation = useCreateInfographicReportRunMutation()
  const onSubmit = async (data) => {
    await saveMutation.mutateAsync({
      inputParams: {
        startDate: data.asOfDate,
        endDate: data.asOfDate,
        name: null,
        clientId: data.clientId
      },
      returnTypes: data.returnTypes,
      infographicTemplateId: data.templateId
    })
    _onSubmit?.()
  }

  return (
    <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            control={form.control}
            name='clientId'
            rules={{ required: { value: true, message: 'Client required' } }}
            render={({ field, fieldState }) => (
              <SydLabel label='Client' error={fieldState?.error?.message}>
                <AutocompleteClientSelect field={field} />
              </SydLabel>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            control={form.control}
            name='templateId'
            rules={{ required: { value: true, message: 'Template required' } }}
            render={({ field, fieldState }) => (
              <SydLabel label='Template' error={fieldState?.error?.message}>
                {!clientId || templatesLoading ? (
                  <SydInput
                    size='sm'
                    disabled
                    placeholder={templatesLoading ? 'Loading templates...' : 'Select a client'}
                  />
                ) : (
                  <SydSelect
                    options={(templates ?? []).map(({ infographicTemplateId, name }) => ({ value: infographicTemplateId, label: name }))}
                    loading={templatesLoading}
                    size='sm'
                    {...field}
                    minWidth='200px'
                  />
                )}
              </SydLabel>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            control={form.control}
            name='asOfDate'
            rules={{ required: { value: true, message: 'As of date required' } }}
            render={({ field, fieldState }) => (
              <SydLabel label='As Of Date' error={fieldState?.error?.message}>
                {loadingAvailableDates || loadingHolidays || !clientId ? (
                  <SydInput
                    size='sm'
                    disabled
                    placeholder={clientId ? 'Loading dates...' : 'Select a client'}
                  />
                ) : (
                  <SydDatePicker
                    {...field}
                    size='sm'
                    disabled={loadingAvailableDates || loadingHolidays || !clientId}
                    shouldDisableDate={(d) => shouldDisableDate(d)}
                    maxDate={availableDates?.max ? dayjs(availableDates?.max).add(1, 'day') : defaultMaxDate}
                    minDate={availableDates?.min}
                  />
                )}
              </SydLabel>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            control={form.control}
            name='returnTypes'
            rules={{ required: { value: true, message: 'Must select at least 1 return type' } }}
            render={({ field, fieldState }) => (
              <SydLabel label='Return Types' error={fieldState?.error?.message}>
                <Box display='flex' gridGap='8px' alignItems='center'>
                  <div>
                    <Checkbox
                      value='pdf'
                      isChecked={field.value?.includes('pdf')}
                      {...field}
                      onChange={(e, isChecked) => field.onChange(isChecked ? [...field.value, 'pdf'] : field.value.filter(v => v !== 'pdf'))}
                    />
                    PDF
                  </div>
                  <div>
                    <Checkbox
                      value='html'
                      isChecked={field.value?.includes('html')}
                      {...field}
                      onChange={(e, isChecked) => field.onChange(isChecked ? [...field.value, 'html'] : [...field.value].filter(v => v !== 'html'))}
                    />
                    HTML
                  </div>
                </Box>
              </SydLabel>
            )}
          />
        </Grid>
      </Grid>

      <Box display='flex' justifyContent='flex-end' gridGap={8} mt={2}>
        <SydButton
          type='button'
          onClick={() => form.reset()}
          disabled={saveMutation.isLoading}
        >
          Clear
        </SydButton>
        <SydButton
          type='submit'
          variant='primary'
          disabled={saveMutation.isLoading}
          processing={saveMutation.isLoading}
        >
          {saveMutation.isLoading ? 'Loading...' : 'Create Infographic Report Run'}
        </SydButton>
      </Box>
    </form>
  )
}

CreateInfographicReportForm.propTypes = {
  onSubmit: PropTypes.func
}
