import { useCallback } from 'react'
import { postNamedCommand } from '../../../../../service'

export const useSchwabAuthenticate = () => {
  const handleAuthenticate = useCallback(async () => {
    const result = await postNamedCommand('integrator', 'authenticate-schwab', {
      authorizationKey: 'firm'
    })

    if (result.response.status === 200) {
      window.location.href = result.data.redirectLocation
    }
  }, [])

  return {
    handleAuthenticate
  }
}

export const useSchwabExchange = (code, state) => {
  const handleExchange = useCallback(async () => {
    const result = await postNamedCommand('integrator', 'exchange-code-schwab', {
      code,
      state
    })

    return result
  }, [code, state])

  return { handleExchange }
}
