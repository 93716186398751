import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import SydButton from '../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../api/coreData'
import { decipherError } from '../../../../../../../utils/decipherError'

const useSubmitter = (onComplete, report) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: removeDenaliReportExportConfiguration } = dangerZone.__useRemoveDenaliReportExportConfiguration()
  const onSubmit = useCallback(async () => {
    const command = {
      reportId: report.reportId
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await removeDenaliReportExportConfiguration(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify report columns')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, removeDenaliReportExportConfiguration, report])

  return {
    submitter: onSubmit,
    processing,
    error
  }
}

function DeleteReportExportForm ({ report, onCancel, onComplete }) {
  const { submitter, processing, error } = useSubmitter(onComplete, report)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Deleting this export configuration will prevent this Denali View from exporting</h2>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            priority='warning'
            icon='delete'
            size='md'
            onClick={submitter}
          >
            Delete
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

DeleteReportExportForm.propTypes = {
  report: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default DeleteReportExportForm
