import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import { exportFormatTypes } from '../../../../../firmReporting/ExportControls/exportFormats'
import ModifyReportExportForm from './form'

const ModifyReportExportDialog = React.forwardRef(function ModifyReportAttributesDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (report) => {
      const formats = report?.configuration?.export?.formats || []
      const initialValues = exportFormatTypes.reduce((p, c) => {
        p[c.key] = {
          label: c.label,
          value: formats.includes(c.value)
        }
        return p
      }, {})
      setDialogState({ open: true, processing: false, report, initialValues })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Denali View Export Configuration'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <ModifyReportExportForm
            report={dialogState.report}
            initialValues={dialogState.initialValues}
            onComplete={handleComplete}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

ModifyReportExportDialog.propTypes = {
  onComplete: PropTypes.func
}

ModifyReportExportDialog.defaultProps = {
  onComplete: () => {}
}

export default ModifyReportExportDialog
