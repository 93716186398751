import React, { useCallback } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { LoginCallback, SecureRoute } from '@okta/okta-react'
import Signout from './components/pages/publicViews/Signout'
import SummitApp from './SummitApp'
import ActivationRedirect from './components/pages/publicViews/ActivationRedirect'
import AuthError from './AuthError'
import BrandProvider from './providers/BrandProvider'

function LoginRouter () {
  const history = useHistory()
  const errorComponent = useCallback(({ error }) => {
    console.error('login callback Error:', error)
    history.push('/login/auth-error', { state: { error: error.message } })
    return null
  }, [history])

  return (
    <Switch>
      <Route path='/login/auth-error' exact>
        <BrandProvider>
          <AuthError />
        </BrandProvider>
      </Route>
      <Route path='/login/callback' exact>
        <div>
          <LoginCallback errorComponent={errorComponent} />
        </div>
      </Route>
      <Route path='/activation'>
        <ActivationRedirect />
      </Route>
      <Route path='/reset-user'>
        <ActivationRedirect />
      </Route>
      <Route path='/logout' exact>
        <BrandProvider>
          <Signout />
        </BrandProvider>
      </Route>
      <SecureRoute path='/'>
        <BrandProvider>
          <SummitApp />
        </BrandProvider>
      </SecureRoute>
    </Switch>
  )
}

export default LoginRouter
