import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Card from '../../../molecules/Card'
import AdminPage from '../../admin/shared/AdminPage'

function CatalogHome ({ root }) {
  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Link to={`${root}/catalog/sources`}>
            <Card>
              Data Sources
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Link to={`${root}/catalog/events`}>
            <Card>
              Event Logs
            </Card>
          </Link>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

CatalogHome.propTypes = {
  root: PropTypes.any
}

export default CatalogHome
