import React, { useEffect } from 'react'
import AceEditor from 'react-ace'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ErrorBoundary from '../../../../../../molecules/ErrorBoundary'
import { JsonWithPathsMode } from '../templatedJson'
import { usePipelineEditor } from '../PipelineEditorContext'
import EditorHeader from './EditorHeader'
import Variables from './Variables'

const useStyles = makeStyles((theme) => ({
  editorPanel: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '40px 1fr',
    gap: '0px 0px',
    gridTemplateAreas: '"editor-header editor-header" "editor-body editor-variables"',
    '& .__editor-header': {
      gridArea: 'editor-header'
    },
    '& .__editor-body': {
      gridArea: 'editor-body'
    },
    '& .__editor-variables': {
      gridArea: 'editor-variables'
    },

    backgroundColor: theme.palette.gray.main
  }
}))

function Index ({ defaultValue }) {
  const classes = useStyles()
  const { editorRef, setVariables, variables } = usePipelineEditor()

  useEffect(() => {
    if (editorRef.current) {
      const session = editorRef.current.editor.getSession()
      session.setMode(new JsonWithPathsMode())
    }
  }, [editorRef])

  return (
    <ErrorBoundary name='Code Editor'>
      <div className={classes.editorPanel}>
        <div className='__editor-header'>
          <EditorHeader />
        </div>
        <div className='__editor-body'>
          <AceEditor
            ref={editorRef}
            height='100%'
            width='100%'
            mode='json'
            theme='xcode'
            name='json-editor'
            editorRef={{ $blockScroll: true }}
            defaultValue={defaultValue}
            tabSize={2}
          />
        </div>
        <div className='__editor-variables'>
          <Variables onChange={setVariables} defaultVariables={variables} />
        </div>
      </div>
    </ErrorBoundary>
  )
}

Index.propTypes = {
  defaultValue: PropTypes.string
}

Index.defaultProps = {
  defaultValue: '{}'
}

export default Index
