import React, { useCallback } from 'react'
import {
  makeStyles,
  Button,
  Container,
  Box
} from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import CSVButton from '../../../atoms/CSVButton'
import ExportCsvButton from '../../../molecules/ExportCsvButton'
import ViewTabs from '../../../organisms/LinkViewTabs'
import { ADMIN_ROUTES, ICON_NAMES } from '../../../../constants'
import { ADVISOR } from '../../../../policies/admin'
import { useCheckPolicy, usePermissions, useAuthState } from '../../../../hooks'
import Icon from '../../../atoms/Icon'
import SydLinkButton from '../../../commonDesign/LinkButton'
import { USERS_PATH } from './helpers'
import { useUsersCSVData, useSearchAdvisors, useTabsOptions, useAdvisorsColumnConfig } from './hooks'

const useStyles = makeStyles((theme) => ({
  headspace: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  container: {
    padding: '1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  pillBtn: {
    borderRadius: 25,
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillSecondary: {
    backgroundColor: theme.palette.summitBlue,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.summitBlue
    },
    '&:disabled': {
      backgroundColor: theme.palette.romanSilver,
      color: theme.palette.white
    }
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800
  },
  link: {
    textDecoration: 'none !important'
  },
  withAvatar: {
    display: 'flex',
    alignItems: 'baseline'
  },
  avatar: {
    padding: '5px',
    maxWidth: '40px',
    zIndex: -1
  }
}))

function AdvisorView () {
  const history = useHistory()
  const classes = useStyles()
  const defaultColumnConfig = useAdvisorsColumnConfig({ classes })
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable(defaultColumnConfig.defaultSort)
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})
  const linkTabOptions = useTabsOptions()
  const canEditAdvisor = useCheckPolicy(ADVISOR.editAdvisor)

  const onChangeTab = useCallback(() => {
    onSearchTextChange('')
  }, [onSearchTextChange])

  const onClickRow = useCallback((row) => {
    if (row.original) {
      history.push(`${USERS_PATH}/${row.original.externalUserId}`)
    }
  }, [history])

  const { data = [], loading, count = 0 } = useSearchAdvisors({
    pageIndex,
    pageSize,
    sort,
    searchText,
    tableMode,
    isSummitUser
  })

  const onAddAdvisor = useCallback(() => {
    history.push(ADMIN_ROUTES.ADVISORS_NEW)
  }, [history])

  const csvData = useUsersCSVData({ data, columns: defaultColumnConfig.columns })

  return (
    <Container maxWidth={false} className={classes.container}>
      <ViewTabs.Group tabOptions={linkTabOptions} onChange={onChangeTab}>
        {canEditAdvisor && (
          <ViewTabs.Links
            tabsKey='advisors'
            rightAdornment={(
              <Button
                className={clsx(classes.pillBtn, classes.pillSecondary)}
                variant='outlined'
                onClick={onAddAdvisor}
                startIcon={<Icon customSize='1rem' name={ICON_NAMES.add} />}
              >
                Add Advisor
              </Button>)}
          />
        )}
      </ViewTabs.Group>
      <Box className={classes.tabsContainer}>
        <OperationalTable.Wrapper>
          <OperationalTable.SuperHeader className={classes.headspace}>
            <OperationalTable.Search
              onChange={onSearchTextChange}
              placeholder='Search by advisor name'
              delay={250}
            />
            <Box display='flex' gridGap='8px' alignItems='center'>
              <CSVButton data={csvData} fileName='advisors_list_export'>
                <ExportCsvButton />
              </CSVButton>
              <SydLinkButton
                size='sm'
                href={`${ADMIN_ROUTES.ADVISORS}/deactivated`}
              >
                View deactivated advisors
              </SydLinkButton>
            </Box>
          </OperationalTable.SuperHeader>
          <OperationalTable
            mode='server'
            columns={defaultColumnConfig.columns}
            data={data}
            defaultPageSize={defaultPageSize}
            defaultSort={defaultColumnConfig.defaultSort}
            itemName='Advisors'
            loading={loading}
            total={count}
            searchText={searchText}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
            onRowClick={onClickRow}
          />
        </OperationalTable.Wrapper>
      </Box>
    </Container>
  )
}

export default AdvisorView
