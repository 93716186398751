export function makePathRoot () {
  return ''
}

/**
 * @param {string} root
 * @param {string} segment
 * @returns {string}
 */
export function makePath (root, segment) {
  return `${root}/${segment}`
}

/**
 * @param {string} root
 * @param {number} index
 * @returns {string}
 */
export function makePathIndex (root, index) {
  return `${root}/${index}`
}
