import { useMemo } from 'react'

export const useGetSelectedPageContent = (currentPageIndex, templateContent) => {
  return useMemo(() => {
    if (!templateContent?.pages) return []
    const pageContent = templateContent?.pages?.[currentPageIndex] ?? {}
    const blocks = pageContent?.blocks?.filter(x => !x.isEmptyBlock) ?? []

    // find gaps where there are no blocks (rows with no items)
    for (let i = 0; i < 16; i++) {
      const blocksInRange = blocks.filter(block => {
        const blockCords = block.cords ?? {}
        const blockSize = block.size ?? { rows: 1, cols: 12 }
        return blockCords.y <= i && ((blockSize.rows + blockCords.y - 1) >= i)
      })
      if (!blocksInRange?.filter(b => b.size.cols >= 12)?.length) {
        // find empty one that is touching
        const existingEmptyBlockIndex = blocks.findIndex(block => {
          const blockCords = block.cords ?? {}
          const blockSize = block.size ?? { rows: 1, cols: 12 }
          return block.isEmptyBlock &&
            blockCords.y <= i && ((blockSize.rows + blockCords.y - 1) === i)
        })

        if (existingEmptyBlockIndex >= 0) {
          blocks[existingEmptyBlockIndex].size.rows += 1
        } else {
          const totalCols = blocksInRange.reduce((total, block) => total + block.size.cols, 0)

          if (totalCols > 0) {
            // find missing cols
            blocksInRange
              .sort((a, b) => a.cords.x - b.cords.y)
              .forEach((block, bi) => {
                const nextBlock = blocksInRange[bi + 1]
                const colGap = !nextBlock ? 12 - (block.cords.x + block.size.cols) : nextBlock.cords.x - (block.cords.x + block.size.cols)
                if (colGap > 0) {
                  blocks.push({
                    cords: { x: block.cords.x + block.size.cols, y: i },
                    size: { cols: colGap, rows: 1 },
                    infographicBlock: { name: 'Empty block' },
                    infographicBlockId: `EMPTY_${i}_col`,
                    isEmptyBlock: true
                  })
                }
              })
          } else {
            blocks.push({
              cords: { x: 0, y: i },
              size: { cols: 12, rows: 1 },
              infographicBlock: { name: 'Empty block' },
              infographicBlockId: `EMPTY_${i}`,
              isEmptyBlock: true
            })
          }
        }
      }
    }

    // find any empty blocks that are touching
    return blocks.reduce((combinedBlocks, currentBlock) => {
      if (!currentBlock) return combinedBlocks

      const lastCombinedBlock = combinedBlocks[combinedBlocks.length - 1]

      if (!lastCombinedBlock || !currentBlock.isEmptyBlock) {
        combinedBlocks.push(currentBlock)
        return combinedBlocks
      }

      if (lastCombinedBlock.isEmptyBlock &&
        lastCombinedBlock.cords.y + lastCombinedBlock.size.rows === currentBlock.cords.y &&
        lastCombinedBlock.size.cols === currentBlock.size.cols
      ) {
        lastCombinedBlock.size.rows += currentBlock.size.rows
      } else {
        combinedBlocks.push(currentBlock)
      }

      return combinedBlocks
    }, []).sort((a, b) => a.isEmptyBlock - b.isEmptyBlock)
  }, [currentPageIndex, templateContent])
}
