import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../../commonDesign/Button'
import SydModalActions from '../../../../../../../commonDesign/SydModal/SydModalActions'
import { dangerZone } from '../../../../../../../../api/coreData'
import { decipherError } from '../../../../../../../../utils/decipherError'
import SydInput from '../../../../../../../commonDesign/SydInput'
import FilterFieldEditor from '../FilterFieldEditor'
import SydLabel, { hookFormErrorAdapter } from '../../../../../../../commonDesign/SydLabel'

const useSubmitter = (form, onComplete, report, filterId) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: addReportFilter } = dangerZone.__useAddDenaliReportFilter()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      reportId: report.reportId,
      filterId: filterId,
      filter: formData.filter,
      name: formData.name || null,
      ordinal: formData.ordinal,
      summary: formData.summary || null
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await addReportFilter(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add report filter')
      }
      // eslint-disable-next-line no-debugger
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, addReportFilter, report, filterId])
  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function EditFilterForm ({ report, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      filter: {},
      name: '',
      ordinal: 999,
      summary: ''
    }
  })

  const name = form.watch('name')
  const filterId = useMemo(() => {
    if (!name) return ''
    return name.replace(/[\s_.]+/g, '-').toLowerCase()
  }, [name])

  const { submitter, processing, error } = useSubmitter(form, onComplete, report, filterId)
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <Controller
          name='name'
          control={form.control}
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 64, message: 'Maximum 64 characters' }
          }}
          render={(f) => (
            <SydLabel required label='Name' description='' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SydLabel label='Auto-Generated ID' description=''>
          <SydInput value={filterId} inputProps={{ readOnly: true }} />
        </SydLabel>
      </Grid>
      <Grid item xs={6} md={4}>
        <Controller
          name='ordinal'
          control={form.control}
          rules={{
            min: { value: 0, message: 'Minimum value 0' }
          }}
          render={(f) => (
            <SydLabel required label='Ordinal' description='' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='filter'
          control={form.control}
          render={(f) => (
            <FilterFieldEditor value={f.field.value} columns={report.configuration?.columns} onChange={f.field.onChange} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

EditFilterForm.propTypes = {
  report: PropTypes.object,
  filter: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditFilterForm
