import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import OperationalTable from '../../../../organisms/OperationalTable'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'
import { dataCatalog } from '../../../../../api/coreData'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import EmptySection from '../../../../atoms/EmptySection'

const columnsConfig = {
  columns: [
    { id: 'catalogDatasourceId', accessor: 'catalogDatasourceId', Header: 'ID' },
    { id: 'displayName', accessor: 'displayName', Header: 'Name' },
    { id: 'code', accessor: 'code', Header: 'Code' },
    { id: 'type', accessor: 'type', Header: 'Type' },
    { id: 'subType', accessor: 'subType', Header: 'Sub-Type' }
  ],
  defaultSort: [{
    id: 'displayName', desc: true
  }]
}

function DataSourceList () {
  const { data, isLoading, refetch, isFetching } = dataCatalog.useListCatalogDataSources()
  const _columns = useConfigColumns({
    columnsConfig
  })

  const history = useHistory()
  const onRowClick = useCallback((row) => {
    if (!row.original?.catalogDatasourceId) return
    history.push(`/admin/denali/catalog/sources/${row.original.catalogDatasourceId}`)
  }, [history])

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  if (!data?.sources?.length) {
    return (
      <AdminPage>
        <EmptySection title='No Data Available' description='' />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <div />
            <div>
              <SydButton size='sm' variant='primary' icon='sync' onClick={refetch} processing={isFetching}>Refresh</SydButton>
            </div>
          </FlexRow>
        </Grid>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            data={data?.sources ?? []}
            isLoading={isLoading || isFetching}
            onRowClick={onRowClick}
            itemName='Data Sources'
            columns={_columns.columns}
            defaultSort={_columns.defaultSort}
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

DataSourceList.propTypes = {
}

export default DataSourceList
