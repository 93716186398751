import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import InfographicNavigation from '../components/InfographicNavigation'
import { EditInfographicTemplateForm } from './components/EditInfographicTemplateForm'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }
}))

const InfographicTemplatesDetailsView = () => {
  const classes = useStyles()
  const params = useParams()

  return (
    <div className={classes.container}>
      <InfographicNavigation />
      <Box mt={2}>
        <EditInfographicTemplateForm
          defaultTemplateId={params.templateId}
        />
      </Box>
    </div>
  )
}

export default InfographicTemplatesDetailsView
