/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import DefaultLayout from '../../../abundanceEngine/layouts/DefaultLayout'
import { useAppContext } from '../../../redux/slices/appContext'
import ResearchViewProvider from './ResearchViewProvider'
import { ResearchContent } from './ResearchContent'
import { defaultExportColumns } from './constants'

const ResearchHome = ({
  exportColumns,
  ...props
}) => {
  const { clientId, availableDates } = useAppContext()

  if (!clientId) {
    return null
  }

  return (
    <ResearchViewProvider endDate={availableDates.max} clientId={clientId} exportColumns={exportColumns}>
      <DefaultLayout>
        <ResearchContent />
      </DefaultLayout>
    </ResearchViewProvider>
  )
}

ResearchHome.propTypes = {
  exportColumns: PropTypes.array
}

ResearchHome.defaultProps = {
  exportColumns: defaultExportColumns
}

export default ResearchHome
