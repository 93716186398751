import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import Card from '../../../molecules/Card'

function DataHomeIndexView ({ root }) {
  return (
    <FadeIn>
      <div style={{ margin: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/integrator`}>
              <Card>
                Denali Integrator
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/ingestion`}>
              <Card>
                Denali Ingestion
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/analysis`}>
              <Card>
                Denali Analysis
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/alerts`}>
              <Card>
                Denali Alerts
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/orchestration`}>
              <Card>
                Denali Orchestration
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to={`${root}/catalog`}>
              <Card>
                Denali Data Catalog
              </Card>
            </Link>
          </Grid>
        </Grid>
      </div>
    </FadeIn>
  )
}

DataHomeIndexView.propTypes = {
  root: PropTypes.any
}

export default DataHomeIndexView
