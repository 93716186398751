import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FilterContext } from './FilterContext'
import Token from './Token'

function ColumnToken ({ value }) {
  const { columns } = useContext(FilterContext)
  const warning = useMemo(() => {
    return value in columns.dictionary
      ? undefined
      : 'This column does not exist in the report'
  }, [value, columns])

  return <Token warn={warning}>{value}</Token>
}

ColumnToken.propTypes = {
  value: PropTypes.string
}

export default ColumnToken
