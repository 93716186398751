import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Grid, makeStyles } from '@material-ui/core'
import OperationalTable, { useOperationalTable } from '../OperationalTable'
import { useSchwabRealtime, useColumns } from './hooks'

const makeHeader = ({
  data,
  classes,
  showTransactionsChecked,
  setShowTransactionsChecked,
  maskAccount,
  setMaskAccount,
  useRealtime
}) => () => {
  let accountName = ''
  if (data?.accountTitle1) {
    accountName = data.accountTitle1
  }

  if (data?.accountTitle2) {
    accountName = `${accountName} - ${data.accountTitle2}`
  }

  if (data?.accountTitle3) {
    accountName = `${accountName} ${data.accountTitle3}`
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <div className={classes.headerItem}> */}
        {/*   <label>Account Number</label> */}
        {/*   <span>{data?.id ?? ''}</span> */}
        {/* </div> */}
        <div className={classes.headerItem}>
          <label>Account Name</label>
          <span>{accountName}</span>
        </div>
        <div className={classes.headerItem}>
          <label>Show Transactions</label>
          <span><Checkbox checked={showTransactionsChecked} onChange={setShowTransactionsChecked} /></span>
        </div>
        {useRealtime && (
          <div>
            <label>Mask Account</label>
            <span><Checkbox checked={maskAccount} onChange={setMaskAccount} /></span>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

const positionColumns = [
  {
    id: 'accountNumber',
    Header: 'Account Number',
    accessor: 'formattedAccount'
  },
  {
    id: 'securityName',
    Header: 'Security Name',
    accessor: 'securityName'
  },
  {
    id: 'symbol',
    Header: 'Symbol',
    accessor: 'symbol'
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'type'
  },
  {
    id: 'marketValue',
    Header: 'Market Value',
    accessor: 'marketValue',
    alignRight: true,
    format: '$(0,0.00)'
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: 'quantity',
    alignRight: true,
    format: '0,0.00'
  },
  {
    id: 'dayChange',
    Header: 'Day Change',
    accessor: 'dayChange',
    alignRight: true
  }]

const transactionColumns = [
  {
    id: 'accountNumber',
    Header: 'Account Number',
    accessor: 'formattedAccount'
  },
  {
    id: 'action',
    Header: 'Action',
    accessor: 'action'
  },
  {
    id: 'cusipNumber',
    Header: 'Cusip #',
    accessor: 'cusipNumber'
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: 'Description'
  },
  {
    id: 'executedDate',
    Header: 'Executed Date',
    accessor: 'executedDate'
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: 'amount',
    alignRight: true,
    format: '(0,0.00)'
  },
  {
    id: 'netAmount',
    Header: 'Net Amount',
    accessor: 'netAmount',
    alignRight: true,
    format: '(0,0.00)'
  },
  {
    id: 'price',
    Header: 'Price',
    accessor: 'price',
    alignRight: true,
    format: '$0.00'
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: 'quantity',
    alignRight: true
  },
  {
    id: 'tradeDate',
    Header: 'Trade Date',
    accessor: 'tradeDate'
  },
  {
    id: 'settleDate',
    Header: 'Settle Date',
    accessor: 'settleDate'
  }
]

const makeColumns = (props) => [
  {
    id: 'header',
    Header: makeHeader(props),
    columns: [
      {
        id: 'dataPoint',
        Header: () => props.showTransactionsChecked ? 'Transactions' : 'Positions',
        columns: props.showTransactionsChecked === true
          ? transactionColumns
          : positionColumns
      }
    ]
  }
]

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    backgroundColor: '#eeeeee'
  },
  headerItem: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '1rem',
    '& > label': {
      fontSize: '1rem'
    },
    '& > span': {
      fontSize: '.8rem'
    }
  }
})

// const useColumns = (columns, defaultSort) => ({
//   columns,
//   defaultSort
// })const useColumns = (columns, defaultSort) => ({
//   columns,
//   defaultSort
// })

const SchwabTable = ({
  accountNumber,
  defaultSort,
  useRealtime
}) => {
  const [maskAccount, setMaskAccount] = useState(false)
  const {
    data,
    isLoading
  } = useSchwabRealtime({
    accountNumber: accountNumber,
    useRealtime,
    maskAccount
  })
  const [showTransactionsChecked, setShowTransactionsChecked] = useState(false)
  console.log('showTransactionsChecked', showTransactionsChecked)

  const onChecked = useCallback((event) => {
    setShowTransactionsChecked(event.currentTarget.checked)
  }, [setShowTransactionsChecked])

  const onMaskAccountChecked = useCallback((event) => {
    setMaskAccount(event.currentTarget.checked)
  }, [setMaskAccount])

  const classes = useStyles()
  const columns = useMemo(() => {
    return makeColumns({
      data,
      classes,
      showTransactionsChecked,
      setShowTransactionsChecked: onChecked,
      maskAccount,
      setMaskAccount: onMaskAccountChecked,
      useRealtime
    })
  }, [data, classes, showTransactionsChecked, onChecked, maskAccount, onMaskAccountChecked, useRealtime])

  const columnConfig = useColumns({ columns, defaultSort })
  const { onSortingChange } = useOperationalTable({ defaultSort: columnConfig.defaultSort || [] })

  return (
    <div className={classes.root}>
      <OperationalTable.Wrapper>
        <OperationalTable
          loading={isLoading}
          mode='client'
          columns={columnConfig.columns || []}
          defaultSort={columnConfig.defaultSort || []}
          data={(showTransactionsChecked ? data?.transactions : data?.positions) || []}
          onSortingChange={onSortingChange}
          hideFooter
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

SchwabTable.propTypes = {
  accountNumber: PropTypes.string,
  defaultSort: PropTypes.any,
  useRealtime: PropTypes.bool
}

export default SchwabTable
