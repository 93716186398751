import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import ModifyFieldsForm from './form'

const ModifyFieldsDialog = React.forwardRef(function ModifyFieldsDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (job, source) => {
      const initialFormState = {
        ...source,
        fields: (source.fields || []).map(f => ({
          ...f,
          isIncluded: [...(job.configuration?.virtualFields || [])].some(vf => vf.name === f.fieldName)
        }))
      }
      setDialogState({ open: true, processing: false, job, source: initialFormState })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Included Fields'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <ModifyFieldsForm
            job={dialogState.job}
            source={dialogState.source}
            onComplete={handleComplete}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

ModifyFieldsDialog.propTypes = {
  onComplete: PropTypes.func
}

ModifyFieldsDialog.defaultProps = {
  onComplete: () => {}
}

export default ModifyFieldsDialog
