import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import EmptySection from '../../../../atoms/EmptySection'
import SydButton from '../../../../commonDesign/Button'
import FlexActions from '../../../../molecules/FlexActions'

const columnsConfig = {
  columns: [
    { id: 'ordinal', accessor: 'ordinal', Header: 'Order' },
    { id: 'jobId', accessor: 'jobId', Header: 'Job ID' },
    { id: 'code', accessor: 'code', Header: 'Job Code' },
    { id: 'name', accessor: 'name', Header: 'Job Name' }
  ],
  defaultSort: [{
    id: 'ordinal', desc: false
  }]
}

function JobGroupMemberDisplay ({ jobGroup, onEdit }) {
  const _columns = useConfigColumns({
    columnsConfig
  })

  if (!jobGroup?.members?.length) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EmptySection
            title='No Jobs Assigned to Group'
            description={(
              <SydButton variant='primary' size='lg' icon='add' onClick={onEdit}>Add Jobs</SydButton>
            )}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FlexActions>
          <SydButton size='xs' icon='edit' onClick={onEdit}>Edit Membership</SydButton>
        </FlexActions>
      </Grid>
      <Grid item xs={12}>
        <OperationalTable
          variant='v2'
          data={jobGroup?.members ?? []}
          itemName='Members'
          columns={_columns.columns}
          defaultSort={_columns.defaultSort}
        />
      </Grid>
    </Grid>
  )
}

JobGroupMemberDisplay.propTypes = {
  jobGroup: PropTypes.shape({
    members: PropTypes.array
  }),
  onEdit: PropTypes.func
}

export default JobGroupMemberDisplay
