export function decipherError (err) {
  if (!err) return 'An unexpected error occurred'
  if (err.response?.type) {
    // Probably a ProblemDetails
    const pd = err.response
    if (pd.message && pd.detail) {
      return `${pd.message}: ${pd.detail}`
    }
    return pd.message
  }
  return err.toString()
}
