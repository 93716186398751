import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  useSearchIntegrationJobs
} from '../../../../api/integrations'
import AdminPage from '../../admin/shared/AdminPage'
import OperationalTable from '../../../organisms/OperationalTable'

const sourceColumns = [
  { id: 'connection', accessor: 'connection.displayName', Header: 'Connection' },
  { id: 'namespace', accessor: 'integrationSource.namespace', Header: 'Namespace' },
  { id: 'source', accessor: 'integrationSource.label', Header: 'Source' },
  { id: 'domain', accessor: 'domain', Header: 'Domain' },
  { id: 'jobKey', accessor: 'jobKey', Header: 'Job Key' },
  { id: 'description', accessor: 'description', Header: 'Description' },
  { id: 'isEnabled', accessor: 'isEnabled', Header: 'Enabled?', Cell: ({ value }) => value ? 'Y' : 'N' }
]
const defaultSort = [
  { id: 'domain', desc: false },
  { id: 'jobKey', desc: false }
]

function IntegrationJobs ({ root }) {
  const query = useMemo(() => {
    return {
      includes: {
        integrationSource: true,
        connection: true
      }
    }
  }, [])
  const { data, isLoading } = useSearchIntegrationJobs(query)

  const history = useHistory()
  const onRowClick = useCallback((row) => {
    if (!row.original?.jobConfigurationId) return
    history.push(`${root}/integrator/jobs/${row.original.jobConfigurationId}`)
  }, [history, root])

  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OperationalTable
            variant='v2'
            data={data?.data ?? []}
            isLoading={isLoading}
            columns={sourceColumns}
            onRowClick={onRowClick}
            defaultSort={defaultSort}
            hideFooter
          />
        </Grid>
      </Grid>
    </AdminPage>
  )
}

IntegrationJobs.propTypes = {
  root: PropTypes.any
}

export default IntegrationJobs
