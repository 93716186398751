import React from 'react'
import { makeStyles, Grid, Divider } from '@material-ui/core'
import SydInput from '../../../../../../commonDesign/SydInput'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import { usePipelineEditor } from '../PipelineEditorContext'
import ScopePicker from './ScopePicker'
import PanelActions from './PanelActions'
import RequestStatus from './RequestStatus'

const useStyles = makeStyles((theme) => ({
  infoPanel: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.light,
    borderRight: `1px solid ${theme.palette.gray.dark}`,
    boxSizing: 'border-box',
    padding: theme.layout.padding.p5,
    overflowY: 'scroll',

    '& .__description-input': {
      width: '100%',
      minHeight: '80px',
      resize: 'none',
      fontFamily: theme.typography.fontFamily,
      padding: theme.layout.padding.p5,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.gray.dark,
      borderRadius: theme.layout.radius.tight
    }
  }
}))

function Index () {
  const classes = useStyles()
  const { displayName, setDisplayName, code, setCode, description, setDescription, scope, setScope, validation } = usePipelineEditor()

  return (
    <div className={classes.infoPanel}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SydLabel label='Display Name' required error={validation?.displayName}>
            <SydInput size='sm' value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <SydLabel label='Code' required error={validation?.code}>
            <SydInput size='sm' value={code} onChange={(e) => setCode(e.target.value)} />
          </SydLabel>
        </Grid>
        <Grid item xs={12}>
          <SydLabel label='Description'>
            <textarea className='__description-input' value={description} onChange={(e) => setDescription(e.target.value)} />
          </SydLabel>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <SydLabel label='Scope' required error={validation?.scope}>
            <ScopePicker value={scope} onChange={setScope} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <SydLabel label='Request Status'>
            <RequestStatus />
          </SydLabel>
        </Grid>
        <Grid item xs={12}>
          <PanelActions />
        </Grid>
      </Grid>
    </div>
  )
}

export default Index
