import { useMemo } from 'react'
import { useAccountStatusDetailsSearch } from '../../../../api/coreData'

function getDataSourcePredicate (dataSourceIds = []) {
  if (!dataSourceIds?.length) return null

  const dataSourcePredicate = dataSourceIds.reduce((prev, cur, index) => {
    const predicateItem = { op: 'eq', value: cur }
    if (index > 0) {
      predicateItem.combine = 'or'
    }
    prev.push(predicateItem)
    return prev
  }, [])
  return dataSourcePredicate
}

const getTotalsQuery = (dataSourceIds) => {
  const dataSourcePredicate = getDataSourcePredicate(dataSourceIds)

  const query = {
    stale: {
      filters: {
        isClosed: false,
        isStale: true,
        dataSourceId: dataSourcePredicate
      },
      resultType: 'total'
    },
    current: {
      filters: {
        isClosed: false,
        isStale: false,
        dataSourceId: dataSourcePredicate
      },
      resultType: 'total'
    },
    closed: {
      filters: {
        isClosed: true,
        dataSourceId: dataSourcePredicate
      },
      resultType: 'total'
    }
  }

  return query
}

const getDetailsQuery = (dataSourceIds = [], query = {}) => {
  const dataSourcePredicate = getDataSourcePredicate(dataSourceIds)
  const filterOptions = query?.filters ?? {}
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100
  const sort = query?.sort
  const includes = {
    custodian: true,
    asOfDayValues: true,
    accountCategory: true,
    assignedClientCount: true,
    accountExtraJson: ['accountStatus']
  }
  return {
    ...query,
    includes,
    filters: {
      ...filterOptions,
      dataSourceId: dataSourcePredicate
    },
    skip,
    take,
    sort,
    resultType: 'details'
  }
}

export const useAccountStatusTotals = (dataSourceIds) => {
  const query = useMemo(() => getTotalsQuery(dataSourceIds), [dataSourceIds])
  const { data, isLoading, error } = useAccountStatusDetailsSearch(query, true)
  return {
    result: data,
    loading: isLoading,
    error: error
  }
}

const mapSort = (sorts) => sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))
const textSearchFields = ['shortNumber', 'longNumber', 'shortName', 'longName', 'displayNumber', 'displayName']
const mapSearch = (searchText) => searchText
  ? textSearchFields.reduce((prev, field) => {
    prev[field] = [{ op: 'contains', value: searchText }]
    return prev
  }, {})
  : {}

export const useAccountStatusDetails = (dataSourceIds, filters, tableStuff) => {
  const {
    pageIndex = 0,
    pageSize,
    sort,
    searchText,
    tableMode
  } = tableStuff
  const search = useMemo(() => {
    if (tableMode !== 'client') return searchText
    return ''
  }, [searchText, tableMode])
  const query = useMemo(() => {
    return getDetailsQuery(dataSourceIds, {
      filters,
      skip: pageIndex * pageSize,
      take: pageSize,
      sort: mapSort(sort),
      textSearch: mapSearch(search)
    })
  }, [dataSourceIds, filters, pageSize, pageIndex, sort, search])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useAccountStatusDetailsSearch(query)

  return {
    data: isLoading ? [] : data?.data ?? [],
    loading: isLoading || isPreviousData,
    error
  }
}
