import React from 'react'
import FadeIn from '../components/molecules/Transitions/FadeIn'

export const viewDisabledWarningMessage = (viewOriginal) => {
  return viewOriginal
    ? (
      <FadeIn key='original'>
        <p>You are viewing what your selected client would see, because this view is not enabled for them.</p>
        <p>Click to see the enabled version of the view.</p>
      </FadeIn>
    )
    : (
      <FadeIn key='disabled'>
        <p>You are viewing a version of this view your selected client cannot see because it is not enabled for them.</p>
        <p>Click to see what they would see.</p>
      </FadeIn>
    )
}
