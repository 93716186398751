import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.layout.gap.g5,
    padding: theme.layout.padding.p5
  }
}))

function FlexActions ({ children, className, style, ...rest }) {
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.flexRow, className)} style={style}>{children}</div>
  )
}

FlexActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}

export default FlexActions
