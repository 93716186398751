import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useClientNetWorth } from '../../../../../api/coreData'
import { useFormattingContext } from '../../../FormattingProvider/FormattingContext'
import ClientMetricContainer from './ClientMetricContainer'

const ClientNetWorthValue = ({ title, valueAccessor, formatString, filters }) => {
  const { clientId, availableDates } = useAppContext()
  const { formatter } = useFormattingContext()

  const coreDataQuery = useMemo(
    () => ({
      asOfDate: availableDates.mainDate,
      clientId,
      netWorthGroupName: 'netWorth',
      memberGroupName: 'AccountCategory',
      ...filters
    }),
    [clientId, filters, availableDates.mainDate]
  )
  const { data, isLoading, error } = useClientNetWorth(coreDataQuery)
  const marketValue = useMemo(() => {
    if (isLoading || error) return null

    return data?.[valueAccessor] ?? null
  }, [isLoading, error, valueAccessor, data])
  if (error) return null

  return (
    <ClientMetricContainer title={title} isLoading={isLoading}>
      {formatter(marketValue, formatString)}
    </ClientMetricContainer>
  )
}

ClientNetWorthValue.propTypes = {
  title: PropTypes.string,
  valueAccessor: PropTypes.string,
  formatString: PropTypes.string,
  filters: PropTypes.object
}

ClientNetWorthValue.defaultProps = {
  title: 'Net Worth',
  valueAccessor: 'netWorth',
  formatString: '($0,0a)',
  filters: {}
}

export default ClientNetWorthValue
