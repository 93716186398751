import React, { useState, useCallback, useMemo } from 'react'
import { useLocation, useHistory, matchPath } from 'react-router-dom'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useOktaAuth } from '@okta/okta-react'
import {
  AppBar,
  IconButton,
  Snackbar,
  Toolbar,
  Typography
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Alert } from '@material-ui/lab'
import isNumber from 'lodash/isNumber'
import { useAuthState } from '../../../hooks'
import {
  ABUNDANCE_ENGINE_PREFIX_PATH,
  ALERT_SEVERITY,
  ICON_NAMES,
  TEXT_VARIANTS,
  ADVISOR_VIEW_PATH
} from '../../../constants'
import {
  useAppContext,
  useSetAppContext,
  useSetShowHeaderBackButton
} from '../../../redux/slices/appContext'
import ProfileTitle from '../../molecules/ProfileTitle'
import MainNavigationMenu from '../../molecules/MainNavigationMenu'
import Icon from '../../atoms/Icon'
import { staticViews } from '../../../staticViews'
import { useNoteThreadContext } from '../../../contexts/noteThreadContext'
import { useAbundanceEngineCurrentView, useAbundanceViewByPath } from '../../../redux/slices/abundanceEngineContext'
import { useHomeViewPath } from '../../../redux/slices/appConfig'
import WealthOwnerNavigation from '../../molecules/WealthOwnerNavigation'
import Profile from '../Profile'
import RightPanel from '../RightPanel'
import NotesBoard from '../NotesBoard'
import { useScrollPosition } from './HeaderScrollContext'

const useStyles = makeStyles((theme) => ({
  appBar: ({ scrollPosition }) => {
    console.debug('appbar scrollposition', scrollPosition)
    return scrollPosition > 10 ? theme.layout.appBarScroll : theme.layout.appBar
  },
  appToolBar: theme.layout.appToolBar,
  viewTitle: theme.layout.viewTitle,
  grow: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  sectionDesktop: {
    display: 'flex'
  },
  [theme.breakpoints.down('xs')]: {
    frameLayoutContainer: {
      padding: '3.8rem 0 0'
    }
  }
}))

const initialAlertState = {
  alertMessage: '',
  alertSeverity: ALERT_SEVERITY.info,
  openAlert: false
}

function MainHeader ({ sideNavOpen, toggleSideNav, overContentOnDesktop }) {
  const scrollPosition = useScrollPosition()
  const classes = useStyles({ scrollPosition })
  const location = useLocation()
  const history = useHistory()
  const { clearNotesContext } = useNoteThreadContext()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const fullName = authState.idToken?.claims?.name || ''
  const email = authState.idToken?.claims?.email || ''
  const homeViewPath = useHomeViewPath()
  const homeView = useAbundanceViewByPath(homeViewPath === '/views/home' ? 'home' : homeViewPath)
  const advisorView = useAbundanceViewByPath(ADVISOR_VIEW_PATH)
  const abundanceEngineCurrentView = useAbundanceEngineCurrentView()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()

  const STATIC_VIEWS = staticViews.map((view) => {
    const paths = view.path.split('/')
    return {
      ...view,
      path: paths.filter(item => !item.endsWith('?')).join('/')
    }
  })

  const [title, backButton, parentPath] = useMemo(() => {
    const pathname = location.pathname
    const isHomeView = pathname === '/' || pathname === homeViewPath
    const isAdvisorView = pathname.includes(ADVISOR_VIEW_PATH) && advisorView?.name
    const isAbundanceView = pathname.startsWith(`/${ABUNDANCE_ENGINE_PREFIX_PATH}`)
    const hideBackButton = (isHomeView || isAdvisorView)

    if (hideBackButton) {
      setShowHeaderBackButton(false)
    }

    if (isHomeView) {
      return [homeView?.name]
    }

    if (isAbundanceView) {
      if (abundanceEngineCurrentView?.hideTitle) return ['']
      return [abundanceEngineCurrentView?.name || 'Abundance Engine']
    }

    if (isAdvisorView) {
      return [advisorView?.name]
    }
    if (abundanceEngineCurrentView?.name) {
      if (abundanceEngineCurrentView?.hideTitle) return ['']
      return [abundanceEngineCurrentView?.name]
    }

    const staticView = STATIC_VIEWS.find((view) => {
      const match = matchPath(location.pathname, view)
      return match?.url === pathname
    })

    return [staticView?.name || '', staticView?.backButton, staticView?.parentPath]
  }, [
    location,
    homeView,
    homeViewPath,
    advisorView,
    abundanceEngineCurrentView,
    STATIC_VIEWS,
    setShowHeaderBackButton
  ])

  const setAppContext = useSetAppContext()
  const {
    isProfileSideNavOpen,
    isNotesSideNavOpen,
    isAdvisor,
    clientCount,
    showHeaderBackButton,
    viewTitle
  } = useAppContext()

  const showBackButton = useMemo(
    () => backButton || showHeaderBackButton,
    [showHeaderBackButton, backButton]
  )

  const toggleProfileSideNav = useCallback(
    () => setAppContext({ isProfileSideNavOpen: !isProfileSideNavOpen }),
    [isProfileSideNavOpen, setAppContext]
  )

  const toggleNotesSideNav = useCallback(
    () => {
      setAppContext({ isNotesSideNavOpen: !isNotesSideNavOpen })
      clearNotesContext()
    },
    [isNotesSideNavOpen, setAppContext, clearNotesContext]
  )

  const [alertState, setAlertState] = useState(initialAlertState)
  const { alertMessage, alertSeverity, openAlert } = alertState

  const handleCloseAlert = useCallback((_, reason) => {
    if (reason === 'clickaway') return
    setAlertState(initialAlertState)
  }, [])

  const handleBack = useCallback(() => {
    if (parentPath) {
      history.push(parentPath)
      return
    }

    const paths = location.pathname.split('/').slice(0, -1)
    const path = paths.join('/')
    history.push(path)
  }, [history, location, parentPath])

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))

  const renderNavigation = useCallback(() => {
    if (!isNumber(clientCount)) return null

    if (!isAdvisor && clientCount > 1) {
      return (
        <WealthOwnerNavigation toggleNotesSideNav={toggleNotesSideNav} />
      )
    }

    return (
      <MainNavigationMenu
        showSearchInputToggle
        toggleNotesSideNav={toggleNotesSideNav}
        toggleProfileSideNav={toggleProfileSideNav}
      />
    )
  }, [clientCount, isAdvisor, toggleNotesSideNav, toggleProfileSideNav])

  return (
    <>
      <AppBar position='sticky' className={classes.appBar}>
        <Toolbar className={classes.appToolBar}>
          {isAdvisor && (!matches || overContentOnDesktop) && (
            <IconButton
              aria-label='open drawer'
              edge='start'
              onClick={toggleSideNav}
              className={clsx(classes.menuButton, sideNavOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {showBackButton && (
            <IconButton
              edge='start'
              onClick={handleBack}
            >
              <Icon name={ICON_NAMES.chevronLeft} customSize='30px' color='black' />
            </IconButton>
          )}
          <Typography className={classes.viewTitle} color='primary' variant={TEXT_VARIANTS.h2} noWrap>
            {viewTitle || title}
          </Typography>
          <div className={classes.grow} />
          {renderNavigation()}
        </Toolbar>
      </AppBar>
      <RightPanel
        open={isProfileSideNavOpen}
        toggleOpen={toggleProfileSideNav}
        title={<ProfileTitle subtitle={email} title={fullName} />}
      >
        <Profile closeProfileNav={toggleProfileSideNav} />
      </RightPanel>
      <RightPanel
        open={isNotesSideNavOpen}
        toggleOpen={toggleNotesSideNav}
        skipHeader
      >
        <NotesBoard open={isNotesSideNavOpen} onClose={toggleNotesSideNav} />
      </RightPanel>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

MainHeader.propTypes = {
  sideNavOpen: PropTypes.bool.isRequired,
  overContentOnDesktop: PropTypes.bool,
  toggleSideNav: PropTypes.func.isRequired
}

MainHeader.defaultProps = {
  overContentOnDesktop: false
}

export default MainHeader
