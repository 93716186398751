import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../molecules/Transitions/FadeIn'

export const HideIfNoDataContext = createContext({ hidden: false, setHidden: () => {} })

export const useHideIfNoData = () => {
  return useContext(HideIfNoDataContext)
}

export const useHideIfNoDataWhen = (fn, deps) => {
  const { setHidden } = useHideIfNoData()
  useEffect(() => {
    setHidden(fn())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, setHidden, ...deps])
}

function HideIfNoData ({ children, style, className, defaultHidden, ...fadeInProps }) {
  const [hidden, setHidden] = useState(defaultHidden)
  const hideIfNoDataManager = useMemo(() => {
    return {
      hidden,
      setHidden
    }
  }, [hidden, setHidden])

  return (
    <HideIfNoDataContext.Provider value={hideIfNoDataManager}>
      <FadeIn {...fadeInProps} style={{ display: hidden ? 'none' : undefined, ...(style || {}) }} className={className}>
        {children}
      </FadeIn>
    </HideIfNoDataContext.Provider>
  )
}

HideIfNoData.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  defaultHidden: PropTypes.bool
}

HideIfNoData.defaultProps = {
  defaultHidden: false
}

export default HideIfNoData
