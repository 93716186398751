import { Box, Card, CardContent } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../../../constants'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import Checkbox from '../../../../../molecules/Checkbox'

export const SelectedBlockEditCard = ({ block, onBlockChange }) => {
  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <Text variant={TEXT_VARIANTS.h3} text='Selected Block Properties' />
          <Text variant={TEXT_VARIANTS.caption} text={`Change display settings for ${block?.infographicBlock?.name}`} />
        </Box>

        <SydLabel label='Coordinates' style={{ fontSize: '14px' }} />
        <Box display='flex' gridGap='12px'>
          <Box flex={1}>
            <SydLabel
              label='Row'
              description={(
                <>
                  Determines what row this item starts on.<br />
                  (Min: 0 / Max: {16 - block.size?.rows})
                </>
              )}
            >
              <SydInput
                size='sm'
                type='number'
                minWidth='100%'
                inputProps={{
                  increment: 1,
                  min: 0,
                  max: 16 - block.size?.rows
                }}
                value={block.cords?.y}
                onChange={(e) => onBlockChange('cords', {
                  ...block.cords,
                  y: parseInt(e.target.value)
                })}
              />
            </SydLabel>
          </Box>
          <Box flex={1}>
            <SydLabel
              label='Column'
              description={(
                <>
                  Determines what column this item starts on.<br />
                  (Min: 0 / Max: {12 - block.size?.cols})
                </>
              )}
            >
              <SydInput
                size='sm'
                type='number'
                minWidth='100%'
                inputProps={{
                  increment: 1,
                  min: 0,
                  max: 12 - block.size?.cols
                }}
                value={block.cords?.x}
                onChange={(e) => onBlockChange('cords', {
                  ...block.cords,
                  x: parseInt(e.target.value)
                })}
              />
            </SydLabel>
          </Box>
        </Box>
        <hr style={{ margin: '1rem 0' }} />
        <SydLabel label='Size' style={{ fontSize: '14px' }} />
        <Box display='flex' gridGap='12px'>
          <Box flex={1}>
            <SydLabel
              label='Height (Rows)'
              description={(
                <>
                  Determines how many rows this block takes up<br />
                  (Min: {block.infographicBlock?.metadata?.size?.rows ?? 0} /
                  Max: {16 - block.cords?.y})
                </>
              )}
            >
              <SydInput
                size='sm'
                type='number'
                minWidth='100%'
                disabled={block.infographicBlock?.metadata?.size?.rows === 16 - block.cords?.y}
                inputProps={{
                  increment: 1,
                  min: block.infographicBlock?.metadata?.size?.rows ?? 0,
                  max: (16 - block.cords?.y)
                }}
                value={block?.size?.rows}
                onChange={(e) => onBlockChange('size', {
                  ...block.size,
                  rows: parseInt(e.target.value)
                })}
              />
            </SydLabel>
          </Box>
          <Box flex={1}>
            <SydLabel
              label='Width (Columns)'
              description={(
                <>
                  Determines how many columns this block takes up<br />
                  (Min: {block.infographicBlock?.metadata?.size?.cols ?? 0} /
                  Max: {12 - block.cords?.x})
                </>
              )}
            >
              <SydInput
                size='sm'
                type='number'
                minWidth='100%'
                disabled={block.infographicBlock?.metadata?.size?.cols === 12 - block.cords?.x}
                inputProps={{
                  increment: 1,
                  min: block.infographicBlock?.metadata?.size?.cols ?? 0,
                  max: (12 - block.cords?.x)
                }}
                value={block?.size?.cols}
                onChange={(e) => {
                  return onBlockChange('size', {
                    ...block.size,
                    cols: parseInt(e.target.value)
                  })
                }}
              />
            </SydLabel>
          </Box>
        </Box>

        <hr style={{ margin: '1rem 0' }} />

        <Box display='flex' gridGap={4}>
          <Checkbox
            checked={!!block.hideInHtml}
            onChange={(e, checked) => onBlockChange('hideInHtml', checked)}
          />
          <Box onClick={() => onBlockChange('hideInHtml', !block.hideInHtml)}>
            <SydLabel
              label='Hide in HTML Delivery'
              description='Will only show in PDF Delivery / export'
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

SelectedBlockEditCard.propTypes = {
  block: PropTypes.shape({
    cords: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    hideInHtml: PropTypes.bool,
    infographicBlock: PropTypes.shape({
      name: PropTypes.string,
      metadata: PropTypes.shape({
        size: PropTypes.shape({
          rows: PropTypes.number,
          cols: PropTypes.number
        })
      })
    }),
    size: PropTypes.shape({
      rows: PropTypes.number,
      cols: PropTypes.number
    })
  }),
  onBlockChange: PropTypes.func
}
