import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { useModifyIntegrationJobStatus } from '../../../../../../api/integrations'
import { decipherError } from '../../../../../../utils/decipherError'

const useSubmitter = (onComplete, job, direction) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyIntegrationJobStatus } = useModifyIntegrationJobStatus()
  const onSubmit = useCallback(async () => {
    const command = {
      jobConfigurationId: job.jobConfigurationId,
      isEnabled: direction === 'enable'
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await modifyIntegrationJobStatus(command)
      if (result?.statusCode === 500) {
        setError('Something unexpected happened while setting the integration job status')
        return
      }
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, modifyIntegrationJobStatus, job, direction])

  return {
    submitter: onSubmit,
    processing,
    error
  }
}

function EnableJobForm ({ job, direction, onCancel, onComplete }) {
  const { submitter, processing, error } = useSubmitter(onComplete, job, direction)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>{job.domain} / {job.jobKey}</h3>
      </Grid>
      <Grid item xs={12}>
        {direction === 'enable' ? (
          <p>This will enable the integration job and allow it to sync as part of the group</p>
        ) : (
          <p>This will disable the integration job and stop it from automatically syncing</p>
        )}
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? <div className='__error'>{error}</div> : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            {direction === 'enable' ? 'Enable' : 'Disable'}
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

EnableJobForm.propTypes = {
  job: PropTypes.shape({
    jobConfigurationId: PropTypes.number,
    jobKey: PropTypes.string,
    domain: PropTypes.string
  }),
  direction: PropTypes.oneOf(['enable', 'disable']),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EnableJobForm
