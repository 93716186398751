import { createContext, useMemo } from 'react'

export const FilterContext = createContext({
  columns: {
    dictionary: {},
    list: []
  }
})

export const useFilterContextValues = (columns) => {
  const contextValue = useMemo(() => {
    return {
      columns: {
        dictionary: columns,
        list: Object.entries(columns).map(([key, val]) => ({
          id: key,
          ...structuredClone(val)
        }))
      }
    }
  }, [columns])

  return contextValue
}
