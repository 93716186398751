import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useThingContext } from '../ThingContext'
import Thing from '../Thing'
import { useHideIfNoData } from '../../HideIfNoData'

function SingleThing ({
  code,
  showHeader,
  className,
  style,
  variant,
  commentsIcon,
  attachmentsIcon,
  hideComments,
  hideAttachments,
  hideIfEmpty,
  labels
}) {
  const { data, isLoading } = useThingContext()

  const theThing = useMemo(() => {
    if (!data?.things) return null

    return data.things.find(x => x.code === code)
  }, [code, data])

  const { setHidden } = useHideIfNoData()
  useEffect(() => {
    setHidden(!isLoading && !theThing?.content)
  }, [theThing, isLoading, setHidden])

  if (isLoading) return null

  return (
    <Thing
      thing={theThing}
      className={className}
      style={style}
      showHeader={showHeader}
      variant={variant}
      commentsIcon={commentsIcon}
      attachmentsIcon={attachmentsIcon}
      hideComments={hideComments}
      hideAttachments={hideAttachments}
      hideIfEmpty={hideIfEmpty}
      labels={labels}
    />
  )
}

SingleThing.propTypes = {
  code: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['default', 'borderless']),
  commentsIcon: PropTypes.string,
  attachmentsIcon: PropTypes.string,
  hideComments: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  hideIfEmpty: PropTypes.bool,
  labels: PropTypes.shape({
    empty: PropTypes.string
  })
}

SingleThing.propTypes = {
  showHeader: true,
  variant: 'default'
}

export default SingleThing
