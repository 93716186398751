import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Card from '../../../../molecules/Card'
import InlineCardHeader from '../../../../molecules/Card/InlineCardHeader'
import EmptySection from '../../../../atoms/EmptySection'
import { systemJobs } from '../../../../../api/ingestion'
import SydButton from '../../../../commonDesign/Button'
import JobExecutionDetails from './JobExecutionDetails'

function JobGroup ({ jobGroup, processDate }) {
  const { mutateAsync: startJobGroupCommand, isLoading: starting } = systemJobs.useStartSystemJobGroup()
  const startJobGroup = useCallback(async () => {
    await startJobGroupCommand({
      code: jobGroup.code,
      processDate: dayjs(processDate).format('YYYY-MM-DD'),
      executionArgs: {}
    })
  }, [jobGroup.code, processDate, startJobGroupCommand])

  return (
    <Card>
      <InlineCardHeader action={(<SydButton onClick={startJobGroup} size='sm' processing={starting} icon='sync'>Start</SydButton>)}>
        <div>{jobGroup.name} ({jobGroup.code})</div>
      </InlineCardHeader>
      {!jobGroup.members?.length ? (
        <EmptySection title='Job Group is Empty' description='' />
      ) : (jobGroup.members.map((job) => (
        <JobExecutionDetails job={job} processDate={processDate} key={`jm_${job.jobGroupMemberId}`} />
      )))}
    </Card>
  )
}

JobGroup.propTypes = {
  jobGroup: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.shape({
      jobGroupMemberId: PropTypes.number
    }))
  }),
  processDate: PropTypes.any
}

export default JobGroup
