import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Link, useHistory, useParams } from 'react-router-dom'
import AdminPage from '../../../admin/shared/AdminPage'
import Loading from '../../../../molecules/Loading'
import {
  connections,
  useCheckMigrationsMutation,
  useScanMutation, useSearchIntegrationSources,
  useSyncMutation
} from '../../../../../api/integrations'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'
import NavTile from '../../shared/NavTile'
import SydActionMenu from '../../../../commonDesign/SydActionMenu'
import { useDenaliContext } from '../../DenaliProvider'
import { SUCCESS_ALERT } from '../../../../../constants'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import Card from '../../../../molecules/Card'
import EmptySection from '../../../../atoms/EmptySection'
import CreateConnectionDialog from './CreateConnectionDialog'
import IntegrationSourceList from './IntegrationSourceList'

const useConnectionActions = (root) => {
  const denaliContext = useDenaliContext()
  const { mutateAsync: syncJob } = useSyncMutation()
  const { mutateAsync: scanJob } = useScanMutation()
  const { mutateAsync: migrate } = useCheckMigrationsMutation()

  const handleSync = useCallback(async (connection) => {
    const result = await syncJob({
      connection: connection.connectionId
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`${root}/integrator/batches/${result.batchId}`}>Sync started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [syncJob, denaliContext, root])

  const handleScan = useCallback(async (connection) => {
    const result = await scanJob({
      connectionId: connection.connectionId,
      domain: connection.domain
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`${root}/integrator/batches/${result.batchId}`}>Scan started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [scanJob, denaliContext, root])

  const handleMigrate = useCallback(async (connection) => {
    const result = await migrate({
      connection: connection.connectionId,
      domain: connection.domain
    })

    denaliContext.setAlert({
      ...SUCCESS_ALERT,
      alertMessage: (
        <Link to={`${root}/integrator/batches/${result.batchId}`}>Migration Check started in batch {result.batchId}</Link>
      )
    })
    return result
  }, [migrate, denaliContext, root])

  return useMemo(() => {
    return [
      { key: 'handle-connection-scan', label: 'Scan', icon: 'search', onClick: handleScan },
      { key: 'handle-connection-sync', label: 'Sync', icon: 'sync', onClick: handleSync },
      { key: 'handle-connection-migrate', label: 'Check Migrations', icon: 'database', onClick: handleMigrate }
    ]
  }, [handleSync, handleScan, handleMigrate])
}

const useSelectedConnectionState = (data) => {
  const { connectionCode } = useParams()
  const selectedConnection = useMemo(() => {
    return (data?.connections || []).find(c => c.code.toLowerCase() === connectionCode?.toLowerCase())
  }, [connectionCode, data])

  const history = useHistory()
  const setSelectedConnection = useCallback((conn) => {
    if (conn) {
      history.replace(`/admin/denali/integrator/connections/${conn.code}`)
    } else {
      history.replace('/admin/denali/integrator/connections')
    }
  }, [history])

  return [selectedConnection, setSelectedConnection]
}

function ConnectionsList ({ root }) {
  const createConnectionRef = useRef(null)
  const connectionActions = useConnectionActions(root)

  const { data, isLoading, isFetching } = connections.useListIntegrationConnections()
  const [selectedConnection, setSelectedConnection] = useSelectedConnectionState(data)
  const sourceQuery = useMemo(() => ({ filters: { connectionId: selectedConnection?.connectionId } }), [selectedConnection])
  const { data: isData, isLoading: integrationSourcesLoading } = useSearchIntegrationSources(sourceQuery, { enabled: !!selectedConnection })

  if (isLoading) {
    return (
      <AdminPage>
        <Loading />
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <div />
            <div>
              <SydButton size='sm' variant='primary' icon='add' onClick={() => createConnectionRef.current.open()} processing={isFetching}>Create</SydButton>
            </div>
          </FlexRow>
        </Grid>
        <Grid item xs={12} md={3}>
          {(data?.connections || []).map(conn => (
            <NavTile
              key={`connection_${conn.connectionId}`}
              label={`${conn.displayName} (${conn.domain})`}
              menu={<SydActionMenu actions={connectionActions} subject={conn} />}
              selected={selectedConnection === conn}
              onSelect={() => setSelectedConnection(conn)}
            />
          ))}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {!selectedConnection ? (
                <FadeIn>
                  <Card>
                    <EmptySection title='Select a connection to view details' description='' />
                  </Card>
                </FadeIn>
              ) : integrationSourcesLoading ? (
                <FadeIn>
                  <Loading />
                </FadeIn>
              ) : selectedConnection ? (
                <FadeIn>
                  <Card>
                    <IntegrationSourceList
                      root={root}
                      connectionId={selectedConnection.connectionId}
                      sources={(isData?.data || [])}
                    />
                  </Card>
                </FadeIn>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreateConnectionDialog ref={createConnectionRef} />
    </AdminPage>
  )
}

ConnectionsList.propTypes = {
  root: PropTypes.any
}

export default ConnectionsList
