import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  codeWarn: {
    padding: theme.layout.padding.p5,
    marginLeft: theme.layout.margin.m2,
    marginRight: theme.layout.margin.m2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.warning.light
  },
  codeNormal: {
    padding: theme.layout.padding.p5,
    marginLeft: theme.layout.margin.m2,
    marginRight: theme.layout.margin.m2,
    backgroundColor: theme.palette.empty
  }
}))

function Token ({ warn, children }) {
  const classes = useStyles()
  if (warn) {
    return (
      <Tooltip title={warn} placement='top'>
        <code className={classes.codeWarn}>{children}</code>
      </Tooltip>
    )
  }
  return (
    <code className={classes.codeNormal}>{children}</code>
  )
}

Token.propTypes = {
  children: PropTypes.node,
  warn: PropTypes.string
}

export default Token
