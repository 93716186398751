import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { dangerZone } from '../../../../../api/coreData'
import Card from '../../../../molecules/Card'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import InlineCardHeader from '../../../../molecules/Card/InlineCardHeader'
import SydButton from '../../../../commonDesign/Button'
import CodeTag from '../../../../atoms/CodeTag'
import ErrorDisplay from '../../../../molecules/ErrorDisplay'
import SydActionMenu from '../../../../commonDesign/SydActionMenu'
import ModifyReportExportDialog from './ViewDetails/ModifyReportExportDialog'
import RemoveReportExportDialog from './ViewDetails/RemoveReportExportDialog'

const useExportActions = ({ exportRef, deleteRef }) => {
  const handleEdit = useCallback((report) => {
    return exportRef.current.open(report)
  }, [exportRef])
  const handleDelete = useCallback((report) => {
    return deleteRef.current.open(report)
  }, [deleteRef])

  return useMemo(() => {
    return [
      { key: 'edit-export-config', label: 'Edit Export Config', icon: 'edit', onClick: handleEdit },
      { key: 'delete-export-config', label: 'Delete Export Config', icon: 'delete', onClick: handleDelete }
    ]
  }, [handleEdit, handleDelete])
}

function DenaliReportExportInfo ({ report }) {
  const { data: exportInfo, isLoading, error } = dangerZone.__useGetDenaliReportExportInfo(report.reportId)
  const exportConfig = useMemo(() => {
    if (exportInfo?.exportConfiguration && report?.configuration?.export) {
      return {
        formats: report.configuration.export.formats
      }
    }
    return null
  }, [exportInfo, report])
  const exportRef = useRef(null)
  const deleteRef = useRef(null)
  const exportActions = useExportActions({ exportRef, deleteRef })

  if (error) {
    return (
      <Card>
        <InlineCardHeader>Export Configuration</InlineCardHeader>
        <ErrorDisplay error={error} />
      </Card>
    )
  }

  if (isLoading) {
    return (
      <Card>
        <InlineCardHeader>Export Configuration</InlineCardHeader>
        <Loading />
      </Card>
    )
  }

  if (!exportConfig) {
    return (
      <Card>
        <InlineCardHeader>Export Configuration</InlineCardHeader>
        <EmptySection
          title='Export Configuration Not Set'
          description={<SydButton size='sm' variant='primary' icon='add' onClick={() => exportRef.current.open(report)}>Add Export Configuration</SydButton>}
        />
        <ModifyReportExportDialog ref={exportRef} />
      </Card>
    )
  }

  return (
    <Card>
      <InlineCardHeader
        action={<SydActionMenu subject={report} actions={exportActions} />}
      >
        Export Configuration
      </InlineCardHeader>
      <ul>
        {exportConfig.formats.map(f => (
          <li key={`format_${f}`}>
            <CodeTag style={{ display: 'inline' }}>{f}</CodeTag>
          </li>
        ))}
      </ul>
      <ModifyReportExportDialog ref={exportRef} />
      <RemoveReportExportDialog ref={deleteRef} />
    </Card>
  )
}

DenaliReportExportInfo.propTypes = {
  report: PropTypes.object.isRequired
}

export default DenaliReportExportInfo
