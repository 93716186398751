import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import FieldDisplay from '../../../../molecules/FieldDisplay'

function DataSourceAttributes ({ datasource }) {
  const fields = useMemo(() => {
    return [
      { label: 'ID', value: datasource.catalogDatasourceId },
      { label: 'Namespace ID', value: datasource.namespaceId },
      { label: 'Name', value: datasource.displayName },
      { label: 'Code', value: datasource.code },
      { label: 'Type', value: datasource.type },
      { label: 'Sub-Type', value: datasource.subType },
      { label: 'is System', value: datasource.isSystem, format: 'bool' },
      { label: 'is Internal', value: datasource.isInternal, format: 'bool' },
      { label: 'Description', value: datasource.description }
    ]
  }, [datasource])

  return (
    <FieldDisplay fields={fields} />
  )
}

DataSourceAttributes.propTypes = {
  datasource: PropTypes.shape({
    catalogDatasourceId: PropTypes.number,
    namespaceId: PropTypes.number,
    displayName: PropTypes.string,
    code: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    data: PropTypes.object,
    isSystem: PropTypes.bool,
    isInternal: PropTypes.bool,
    description: PropTypes.string
  })
}

export default DataSourceAttributes
