import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import StatusDisplay from './StatusDisplay'

const useStyles = makeStyles((theme) => ({
  itemHeader: {
    padding: '16px 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px'
  },
  names: {
    flex: '1 0'
  },
  status: {
  },
  parentTitle: {
    fontWeight: '100',
    paddingBottom: '5px'
  },
  itemTitle: {
    fontSize: '16px'
  }
}))

function StatusReportItemTitle ({ item, parent, statusInfo }) {
  const classes = useStyles()

  return (
    <header className={classes.itemHeader}>
      {statusInfo ? (<StatusDisplay statusInfo={statusInfo} useIcon size={32} />) : null}
      <div className={classes.names}>
        {parent ? (<div className={classes.parentTitle}>{parent?.displayName}</div>) : null}
        <div className={classes.itemTitle}>{item?.displayName}</div>
      </div>
    </header>
  )
}

StatusReportItemTitle.propTypes = {
  statusInfo: PropTypes.any,
  item: PropTypes.shape({
    displayName: PropTypes.string
  }),
  parent: PropTypes.any
}

export default StatusReportItemTitle
