import React, { useCallback, useMemo, useState } from 'react'
import { useGetGroupedCoreData } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import SydAutocomplete from '../../commonDesign/SydAutocomplete'
import { useResearchViewContext } from './ResearchViewProvider'

export const AssetClassFilter = () => {
  const { clientId } = useAppContext()
  const { setLevelFilters, levelFilters, endDate } = useResearchViewContext()
  const [searchText, setSearchText] = useState('')

  const { data, isLoading } = useGetGroupedCoreData({
    dateRange: {
      startDate: endDate,
      endDate: endDate
    },
    levelFilters: {
      dateType: 'all',
      clientIds: [clientId],
      levelTypes: ['client', 'assetClassTag'],
      calcType: 'balance'
    }
  })

  const filteredAssetClasses = useMemo(() => {
    if (!data?.length || !searchText) return data
    return data.filter(({ assetClassTagName }) => assetClassTagName.toLowerCase().includes(searchText.toLowerCase()))
  }, [data, searchText])

  const selectedAssetClasses = useMemo(() => {
    if (!data?.length || !levelFilters?.assetClassTagIds?.length) return []
    return data?.filter(({ assetClassTagId }) => levelFilters.assetClassTagIds.includes(assetClassTagId)).map(({ assetClassTagId, assetClassTagName }) => ({ value: assetClassTagId, label: assetClassTagName }))
  }, [data, levelFilters.assetClassTagIds])

  const handleOnChange = useCallback((val) => {
    setSearchText(null)
    if (!val?.length) {
      setLevelFilters(previous => ({ ...previous, assetClassTagIds: undefined }))
      return
    }
    setLevelFilters(previous => ({ ...previous, assetClassTagIds: val?.map(({ value }) => value) }))
  }, [setLevelFilters])

  return (
    <>
      <SydAutocomplete
        label='Filter by asset class'
        options={filteredAssetClasses?.map(({ assetClassTagId, assetClassTagName }) => ({
          value: assetClassTagId,
          label: assetClassTagName
        })) ?? []}
        placeholder={isLoading ? 'Loading...' : 'Filter by asset class'}
        isLoading={isLoading}
        size='sm'
        onInputChange={(e, value) => setSearchText(value)}
        onChange={(e, value) => handleOnChange(value)}
        multiple
        defaultValue={[]}
        value={selectedAssetClasses}
      />
    </>
  )
}
