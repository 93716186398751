import React, { useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

export const hookFormErrorAdapter = (form, fieldState) => {
  return (form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error?.message
}

export const hookFormFieldErrorAdapter = (fieldState) => {
  return (fieldState.isDirty || fieldState.isTouched) && fieldState.error?.message
}

const useStyles = makeStyles((theme) => ({
  sydLabelWrapper: {
    '& .__error': {
      color: theme.palette.danger.main,
      fontSize: theme.typography.fontSizes.md
    },
    '& .__desc': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.md
    }
  },
  sydLabel: {
    paddingBottom: '3px',
    '& > span': {
      fontWeight: theme.typography.weights.bold
    }
  },
  sydRequiredMarker: {
    color: theme.palette.danger.main,
    marginLeft: '3px'
  }
}))

const SydLabel = React.forwardRef(function SydLabel ({ className, label, required, children, error, description, style }, ref) {
  const classes = useStyles()

  useImperativeHandle(ref, () => ({
    focus: () => {}
  }), [])

  return (
    <div className={clsx(className, classes.sydLabelWrapper)} style={style}>
      <div className={classes.sydLabel}>
        <span>{label}</span>
        {(required ? (<span className={classes.sydRequiredMarker}>*</span>) : null)}
      </div>
      {children}
      {error ? (
        <div className='__error'>{error}</div>
      ) : null}
      {description && !error ? (
        <div className='__desc'>{description}</div>
      ) : null}
    </div>
  )
})

SydLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  style: PropTypes.object
}

SydLabel.defaultProps = {
  required: false
}

export default SydLabel
