import React from 'react'
import { Button, Grid, Container, makeStyles } from '@material-ui/core'
import { useSchwabAuthenticate } from './hooks'

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    border: '1px solid black',
    borderRadius: '3px',
    padding: '2rem',
    justifyContent: 'center',
    alignContent: 'center'
  },
  headerText: {
    fontSize: '2rem',
    textAlign: 'center'
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    '& .__button': {
      borderRadius: '2rem',
      border: '1.5px solid #212945',
      backgroundColor: '#FFF',
      textTransform: 'none',
      padding: '0.5rem 1rem',
      maxHeight: '2.5rem'
    }
  }
})

const Authenticate = () => {
  const classes = useStyles()
  const { handleAuthenticate } = useSchwabAuthenticate()
  return (
    <Container maxWidth='md' style={{ paddingTop: '1rem' }}>
      <div className={classes.formContainer}>
        <Grid container>
          <Grid item xs={12}>
            <h3 className={classes.headerText}>Schwab API</h3>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.action}>
              <Button className={classes.__button} onClick={handleAuthenticate}>Authenticate</Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default Authenticate
