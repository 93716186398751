import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import get from 'lodash/get'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& thead tr': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.default,
      color: ({ lowlighted }) => lowlighted ? theme.palette.gray.darker : undefined
    },
    '& thead th:first-child': {
      paddingLeft: theme.layout.padding.thin
    },
    '& thead th': {
      textAlign: 'left'
    },
    '& thead th.__right': {
      textAlign: 'right'
    },
    '& tr td': {
      color: ({ lowlighted }) => lowlighted ? theme.palette.gray.darker : undefined,
      fontWeight: theme.typography.weights.light,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& tr td:first-child': {
      paddingLeft: theme.layout.padding.thin
    },
    '& tr td.__highlight': {
      fontWeight: theme.typography.weights.medium
    },
    '& tbody tr.__selected td': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '& tbody tr td.__right': {
      textAlign: 'right'
    }
  }
}))
function MiniTable ({ data, columns, onSelect, selected, keyAccessor, lowlighted }) {
  const classes = useStyles({ lowlighted })
  const { formatter } = useFormattingContext()

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {(columns || []).map(col => (
            <th
              key={`col_${col.accessor}`}
              className={clsx({ __right: col.align === 'right' })}
            >
              {col.description ? (
                <Tooltip title={col.description}>
                  <div>{col.header}</div>
                </Tooltip>
              ) : (col.header)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data || []).map(item => (
          <tr
            onClick={() => onSelect(item)} key={keyAccessor(item)}
            className={clsx({ __selected: keyAccessor(selected) === keyAccessor(item) })}
          >
            {(columns || []).map(col => {
              const formattedValue = formatter(get(item, col.accessor), col.format, col.formatOptions)
              const displayValue = col.maxLength && formattedValue?.toString()?.length > col.maxLength
                ? `${formattedValue?.toString()?.slice(0, col.maxLength)}...`
                : formattedValue
              return (
                <td
                  key={`row_${item.clientId}_col_${col.accessor}`}
                  className={clsx({ __right: col.align === 'right' })}
                  title={formattedValue}
                >
                  {col.link ? (
                    <Link to={col.link(item)}>{displayValue}</Link>
                  ) : (displayValue)}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

MiniTable.propTypes = {
  lowlighted: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string,
    header: PropTypes.string,
    format: PropTypes.string,
    align: PropTypes.string,
    link: PropTypes.func
  })),
  onSelect: PropTypes.func,
  selected: PropTypes.object,
  keyAccessor: PropTypes.func
}

export default MiniTable
