import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import SydLinkButton from '../../../../commonDesign/LinkButton'
import Card from '../../../../molecules/Card'
import { useGetIntegrationTableMetadata } from '../../../../../api/integrations'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

function JobTableInfo ({ jobConfigurationId }) {
  const { data } = useGetIntegrationTableMetadata(jobConfigurationId)

  if (!data?.tableSchema) return null

  return (
    <Grid item xs={12}>
      <FadeIn>
        <Card>
          <h3>Table Info: {data?.tableSchema?.tableName}</h3>
          <div>
            <SydLinkButton variant='primary' to={`/admin/denali/integrator/jobs/${jobConfigurationId}/query`} icon='search'>Query</SydLinkButton>
          </div>
        </Card>
      </FadeIn>
    </Grid>
  )
}

JobTableInfo.propTypes = {
  jobConfigurationId: PropTypes.number
}

export default JobTableInfo
