import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import BasicTable from '../../../../atoms/BasicTable'

function JobPrimaryKeys ({ job }) {
  const primaryKeys = useMemo(() => {
    if (!job.configuration?.primaryKey) return null
    if (Array.isArray(job.configuration.primaryKey)) {
      return job.configuration.primaryKey
    }
    return [job.configuration.primaryKey]
  }, [job])

  if (!primaryKeys?.length) {
    return (
      <div>
        No Keys Defined
      </div>
    )
  }

  return (
    <div>
      <BasicTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {primaryKeys.map(key => (
            <tr key={`primary_key_${key.name}`}>
              <td>{key.name}</td>
              <td>{key.type}</td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </div>
  )
}

JobPrimaryKeys.propTypes = {
  job: PropTypes.shape({
    configuration: PropTypes.shape({
      primaryKey: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    })
  })
}

export default JobPrimaryKeys
