import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g5
  },
  item: {
    borderRadius: theme.layout.radius.loose,
    outlineStyle: 'solid',
    outlineWidth: '1px',
    outlineColor: theme.palette.primary.light,
    paddingTop: theme.layout.padding.p5,
    paddingRight: theme.layout.padding.p10,
    paddingBottom: theme.layout.padding.p5,
    paddingLeft: theme.layout.padding.p10,

    transition: 'all .2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 25%, transparent)`
    },

    '&.__selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      outlineColor: theme.palette.primary.main,
      outlineWidth: '2px'
    }
  }
}))

function DomainSelect ({ domains, selected, onSelect, processing }) {
  const classes = useStyles()
  const handleSelect = useCallback((v) => {
    if (processing) return

    onSelect(v)
  }, [onSelect, processing])

  useEffect(() => {
    if (!selected && domains.length) {
      handleSelect(domains.at(0).domain)
    }
  }, [domains, selected, handleSelect])

  return (
    <div className={classes.selectContainer}>
      {(domains || []).map((domain) => (
        <div key={domain.domain} className={clsx(classes.item, { __selected: selected === domain.domain })} onClick={() => handleSelect(domain.domain)}>
          {domain.name}
        </div>
      ))}
    </div>
  )
}

DomainSelect.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape({
    domain: PropTypes.string,
    name: PropTypes.string
  })),
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  processing: PropTypes.bool
}

export default DomainSelect
