import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import InfographicNavigation from '../components/InfographicNavigation'
import SydButton from '../../../../commonDesign/Button'
import SydModal from '../../../../commonDesign/SydModal'
import InfographicTemplatesTable from './components/InfographicTemplatesTable'
import { CreateInfographicTemplateForm } from './components/CreateInfographicTemplateForm'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }
}))

const InfographicTemplatesBuilderView = () => {
  const classes = useStyles()
  const [showCreateModal, setShowCreateModal] = useState(false)

  return (
    <div className={classes.container}>
      <InfographicNavigation />
      <Box display='flex' justifyContent='flex-end'>
        <SydButton onClick={() => setShowCreateModal(true)} icon='add' variant='primary'>
          Create Infographic Template
        </SydButton>
      </Box>

      <Box mt={2}>
        <InfographicTemplatesTable />
      </Box>

      <SydModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        title='Create Infographic Template'
      >
        <Box>
          <CreateInfographicTemplateForm onCancel={() => setShowCreateModal(false)} />
        </Box>
      </SydModal>
    </div>
  )
}

export default InfographicTemplatesBuilderView
