export function substituteValues (obj, substitutionValues) {
  if (Array.isArray(obj)) {
    return obj.map(element => substituteValues(element, substitutionValues))
  } else if (typeof obj === 'object' && obj !== null) {
    const substitutedObject = {}
    for (const key in obj) {
      substitutedObject[key] = substituteValues(obj[key], substitutionValues)
    }
    return substitutedObject
  } else if (typeof obj === 'string' && obj.startsWith('$')) {
    const path = obj.split('.').slice(1)
    let currentValue = substitutionValues
    for (const segment of path) {
      if (currentValue && typeof currentValue === 'object') {
        currentValue = currentValue[segment]
      } else {
        return obj // Return original string if path is invalid
      }
    }
    return currentValue
  }
  return obj
}
