import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import CreateConnectionForm from './form'

const CreateConnectionDialog = React.forwardRef(function CreateConnectionDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: () => setDialogState({ open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Create Integration Connection'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<CreateConnectionForm onComplete={handleComplete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

CreateConnectionDialog.propTypes = {
  onComplete: PropTypes.func
}

CreateConnectionDialog.defaultProps = {
  onComplete: () => {}
}

export default CreateConnectionDialog
