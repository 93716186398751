import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../../../molecules/RoundedModal'
import ErrorBoundary from '../../../../../../../molecules/ErrorBoundary'
import AddFilterForm from './form'

const AddFilterDialog = React.forwardRef(function AddFilterDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (report) => {
      setDialogState({ open: true, processing: false, report })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Filter'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <ErrorBoundary name='Add Filter'>
            <AddFilterForm
              report={dialogState.report}
              onComplete={handleComplete}
              onCancel={close}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    </RoundedModal>
  )
})

AddFilterDialog.propTypes = {
  onComplete: PropTypes.func
}

AddFilterDialog.defaultProps = {
  onComplete: () => {}
}

export default AddFilterDialog
