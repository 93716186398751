import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { useCreateIntegrationJob } from '../../../../../../api/integrations'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { decipherError } from '../../../../../../utils/decipherError'

const useSubmitter = (form, onComplete, source) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: createIntegrationJob } = useCreateIntegrationJob()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      integrationSourceId: source.integrationSourceId,
      jobKey: formData.jobKey || null,
      description: formData.description || null
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await createIntegrationJob(command)
      if (result?.statusCode === 500) {
        setError('Something unexpected happened while creating the integration job')
        return
      }
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, createIntegrationJob, source])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function CreateJobForm ({ source, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      jobKey: '',
      description: ''
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, source)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>{source.domain} / {source.sourceKey}</h3>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='jobKey'
          control={form.control}
          rules={{
            required: { value: true, message: 'Required.' },
            maxLength: { value: 64, message: 'Max 64 Characters.' }
          }}
          render={(f) => (
            <SydLabel label='Job Key / Name' description='' error={hookFormErrorAdapter(f, f.fieldState)} required>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='description'
          control={form.control}
          rules={{
            maxLength: { value: 256, message: 'Max 256 Characters.' }
          }}
          render={(f) => (
            <SydLabel label='Description' description='' error={hookFormErrorAdapter(f, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? <div className='__error'>{error}</div> : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
            disabled={!form.formState.isValid}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

CreateJobForm.propTypes = {
  source: PropTypes.shape({
    sourceKey: PropTypes.string,
    domain: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateJobForm
