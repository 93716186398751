import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { dataCatalog } from '../../../../../api/coreData'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import QueryTableCell from '../../shared/QueryTableCell'

const columnsConfig = {
  columns: [
    { id: 'eventLogId', accessor: 'eventLogId', Header: 'ID' },
    { id: 'eventDate', accessor: 'eventDate', Header: 'Event Date', format: 'timestamp' },
    { id: 'eventType', accessor: 'eventType', Header: 'Event Type' },
    { id: 'message', accessor: 'message', Header: 'Message' },
    { id: 'severity', accessor: 'severity', Header: 'Level' },
    { id: 'data', accessor: 'data', Header: 'Data', Cell: QueryTableCell }
  ],
  defaultSort: [{
    id: 'eventDate', desc: true
  }]
}

function RecentLogs ({ targetType, targetIdentifier }) {
  const query = useMemo(() => {
    return {
      filters: {
        targetType,
        targetIdentifier
      },
      includes: { actorName: true },
      skip: 0,
      take: 100
    }
  }, [targetType, targetIdentifier])
  const { data, isLoading } = dataCatalog.useGetCatalogEventLogs(query)
  const _columns = useConfigColumns({
    columnsConfig
  })

  return (
    <OperationalTable
      variant='v2'
      data={data?.logs ?? []}
      isLoading={isLoading}
      itemName='Logs'
      columns={_columns.columns}
      defaultSort={_columns.defaultSort}
      hideFooter
    />
  )
}

RecentLogs.propTypes = {
  targetType: PropTypes.string,
  targetIdentifier: PropTypes.string
}

export default RecentLogs
