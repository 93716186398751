export const presetMonthOptions = [
  1,
  3,
  6,
  12,
  36
]

export const defaultExportColumns = [
  {
    key: 'accountId',
    label: 'Account ID'
  },
  {
    key: 'accountNumber',
    label: 'Account Number'
  },
  {
    key: 'accountLongName',
    label: 'Account Long Name'
  },
  {
    key: 'accountName',
    label: 'Account Name'
  },
  {
    key: 'dataSourceId',
    label: 'Datasource ID',
    internalOnly: true
  },
  {
    key: 'dataSourceName',
    label: 'Datasource'
  },
  {
    key: 'assetId',
    label: 'Asset ID'
  },
  {
    key: 'symbol',
    label: 'Symbol'
  },
  {
    key: 'cusip',
    label: 'CUSIP'
  },
  {
    key: 'assetIdentifier',
    label: 'Asset Identifier'
  },
  {
    key: 'assetName',
    label: 'Asset Name'
  },
  {
    key: 'assetLongName',
    label: 'Asset Long Name'
  },
  {
    key: 'estimatedPrice',
    label: 'Estimated Price'
  },
  {
    key: 'valueDate',
    label: 'maxDate',
    formatString: '@MM/DD/YY'
  },
  {
    key: 'minDate',
    label: 'Min Date',
    formatString: '@MM/DD/YY',
    internalOnly: true
  },
  {
    key: 'maxStatusId',
    label: 'Max Status ID',
    internalOnly: true
  },
  {
    key: 'maxDate',
    label: 'Max Date',
    formatString: '@MM/DD/YY',
    internalOnly: true
  },
  {
    key: 'firstDataAvailable',
    label: 'First Data Available',
    formatString: '@MM/DD/YY'
  },
  {
    key: 'lastDataAvailable',
    label: 'Last Data Available',
    formatString: '@MM/DD/YY',
    internalOnly: true
  },
  {
    key: 'closeDate',
    label: 'Close Date',
    formatString: '@MM/DD/YY'
  },
  {
    key: 'maxPerformanceStatusId',
    label: 'Max Performance Status ID',
    internalOnly: true
  },
  {
    key: 'minPerformanceStartDate',
    label: 'Performance Start Date',
    formatString: '@MM/DD/YY'
  },
  {
    key: 'maxPerformanceCloseDate',
    label: 'Performance Close Date',
    formatString: '@MM/DD/YY',
    internalOnly: true
  },
  {
    key: 'startDateValue',
    label: 'Start Date Value'
  },
  {
    key: 'previousValue',
    label: 'Previous Value'
  },
  {
    key: 'netAdditions',
    label: 'Net Additions GOF'
  },
  {
    key: 'netAdditionsNOF',
    label: 'Net Additions NOF'
  },
  {
    key: 'endingValue',
    label: 'Ending Value'
  },
  {
    key: 'previousAccrual',
    label: 'Previous Accrual'
  },
  {
    key: 'accrual',
    label: 'Accrual'
  },
  {
    key: 'previousUnits',
    label: 'Previous Units'
  },
  {
    key: 'units',
    label: 'Units'
  },
  {
    key: 'additions',
    label: 'Additions'
  },
  {
    key: 'withdrawals',
    label: 'Withdrawals'
  },
  {
    key: 'fee',
    label: 'Fee'
  },
  {
    key: 'income',
    label: 'Income'
  },
  {
    key: 'returnValue',
    label: 'Return GOF'
  },
  {
    key: 'returnValueNOF',
    label: 'Return NOF'
  },
  {
    key: 'periodDayCount',
    label: 'Period Day Count'
  },
  {
    key: 'heldEntirePeriod',
    label: 'Held Entire Period'
  },
  {
    key: 'cumulativeNetAdditions',
    label: 'Cumulative Net Additions',
    internalOnly: true
  },
  {
    key: 'adjustedNetAdditions',
    label: 'Adjusted Net Additions',
    internalOnly: true
  },
  {
    key: 'audit',
    label: 'Audit',
    internalOnly: true
  },
  {
    key: 'cumulativeReturn',
    label: 'Cumulative Return GOF'
  },
  {
    key: 'cumulativeReturnNOF',
    label: 'Cumulative Return NOF'
  },
  {
    key: 'annualizedReturn',
    label: 'Annualized Return GOF'
  },
  {
    key: 'annualizedReturnNOF',
    label: 'Annualized Return NOF'
  }
]
