import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../../../molecules/RoundedModal'
import RemoveFilterForm from './form'

const RemoveFilterDialog = React.forwardRef(function RemoveFilterDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (report, filter) => {
      setDialogState({ open: true, processing: false, report, filter })
    }
  }), [setDialogState])

  return (
    <RoundedModal
      title='Delete Denali View Filter'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <RemoveFilterForm
            report={dialogState.report}
            filter={dialogState.filter}
            onComplete={handleComplete}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

RemoveFilterDialog.propTypes = {
  onComplete: PropTypes.func
}

RemoveFilterDialog.defaultProps = {
  onComplete: () => {}
}

export default RemoveFilterDialog
