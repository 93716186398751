import React, { useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import FlexRow from '../../../../../../molecules/FlexRow'
import SydButton from '../../../../../../commonDesign/Button'
import { usePipelineEditor } from '../PipelineEditorContext'
import ErrorDisplay from '../../../../../../molecules/ErrorDisplay'
import SydActionMenu from '../../../../../../commonDesign/SydActionMenu'
import { dataPipelines } from '../../../../../../../api/coreData'

const useOtherActions = ({ pipelineId }) => {
  const { mutateAsync: addToDataCatalog } = dataPipelines.useAddDataPipelineToDataCatalog()
  const handleAddToDataCatalog = useCallback(async () => {
    return await addToDataCatalog({ pipelineId })
  }, [pipelineId, addToDataCatalog])

  return useMemo(() => {
    return [
      { key: 'add-to-data-catalog', label: 'Add To Data Catalog', icon: 'add', onClick: handleAddToDataCatalog, disabled: !pipelineId }
    ]
  }, [handleAddToDataCatalog, pipelineId])
}

function PanelActions () {
  const { handleCreate, requestValidation, responseError, processing, submitError, responseFetching, pipelineId } = usePipelineEditor()
  const isInvalid = useMemo(() => (responseFetching || (!requestValidation?.isValid && !responseError)), [responseFetching, requestValidation, responseError])
  const otherActions = useOtherActions({ isInvalid, pipelineId })

  return (
    <Grid container spacing={1}>
      {submitError ? (
        <Grid item xs={12}>
          <ErrorDisplay error={submitError} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FlexRow>
          <SydButton
            style={{ flex: 1 }}
            variant='primary'
            size='sm'
            disabled={isInvalid}
            onClick={handleCreate}
            icon={isInvalid ? 'close' : 'check'}
            processing={processing}
          >
            Save
          </SydButton>
          <SydActionMenu actions={otherActions} size='20' />
        </FlexRow>
      </Grid>
    </Grid>
  )
}

export default PanelActions
