import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import NullValue from '../../../molecules/NullValue'

const missing = <>&lt;missing&gt;</>

function QueryTableCell ({ value }) {
  if (value === undefined) {
    return <NullValue value={missing} />
  }
  if (value === null) {
    return <NullValue />
  }
  if (typeof value === 'object') {
    return (
      <Tooltip title={<pre>{JSON.stringify(value, null, 2)}</pre>}>
        <span>...</span>
      </Tooltip>
    )
  }

  return <div>{value}</div>
}

QueryTableCell.propTypes = {
  value: PropTypes.any
}

export default QueryTableCell
