import React from 'react'
import { makeStyles } from '@material-ui/core'
import ErrorBoundary from '../../../../../../molecules/ErrorBoundary'
import ResultPanelHeader from './ResultPanelHeader'
import ResultPanelBody from './ResultPanelBody'
import FieldInfo from './FieldInfo'

const useStyles = makeStyles((theme) => ({
  resultPanel: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.main,
    borderTop: `1px solid ${theme.palette.gray.dark}`,
    display: 'grid',
    gridTemplateColumns: '400px 1fr',
    gridTemplateRows: '40px 1fr',
    gap: '0px 0px',
    gridTemplateAreas: '"result-header result-header" "field-info result-body"',
    '& .__result-header': {
      gridArea: 'result-header'
    },
    '& .__result-body': {
      gridArea: 'result-body',
      overflow: 'scroll',
      height: '100%',
      width: '100%'
    },
    '& .__field-info': {
      gridArea: 'field-info',
      overflow: 'scroll',
      height: '100%',
      width: '100%'
    }
  }
}))

function Index () {
  const classes = useStyles()

  return (
    <div className={classes.resultPanel}>
      <ErrorBoundary name='Results'>
        <div className='__result-header'>
          <ResultPanelHeader />
        </div>
        <div className='__field-info'>
          <FieldInfo />
        </div>
        <div className='__result-body'>
          <ResultPanelBody />
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default Index
