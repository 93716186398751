import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs, { Tab } from '../../molecules/Tabs'
import RightPanel from '../RightPanel'
import { useComments } from './CommentContext'
import CommentPanelBody from './CommentPanelBody'
import AttachmentPanelBody from './AttachmentPanelBody'
import Title from './Title'

const useStyles = makeStyles((theme) => ({
  panel: {
    position: 'relative',
    height: '100%',
    '& .__title': {
      padding: '20px 1.5rem',
      fontSize: '14px'
    }
  }
}))

function CommentPanel () {
  const classes = useStyles()
  const {
    open,
    title,
    toggleOpen,
    threadKey,
    finishCreatingThread,
    threadDetails,
    tab,
    setTab,
    disabled
  } = useComments()

  const { data, isLoading } = threadDetails

  return (
    <RightPanel
      open={open === 'comments'}
      title='Comments'
      toggleOpen={toggleOpen}
      width='600px'
      height='100%'
      showCloseButton
      titleClassName='__large'
    >
      <div className={classes.panel}>
        <Title />
        <Tabs.Wrapper noMargin>
          <Tabs
            value={tab}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            style={{ width: '100%' }}
            onChange={(_, value) => setTab(value)}
          >
            <Tab value='comments' label='Comments' />
            <Tab value='attachments' label='Documents' />
          </Tabs>
        </Tabs.Wrapper>
        {(tab === 'comments') ? (
          <CommentPanelBody
            title={title}
            threadKey={threadKey}
            data={data}
            loading={isLoading}
            onSave={finishCreatingThread}
            onCancel={toggleOpen}
            disabled={disabled}
          />
        ) : null}
        {(tab === 'attachments') ? (
          <AttachmentPanelBody
            title={title}
            disabled={disabled}
          />
        ) : null}
      </div>
    </RightPanel>
  )
}

export default CommentPanel
