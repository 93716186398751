import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../shared/SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import { useSectionEditing, useAssetDetails } from '../AssetProvider'
import { useGetGroupedCoreData, useLevelDates } from '../../../../../api/coreData'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'
import { compareNumericString } from '../../../../../utils'
import Loading from '../../../../molecules/Loading'
import { ADMIN_ROUTES } from '../../../../../constants'

const levelDatesQuery = { levelType: 'user' }
const LinkCell = (cell) => <Link to={`${ADMIN_ROUTES.ACCOUNTS}/${cell.row.original.accountId}`}>{cell.value}</Link>

const defaultColumnConfig = {
  columns: [
    { Header: 'Account ID', accessor: 'accountId', id: 'accountId', sortType: compareNumericString, Cell: LinkCell },
    { Header: 'Account Number', accessor: 'accountNumber', id: 'accountNumber', Cell: LinkCell },
    { Header: 'Account Name', accessor: 'accountName', id: 'accountName', Cell: LinkCell },
    { Header: 'Data Source', accessor: 'dataSourceName', id: 'dataSourceName' },
    { Header: 'Ending Value', accessor: 'endingValue', id: 'endingValue', format: '(0,0.00)', sortType: compareNumericString, alignRight: true },
    { Header: 'Quantity', accessor: 'units', id: 'units', format: 'number', sortType: compareNumericString, alignRight: true }
  ],
  defaultSort: [
    { id: 'accountName', desc: false }
  ]
}

function PositionsTab () {
  const { editing, asset } = useAssetDetails()
  const sectionIsEditing = useSectionEditing('positions')
  const { formatter } = useFormattingContext()
  const { data: userLevelDates = {}, isLoading: levelDatesLoading, isSuccess: levelDatesSuccess } = useLevelDates(levelDatesQuery)
  const query = useMemo(() => ({
    levelFilters: {
      assetIds: [asset.assetId],
      calcType: 'balance',
      levelTypes: [
        'asset', 'account'
      ],
      additionalData: {
        dataSources: ['name']
      }
    },
    dateRange: {
      startDate: dayjs.utc(userLevelDates.max).format('YYYY-MM-DD'),
      endDate: dayjs.utc(userLevelDates.max).format('YYYY-MM-DD')
    }
  }), [asset, userLevelDates])

  const { data, isLoading } = useGetGroupedCoreData(query, { enabled: levelDatesSuccess })
  const columnConfig = useConfigColumns({ columnsConfig: defaultColumnConfig })

  if (levelDatesLoading) {
    return (
      <Loading />
    )
  }

  return (
    <FadeIn>
      <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
        <SectionHeader text={`Account Positions as of ${formatter(userLevelDates?.max, 'date')}`} />
        <OperationalTable.Wrapper>
          <OperationalTable
            mode='client'
            variant='v2'
            data={data || []}
            isLoading={isLoading || levelDatesLoading}
            columns={columnConfig.columns}
            defaultSort={defaultColumnConfig.defaultSort}
            total={data?.length}
            itemName='Accounts'
          />
        </OperationalTable.Wrapper>
      </SectionScreen>
      <PersonalSpace />
    </FadeIn>
  )
}

PositionsTab.propTypes = {
}

export default PositionsTab
