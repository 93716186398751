import clsx from 'clsx'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydButton from '../../../../../commonDesign/Button'
import Icon from '../../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  templateContentPageNavigation: {
    display: 'flex',
    flex: '1 0',
    width: 'fit-content',
    gap: '1rem',
    flexDirection: 'column',
    padding: '1rem 0',
    margin: '0 -1px 0 0',
    alignItems: 'flex-end'
  },
  pageButtonContainer: {
    position: 'relative'
  },
  pageButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingRight: '1em',
    borderRight: '0px',
    minWidth: '6.5rem',
    transition: 'all 0.2s ease-in-out',
    '&.active': {
      fontWeight: '700',
      paddingLeft: '3rem',
      paddingRight: '2.5rem'
    }
  },
  pageDeleteButton: {
    position: 'absolute',
    width: '1.5em',
    minWidth: '1.6rem',
    height: '1.6rem',
    padding: 0,
    top: '-.75rem',
    left: '-.75rem',
    background: theme.palette.error.light,
    '& svg': {
      width: '.7em',
      height: '.85em',
      color: theme.palette.error.contrastText
    }
  }
}))

export const TemplatePageNavigation =
  ({
    templateContent,
    onSetContent,
    currentPageIndex,
    onSetCurrentPageIndex
  }) => {
    const classes = useStyles()

    const appendNewPage = () => {
      const newValues = {
        ...templateContent,
        pages: [...templateContent.pages, { blocks: [] }]
      }
      onSetContent(newValues)
      onSetCurrentPageIndex(newValues?.pages?.length - 1)
    }

    const deletePage = (index) => {
      const newValues = {
        ...templateContent,
        pages: templateContent.pages.filter((_, i) => i !== index)
      }
      onSetContent(newValues)
      if (currentPageIndex === index) {
        onSetCurrentPageIndex(0)
      }
    }

    return (
      <div className={classes.templateContentPageNavigation}>
        {Array.from({ length: templateContent.pages?.length || 0 }).map((_, i) => (
          <div
            key={i}
            className={classes.pageButtonContainer}
          >
            <SydButton
              type='button'
              onClick={() => onSetCurrentPageIndex(i)}
              className={clsx(
                classes.pageButton,
                currentPageIndex === i ? 'active' : ''
              )}
              variant={currentPageIndex === i ? 'primary' : 'outline'}
            >
              Page {i + 1}
            </SydButton>
            {!templateContent.pages[i]?.blocks?.length && (
              <SydButton
                size='xs'
                type='button'
                priority='warning'
                className={classes.pageDeleteButton}
                onClick={() => deletePage(i)}
              >
                <Icon name='delete' customSize={24} />
              </SydButton>
            )}
          </div>
        ))}
        <SydButton
          type='button'
          variant='secondary'
          size='sm'
          className={classes.pageButton}
          onClick={appendNewPage}
          icon='add'
        >
          Page
        </SydButton>
      </div>
    )
  }

TemplatePageNavigation.propTypes = {
  templateContent: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.object)
  }),
  currentPageIndex: PropTypes.number,
  onSetCurrentPageIndex: PropTypes.func,
  onSetContent: PropTypes.func
}
