import React from 'react'
import PropTypes from 'prop-types'
import SydSelect from '../../../../../../commonDesign/SydSelect'

const options = [
  { label: 'account', value: 'account' },
  { label: 'client', value: 'client' },
  { label: 'asset', value: 'asset' }
]

function ScopePicker ({ value, onChange }) {
  return (
    <SydSelect options={options} value={value} onChange={(e) => onChange(e.target.value)} />
  )
}

ScopePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default ScopePicker
