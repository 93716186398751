import { alpha, makeStyles } from '@material-ui/core'

export const useTableStyles = makeStyles((theme) => ({
  '@keyframes tableCellOpen': {
    '0%': {
      opacity: 0,
      transform: 'scale(.9, 1)',
      transformOrigin: 'left'
    },
    '1%': {
      opacity: 0
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1, 1)',
      transformOrigin: 'left'
    }
  },
  v1: {
    minWidth: '800px',
    width: '100%',
    tableLayout: 'auto',
    borderCollapse: 'collapse',
    '& th, td': {
      textAlign: 'left',
      padding: '8px 6px',
      fontWeight: '400',
      transition: 'background-color .2s ease-in-out 0s'
    },
    '& > thead > tr > th': {
      backgroundColor: theme.palette.gray.main,
      fontWeight: '800',
      padding: '16px 6px',
      userSelect: 'none'
    },
    '& > thead > tr > th.__right .__thContainer': {
      justifyContent: 'flex-end',
      textAlign: 'right'
    },
    '& > thead': {
      zIndex: 2,
      position: 'sticky',
      top: ({ superHeaderRect, hasHorizontalOverflow }) => {
        if (hasHorizontalOverflow) {
          return 'initial'
        }
        return superHeaderRect ? superHeaderRect.height : 0
      },
      boxShadow: '0px 10px 6px -6px rgba(24, 27, 53, 0.06)'
    },
    '& > tbody > tr.__child': {
      animation: '$tableCellOpen .5s ease-in-out'
    },
    '& > tbody > tr.__child > td': {
      backgroundColor: theme.palette.gray.light
    },
    '& > tbody > tr:hover > td': {
      backgroundColor: theme.palette.gray.main,
      transition: 'background-color .5s ease-in-out .2s',
      cursor: ({ hasOnRowClick }) => hasOnRowClick ? 'pointer' : 'auto'
    },
    '& > tbody > tr.__child:hover > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color .5s ease-in-out .2s'
    },
    '& > tbody > tr.__child.__depth_1 > td:first-child': {
      paddingLeft: '20px'
    },
    '& > tbody > tr.__child.__depth_2 > td:first-child': {
      paddingLeft: '40px'
    },
    '& > tbody > tr.__child.__depth_3 > td:first-child': {
      paddingLeft: '60px'
    },
    '& > tbody > tr.__expandable:active > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color 0s ease-in-out 0s'
    },
    '& > tbody > tr > td.__right': {
      textAlign: 'right'
    },
    '& .__thContainer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .__sort-icon': {
      height: '15px',
      padding: '0 2px'
    },
    '& .__expand-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *:first-child': {
        margin: '0 4px 0 0'
      }
    },
    '& .__expand-handle': {
      animation: '$fadey .25s ease-in-out'
    }
  },
  v2: {
    minWidth: '800px',
    width: 'calc(100% - 3px)',
    margin: '0 1px',
    tableLayout: 'auto',
    fontSize: '13px',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th, td': {
      textAlign: 'left',
      padding: '12px 18px 6px 18px',
      fontWeight: '400',
      transition: 'background-color 0.1s ease 0s'
    },
    '& > thead > tr > th': {
      border: `1px solid ${theme.palette.gray.dark}`,
      borderLeft: '0px',
      fontWeight: '800',
      padding: '12px 18px',
      userSelect: 'none'
    },
    '& > thead > tr > th:first-child': {
      borderLeft: `1px solid ${theme.palette.gray.dark}`,
      borderBottomLeftRadius: '12px',
      borderTopLeftRadius: '12px'
    },
    '& > thead > tr > th:last-child': {
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px'
    },
    '& > thead > tr > th.__right .__thContainer': {
      justifyContent: 'flex-end',
      textAlign: 'right'
    },
    '& > thead': {
      zIndex: 2,
      position: 'sticky',
      backgroundColor: theme.palette.gray.light,
      borderRadius: '8px',
      boxShadow: `0px 6px 6px -3px ${theme.palette.tableShadow.main}`,
      top: ({ superHeaderRect, hasHorizontalOverflow }) => {
        if (hasHorizontalOverflow) {
          return 'initial'
        }
        return superHeaderRect ? superHeaderRect.height + 1 : 1
      }
    },
    '& > tbody > tr > td': {
      borderBottom: `1px solid ${theme.palette.gray.lighter}`,
      backgroundColor: theme.palette.background.default
    },
    '& > tbody > tr.__child': {
      animation: '$tableCellOpen .5s ease-in-out'
    },
    '& > tbody > tr.__child > td': {
      backgroundColor: theme.palette.gray.light
    },
    '& > tbody > tr:hover > td': {
      backgroundColor: theme.palette.gray.main,
      transition: 'background-color .5s ease-in-out .2s',
      cursor: ({ hasOnRowClick }) => hasOnRowClick ? 'pointer' : 'auto'
    },
    '& > tbody > tr.__child:hover > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color .5s ease-in-out .2s'
    },
    '& > tbody > tr.__child.__depth_1 > td:first-child': {
      paddingLeft: '20px'
    },
    '& > tbody > tr.__child.__depth_2 > td:first-child': {
      paddingLeft: '40px'
    },
    '& > tbody > tr.__child.__depth_3 > td:first-child': {
      paddingLeft: '60px'
    },
    '& > tbody > tr.__expandable:active > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color 0s ease-in-out 0s'
    },
    '& > tbody > tr > td.__right': {
      textAlign: 'right'
    },
    '& .__thContainer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .__sort-icon': {
      height: '15px',
      padding: '0 2px'
    },
    '& .__expand-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *:first-child': {
        margin: '0 4px 0 0'
      }
    },
    '& .__expand-handle': {
      animation: '$fadey .25s ease-in-out'
    }
  },

  blank: {
    minWidth: '800px',
    width: 'calc(100% - 3px)',
    margin: '0 1px',
    tableLayout: 'auto',
    fontSize: '13px',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th, td': {
      textAlign: 'left',
      padding: '12px 18px 6px 18px',
      fontWeight: '400',
      transition: 'background-color 0.1s ease 0s'
    },
    '& > thead > tr > th': {
      border: 'none',
      fontWeight: '800',
      padding: '12px 18px',
      userSelect: 'none'
    },
    '& > thead > tr > th.__right .__thContainer': {
      justifyContent: 'flex-end',
      textAlign: 'right'
    },
    '& > thead': {
      zIndex: 2,
      position: 'sticky',
      backgroundColor: theme.palette.background.default,
      top: ({ superHeaderRect, hasHorizontalOverflow }) => {
        if (hasHorizontalOverflow) {
          return 'initial'
        }
        return superHeaderRect ? superHeaderRect.height : 0
      }
    },
    '& > tbody > tr > td': {
    },
    '& > tbody > tr.__child': {
      animation: '$tableCellOpen .5s ease-in-out'
    },
    '& > tbody > tr.__child > td': {
      backgroundColor: theme.palette.gray.light
    },
    '& > tbody > tr:hover > td': {
      backgroundColor: theme.palette.gray.main,
      transition: 'background-color .5s ease-in-out .2s',
      cursor: ({ hasOnRowClick }) => hasOnRowClick ? 'pointer' : 'auto'
    },
    '& > tbody > tr.__child:hover > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color .5s ease-in-out .2s'
    },
    '& > tbody > tr.__child.__depth_1 > td:first-child': {
      paddingLeft: '20px'
    },
    '& > tbody > tr.__child.__depth_2 > td:first-child': {
      paddingLeft: '40px'
    },
    '& > tbody > tr.__child.__depth_3 > td:first-child': {
      paddingLeft: '60px'
    },
    '& > tbody > tr.__expandable:active > td': {
      backgroundColor: theme.palette.gray.dark,
      transition: 'background-color 0s ease-in-out 0s'
    },
    '& > tbody > tr > td.__right': {
      textAlign: 'right'
    },
    '& .__thContainer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .__sort-icon': {
      height: '15px',
      padding: '0 2px'
    },
    '& .__expand-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *:first-child': {
        margin: '0 4px 0 0'
      }
    },
    '& .__expand-handle': {
      animation: '$fadey .25s ease-in-out'
    }
  },

  wrapper: {
    overflowX: ({ hasHorizontalOverflow }) => hasHorizontalOverflow
      ? 'auto' : 'unset'
  },
  headspace: {
    height: '1rem',
    backgroundColor: theme.palette.white,
    display: 'block',
    width: '100%',
    position: 'sticky',
    top: '-1rem'
  },
  footer: ({ hasHorizontalOverflow }) => ({
    userSelect: 'none',
    height: '1rem',
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'sticky',
    bottom: '0rem',
    padding: '30px 6px',
    boxShadow: '0px -9px 6px -6px rgba(24, 27, 53, 0.06)',
    ...(hasHorizontalOverflow ? { right: 0, left: 0 } : {})
  }),
  spark: {
    padding: ({ variant }) => {
      if (variant === 'v2') return '0 9px'
    },
    backgroundColor: theme.palette.white,
    display: 'block',
    position: ({ hasHorizontalOverflow }) => hasHorizontalOverflow
      ? 'initial'
      : 'sticky',
    height: '2px',
    top: ({ superHeaderRect, hasHorizontalOverflow, variant }) => {
      if (hasHorizontalOverflow) {
        return 'initial'
      }
      return superHeaderRect
        ? `calc(${superHeaderRect.height}px - ${variant === 'v2' ? 0 : 2}px)`
        : `calc(0 - ${variant === 'v2' ? 0 : 2}px)`
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.gray.main,
      background: `linear-gradient(90deg, transparent 0%, ${theme.palette.tableShadow.main} 50%, transparent 100%)`
    },
    '& .MuiLinearProgress-barColorPrimary': {
      background: `linear-gradient(90deg, ${theme.palette.tableShadow.main} 0%, ${alpha(theme.palette.primary.main, 0.5)} 50%, ${theme.palette.tableShadow.main} 100%)`
    }
  },
  sparkRoot: {
    opacity: ({ loading }) => loading ? 1 : 0,
    transition: ({ loading }) => loading ? 'opacity .25s ease-in-out .25s' : 'opacity .1s ease-in-out',
    height: '2px'
  }
}))
