import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import CreateJobGroupForm from './form'

const CreateJobGroupDialog = React.forwardRef(function CreateJobGroupDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: () => setDialogState({ open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Create System Job Group'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<CreateJobGroupForm onComplete={handleComplete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

CreateJobGroupDialog.propTypes = {
  onComplete: PropTypes.func
}

CreateJobGroupDialog.defaultProps = {
  onComplete: () => {}
}

export default CreateJobGroupDialog
