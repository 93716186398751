import React from 'react'
import { makeStyles } from '@material-ui/core'
import FlexRow from '../../../../../../molecules/FlexRow'
import SydButton from '../../../../../../commonDesign/Button'
import { usePipelineEditor } from '../PipelineEditorContext'

const useStyles = makeStyles((theme) => ({
  editorHeader: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.gray.main,
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    boxSizing: 'border-box'
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    marginLeft: theme.layout.margin.m5,
    marginRight: theme.layout.margin.m5,
    paddingLeft: theme.layout.padding.p2,
    paddingRight: theme.layout.padding.p2
  },
  actions: {
  }
}))

function EditorHeader () {
  const classes = useStyles()
  const { handleRun, responseFetching } = usePipelineEditor()

  return (
    <div className={classes.editorHeader}>
      <FlexRow className={classes.toolbar}>
        <div />
        <FlexRow className={classes.actions}>
          <SydButton
            size='xs'
            variant='primary'
            onClick={handleRun}
            processing={responseFetching}
          >
            Go
          </SydButton>
        </FlexRow>
      </FlexRow>
    </div>
  )
}

export default EditorHeader
