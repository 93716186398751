import React from 'react'
import PropTypes from 'prop-types'
import { dangerZone } from '../../../../../api/coreData'
import Card from '../../../../molecules/Card'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import SydLinkButton from '../../../../commonDesign/LinkButton'
import InlineCardHeader from '../../../../molecules/Card/InlineCardHeader'

function DenaliReportQueryInfo ({ report }) {
  const { data: tableInfo, isLoading } = dangerZone.__useGetDenaliReportTableInfo(report.reportId)

  if (isLoading) {
    return (
      <Card>
        <InlineCardHeader>Query Info</InlineCardHeader>
        <Loading />
      </Card>
    )
  }

  if (!tableInfo?.tableInfo) {
    return (
      <Card>
        <InlineCardHeader>Query Info</InlineCardHeader>
        <EmptySection title='Underlying table not available' description='' />
      </Card>
    )
  }

  return (
    <Card>
      <InlineCardHeader>Query Info</InlineCardHeader>
      <SydLinkButton icon='search' to={`/admin/denali/analysis/views/${report.reportId}/query`}>Query View</SydLinkButton>
    </Card>
  )
}

DenaliReportQueryInfo.propTypes = {
  report: PropTypes.object.isRequired
}

export default DenaliReportQueryInfo
