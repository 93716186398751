import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../../api/queryKeys'
import { useAppContext } from '../../../redux/slices/appContext'
import { postNamedQuery } from '../../../service'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { defaultColumnConfig } from '../TargetAllocationTable/columnConfig'

const defaultRequestConfiguration = (accountNumber, showAccount) => ({
  accountNumber,
  resultSelector: '$.account',
  accounts: {
    selector: '$.data.attributes',
    resultFieldName: 'account',
    appendTo: '$',
    params: {
      showAccount
    }
  },
  positions: {
    selector: '$.data.attributes.positions',
    resultFieldName: 'positions',
    appendTo: '$.account',
    params: {
      showAccount
    }
  },
  transactions: {
    selector: '$.data',
    resultFieldName: 'transactions',
    appendTo: '$.account',
    params: {
      showAccount
    }
  }
})

export const useSchwabRealtime = ({
  accountNumber,
  useRealtime,
  maskAccount
}) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.schwabRealtime, userId, accountNumber, useRealtime, maskAccount],
    queryFn: async () => {
      const showAccount = maskAccount ? 'Mask' : 'Show'
      const body = !useRealtime
        ? { accountNumber }
        : defaultRequestConfiguration(accountNumber, showAccount)

      const queryName = useRealtime ? 'schwab-real-realtime' : 'schwab-realtime'
      const result = await postNamedQuery('integrator', queryName, body)

      return result.data
    }
  })
}

export const useColumns = (columnConfig = defaultColumnConfig) => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    const {
      columns,
      defaultSort
    } = columnConfig

    const mapColumns = columns => {
      if (!columns) return undefined
      return columns.map(column => {
        const formatString = column.format
        // const type = column.type in typeLookup ? typeLookup[column.type] : {}
        return {
          ...column,
          // ...type,
          type: undefined,
          // eslint-disable-next-line react/prop-types
          Cell: ({ value }) => <div>{formatter(value, formatString)}</div>,
          columns: mapColumns(column.columns)
        }
      })
    }
    const templatedColumns = mapColumns(columns)

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnConfig, formatter])
}
