import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Card from '../../../molecules/Card'
import AdminPage from '../../admin/shared/AdminPage'

function IngestionHome ({ root }) {
  return (
    <AdminPage>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Link to={`${root}/ingestion/system-jobs`}>
            <Card>
              System Jobs
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Link to='/data-home/jobs'>
            <Card>
              Ingestion Jobs
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Link to='/data-home/targets'>
            <Card>
              Ingestion Targets
            </Card>
          </Link>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

IngestionHome.propTypes = {
  root: PropTypes.any
}

export default IngestionHome
