import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Grid, makeStyles } from '@material-ui/core'
import { systemJobs } from '../../../../../api/ingestion'
import Loading from '../../../../molecules/Loading'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorDisplay from '../../../../molecules/ErrorDisplay'
import Icon from '../../../../atoms/Icon'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import CodeTag from '../../../../atoms/CodeTag'
import FlexRow from '../../../../molecules/FlexRow'

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrast,
    padding: theme.layout.padding.p10,
    fontSize: theme.typography.fontSizes.lg
  },
  detailTable: {
    borderLeftStyle: 'solid',
    borderLeftWidth: '2px',
    borderLeftColor: theme.palette.gray.main,
    marginLeft: theme.layout.margin.m20,
    paddingLeft: theme.layout.padding.p10
  }
}))

function JobExecutionDetails ({ job, processDate }) {
  const classes = useStyles()
  const { data, isFetching, error } = systemJobs.useGetSystemJobExecutionSummary(job.jobId, processDate, 1)
  const { formatter } = useFormattingContext()
  const hasNotRun = useMemo(() => {
    const hasProcessDate = (data?.executions || []).some(e => {
      return (dayjs(e.processDate).startOf('day').isSame(dayjs(processDate).startOf('day')))
    })

    return !hasProcessDate
  }, [data, processDate])

  if (isFetching) {
    return (
      <FadeIn key='loading'>
        <Loading />
      </FadeIn>
    )
  }

  if (error) {
    return (
      <FadeIn key='error'>
        <ErrorDisplay error={error} />
      </FadeIn>
    )
  }

  return (
    <FadeIn key='data'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexRow>
            <span>{job.ordinal}.</span>
            <span>{job.name}</span>
            <CodeTag>{job.code}</CodeTag>
          </FlexRow>
        </Grid>
        {hasNotRun ? (
          <Grid item xs={12}>
            <span className={classes.error}>This job has not run for this process date</span>
          </Grid>
        ) : null}
        {(data.executions || []).map(execution => (
          <Grid item xs={12} key={`execution_${execution.jobExecutionId}`}>
            <table className={classes.detailTable}>
              <tbody>
                {execution.checks.map(check => (
                  <tr key={`check_${check.checkId}`}>
                    <td><CodeTag>Check</CodeTag></td>
                    <td>{check.name}</td>
                    <td>{check.clear ? <Icon name='check' /> : <Icon name='close' />}</td>
                  </tr>
                ))}
                <tr>
                  <td><CodeTag>Job</CodeTag></td>
                  <td>Started</td>
                  <td>{formatter(execution.startedAt, 'timestamp')}</td>
                </tr>
                <tr>
                  <td><CodeTag>Job</CodeTag></td>
                  <td>Finished</td>
                  <td>{formatter(execution.finishedAt, 'timestamp')}</td>
                </tr>
                {execution.stats.map(stat => (
                  <tr key={`check_${stat.statId}`}>
                    <td><CodeTag>Result</CodeTag></td>
                    <td>{stat.name}</td>
                    <td>{formatter(stat.statValue, 'number')}</td>
                    <td>{stat.statUnits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        ))}
      </Grid>
    </FadeIn>
  )
}

JobExecutionDetails.propTypes = {
  job: PropTypes.shape({
    jobId: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    ordinal: PropTypes.number
  }),
  processDate: PropTypes.any
}

export default JobExecutionDetails
