import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useSearchIntegrationJobs } from '../../../../../api/integrations'
import Loading from '../../../../molecules/Loading'
import QueryTable from './QueryTable'

function QueryHome () {
  const { jobConfigurationId } = useParams()
  const query = useMemo(() => {
    return {
      filters: {
        jobConfigurationId
      },
      take: 1
    }
  }, [jobConfigurationId])
  const { data, isLoading } = useSearchIntegrationJobs(query)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  const job = data?.data?.length ? data?.data?.at(0) : null

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <QueryTable job={job} />
      </Grid>
    </Grid>
  )
}

export default QueryHome
