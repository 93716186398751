export const defaultFormattingConfiguration = {
  human: '0.0a',
  default: null,
  title: null,
  marketValue: '(0,0)',
  marketValueCents: '(0,0.[00])',
  money: '(0,0)',
  number: '0,0',
  returns: '(0,0.0)%',
  percentage: '(0,0.0)%',
  allocation: '0%',
  date: '@M/D/YYYY',
  tradeDate: '@M/D/YYYY',
  timestamp: '&YYYY-MM-DD hh:mm:ss A',
  timestampLocal: '@YYYY-MM-DD hh:mm:ss A',
  multiple: '0.0x',
  currency: '$(0,0)',
  localDateTime: '&M/D/YYYY hh:mm:ss a',
  options: {
    dashNulls: true,
    dashEmptyString: true,
    dashZero: false
  }
}
