import React from 'react'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'

function RequestValidationErrors ({ requestValidation }) {
  return (
    <Grid container spacing={1}>
      {requestValidation?.isValid === false ? (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {requestValidation.errors.map(v => (
              <Grid item xs={12} key={`ve_${v}`}>
                <Alert severity='error'>{v}</Alert>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

RequestValidationErrors.propTypes = {
  requestValidation: PropTypes.array
}

export default RequestValidationErrors
