import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFirmReportData } from '../../../api/coreData'
import PresentationTable from '../PresentationTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useHideIfNoDataWhen } from '../HideIfNoData'

export const useColumns = (columns) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const defaultSort = []
    const templatedColumns = columns.map((column) => {
      if (column?.Cell) return column

      return {
        ...column,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [formatter, columns])
}

function DenaliTable ({
  reportId,
  take,
  columnConfig,
  select,
  groupBy,
  sortBy,
  pivot,
  baseFilter,
  defaultFilter
}) {
  const query = useMemo(() => {
    return {
      reportId,
      take,
      filters: defaultFilter || {},
      baseFilter,
      select,
      groupBy,
      sortBy,
      pivot
    }
  }, [reportId, take, sortBy, defaultFilter, select, groupBy, baseFilter, pivot])
  const { data, isLoading } = useFirmReportData(query)
  const { columns, defaultSort } = useColumns(columnConfig)
  useHideIfNoDataWhen(() => !isLoading && !data?.data?.length, [data, isLoading])

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        loading={isLoading}
        variant='bordered'
        data={data?.data || []}
        columns={columns}
        defaultSort={defaultSort}
      />
    </PresentationTable.Wrapper>
  )
}

DenaliTable.propTypes = {
  reportId: PropTypes.string,
  take: PropTypes.number,
  columnConfig: PropTypes.array,
  defaultFilter: PropTypes.object,
  baseFilter: PropTypes.string,
  select: PropTypes.array,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sortBy: PropTypes.shape({
    field: PropTypes.string,
    dir: PropTypes.oneOf(['asc', 'desc'])
  }),
  pivot: PropTypes.string
}

DenaliTable.defaultProps = {
  columnConfig: []
}

export default DenaliTable
