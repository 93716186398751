import clsx from 'clsx'
import React from 'react'
import { alpha, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydButton from '../../../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  templateContent: {
    flex: '0 0',
    border: '2px solid #aaa',
    background: '#eeeeee',
    padding: '1rem',
    minWidth: 'fit-content',
    position: 'relative',
    zIndex: 10,
    borderRadius: '4px'
  },
  templateContentInner: {
    position: 'relative',
    width: '600px',
    height: '800px',
    margin: '0 auto'
  },
  gridDisplay: {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'repeat(16, 1fr)',
    gap: '1px',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,.25)',
    opacity: 0.25,
    pointerEvents: 'none'
  },
  gridDisplayContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'repeat(16, 1fr)',
    display: 'grid',
    zIndex: 10,
    gap: '1px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  },
  blockContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 1rem'
  },
  hiddenInHtmlText: {
    display: 'block',
    fontSize: '.4em',
    lineHeight: '1em',
    backgroundColor: theme.palette.gray.dark,
    color: theme.palette.text.disabled,
    borderRadius: '.25em',
    padding: '.3em 1em',
    width: 'fit-content',
    margin: '0 auto'
  },
  gridDisplayContentItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    border: '8px solid rgba(0, 0, 0, 0.35)',
    cursor: 'pointer',
    opacity: 0.75,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4px',
    transitionDuration: '.125s',
    '&.selected': {
      opacity: 0.9,
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      borderColor: theme.palette.primary.main
    },
    '&.emptyBlock': {
      borderStyle: 'dashed'
    }
  },
  blockContentInner: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '.25rem',
    lineHeight: '1em',
    overflow: 'hidden'
  },
  blockContentName: {
    width: '100%',
    lineHeight: '1.4em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  deleteButton: {
    position: 'absolute',
    top: '5px',
    left: '5px'
  }
}))

export const TemplatePageDisplay = ({
  content,
  templateContent,
  onSetContent,
  selectedBlockIndex,
  onSetSelectedBlockIndex
}) => {
  const classes = useStyles()

  const removeBlockIndex = (blockIndex) => {
    const newContent = {
      ...templateContent,
      pages: templateContent?.pages?.map(page => {
        const newBlocks = page.blocks?.filter((_, i) => i !== blockIndex)
        return { ...page, blocks: newBlocks }
      })
    }
    onSetContent?.(newContent)
  }

  return (
    <div className={classes.templateContent}>
      <div className={classes.templateContentInner}>
        <div className={classes.gridDisplay}>
          {Array.from({ length: 192 }).map((_, i) => (
            <div key={i} style={{ backgroundColor: '#eee' }} />
          ))}
        </div>
        <div className={classes.gridDisplayContent}>
          {content?.map((block, i) => {
            return (
              <div
                key={i}
                style={{
                  gridColumn: `${block?.cords?.x + 1} / span ${block.size?.cols}`,
                  gridRow: `${block.cords?.y + 1} / span ${block.size?.rows}`
                }}
                className={clsx(
                  classes.gridDisplayContentItem,
                  selectedBlockIndex === i ? 'selected' : '',
                  block.isEmptyBlock ? 'emptyBlock' : ''
                )}
                onClick={() => onSetSelectedBlockIndex(i)}
              >
                {!block.isEmptyBlock && (
                  <SydButton
                    size='xs'
                    variant='primary'
                    priority='warning'
                    onClick={(e) => {
                      e.stopPropagation()
                      removeBlockIndex(i)
                    }}
                    type='button'
                    className={classes.deleteButton}
                  >
                    Delete
                  </SydButton>
                )}

                <div className={classes.blockContent}>
                  <div className={classes.blockContentInner}>
                    <span
                      className={classes.blockContentName}
                      style={{ fontSize: Math.min(Math.max(18, block.size.cols * 5), 40) + 'px' }}
                    >
                      {block.infographicBlock?.name}
                    </span>
                    {block.hideInHtml && (
                      <div
                        className={classes.hiddenInHtmlText}
                      >
                        (Hidden in HTML)
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

TemplatePageDisplay.propTypes = {
  content: PropTypes.array,
  templateContent: PropTypes.object,
  onSetContent: PropTypes.func,
  selectedBlockIndex: PropTypes.number,
  onSetSelectedBlockIndex: PropTypes.func
}
