import { substituteValues } from './substituteValues'

const datasetTypes = [
  'balanceColumns',
  'performanceColumns',
  'projectedIncome',
  'ugl',
  'rgl',
  'fi',
  'alts',
  'avt',
  'ta'
]

export function validateTableDataRequest (editorRef, variables) {
  const errors = []
  const value = editorRef.current.editor.getValue()
  let request = null
  try {
    request = JSON.parse(value)
  } catch (error) {
    errors.push('Request not parseable')
    return {
      isValid: false,
      errors
    }
  }

  const parsedRequest = substituteValues(request, variables)

  if (!parsedRequest) {
    errors.push('Request is required')
  } else {
    if (typeof parsedRequest.levelFilters !== 'object') {
      errors.push('"levelFilters" - must be defined')
    } else {
      if (!parsedRequest.levelFilters.levelTypes?.length) {
        errors.push('"levelFilters.levelTypes" - must have at least one level type')
      }
    }

    if (typeof parsedRequest.tableDateRanges !== 'object') {
      errors.push('"tableDateRanges" - must be defined')
    } else {
      const tableDateRanges = Object.entries(parsedRequest.tableDateRanges)

      tableDateRanges.forEach(([datasetType, values], idx) => {
        const sub = `tableDateRanges.${datasetType}`
        if (!datasetTypes.includes(datasetType)) {
          errors.push(`"${sub}" - not a valid dataset type. Must be one of ${datasetTypes.map(ds => `"${ds}"`).join(', ')}`)
        }
        if (!values) {
          errors.push(`"${sub}" - must be defined`)
        }
        if (typeof values !== 'object') {
          errors.push(`"${sub}" - must be an object "{}"`)
        }

        Object.entries(values).forEach(([dsn, dsv]) => {
          const dsub = `${sub}.${dsn}`
          if (!dsv) {
            errors.push(`"${dsub}" - must be defined`)
          }
          if (typeof dsv !== 'object') {
            errors.push(`"${dsub}" - must be an object "{}"`)
          }
          if (typeof dsv.startDate !== 'string') {
            errors.push(`"${dsub}.startDate" - must be defined and a string ("")`)
          }
          if (typeof dsv.endDate !== 'string') {
            errors.push(`"${dsub}.endDate" - must be defined and a string ("")`)
          }
        })
      })

      // count up all the tableDateRanges dataset names
      const datasetNames = tableDateRanges.reduce((p, [_, v]) => {
        if (v && typeof v === 'object') {
          Object.keys(v).forEach(k => {
            p[k] = (p[k] || 0) + 1
          })
        }
        return p
      }, {})
      Object.entries(datasetNames).forEach(([name, count]) => {
        if (count > 1) {
          errors.push(`the dataset named "${name}" is defined more than once`)
        }
      })
    }
  }

  return {
    isValid: errors.length === 0,
    errors,
    request,
    parsedRequest
  }
}
