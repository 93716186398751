import React from 'react'
import { SecureRoute } from '@okta/okta-react'
import { Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import Authenticate from './Schwab/Authenticate'

// const useStyles = makeStyles(() => ({
//   container: {
//     paddingTop: '1rem'
//   }
// }))

const Schwab = (prop) => {
  // const code = useSearchParam('code')
  // const state = useSearchParam('state')
  //
  // const classes = useStyles()
  const { path } = useRouteMatch()
  return (
    <ErrorBoundary name='Schwab Partner'>
      <Switch>
        <SecureRoute exact path={`${path}/`}>
          <Authenticate />
        </SecureRoute>
      </Switch>
    </ErrorBoundary>
  )
}

export default Schwab
