import React from 'react'
import PropTypes from 'prop-types'
import { BasicTooltip } from '@nivo/tooltip'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'

function SimpleGaugeTooltip ({ datum }) {
  const { formatter } = useFormattingContext()

  return (
    <BasicTooltip
      id={datum.id}
      enableChip
      color={datum.color}
      value={formatter(datum.formattedValue, datum.data?.format || 'percentage')}
    />
  )
}

SimpleGaugeTooltip.propTypes = {
  datum: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    data: PropTypes.shape({
      format: PropTypes.string
    })
  })
}

export default SimpleGaugeTooltip
