import React from 'react'
import { useInfographicTemplateColumnConfig } from '../hooks/useInfographicTemplateColumnConfig'
import { useOperationalTable } from '../../../../../organisms/OperationalTable'
import { useListInfographicTemplates } from '../../hooks/useListInfographicTemplates'
import { useCheckPolicy } from '../../../../../../hooks'
import PresentationTable from '../../../../../organisms/PresentationTable'

const InfographicTemplatesTable = () => {
  const userIsInternal = useCheckPolicy('internalOnly')

  const onRefetch = async () => {
    await refetch()
  }

  const columnConfig = useInfographicTemplateColumnConfig({
    onRefetch,
    userIsInternal
  })

  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange
  } = useOperationalTable(columnConfig)

  const { data, isLoading, isFetching, refetch } = useListInfographicTemplates({
    limit: pageSize,
    offset: pageIndex * pageSize,
    sort,
    includes: {
      levelTypes: true
    }
  })

  return (
    <>
      <PresentationTable.Wrapper>
        <PresentationTable
          showTotalItemsLabel={false}
          autoSticky
          mode='server'
          columns={columnConfig.columns}
          data={data || []}
          defaultPageSize={100}
          defaultSort={columnConfig.defaultSort}
          itemName='Reports'
          loading={isLoading || isFetching}
          total={data?.length || 0}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
        />
      </PresentationTable.Wrapper>

    </>
  )
}

export default InfographicTemplatesTable
