import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchIntegrationSources } from '../../../../../api/integrations'
import Loading from '../../../../molecules/Loading'
import EmptySection from '../../../../atoms/EmptySection'
import MiniTable from '../../../admin/shared/MiniTable'

const sourceColumns = [
  { id: 'sourceKey', accessor: 'sourceKey', header: 'Source Key', minWidth: 20, link: (job) => `/admin/denali/integrator/sources/${job.integrationSourceId}` },
  { id: 'label', accessor: 'label', header: 'Label', minWidth: 20 }
]

const keyAccessor = (x) => x?.sourceKey

function JobSources ({ jobConfigurationId }) {
  const query = useMemo(() => {
    return {
      filters: {
        relatedToJob: {
          jobConfigurationId
        }
      }
    }
  }, [jobConfigurationId])
  const { data, isLoading } = useSearchIntegrationSources(query)

  if (isLoading) {
    return <Loading />
  }

  if (!data?.data?.length) {
    return <EmptySection description='No Results' title='' />
  }

  return (
    <MiniTable
      data={data?.data || []}
      columns={sourceColumns}
      keyAccessor={keyAccessor}
    />
  )
}

JobSources.propTypes = {
  jobConfigurationId: PropTypes.number
}

export default JobSources
