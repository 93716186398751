import React from 'react'
import { useParams } from 'react-router-dom'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import FirmReport from '../../../../firmReporting/FirmReport'
import { useSearchParam } from '../../../../../../hooks/useSearchParam'
import AdminPage from '../../../../admin/shared/AdminPage'

function FirmReporting () {
  const { reportId } = useParams()
  const filter = useSearchParam('filter')

  return (
    <AdminPage>
      <FadeIn>
        <FirmReport reportId={reportId} defaultFilter={filter} layout='horizontal' />
      </FadeIn>
    </AdminPage>
  )
}

export default FirmReporting
