import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  postNamedCommand,
  postNamedQuery
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useListIntegrations = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.listIntegrations, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-integrations', {})
      return data
    }
  })
}

export const useGetIntegrationBatchById = (batchId, options = {}) => {
  const includeTasks = options.includeTasks || false
  return useQuery({
    queryKey: [QUERY_KEYS.getIntegrationBatchById, batchId, includeTasks],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'get-integration-batch-by-id', {
        batchId,
        includeTasks
      })
      return data
    }
  })
}

export const useListIntegrationDomains = (query = {}) => {
  const queryKey = query ? JSON.stringify(query) : '{}'
  return useQuery({
    queryKey: [QUERY_KEYS.listIntegrationDomains, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'list-integration-domains', query)
      return data
    }
  })
}

export const useGetIntegrationSourceByKey = (domain, sourceKey) => {
  return useQuery({
    queryKey: [QUERY_KEYS.getIntegrationSourceByKey, domain, sourceKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'get-integration-source-by-key', {
        domain,
        sourceKey
      })
      return data
    }
  })
}

export const useGetIntegrationSourceById = (integrationSourceId) => {
  return useQuery({
    queryKey: [QUERY_KEYS.getIntegrationSourceByKey, integrationSourceId],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'get-integration-source-by-id', {
        integrationSourceId
      })
      return data
    }
  })
}

export const useListIntegrationSources = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.listIntegrationSources],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'list-integration-sources', {})
      return data
    }
  })
}

export const useGetIntegrationTableMetadata = (jobConfigurationId) => {
  return useQuery({
    queryKey: [QUERY_KEYS.getIntegrationTableMetadata, jobConfigurationId],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'get-integration-table-metadata', {
        jobConfigurationId
      })
      return data
    }
  })
}

export const useQueryIntegrationTable = (jobConfigurationId, query) => {
  const queryKey = query ? JSON.stringify(query) : ''
  return useQuery({
    queryKey: [QUERY_KEYS.queryIntegrationTable, jobConfigurationId, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'query-integration-table', {
        jobConfigurationId,
        queryAst: query
      })
      return data
    }
  })
}

export const useSearchIntegrationJobs = (query = {}) => {
  const queryKey = query ? JSON.stringify(query) : ''
  return useQuery({
    queryKey: [QUERY_KEYS.searchIntegrationJobs, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'search-integration-jobs', query)
      return data
    }
  })
}

export const useSearchIntegrationSources = (query = {}, options = {}) => {
  const enabled = options?.enabled ?? true
  const queryKey = query ? JSON.stringify(query) : ''
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchIntegrationSources, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'search-integration-sources', query)
      return data
    }
  })
}

export const useSearchIntegrationBatches = (query = {}, options = {}) => {
  const enabled = options?.enabled ?? true
  const queryKey = query ? JSON.stringify(query) : ''
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchIntegrationBatches, queryKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('integrator', 'search-integration-batches', query)
      return data
    }
  })
}

export const useSyncMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('integrator', 'sync', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchIntegrationBatches], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useScanMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { domain, objects, arguments: args, connectionId } = command
      const { data } = await postNamedCommand('integrator', 'scan', {
        domain,
        objects,
        arguments: args,
        connectionId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchIntegrationBatches], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCheckMigrationsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { domain, objects, connectionId } = command
      const { data } = await postNamedCommand('integrator', 'check-migrations', {
        domain,
        objects,
        connectionId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listIntegrationSources], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyIntegrationSourceKeys = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { integrationSourceId, keys } = command
      const { data } = await postNamedCommand('integrator', 'modify-integration-source-keys', {
        integrationSourceId,
        keys
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getIntegrationSourceByKey], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyIntegrationJobFields = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { jobConfigurationId, fields } = command
      const { data } = await postNamedCommand('integrator', 'modify-integration-job-fields', {
        jobConfigurationId,
        fields
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchIntegrationJobs], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyIntegrationJobStatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('integrator', 'modify-integration-job-status', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchIntegrationJobs], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateIntegrationJob = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('integrator', 'create-integration-job', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchIntegrationJobs], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getIntegrationSourceByKey], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const connections = {
  useListIntegrationConnections: () => {
    return useQuery({
      queryKey: ['integrations/list-integration-connections'],
      queryFn: async () => {
        const { data } = await postNamedQuery('integrator', 'list-integration-connections', {})
        return data
      }
    })
  },

  useIntegrationConnectionTemplate: (domain) => {
    return useQuery({
      enabled: !!domain,
      queryKey: ['integrations/get-integration-connection-template', domain],
      queryFn: async () => {
        const { data } = await postNamedQuery('integrator', 'get-integration-connection-template', {
          domain
        })

        return data
      }
    })
  },

  useCreateIntegrationConnection: () => {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: async (command) => {
        const { data } = await postNamedCommand('integrator', 'create-integration-connection', command)

        return data
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['integrations/list-integration-connections'], refetchType: 'all' }).catch(console.error)
      }
    })
  }
}
