import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  navTile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.layout.gap.g5,
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    padding: theme.layout.padding.p10,
    outline: '1px solid transparent',
    transition: 'outline-color .2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      outlineColor: `color-mix(in srgb, ${theme.palette.primary.main} 80%, transparent)`,
      textDecoration: 'none'
    },
    overflow: 'hidden',
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`
    },
    '&.__label': {
      paddingLeft: theme.layout.padding.p10,
      paddingRight: theme.layout.padding.p10
    }
  }
}))

function NavTile ({ label, menu, selected, onSelect, to }) {
  const classes = useStyles()
  if (to) {
    return (
      <Link to={to} className={clsx(classes.navTile, { __selected: selected })} onClick={onSelect}>
        <div className='__label'>{label}</div>
        {menu || null}
      </Link>
    )
  }

  return (
    <div className={clsx(classes.navTile, { __selected: selected })} onClick={onSelect}>
      <div className='__label'>{label}</div>
      {menu || null}
    </div>
  )
}

NavTile.propTypes = {
  label: PropTypes.string,
  menu: PropTypes.node,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  to: PropTypes.string
}

export default NavTile
