import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Box } from '@material-ui/core'
import noop from 'lodash/noop'
import { useHistory } from 'react-router-dom'
import Checkbox from '../../../../../molecules/Checkbox'
import SydInput from '../../../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import Loading from '../../../../../molecules/Loading'
import { AutocompleteClientSelect } from '../../components/AutocompleteClientSelect'
import { useListInfographicTemplates } from '../../hooks/useListInfographicTemplates'
import SydSelect from '../../../../../commonDesign/SydSelect'
import SydButton from '../../../../../commonDesign/Button'
import { useCheckPolicy } from '../../../../../../hooks'
import {
  useSaveInfographicTemplateMutation
} from '../../InfographicTemplatesDetailsView/hooks/useSaveInfographicTemplateMutation'
import { ADMIN_ROUTES } from '../../../../../../constants'

export const CreateInfographicTemplateForm = ({
  onCancel = noop
}) => {
  const userIsInternal = useCheckPolicy('internalOnly')
  const history = useHistory()
  const form = useForm({
    defaultValues: {
      duplicateInfographicTemplateId: null,
      levelTypes: [],
      global: true,
      internal: false,
      ordinal: 99999,
      name: ''
    }
  })

  const templateIsGlobal = form.watch('global')
  const { data: templates, isFetching } = useListInfographicTemplates({
    sort: [{ id: 'ordinal', desc: false }]
  })
  const templateOptions = useMemo(() => {
    if (!templates) return []
    return [
      { value: '0', label: '+ Create new template' },
      ...templates.map(template => ({
        value: template.infographicTemplateId,
        label: template.name
      }))
    ]
  }, [templates])

  const { mutateAsync: saveMutateAsync, isLoading: isLoadingSave } = useSaveInfographicTemplateMutation()
  const onSubmit = async (data) => {
    const saveResponse = await saveMutateAsync({
      ...data,
      ordinal: Math.min(data.ordinal, 99999)
    })
    try {
      const body = JSON.parse(saveResponse.body)
      if (body?.infographicTemplateId) {
        history.push(`${ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE}/${body.infographicTemplateId}`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
      <Box display='flex' flexDirection='column' gridGap='10px'>
        {isFetching ? (
          <Box flex={1} alignItems='center' justifyContent='center'>
            <Loading />
          </Box>
        ) : (
          <>
            <Controller
              control={form.control}
              name='duplicateInfographicTemplateId'
              render={({ field, fieldState }) => (
                <SydLabel label='Duplicate from existing template' error={hookFormErrorAdapter(form, fieldState)} required>
                  <SydSelect
                    options={templateOptions}
                    size='sm'
                    {...field}
                    minWidth='200px'
                  />
                </SydLabel>
              )}
            />

            <Controller
              control={form.control}
              name='name'
              rules={{ required: { value: true, message: 'Name required' } }}
              render={({ field, fieldState }) => (
                <SydLabel label='Template Name' error={hookFormErrorAdapter(form, fieldState)} required>
                  <SydInput
                    size='sm'
                    {...field}
                  />
                </SydLabel>
              )}
            />

            <Box display='flex' gridGap='8px' flexWrap='wrap' justifyContent='space-between'>
              <Controller
                control={form.control}
                name='global'
                render={({ field, fieldState }) => (
                  <Box display='flex' gridGap={4} alignItems='center'>
                    <Checkbox
                      checked={!!field.value}
                      onChange={(e, checked) => field.onChange(checked)}
                    />
                    <Box onClick={() => field.onChange(!field.value)}>
                      <SydLabel
                        label='Is Global template'
                        description='Template will be available to all clients'
                      />
                    </Box>
                  </Box>
                )}
              />

              <Controller
                control={form.control}
                name='ordinal'
                render={({ field, fieldState }) => (
                  <SydLabel label='Ordinal' error={hookFormErrorAdapter(form, fieldState)} required>
                    <SydInput
                      size='sm'
                      type='number'
                      {...field}
                      defaultValue={99999}
                      inputProps={{ min: 1, max: 99999 }}
                    />
                  </SydLabel>
                )}
              />
            </Box>

            {!templateIsGlobal && (
              <Box>
                <Controller
                  control={form.control}
                  name='levelTypes'
                  render={({ field, fieldState }) => (
                    <SydLabel label='Limited to Clients' error={fieldState?.error?.message}>
                      <AutocompleteClientSelect field={field} multiple />
                    </SydLabel>
                  )}
                />
              </Box>
            )}

            {userIsInternal && (
              <Controller
                control={form.control}
                name='internal'
                render={({ field, fieldState }) => (
                  <Box display='flex' gridGap={4} mt={2}>
                    <Checkbox
                      checked={!!field.value}
                      onChange={(e, checked) => field.onChange(checked)}
                    />
                    <Box onClick={() => field.onChange(!field.value)}>
                      <SydLabel
                        label='Internal Only?'
                        description='Will only be visible to internal users'
                      />
                    </Box>
                  </Box>
                )}
              />
            )}

            <Box display='flex' justifyContent='space-between' mt={2}>
              <SydButton
                type='button'
                variant='outline'
                onClick={() => onCancel()}
              >
                Cancel
              </SydButton>

              <SydButton
                type='submit'
                variant='primary'
                processing={isLoadingSave}
              >
                Save
              </SydButton>
            </Box>
          </>
        )}
      </Box>
    </form>
  )
}

CreateInfographicTemplateForm.propTypes = {
  onCancel: PropTypes.func
}
