export const exportFormatTypes = [
  { key: 'csv', label: 'Comma Separated (.csv)', value: 'csv' },
  { key: 'json', label: 'JSON', value: 'json' },
  { key: 'xml', label: 'XML', value: 'xml' },
  { key: '13f.xml', label: 'SEC 13F Compatible XML', value: '13f.xml' },
  { key: 'psv', label: 'Pipe-Separated', value: '.psv' }
]

export function mapFormatLabel (value) {
  switch (value.toLowerCase()) {
    case 'csv':
      return 'Comma Separated (.csv)'
    case 'json':
      return 'JSON'
    case 'xml':
      return 'XML'
    case '13f.xml':
      return 'SEC 13F Compatible XML'
    case 'psv':
      return 'Pipe-Separated (.psv)'
    default:
      return value.toLowerCase()
  }
}
