import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { decipherError } from '../../../../../../utils/decipherError'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { systemJobs } from '../../../../../../api/ingestion'

const useSubmitter = (form, onComplete) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { handleSubmit } = form
  const { mutateAsync: createSystemJobGroup } = systemJobs.useCreateSystemJobGroup()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      code: formData.code,
      name: formData.name
    }

    try {
      setError(null)
      setProcessing(true)

      const result = await createSystemJobGroup(command)
      if (result?.statusCode === 500) {
        setError('Something unexpected happened while creating the integration connection')
        return
      }
      onComplete(result)
    } catch (err) {
      setError(decipherError(err))
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, onComplete, setError, createSystemJobGroup])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function CreateJobGroupForm ({ onCancel, onComplete }) {
  const form = useForm({
    defaultValues: {
      code: '',
      name: ''
    },
    mode: 'onChange'
  })
  const { submitter, processing, error } = useSubmitter(form, onComplete)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          name='code'
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 32, message: 'Max 32 Characters' },
            pattern: { value: /^[a-z][a-z0-9_]{0,31}$/, message: 'Only lowercase characters, numbers and _ allowed' }
          }}
          render={(f) => (
            <SydLabel required label='Code' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          name='name'
          rules={{
            required: { value: true, message: 'Required' },
            maxLength: { value: 256, message: 'Max 256 Characters' }
          }}
          render={(f) => (
            <SydLabel required label='Name' error={hookFormErrorAdapter(form, f.fieldState)}>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? <div className='__error'>{error}</div> : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Create
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

CreateJobGroupForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateJobGroupForm
